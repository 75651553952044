import React, { ComponentType, useState } from 'react';
import { useTypedTranslation } from 'translations';
import { Backdrop, CircularProgress, Grid, Theme, Typography, useMediaQuery } from '@mui/material';
import { Filter } from 'components/Input/Filter';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators, UserState } from 'store/User';
import { useDashboardFilters } from 'pages/dashboard/components/DashboardFilters';
import { useAfterMountEffect } from 'helpers/hooks';
import {
    DashboardMetricByStore,
    DashboardMetricGroup,
    DashboardTableMetric,
    GetDashboard,
    GetMetricByStore,
    GetTableMetric,
    MetricType,
} from '../../autogen/swagger/Dashboard';
import MetricSwitch from './components/MetricSwitch';
import DefaultModal from '../../components/modal/DefaultModal';
import ListMetricDetails from './components/ListMetricDetails';
import TableMetricDetails from './components/TableMetricDetails';
import ExportSpinnerMenu from './components/ExportSpinnerMenu';

type IDashboardPageProps = typeof actionCreators & UserState;

export function Dashboard(props: IDashboardPageProps): JSX.Element {
    const { selectedStoreId, role } = props;
    const { t } = useTypedTranslation();
    const [filterValues, filters, onFilterChange, getRequestBody] = useDashboardFilters(role, selectedStoreId);
    const [loading, setLoading] = useState(false);
    const [dashboardData, setDashboardData] = useState<DashboardMetricGroup[]>([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [dashboardMetricModalState, setDashboardMetricModalState] = useState<{
        open: boolean;
        detailedDashboardMetric: DashboardMetricByStore | DashboardTableMetric;
        type: 'DashboardMetricByStore' | 'DashboardTableMetric';
        valueSuffix?: string;
    }>({
        open: false,
        detailedDashboardMetric: { metricType: 'Empty', values: [] },
        type: 'DashboardMetricByStore',
    });

    useAfterMountEffect(() => {
        (async (): Promise<void> => {
            try {
                if (filterValues.toDate.isSameOrBefore(filterValues.fromDate)) {
                    setErrorMessage(t('Dashboard', 'ErrorToBeforeFrom'));
                    setDashboardData([]);
                } else {
                    setLoading(true);
                    setErrorMessage('');
                    const result = await GetDashboard(getRequestBody());
                    setDashboardData(result);
                }
            } finally {
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues]);

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    async function openDashboardMetricModal(
        type: MetricType,
        valueSuffix?: string,
        isByStoreMetric = true,
    ): Promise<void> {
        try {
            setLoading(true);

            const metrics = isByStoreMetric
                ? await GetMetricByStore({ metricType: type }, getRequestBody())
                : await GetTableMetric({ metricType: type }, getRequestBody());

            const modalType = isByStoreMetric ? 'DashboardMetricByStore' : 'DashboardTableMetric';

            if (metrics.metricType !== 'Empty') {
                setDashboardMetricModalState((prev) => ({
                    ...prev,
                    open: true,
                    detailedDashboardMetric: metrics,
                    type: modalType,
                    valueSuffix,
                }));
            }
        } finally {
            setLoading(false);
        }
    }

    function handleCloseDashboardMetricModal(): void {
        setDashboardMetricModalState((prev) => ({
            ...prev,
            open: false,
            detailedDashboardMetric: { metricType: 'Empty', values: [], valueSuffix: null },
        }));
    }

    return (
        <Grid container direction="column" item xs gap={2}>
            <Grid
                container
                item
                xs
                style={{ flexGrow: 0 }}
                wrap="nowrap"
                alignItems={largeScreen ? 'center' : 'flex-start'}
                direction={largeScreen ? 'row' : 'column'}
            >
                <Grid item xs>
                    <Filter
                        filters={filters}
                        filterValues={filterValues}
                        onChange={onFilterChange}
                        shownFiltersFromStart={10}
                        justify="center"
                    />
                </Grid>
                <Grid item xs="auto">
                    <ExportSpinnerMenu filterRequestBody={getRequestBody()} />
                </Grid>
                <Grid
                    container
                    item
                    xs="auto"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    style={{ width: 'auto' }}
                />
            </Grid>
            {errorMessage && (
                <Grid item>
                    <Grid container justifyContent="center">
                        <Typography variant="h6" color="error">
                            {errorMessage}
                        </Typography>
                    </Grid>
                </Grid>
            )}
            <Grid item xs style={{ position: 'relative' }}>
                <Grid container gap={2}>
                    {dashboardData.map((group) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Grid item key={group.groupType} xs={12}>
                            <Typography variant="h6">{t('Dashboard', 'Group', group.groupType)}</Typography>
                            <Grid container spacing={2}>
                                {group.metrics.map((metric, i) => (
                                    <MetricSwitch
                                        // eslint-disable-next-line react/no-array-index-key
                                        key={metric.metricType + i}
                                        metric={metric}
                                        openDetailsCallback={openDashboardMetricModal}
                                    />
                                ))}
                            </Grid>
                        </Grid>
                    ))}
                </Grid>
                <Backdrop style={{ position: 'absolute', zIndex: 100 }} open={loading}>
                    <CircularProgress />
                </Backdrop>
            </Grid>
            <DefaultModal
                open={dashboardMetricModalState.open}
                onClose={handleCloseDashboardMetricModal}
                closeOnBackdrop
                maxWidth={dashboardMetricModalState.type === 'DashboardMetricByStore' ? 'sm' : 'xl'}
            >
                {dashboardMetricModalState.type === 'DashboardMetricByStore' ? (
                    <ListMetricDetails
                        dashboardMetricByStore={
                            dashboardMetricModalState.detailedDashboardMetric as DashboardMetricByStore
                        }
                        valueSuffix={dashboardMetricModalState.valueSuffix}
                    />
                ) : (
                    <TableMetricDetails
                        dashboardTableMetric={dashboardMetricModalState.detailedDashboardMetric as DashboardTableMetric}
                        filterRequestBody={getRequestBody()}
                    />
                )}
            </DefaultModal>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(Dashboard as ComponentType);
