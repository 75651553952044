import React, { ComponentType } from 'react';
import { Container, Grid, ThemeProvider, Theme, StyledEngineProvider, CircularProgress } from '@mui/material';
import { createGlobalStyle } from 'styled-components';
import { theme } from 'shared/theme';
import { ApplicationState } from 'store';
import { connect } from 'react-redux';
import { actionCreators, UserState } from 'store/User';
import NavMenu from './header/NavMenu';

declare module '@mui/styles/defaultTheme' {
    // eslint-disable-next-line @typescript-eslint/no-empty-interface
    interface DefaultTheme extends Theme {}
}

const GlobalStyle = createGlobalStyle`

    html, body, #root{
        height:100%;
    }

    body{
        margin: 0;
        padding:0;
        background: #F9FAFB;
    }
`;

interface ILayoutProps {
    children?: React.ReactNode;
}

type LayoutPropType = typeof actionCreators & UserState & ILayoutProps;

export function Layout(props: LayoutPropType): JSX.Element {
    const { children, stateLoaded } = props;
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
                    <GlobalStyle />
                    <Grid item xs style={{ flexGrow: 0, zIndex: 50 }}>
                        <NavMenu />
                    </Grid>
                    <Grid item xs style={{ overflow: 'auto' }}>
                        {!stateLoaded ? (
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                style={{
                                    width: '100%',
                                    height: 'calc(100% - 40px)',
                                }}
                            >
                                <CircularProgress />
                            </Grid>
                        ) : (
                            !!children && (
                                <Container
                                    maxWidth="xl"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexGrow: 1,
                                        marginTop: '20px',
                                        marginBottom: '20px',
                                        height: 'calc(100% - 40px)',
                                    }}
                                >
                                    {children}
                                </Container>
                            )
                        )}
                    </Grid>
                </Grid>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(Layout as ComponentType);
