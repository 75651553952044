import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { nameof } from 'ts-simple-nameof';
import { CalendarItemEvent, Consultant, GetConsultant, GetEventTime } from 'autogen/swagger/Consultant';
import { StoreSelectViewModel } from 'autogen/swagger/Users';
import SpinnerButton from 'components/button/SpinnerButton';
import { useTypedTranslation } from 'translations';
import { useValidationState } from 'helpers/validation';
import {
    LookupMember,
    ClubMatasMemberModel,
    EventBookingCreateViewModel,
    CreateEventBooking,
    EventTime,
} from 'autogen/swagger/Booking';
import { formatConsultationProductName } from 'helpers/string';
import StoreAndConsultantText from '../StoreAndConsultantText';

function getEmptyBookingValues(eventTimeId: number): EventBookingCreateViewModel {
    return {
        eventTimeId,
        clubMatasMemberId: '',
        spacesBooked: 1,
        firstName: '',
        lastName: '',
    };
}

interface ICreateEventBookingProps {
    consultant?: Consultant;
    onComplete?: (update: boolean) => void;
    eventTime: CalendarItemEvent;
    selectableStores?: StoreSelectViewModel[];
}

export default function CreateEventBookingComponent(props: ICreateEventBookingProps): JSX.Element {
    const { consultant: initialConsultant, onComplete, eventTime: eventTimeItem, selectableStores } = props;
    const { t } = useTypedTranslation();
    const [consultant, setConsultant] = useState(initialConsultant);
    const eventTimeId = eventTimeItem.id;

    const [eventBookingValues, setEventBookingValues] = useState<EventBookingCreateViewModel>(
        getEmptyBookingValues(eventTimeId),
    );

    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const [saving, setSaving] = useState(false);
    const [searchMobile, setSearchMobile] = useState('');
    const [member, setMember] = useState<ClubMatasMemberModel>();
    const [searchingMember, setSearchingMember] = useState(false);
    const [eventTime, setEventTime] = useState<EventTime>();

    useEffect(() => {
        if (eventTimeId) {
            (async (): Promise<void> => {
                const result = await GetEventTime({ id: eventTimeId });
                setEventTime(result);
                setEventBookingValues((prev) => ({ ...prev, ticketPrice: result.ticketPrice }));
            })();
        }
    }, [eventTimeId]);

    useEffect(() => {
        if (eventTime && !consultant) {
            (async (): Promise<void> => {
                const con = await GetConsultant({ consultantId: eventTime.consultantId });
                setConsultant(con);
            })();
        }
    }, [consultant, eventTime]);

    useEffect(() => {
        if (!member) {
            setEventBookingValues((prev) => ({
                ...prev,
                clubMatasMemberId: '',
                firstName: '',
                lastName: '',
            }));
        } else {
            setEventBookingValues((prev) => ({
                ...prev,
                clubMatasMemberId: member.clubMatasMemberId,
                ...(member.firstName ? { firstName: member.firstName } : {}),
                ...(member.lastName ? { lastName: member.lastName } : {}),
            }));
        }
    }, [member]);

    async function searchMember() {
        if (!searchMobile) {
            setMember(undefined);
            return;
        }
        setSearchingMember(true);
        try {
            const number = searchMobile.replace(/ /g, '');
            const result = await LookupMember({ mobileNumber: number });
            if (result.clubMatasMemberId) {
                setMember(result);
            } else {
                setMember(undefined);
            }
        } catch {
            setMember(undefined);
        } finally {
            setSearchingMember(false);
        }
    }

    useEffect(() => {
        if (searchMobile && /^([0-9]{2}[ ]?){4}$/.test(searchMobile)) {
            (async (): Promise<void> => {
                await searchMember();
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchMobile]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            setValidationErrors(undefined);

            const result = await CreateEventBooking(eventBookingValues);

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            let isShowableErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isShowableErrors)
                isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    if (!eventTime) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid item xs>
            <Grid container direction="column" justifyContent="flex-start" gap={2}>
                <Grid item>
                    <Grid container>
                        <Grid item xs>
                            <Typography variant="h4">{t('Booking', 'CreateTitle')}</Typography>
                        </Grid>
                        <Grid item xs>
                            <StoreAndConsultantText
                                consultant={consultant}
                                storeId={eventTime.storeId}
                                selectableStores={selectableStores}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={3} alignItems="center" style={{ justifyContent: 'center' }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item sm={6}>
                                    <Typography fontWeight="bold" display="inline" marginRight={1}>
                                        {t('EventBooking', 'Event')}:
                                    </Typography>
                                    <Typography display="inline">
                                        {moment(eventTime.activeTime.activeFromDate).format('DD-MM-yyyy HH:mm')}
                                    </Typography>
                                </Grid>
                                <Grid item sm={6}>
                                    <Typography fontWeight="bold" display="inline" marginRight={1}>
                                        {t('EventBooking', 'ConsultationProduct')}:
                                    </Typography>
                                    <Typography display="inline">
                                        {formatConsultationProductName(
                                            eventTimeItem.consultationProductName,
                                            eventTimeItem.externalPartnerName,
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                label={t('EventBooking', 'SpacesBooked')}
                                type="number"
                                fullWidth
                                value={eventBookingValues.spacesBooked !== 0 ? eventBookingValues.spacesBooked : ''}
                                variant="outlined"
                                inputProps={{
                                    min: 1,
                                    ...(eventTime.maxSpacesPerBooking ? { max: eventTime.maxSpacesPerBooking } : {}),
                                }}
                                error={isError(nameof<EventBookingCreateViewModel>((x) => x.spacesBooked))}
                                helperText={getErrorText(nameof<EventBookingCreateViewModel>((x) => x.spacesBooked))}
                                onChange={(e): void =>
                                    setEventBookingValues((prev) => ({
                                        ...prev,
                                        spacesBooked: e.target.value ? parseInt(e.target.value) : 0,
                                    }))
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={6}>
                            <TextField
                                label={t('EventBooking', 'TicketPrice')}
                                type="number"
                                fullWidth
                                value={eventBookingValues.ticketPrice !== 0 ? eventBookingValues.ticketPrice : ''}
                                variant="outlined"
                                error={isError(nameof<EventBookingCreateViewModel>((x) => x.ticketPrice))}
                                helperText={getErrorText(nameof<EventBookingCreateViewModel>((x) => x.ticketPrice))}
                                onChange={(e): void =>
                                    setEventBookingValues((prev) => ({
                                        ...prev,
                                        ticketPrice: e.target.value ? parseInt(e.target.value) : 0,
                                    }))
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item sm={9}>
                                    <TextField
                                        label={t('EventBooking', 'SearchMember')}
                                        type="text"
                                        fullWidth
                                        value={searchMobile}
                                        variant="outlined"
                                        onChange={(e): void => setSearchMobile(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <SpinnerButton
                                        onClick={searchMember}
                                        loading={searchingMember}
                                        size="large"
                                        fullWidth
                                    >
                                        {t('Shared', 'Search')}
                                    </SpinnerButton>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item sm={6}>
                                            <TextField
                                                label={t('EventBooking', 'FirstName')}
                                                type="text"
                                                fullWidth
                                                value={eventBookingValues.firstName}
                                                variant="outlined"
                                                error={isError(nameof<EventBookingCreateViewModel>((x) => x.firstName))}
                                                helperText={getErrorText(
                                                    nameof<EventBookingCreateViewModel>((x) => x.firstName),
                                                )}
                                                onChange={(e): void =>
                                                    setEventBookingValues((prev) => ({
                                                        ...prev,
                                                        firstName: e.target.value,
                                                    }))
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField
                                                label={t('EventBooking', 'LastName')}
                                                type="text"
                                                fullWidth
                                                value={eventBookingValues.lastName}
                                                variant="outlined"
                                                error={isError(nameof<EventBookingCreateViewModel>((x) => x.lastName))}
                                                helperText={getErrorText(
                                                    nameof<EventBookingCreateViewModel>((x) => x.lastName),
                                                )}
                                                onChange={(e): void =>
                                                    setEventBookingValues((prev) => ({
                                                        ...prev,
                                                        lastName: e.target.value,
                                                    }))
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField
                                        label={t('EventBooking', 'ClubmatasMemberId')}
                                        type="number"
                                        fullWidth
                                        value={eventBookingValues.clubMatasMemberId}
                                        variant="outlined"
                                        error={isError(nameof<EventBookingCreateViewModel>((x) => x.clubMatasMemberId))}
                                        helperText={getErrorText(
                                            nameof<EventBookingCreateViewModel>((x) => x.clubMatasMemberId),
                                        )}
                                        onChange={(e): void =>
                                            setEventBookingValues((prev) => ({
                                                ...prev,
                                                clubMatasMemberId: e.target.value,
                                            }))
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained" onClick={onCancelClick}>
                            {t('Shared', 'Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <SpinnerButton loading={saving} variant="contained" color="primary" onClick={save}>
                            {t('EventBooking', 'Create')}
                        </SpinnerButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
