/* eslint-disable @typescript-eslint/ban-types */
import { showMessageFromErrorModel } from 'components/messages/Message';
import { login, getAccessToken } from 'shared/login';

const defaultHeaders = {
    'X-Requested-With': 'XMLHttpRequest',
};

async function options({ method = 'GET', headers = {}, requiresAuthentication = true }): Promise<RequestInit> {
    let accessToken: string | undefined;

    if (requiresAuthentication) {
        accessToken = await getAccessToken();

        if (!accessToken) {
            await login();
            throw new Error('User is not logged in');
        }
    }

    return {
        method,
        headers: {
            ...defaultHeaders,
            ...headers,
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
        },
        credentials: 'same-origin',
    };
}

function getJSONOptions(requiresAuthentication = true): Promise<RequestInit> {
    return options({
        requiresAuthentication,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
}

function postJSONOptions(requiresAuthentication = true): Promise<RequestInit> {
    return options({
        method: 'POST',
        requiresAuthentication,
        headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        },
    });
}

function handleError(res: Response): void {
    if (!res.ok) {
        if (res.status === 401) {
            login();
        } else if (res.status === 422) {
            throw res.json();
        } else if (res.status === 409) {
            throw new Promise((resolve) => {
                showMessageFromErrorModel(res.json()).then((messageShown: boolean) => {
                    resolve({ messageShown });
                });
            });
        } else {
            throw new Error(`Failed to fetch JSON content from: ${res.url}`);
        }
    }
}

export async function fetchJSON<T>(
    url: string,
    customOptions: RequestInit = {},
    requiresAuthentication = true,
): Promise<T> {
    const opt = await getJSONOptions(requiresAuthentication);

    const res = await window.fetch(url, { ...opt, ...customOptions });
    handleError(res);

    return res.json();
}

export function fetchWithTimeout<T>(fetchFunc: (url: string, optionsObj: RequestInit) => Promise<T>, timeout: number) {
    return (url: string, optionsObject = {}): Promise<T> => {
        const controller = new window.AbortController();
        const { signal } = controller;
        const promise = fetchFunc(url, { ...optionsObject, signal });
        const timeoutId = setTimeout(() => {
            controller.abort();
        }, timeout);
        promise.then(() => clearTimeout(timeoutId));
        return promise;
    };
}

export function formatParams(
    params: Record<string, Array<number | string | boolean> | number | string | boolean | undefined | null>,
): string {
    return Object.entries(params).reduce((acc, [key, value]) => {
        if (value === undefined || value === null) return acc;
        return `${acc}${!acc ? '?' : '&'}${
            Array.isArray(value)
                ? value.map((val) => `${key}=${encodeURIComponent(val)}`).join('&')
                : `${key}=${encodeURIComponent(value)}`
        }`;
    }, '');
}

export function extractParams(url: string): (string | object)[] {
    const { origin, pathname, searchParams } = new URL(url, window.location.origin);
    const base = `${origin}${pathname}`;
    const params = Object.entries(searchParams).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

    return [base, params];
}

function AddObjectToFormData(formData: FormData, obj: object, prependKey: string): void {
    Object.entries(obj).forEach(([key, val]) => {
        if (Array.isArray(val)) {
            val.forEach((value) => {
                formData.append(`${key}[]`, value);
            });
        } else if (val instanceof File) {
            formData.append(`${prependKey}${key}`, val);
        } else if (typeof val === 'object' && val !== null) {
            AddObjectToFormData(formData, val, `${prependKey}${key}.`);
        } else {
            formData.append(`${prependKey}${key}`, val.toString());
        }
    });
}

export async function postForm<T>(
    url: string,
    form: FormData,
    signal?: AbortSignal,
    requiresAuthentication = true,
): Promise<T> {
    const opt = await options({ method: 'POST', requiresAuthentication, headers: { Accept: 'application/json' } });

    const res = await window.fetch(url, {
        ...opt,
        body: form,
        signal,
    });

    handleError(res);
    return res.json();
}

export function postFormData<T>(
    url: string,
    data: Record<string, object | number | string | boolean | null | undefined>,
    requiresAuthentication = true,
): Promise<T> {
    if (typeof data !== 'object') {
        throw new Error('Data should be an object');
    }
    const formData = new window.FormData();
    AddObjectToFormData(formData, data, '');
    return postForm<T>(url, formData, undefined, requiresAuthentication);
}

export async function postJSON<T>(
    url: string,
    data: Record<string, object | number | string | boolean | null | undefined>,
    requiresAuthentication = true,
): Promise<T> {
    if (typeof data !== 'object') {
        throw new Error('Data should be an object');
    }
    const opt = await postJSONOptions(requiresAuthentication);

    const res = await window.fetch(url, {
        ...opt,
        body: JSON.stringify(data),
    });

    handleError(res);
    return res.json();
}

export async function postQuery<T>(url: string, requiresAuthentication = true): Promise<T> {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();
    const opt = await options({ method: 'POST', requiresAuthentication, headers: { Accept: 'application/json' } });

    const res = await window.fetch(url, opt);

    handleError(res);
    return res.json();
}

export async function deleteQuery<T>(url: string, requiresAuthentication = true): Promise<T> {
    if (typeof url !== 'string' || url.length < 1) return Promise.reject();
    const opt = await options({ method: 'DELETE', requiresAuthentication, headers: { Accept: 'application/json' } });

    const res = await window.fetch(url, opt);
    if (!res.ok) {
        throw new Error(`Failed to DELETE to: ${url}`);
    }

    return res.json();
}

export async function postDownloadFile<T>(
    url: string,
    data: Record<string, object | number | string | boolean | null | undefined>,
    requiresAuthentication = true,
): Promise<T> {
    const opt = await options({
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        requiresAuthentication,
    });
    let filename = '';
    const res = await fetch(url, {
        ...opt,
        body: JSON.stringify(data),
    });

    handleError(res);

    const header = res.headers.get('Content-Disposition');
    const parts = header!.split(';');
    [, filename] = parts[1].split('=');
    const blob = await res.blob();

    const newUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = newUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(newUrl);

    return Promise.resolve(blob as any);
}

export async function downloadFile<T>(url: string, requiresAuthentication = true): Promise<T> {
    const opt = await options({ requiresAuthentication });
    let filename = '';
    const res = await fetch(url, opt);

    const header = res.headers.get('Content-Disposition');
    const parts = header!.split(';');
    [, filename] = parts[1].split('=');
    const blob = await res.blob();

    const newUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = newUrl;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(newUrl);

    return Promise.resolve(blob as any);
}
