import React, { useState, ComponentType, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { NavLink, RouteComponentProps, withRouter, useHistory } from 'react-router-dom';
import {
    AppBar,
    Button,
    Drawer,
    IconButton,
    ListItemText,
    MenuItem,
    MenuList,
    Toolbar,
    Typography,
    Grid,
    createTheme,
    ThemeProvider,
    StyledEngineProvider,
    Menu,
    useMediaQuery,
    Theme,
    adaptV4Theme,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Routes, { getRouteUrl, isActiveRoute } from 'shared/routes';
import { breakpoint, sizes } from 'variables';
import { useTypedTranslation } from 'translations';
import { actionCreators, UserState } from 'store/User';
import StoreSelect from 'components/header/StoreSelect';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { IsInRole } from 'helpers/userHelper';
import { theme } from 'shared/theme';
import { getConsultantsUrl } from 'shared/urls';
import { logout } from 'shared/login';

const Wrapper = styled.div`
    flex-grow: 1;
`;

const Title = styled(Typography)`
    flex: 0 1 auto;

    ${breakpoint.up(sizes.md)} {
        flex: 0 0 auto;
    }
`;

const FullList = styled.div`
    width: auto;
`;

const ToolbarStyled = styled(Toolbar)`
    && {
        padding: 0;
    }
`;

const NavBarList = styled(MenuList)`
    && {
        display: flex;
        flex: 1 1 auto;
        margin-left: 15px;

        a {
            color: #111827;
        }

        ${breakpoint.down(sizes.sm)} {
            display: none;
        }
    }
`;

const MenuButton = styled(IconButton)`
    && {
        ${breakpoint.up(sizes.md)} {
            display: none;
        }
    }
`;

const navTheme = createTheme(
    adaptV4Theme({
        ...theme,
        palette: {
            secondary: {
                light: '#ffffff',
                main: '#ffffff',
                dark: '#F9FAFB',
                contrastText: '#111827',
            },
        },
        overrides: {
            MuiMenuItem: {
                root: {
                    borderTop: '0',
                    '&.Mui-selected': {
                        border: '0',
                        borderRadius: '0',
                        borderBottom: '1px solid #2563EB',
                    },
                },
            },
        },
    }),
);

type navMenuProps = typeof actionCreators & UserState & RouteComponentProps;

export function NavMenu(props: navMenuProps): JSX.Element {
    const { location, role, username, requestUserState, selectedStoreId, consultantId } = props;
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTypedTranslation();
    const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement>();
    const openUserMenu = Boolean(anchorEl);
    const history = useHistory();

    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = (): void => {
        setAnchorEl(undefined);
    };

    const toggleDrawer =
        (open: boolean) =>
        (event: React.KeyboardEvent | React.MouseEvent): void => {
            if (
                event.type === 'keydown' &&
                ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')
            ) {
                return;
            }

            setIsOpen(open);
        };

    function onLogoutClick(): void {
        logout();
    }

    function goToProfile(): void {
        history.push(getConsultantsUrl(consultantId));
    }

    const fetchData = useCallback((): void => {
        requestUserState();
    }, [requestUserState]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const menuList = Routes.map((route) => {
        if (route.allowedRoles && !route.allowedRoles.includes(role)) return null;

        if (route.requireStoreId && !selectedStoreId) return null;

        return (
            <NavLink to={getRouteUrl(route)} key={route.path} style={{ textDecoration: 'none' }}>
                <MenuItem selected={isActiveRoute(route, location.pathname)}>
                    <ListItemText primary={t('Menu', 'Links', route.label)} />
                </MenuItem>
            </NavLink>
        );
    });

    const largeScreen = useMediaQuery((th: Theme) => th.breakpoints.up('md'));

    return (
        <div>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={navTheme}>
                    <Wrapper>
                        <AppBar
                            position="static"
                            color="secondary"
                            style={largeScreen ? { padding: '0 20px' } : { padding: '0 10px' }}
                        >
                            <ToolbarStyled>
                                <MenuButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
                                    <MenuIcon />
                                </MenuButton>
                                <Title variant="h6">{t('MatasBooking')}</Title>
                                <NavBarList>{menuList}</NavBarList>
                                <Grid container justifyContent="flex-end">
                                    {largeScreen && IsInRole(role, 'AdminRole', 'StoreManagerRole') && <StoreSelect />}
                                    <div>
                                        <Button style={{ textTransform: 'none' }} onClick={handleUserMenuClick}>
                                            {username}
                                        </Button>
                                        <Menu
                                            id="basic-menu"
                                            anchorEl={anchorEl}
                                            open={openUserMenu}
                                            style={{ marginTop: '40px' }}
                                            onClose={handleUserMenuClose}
                                            MenuListProps={{
                                                'aria-labelledby': 'basic-button',
                                            }}
                                        >
                                            {!!consultantId && <MenuItem onClick={goToProfile}>Profile</MenuItem>}
                                            <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
                                            <MenuItem
                                                component="a"
                                                href={
                                                    IsInRole(role, 'AdminRole', 'StoreManagerRole')
                                                        ? '/resources/manual_20230206.pdf'
                                                        : '/resources/manual_external.pdf'
                                                }
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('Menu', 'Manual')}
                                            </MenuItem>
                                            <MenuItem
                                                component="a"
                                                href="/resources/manual_pos_20230206.pdf"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {t('Menu', 'ManualPos')}
                                            </MenuItem>
                                        </Menu>
                                    </div>
                                </Grid>
                            </ToolbarStyled>
                            {!largeScreen && IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                                <Grid container justifyContent="center" style={{ marginBottom: '5px' }}>
                                    <StoreSelect />
                                </Grid>
                            )}
                        </AppBar>
                    </Wrapper>
                    <Drawer open={isOpen} onClose={toggleDrawer(false)}>
                        <FullList role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
                            <MenuList>{menuList}</MenuList>
                        </FullList>
                    </Drawer>
                </ThemeProvider>
            </StyledEngineProvider>
        </div>
    );
}

export default withRouter(
    connect(
        (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
        actionCreators, // Selects which action creators are merged into the component's props
    )(NavMenu as ComponentType),
);
