import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Typography, TextField } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { Add } from '@mui/icons-material';
import { Filter, IFilter } from 'components/Input/Filter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    ConsultationCategoryDetailed,
    ListCategories,
    ToggleDeletedCategory,
    SetCategorySortOrder,
} from 'autogen/swagger/ConsultationProduct';
import { nameof } from 'ts-simple-nameof';
import { Link } from 'react-router-dom';
import { getCategoriesUrl } from 'shared/urls';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import { useDataGridStyles } from 'shared/theme';

type IConsultationCategoryFilterValues = {
    ShowDeleted: boolean;
};

export function List(): JSX.Element {
    const { t, tf } = useTypedTranslation();
    const [categories, setCategories] = useState<ConsultationCategoryDetailed[]>([]);
    const [filterValues, setFilterValues] = useState<IConsultationCategoryFilterValues>({ ShowDeleted: false });
    const [loading, setLoading] = useState(false);

    const filters: IFilter<IConsultationCategoryFilterValues>[] = [
        {
            label: t('Filter', 'ShowDisabled'),
            paramName: 'ShowDeleted',
            type: 'checkbox',
        },
    ];

    function onFilterChange(values: IConsultationCategoryFilterValues): void {
        setFilterValues(values);
    }

    const loadCategories = useCallback(async () => {
        try {
            setLoading(true);
            const result = await ListCategories({ onlyDeleted: filterValues.ShowDeleted });
            setCategories(result);
        } finally {
            setLoading(false);
        }
    }, [filterValues.ShowDeleted]);

    useEffect(() => {
        loadCategories();
    }, [filterValues, loadCategories]);

    const columns: GridColDef[] = [
        {
            field: nameof<ConsultationCategoryDetailed>((x) => x.name),
            headerName: t('ConsultationCategory', 'Name'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: nameof<ConsultationCategoryDetailed>((x) => x.teaser),
            headerName: t('ConsultationCategory', 'Teaser'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: nameof<ConsultationCategoryDetailed>((x) => x.sortOrder),
            headerName: t('ConsultationCategory', 'SortOrder'),
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const id = params.row?.id as number;
                const sortOrder = params.row?.sortOrder;

                async function updateSortOrder(sort: number): Promise<void> {
                    await SetCategorySortOrder({ categoryId: id, sortOrder: sort });

                    loadCategories();
                }

                return (
                    <TextField
                        type="number"
                        fullWidth
                        variant="outlined"
                        value={sortOrder || ''}
                        onChange={(e): void => {
                            updateSortOrder(parseInt(e.target.value));
                        }}
                    />
                );
            },
        },
        {
            field: 'id',
            headerName: t('Shared', 'Action'),
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const id = params.value as number;
                const isDeleted = params.row?.isDeleted;

                async function toggleDeletedCategory(): Promise<void> {
                    const { name = '' } = categories.find((x) => x.id === id) || {};
                    ConfirmChoice({
                        content: tf(
                            { name },
                            'ConsultationCategory',
                            isDeleted ? 'UnDeleteConfirmText' : 'DeleteConfirmText',
                        ),
                        onConfirm: async () => {
                            const result = await ToggleDeletedCategory({ id, deleted: !isDeleted });
                            if (result.success) {
                                loadCategories();
                            }
                        },
                    });
                }

                return (
                    <>
                        <Button color="primary" component={Link} to={getCategoriesUrl(id)}>
                            {t('Shared', 'Edit')}
                        </Button>
                        <Button color="primary" onClick={toggleDeletedCategory}>
                            {t('Shared', isDeleted ? 'Enable' : 'Disable')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const classes = useDataGridStyles();

    return (
        <Grid container direction="column" item xs gap={2}>
            <Grid container item xs style={{ flexGrow: 0 }}>
                <Grid item xs>
                    <Typography variant="h4">{t('ConsultationCategory', 'Title')}</Typography>
                </Grid>
                <Grid item xs>
                    <Filter filters={filters} filterValues={filterValues} onChange={onFilterChange} />
                </Grid>
                <Grid container item xs justifyContent="flex-end">
                    <Button variant="contained" color="primary" component={Link} to={getCategoriesUrl(0)}>
                        <Add /> {t('Shared', 'CreateNew')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs direction="column">
                <DataGrid columns={columns} rows={categories} loading={loading} className={classes.root} />
            </Grid>
        </Grid>
    );
}

export default List;
