import React from 'react';
import { DashboardMetric, MetricType } from 'autogen/swagger/Dashboard';
import { Card, CardContent, Grid, Typography, Tooltip, CardActionArea } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { HelpOutline } from '@mui/icons-material';

function StandardMetricContent(props: { metric: DashboardMetric; valueSuffix?: string }): JSX.Element {
    const { metric, valueSuffix } = props;
    const { t } = useTypedTranslation();

    return (
        <>
            <Tooltip
                title={t('Dashboard', 'MetricHelpText', metric.metricType)}
                style={{ position: 'absolute', top: '3px', right: '3px', height: '15px', width: '15px' }}
            >
                <HelpOutline />
            </Tooltip>
            <CardContent sx={{ '&:last-child': { pb: '16px' } }}>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="body2">{t('Dashboard', 'Metric', metric.metricType)}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h3">
                            {Math.round((metric.value || 0) * 100) / 100}
                            {valueSuffix || ''}
                        </Typography>
                    </Grid>
                </Grid>
            </CardContent>
        </>
    );
}

interface IStandardMetricProps {
    metric: DashboardMetric;
    valueSuffix?: string;
    openDetailsCallback?: (type: MetricType, valueSuffix?: string, isByStoreMetric?: boolean) => Promise<void>;
}

export function StandardMetric(props: IStandardMetricProps): JSX.Element {
    const { metric, valueSuffix, openDetailsCallback } = props;

    const Comp = <StandardMetricContent metric={metric} valueSuffix={valueSuffix} />;

    return (
        <Card style={{ position: 'relative' }}>
            {openDetailsCallback == null || metric.value === 0 ? (
                Comp
            ) : (
                <CardActionArea
                    onClick={() => (openDetailsCallback ? openDetailsCallback(metric.metricType, valueSuffix) : false)}
                >
                    {Comp}
                </CardActionArea>
            )}
        </Card>
    );
}

export default StandardMetric;
