import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { UserState, actionCreators } from 'store/User';
import { IsInRole } from 'helpers/userHelper';
import { Grid, Typography, Button, useMediaQuery, Theme, ButtonGroup, Drawer } from '@mui/material';
import SelectConsultant from 'components/consultant/SelectConsultant';
import { useTypedTranslation } from 'translations';
import { Filter, IFilter } from 'components/Input/Filter';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import WeekCalendar from 'pages/calendar/components/Layout/WeekCalendar';
import CreateOrEditShift from 'pages/calendar/components/Overlays/CreateOrEditShift';
import {
    ListCalendarItems,
    ConsultantCalendarItem,
    DuplicateShiftsWeekly,
    DuplicateShiftsDaily,
    GetConsultant,
    Consultant,
    CalendarItemShift,
    CalendarItemBooking,
    CalendarItemEvent,
    EventBookingViewModel,
} from 'autogen/swagger/Consultant';
import { DefaultModal } from 'components/modal/DefaultModal';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router';
import { useInterval } from 'helpers/hooks';
import EventTimeBookingsDrawer from 'pages/calendar/components/Overlays/EventTimeBookingsDrawer';
import EditEventBooking from 'pages/calendar/components/Overlays/EditEventBooking';
import ColorLegend, { IColorLegend } from 'components/display/ColorLegend';
import CreateBookingComponent from 'pages/calendar/components/Overlays/CreateBooking';
import CreateEventBookingComponent from 'pages/calendar/components/Overlays/CreateEventBooking';
import EditBooking from './components/Overlays/EditBooking';
import CalendarButtons from './components/CalendarButtons';
import CreateOrEditEventTime from './components/Overlays/CreateOrEditEventTime';

interface ICalendarHistoryValues {
    consultantId?: number;
    startOfSelectedWeek?: string;
    showBookings?: boolean;
    numberOfWeeks?: number;
    autoUpdateSeconds?: number;
}

type ICalendarPageFilterValues = {
    showBookings: boolean;
    numberOfWeeks: number;
    autoUpdateSeconds: number;
};

type ICalendarPageProps = typeof actionCreators & UserState;

export function CalendarPage(props: ICalendarPageProps): JSX.Element {
    const { role, selectedStoreId, selectableStores, consultantId: userStateConsultantId } = props;
    const history = useHistory();
    const historyValues = (history.location.state as ICalendarHistoryValues) || {};
    const { t, tf } = useTypedTranslation();
    const [filterValues, setFilterValues] = useState<ICalendarPageFilterValues>({
        showBookings: historyValues.showBookings === undefined ? true : historyValues.showBookings,
        numberOfWeeks: historyValues.numberOfWeeks || 4,
        autoUpdateSeconds: historyValues.autoUpdateSeconds || 120,
    });
    const [startOfSelectedWeek, setStartOfSelectedWeek] = useState(
        historyValues.startOfSelectedWeek ? moment(historyValues.startOfSelectedWeek) : moment().startOf('isoWeek'),
    );
    const [selectedConsultantId, setSelectedConsultantId] = useState<number | undefined>(
        historyValues.consultantId || userStateConsultantId,
    );
    const [shiftModalState, setShiftModalState] = useState<{ open: boolean; shiftId?: number }>({ open: false });
    const [eventTimeModalState, setEventTimeModalState] = useState<{ open: boolean; eventTimeId?: number }>({
        open: false,
    });
    const [createBookingModalState, setCreateBookingModalState] = useState<{ open: boolean }>({
        open: false,
    });
    const [createEventBookingModalState, setCreateEventBookingModalState] = useState<{
        open: boolean;
        eventTime?: CalendarItemEvent;
    }>({
        open: false,
    });
    const [bookingModalState, setBookingModalState] = useState<{ open: boolean; bookingId?: number }>({ open: false });
    const [eventBookingModalState, setEventBookingModalState] = useState<{
        open: boolean;
        eventBookingId?: number;
        callback?: () => void;
    }>({
        open: false,
    });
    const [eventTimeDrawerState, setEventTimeDrawerState] = useState<{
        open: boolean;
        eventTime?: CalendarItemEvent;
    }>({
        open: false,
    });
    const [calendarItems, setCalendarItems] = useState<ConsultantCalendarItem[]>([]);
    const [loadingCalendarItems, setLoadingCalendarItems] = useState(false);
    const [lastUpdated, setLastUpdated] = useState<Moment>();
    const [consultant, setConsultant] = useState<Consultant>();
    const [copyShiftsWeeklyEnabled, setCopyShiftsWeeklyEnabled] = useState(false);
    const [copySelectedWeek, setCopySelectedWeek] = useState<Moment>();
    const [copyShiftsDailyEnabled, setCopyShiftsDailyEnabled] = useState(false);
    const [copySelectedDay, setCopySelectedDay] = useState<Moment>();

    const numberOfWeeks = filterValues.numberOfWeeks && filterValues.numberOfWeeks > 0 ? filterValues.numberOfWeeks : 1;

    useEffect(() => {
        if (selectedConsultantId) {
            (async (): Promise<void> => {
                const result = await GetConsultant({ consultantId: selectedConsultantId });
                setConsultant(result);
            })();
        }
    }, [selectedConsultantId]);

    const fetchCalendarItems = useCallback(async () => {
        if (consultant && selectedStoreId && consultant.storeIds.includes(selectedStoreId)) {
            const toDate = startOfSelectedWeek.clone().add(numberOfWeeks - 1, 'weeks');
            const result = await ListCalendarItems({
                consultantId: consultant.id,
                fromDate: startOfSelectedWeek.toISOString(true),
                toDate: toDate.endOf('isoWeek').toISOString(true),
                showBookings: filterValues.showBookings,
            });

            setCalendarItems(result.items);
            setLastUpdated(moment());
        } else {
            setCalendarItems([]);
        }
    }, [consultant, startOfSelectedWeek, numberOfWeeks, filterValues.showBookings, selectedStoreId]);

    const fetchCalendarItemsLoading = useCallback(async () => {
        try {
            setLoadingCalendarItems(true);
            await fetchCalendarItems();
        } finally {
            setLoadingCalendarItems(false);
        }
    }, [fetchCalendarItems]);

    useInterval(() => {
        fetchCalendarItems();
    }, (filterValues.autoUpdateSeconds > 10 || filterValues.autoUpdateSeconds === 0 ? filterValues.autoUpdateSeconds : 10) * 1000);

    useEffect(() => {
        if (consultant) {
            fetchCalendarItemsLoading();
        }
    }, [consultant, fetchCalendarItemsLoading]);

    useEffect(() => {
        if (copyShiftsWeeklyEnabled) setCopySelectedWeek(undefined);
        if (copyShiftsDailyEnabled) setCopySelectedDay(undefined);
    }, [copyShiftsWeeklyEnabled, copyShiftsDailyEnabled]);

    const filters: IFilter<ICalendarPageFilterValues>[] = [
        {
            label: t('Calendar', 'NumberOfWeeks'),
            paramName: 'numberOfWeeks',
            type: 'number',
            size: '75px',
            maxValue: 24,
        },
        {
            label: t('Filter', 'ShowBookings'),
            paramName: 'showBookings',
            type: 'checkbox',
        },
        {
            label: t('Filter', 'AutoUpdateSeconds'),
            paramName: 'autoUpdateSeconds',
            type: 'number',
            size: '150px',
        },
    ];

    function onFilterChange(values: ICalendarPageFilterValues): void {
        setFilterValues(values);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            showBookings: values.showBookings,
            numberOfWeeks: values.numberOfWeeks,
            autoUpdateSeconds: values.autoUpdateSeconds,
        });
    }

    function onSelectConsultant(consultantId: number): void {
        setSelectedConsultantId(consultantId);
        history.replace(history.location.pathname + history.location.search, { ...historyValues, consultantId });
    }

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    function addWeeks(weeks: number): void {
        const newDate = startOfSelectedWeek.clone().add(weeks, 'weeks');
        setStartOfSelectedWeek(newDate);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            startOfSelectedWeek: newDate.toISOString(true),
        });
    }

    function openShiftModal(shift?: CalendarItemShift): void {
        if (!IsInRole(role, 'AdminRole', 'StoreManagerRole', 'ConsultantRole')) return;
        if (shift && selectableStores.every((x) => x.id !== shift.storeId)) return;

        setShiftModalState({ ...shiftModalState, shiftId: shift ? shift.id : 0, open: true });
    }

    function handleCloseShiftModal(): void {
        setShiftModalState({ ...shiftModalState, open: false });
    }

    function onShiftModalComplete(update: boolean): void {
        handleCloseShiftModal();
        if (update) {
            fetchCalendarItems();
        }
    }

    function openEventTimeModal(eventTime?: CalendarItemEvent): void {
        if (eventTime && selectableStores.every((x) => x.id !== eventTime.storeId)) return;

        setEventTimeModalState((prev) => ({ ...prev, eventTimeId: eventTime ? eventTime.id : 0, open: true }));
    }

    function handleCloseEventTimeModal(): void {
        setEventTimeModalState((prev) => ({ ...prev, open: false }));
    }

    function onEventTimeModalComplete(update: boolean): void {
        handleCloseEventTimeModal();
        if (update) {
            fetchCalendarItems();
        }
    }

    function openCreateBookingModal(): void {
        setCreateBookingModalState((prev) => ({ ...prev, open: true }));
    }

    function handleCloseCreateBookingModal(): void {
        setCreateBookingModalState((prev) => ({ ...prev, open: false }));
    }

    function onCreateBookingModalComplete(update: boolean): void {
        handleCloseCreateBookingModal();
        if (update) {
            fetchCalendarItems();
        }
    }

    function openCreateEventBookingModal(eventTime: CalendarItemEvent): void {
        if (eventTime && selectableStores.every((x) => x.id !== eventTime.storeId)) return;

        setCreateEventBookingModalState((prev) => ({ ...prev, open: true, eventTime }));
    }

    function handleCloseCreateEventBookingModal(): void {
        setCreateEventBookingModalState((prev) => ({ ...prev, open: false, eventTime: undefined }));
    }

    function onCreateEventBookingModalComplete(update: boolean): void {
        handleCloseCreateEventBookingModal();
        if (update) {
            fetchCalendarItems();
        }
    }

    function openBookingModal(booking: CalendarItemBooking): void {
        if (selectableStores.every((x) => x.id !== booking.storeId)) return;

        setBookingModalState({ ...bookingModalState, bookingId: booking.id, open: true });
    }

    function handleCloseBookingModal(): void {
        setBookingModalState({ ...bookingModalState, open: false });
    }

    function onBookingModalComplete(update: boolean): void {
        handleCloseBookingModal();
        if (update) {
            fetchCalendarItems();
        }
    }

    function openEventBookingModal(booking: EventBookingViewModel, callback?: () => void): void {
        setEventBookingModalState({ ...bookingModalState, eventBookingId: booking.id, open: true, callback });
    }

    function handleCloseEventBookingModal(): void {
        setEventBookingModalState({ ...bookingModalState, open: false, callback: undefined });
    }

    function onEventBookingModalComplete(update: boolean): void {
        const { callback } = eventBookingModalState;
        handleCloseEventBookingModal();
        if (update) {
            fetchCalendarItems();
            if (callback) {
                callback();
            }
        }
    }

    function openEventTimeDrawerState(eventTime?: CalendarItemEvent): void {
        if (eventTime && selectableStores.every((x) => x.id !== eventTime.storeId)) return;

        setEventTimeDrawerState({ open: true, eventTime });
    }

    function closeEventTimeDrawerState(): void {
        setEventTimeDrawerState({ open: false, eventTime: undefined });
    }

    useEffect(() => {
        if (eventTimeDrawerState.open && eventTimeDrawerState.eventTime) {
            const eventTimeItem = calendarItems?.find((x) => x.eventTime?.id === eventTimeDrawerState.eventTime?.id);
            if (eventTimeItem) {
                setEventTimeDrawerState((prev) => ({ ...prev, eventTime: eventTimeItem.eventTime }));
            } else {
                closeEventTimeDrawerState();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [calendarItems]);

    async function copyShift(fromDate: Moment, toDate: Moment): Promise<void> {
        try {
            if (copyShiftsWeeklyEnabled) {
                if (selectedConsultantId && copySelectedWeek) {
                    setLoadingCalendarItems(true);

                    const endOfSelectedWeek = copySelectedWeek.clone().weekday(6).endOf('day');

                    await DuplicateShiftsWeekly({
                        sourceFrom: copySelectedWeek.toISOString(true),
                        sourceTo: endOfSelectedWeek.toISOString(true),
                        copyFrom: fromDate.toISOString(true),
                        copyTo: toDate.toISOString(true),
                        consultantId: selectedConsultantId,
                    });
                }
            }
            if (copyShiftsDailyEnabled) {
                if (selectedConsultantId && copyShiftsDailyEnabled) {
                    setLoadingCalendarItems(true);
                    await DuplicateShiftsDaily({
                        source: copySelectedDay?.toISOString(true),
                        selectedDay: toDate.toISOString(true),
                        consultantId: selectedConsultantId,
                    });
                }
            }
        } finally {
            await fetchCalendarItems();
            setLoadingCalendarItems(false);
        }
    }

    if (!selectedStoreId) return <div>{t('Shared', 'SelectStoreToViewPage')}</div>;

    const weekStarts: Moment[] = [startOfSelectedWeek];

    for (let i = 1; i < numberOfWeeks; i++) {
        weekStarts.push(startOfSelectedWeek.clone().add(i, 'weeks'));
    }

    const colorLegends: IColorLegend[] = [
        { color: '#D1FAE5', borderColor: '#10B981', text: t('Calendar', 'ColorLegend', 'Shift') },
        { color: '#fdbb84', borderColor: '#ED6C02', text: t('Calendar', 'ColorLegend', 'InActiveShift') },
        { color: '#E0E7FF', borderColor: '#a6b6ff', text: t('Calendar', 'ColorLegend', 'Events') },
        { color: '#FFFFFF', borderColor: '#9ca3af', text: t('Calendar', 'ColorLegend', 'EmptyBookings') },
        { color: '#ff9494', borderColor: '#fa4d32', text: t('Calendar', 'ColorLegend', 'DeletedBookings') },
    ];

    if (IsInRole(role, 'AdminRole', 'StoreManagerRole'))
        colorLegends.push({
            color: '#dbdbdb',
            borderColor: '#808080',
            text: t('Calendar', 'ColorLegend', 'NotInSelectedStore'),
        });

    return (
        <Grid item>
            <Grid
                container
                gap={1}
                style={{
                    width: 'calc(100vw - 9px)',
                    position: 'relative',
                    left: '50%',
                    right: '50%',
                    marginLeft: '-50vw',
                    marginRight: '-50vw',
                    padding: largeScreen ? '0 30px' : '0 20px',
                }}
            >
                <Grid item xs={12}>
                    <Grid container gap={2} direction={largeScreen ? 'row' : 'column'}>
                        <Grid item xs>
                            <Grid container alignItems={largeScreen ? 'center' : 'center'} gap={1}>
                                <Grid item>
                                    <Typography variant="h3">
                                        {tf(
                                            {
                                                weekNumber:
                                                    numberOfWeeks > 1
                                                        ? `${startOfSelectedWeek.isoWeek()} - ${startOfSelectedWeek
                                                              .clone()
                                                              .add(numberOfWeeks - 1, 'weeks')
                                                              .isoWeek()}`
                                                        : startOfSelectedWeek.isoWeek(),
                                            },
                                            'Calendar',
                                            'WeekNumber',
                                        )}{' '}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <ButtonGroup variant="contained" color="inherit">
                                        {numberOfWeeks > 1 && (
                                            <Button onClick={(): void => addWeeks(-numberOfWeeks)}>
                                                <Typography variant="h6">-{numberOfWeeks}</Typography>
                                            </Button>
                                        )}
                                        <Button onClick={(): void => addWeeks(-1)}>
                                            <KeyboardArrowLeft />
                                        </Button>
                                        <Button onClick={(): void => addWeeks(1)}>
                                            <KeyboardArrowRight />
                                        </Button>
                                        {numberOfWeeks > 1 && (
                                            <Button onClick={(): void => addWeeks(numberOfWeeks)}>
                                                <Typography variant="h6">+{numberOfWeeks}</Typography>
                                            </Button>
                                        )}
                                    </ButtonGroup>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs>
                            <Filter
                                filters={filters}
                                onChange={onFilterChange}
                                filterValues={filterValues}
                                justify="center"
                            />
                        </Grid>
                        <Grid item xs>
                            <CalendarButtons
                                copyShiftsDailyEnabled={copyShiftsDailyEnabled}
                                copyShiftsWeeklyEnabled={copyShiftsWeeklyEnabled}
                                setCopyShiftsDailyEnabled={setCopyShiftsDailyEnabled}
                                setCopyShiftsWeeklyEnabled={setCopyShiftsWeeklyEnabled}
                                role={role}
                                disabled={!selectedConsultantId}
                                openShiftModal={openShiftModal}
                                openEventTimeModal={openEventTimeModal}
                                openCreateBooking={openCreateBookingModal}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: '0', paddingBottom: '0' }}>
                    <Grid container direction={largeScreen ? 'row' : 'column'} gap={2}>
                        {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                            <Grid
                                item
                                xs={largeScreen ? 2 : false}
                                style={largeScreen ? { marginRight: '20px' } : {}}
                            />
                        )}
                        <Grid item>
                            <Typography variant="body2">
                                {lastUpdated &&
                                    tf({ dateTime: lastUpdated?.format('HH:mm:ss') }, 'Calendar', 'LastUpdatedDate')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <ColorLegend legends={colorLegends} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Grid container direction={largeScreen ? 'row' : 'column'}>
                        {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                            <Grid
                                item
                                xs={largeScreen ? 2 : false}
                                style={largeScreen ? { marginRight: '20px' } : { marginBottom: '20px' }}
                            >
                                <SelectConsultant
                                    consultantId={selectedConsultantId}
                                    storeId={selectedStoreId}
                                    onSelected={onSelectConsultant}
                                />
                            </Grid>
                        )}
                        <Grid item xs style={{ position: 'relative' }}>
                            {consultant && selectedStoreId && consultant.storeIds.includes(selectedStoreId) ? (
                                <Grid
                                    container
                                    item
                                    style={numberOfWeeks === 1 ? { height: '100%' } : {}}
                                    direction="column"
                                >
                                    {weekStarts.map((start) => (
                                        <WeekCalendar
                                            key={start.toISOString()}
                                            showingMultiple={numberOfWeeks > 1}
                                            itemsLoading={loadingCalendarItems}
                                            copyShiftsWeeklyEnabled={copyShiftsWeeklyEnabled}
                                            copyShiftsDailyEnabled={copyShiftsDailyEnabled}
                                            selectedWeek={copySelectedWeek}
                                            selectedDay={copySelectedDay}
                                            selectedStoreId={selectedStoreId}
                                            fromDate={start}
                                            toDate={start.clone().endOf('isoWeek')}
                                            items={calendarItems}
                                            onShiftItemClick={openShiftModal}
                                            onBookingItemClick={openBookingModal}
                                            setSelectedWeek={setCopySelectedWeek}
                                            setSelectedDay={setCopySelectedDay}
                                            copyShift={copyShift}
                                            setCopyShiftsWeekly={setCopyShiftsWeeklyEnabled}
                                            setCopyShiftsDaily={setCopyShiftsDailyEnabled}
                                            role={role}
                                            onEventTimeItemClick={openEventTimeDrawerState}
                                        />
                                    ))}
                                </Grid>
                            ) : (
                                <Typography>{t('Calendar', 'SelectAConsultant')}</Typography>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <DefaultModal
                open={shiftModalState.open}
                onClose={handleCloseShiftModal}
                closeOnBackdrop={false}
                maxWidth="md"
            >
                {selectedConsultantId && consultant ? (
                    <CreateOrEditShift
                        onComplete={onShiftModalComplete}
                        consultant={consultant}
                        id={shiftModalState.shiftId}
                        currentSelectedWeekDate={startOfSelectedWeek}
                        role={role}
                        selectedStoreId={selectedStoreId}
                        selectableStores={selectableStores}
                    />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <DefaultModal
                open={eventTimeModalState.open}
                onClose={handleCloseEventTimeModal}
                closeOnBackdrop={false}
                maxWidth="md"
            >
                {!!selectedConsultantId && !!consultant && (
                    <CreateOrEditEventTime
                        onComplete={onEventTimeModalComplete}
                        consultant={consultant}
                        id={eventTimeModalState.eventTimeId}
                        currentSelectedWeekDate={startOfSelectedWeek}
                        role={role}
                        selectedStoreId={selectedStoreId}
                        selectableStores={selectableStores}
                    />
                )}
            </DefaultModal>
            <DefaultModal open={bookingModalState.open} onClose={handleCloseBookingModal} maxWidth="sm">
                {bookingModalState.bookingId ? (
                    <EditBooking onComplete={onBookingModalComplete} id={bookingModalState.bookingId} role={role} />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <DefaultModal open={eventBookingModalState.open} onClose={handleCloseEventBookingModal} maxWidth="sm">
                {eventBookingModalState.eventBookingId ? (
                    <EditEventBooking
                        onComplete={onEventBookingModalComplete}
                        id={eventBookingModalState.eventBookingId}
                        role={role}
                    />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <Drawer anchor="right" open={eventTimeDrawerState.open} onClose={closeEventTimeDrawerState}>
                {eventTimeDrawerState.open && eventTimeDrawerState.eventTime && consultant && (
                    <EventTimeBookingsDrawer
                        item={eventTimeDrawerState.eventTime}
                        consultant={consultant}
                        onEventBookingClicked={openEventBookingModal}
                        onEditEventTimeClicked={openEventTimeModal}
                        onCreateEventBookingClicked={openCreateEventBookingModal}
                        closeDrawer={closeEventTimeDrawerState}
                    />
                )}
            </Drawer>
            <DefaultModal
                open={createBookingModalState.open}
                onClose={handleCloseCreateBookingModal}
                closeOnBackdrop={false}
                maxWidth="sm"
            >
                {!!selectedConsultantId && !!consultant && (
                    <CreateBookingComponent
                        onComplete={onCreateBookingModalComplete}
                        consultant={consultant}
                        currentSelectedWeekDate={startOfSelectedWeek}
                        selectedStoreId={selectedStoreId}
                        selectableStores={selectableStores}
                    />
                )}
            </DefaultModal>
            <DefaultModal
                open={createEventBookingModalState.open}
                onClose={handleCloseCreateEventBookingModal}
                closeOnBackdrop={false}
                maxWidth="sm"
            >
                {!!selectedConsultantId && !!consultant && !!createEventBookingModalState.eventTime && (
                    <CreateEventBookingComponent
                        onComplete={onCreateEventBookingModalComplete}
                        consultant={consultant}
                        eventTime={createEventBookingModalState.eventTime}
                        selectableStores={selectableStores}
                    />
                )}
            </DefaultModal>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(CalendarPage as ComponentType);
