import React, { ReactElement } from 'react';
import styled from 'styled-components';
import { Modal, Container, IconButton } from '@mui/material';
import { breakpoint, sizes } from 'variables';
import { Close } from '@mui/icons-material';

const ContainerStyled = styled(Container)`
    background: white;
    padding-top: 30px;
    padding-bottom: 16px;
    overflow-y: auto;
    max-height: 100vh;
    position: relative;

    ${breakpoint.up(sizes.md)} {
        padding: 25px;
        padding-top: 30px;
        border: 1px solid #9ca3af;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

        max-height: calc(100vh - 40px);
        margin: 20px 0;
    }
`;

const IconButtonStyled = styled(IconButton)`
    position: absolute;
    top: 0;
    right: 0;
    padding: 6px;
`;

interface IDefaultModalProps {
    open: boolean;
    onClose?: () => void;
    children: ReactElement | boolean;
    maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
    closeOnBackdrop?: boolean;
}

export function DefaultModal(props: IDefaultModalProps): JSX.Element {
    const { open, onClose, children, maxWidth, closeOnBackdrop = true } = props;

    return (
        <Modal
            open={open}
            onClose={closeOnBackdrop ? onClose : undefined}
            style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <ContainerStyled maxWidth={maxWidth}>
                <IconButtonStyled onClick={onClose}>
                    <Close />
                </IconButtonStyled>
                {children}
            </ContainerStyled>
        </Modal>
    );
}

export default DefaultModal;
