import React from 'react';
import { OverviewConsultant, OverviewItem, Consultant } from 'autogen/swagger/Consultant';
import { Table, TableBody, TableHead, TableRow, TableCell, Typography } from '@mui/material';
import { Moment } from 'moment';
import { getDates } from 'helpers/dateTime';
import { useTypedTranslation } from 'translations';
import OverviewRow from './OverviewRow';

interface IOverviewCalendarProps {
    overviewConsultants: OverviewConsultant[];
    fromDate: Moment;
    toDate: Moment;
    openItemDrawer: (overviewItem: OverviewItem, consultant: Consultant) => void;
}

export function OverviewCalendar(props: IOverviewCalendarProps): JSX.Element {
    const { overviewConsultants, fromDate, toDate, openItemDrawer } = props;
    const { t } = useTypedTranslation();

    const dates = getDates(fromDate, toDate);

    function compare(a: OverviewConsultant, b: OverviewConsultant): number {
        return a.consultant.firstName + a.consultant.lastName > b.consultant.firstName + b.consultant.lastName ? 1 : -1;
    }

    const internals = overviewConsultants.filter((x) => !x.consultant.isExternal).sort(compare);
    const externals = overviewConsultants.filter((x) => x.consultant.isExternal).sort(compare);

    return (
        <Table style={{ background: 'white', border: '2px solid #9ca3af' }}>
            <TableHead>
                <TableRow style={{ borderBottom: '2px solid #9ca3af' }}>
                    <TableCell style={{ border: '1px solid #9ca3af50' }} size="small" />
                    {dates.map((date) => (
                        <TableCell size="small" key={date.toISOString()} style={{ border: '1px solid #9ca3af50' }}>
                            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                                {date.format('dddd')}
                            </Typography>
                            <Typography variant="subtitle2">{date.format('DD-MM-yyyy')}</Typography>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {internals && !!internals.length && (
                    <>
                        <TableRow>
                            <TableCell size="small">
                                <Typography variant="subtitle2">{t('Shared', 'Internals')}</Typography>
                            </TableCell>
                        </TableRow>
                        {internals.map((consultant) => (
                            <OverviewRow
                                key={consultant.consultant.id}
                                overviewConsultant={consultant}
                                dates={dates}
                                openItemDrawer={openItemDrawer}
                            />
                        ))}
                    </>
                )}
                {externals && !!externals.length && (
                    <>
                        <TableRow style={{ borderTop: '2px solid #9ca3af' }}>
                            <TableCell size="small">
                                <Typography variant="subtitle2">{t('Shared', 'Externals')}</Typography>
                            </TableCell>
                        </TableRow>
                        {externals.map((consultant) => (
                            <OverviewRow
                                key={consultant.consultant.id}
                                overviewConsultant={consultant}
                                dates={dates}
                                openItemDrawer={openItemDrawer}
                            />
                        ))}
                    </>
                )}
            </TableBody>
        </Table>
    );
}

export default OverviewCalendar;
