import React from 'react';
import { GridCellParams } from '@mui/x-data-grid';
import { AuthRole } from 'autogen/swagger/Users';
import {
    ConsultationProductListModel,
    ChangeGloballyDisableConsultationProduct,
    DeleteConsultationProduct
} from 'autogen/swagger/ConsultationProduct';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import { useTypedTranslation } from 'translations';
import { Button, Menu, MenuItem, IconButton } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import { getConsultationProductUrl } from 'shared/urls';
import { Link } from 'react-router-dom';

interface IProductRowOptionsProps {
    params: GridCellParams;
    role: AuthRole;
    products: ConsultationProductListModel[];
    loadProducts: () => void;
}

export function ProductRowOptions(props: IProductRowOptionsProps): JSX.Element {
    const { params, role, products, loadProducts } = props;
    const id = params.id as number;
    const { row } = params;
    const { t, tf } = useTypedTranslation();
    const [anchorEl, setAnchorEl] = React.useState<EventTarget & HTMLButtonElement>();
    const openUserMenu = Boolean(anchorEl);

    const handleUserMenuClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleUserMenuClose = (): void => {
        setAnchorEl(undefined);
    };

    const isReadOnly =
        role !== 'AdminRole' && !row.consultationProductDetailed.consultationProductMasterDetailed.localStoreId;

    const isActive = !row.consultationProductDetailed.consultationProductMasterDetailed.globalDisableDate;

    async function changeDisableProduct(): Promise<void> {
        const name =
            products.find((x) => x.consultationProductDetailed.consultationProductMasterDetailed.id === id)
                ?.consultationProductDetailed.name ?? '';
        ConfirmChoice({
            content: tf({ name }, 'ConsultationProduct', isActive ? 'DisableConfirmText' : 'EnableConfirmText'),
            onConfirm: async () => {
                const result = await ChangeGloballyDisableConsultationProduct({ id, disable: isActive });
                if (result.success) {
                    loadProducts();
                }
            },
        });
    }

    async function deleteProduct(): Promise<void> {
        const name =
            products.find((x) => x.consultationProductDetailed.consultationProductMasterDetailed.id === id)
                ?.consultationProductDetailed.name ?? '';
        ConfirmChoice({
            content: tf({ name }, 'ConsultationProduct', 'DeleteConfirmText'),
            onConfirm: async () => {
                const result = await DeleteConsultationProduct({ id });
                if (result.success) {
                    loadProducts();
                }
            },
        });
    }

    return (
        <>
            <Button color="primary" component={Link} to={getConsultationProductUrl(id)}>
                {t('Shared', isReadOnly ? 'SeeDetails' : 'Edit')}
            </Button>
            {!isReadOnly && (
                <>
                    <IconButton onClick={handleUserMenuClick} size="large">
                        <MoreVert />
                    </IconButton>
                    <Menu
                        id="basic-menu"
                        open={openUserMenu}
                        style={{ marginTop: '40px' }}
                        anchorEl={anchorEl}
                        onClose={handleUserMenuClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={changeDisableProduct}>
                            {isActive ? t('Shared', 'Disable') : t('Shared', 'Enable')}
                        </MenuItem>

                        <MenuItem onClick={deleteProduct}>{t('Shared', 'Delete')}</MenuItem>
                    </Menu>
                </>
            )}
        </>
    );
}

export default ProductRowOptions;
