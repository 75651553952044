/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Users';

export type AuthRole = components['schemas']['AuthRole'];
export type StoreSelectViewModel = components['schemas']['StoreSelectViewModel'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type UserStateViewModel = components['schemas']['UserStateViewModel'];
export type ErrorType = components['schemas']['ErrorType'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type OperationResult = components['schemas']['OperationResult'];
export type StoreDistrict = components['schemas']['StoreDistrict'];
export type StoreDistrictMaybe = components['schemas']['StoreDistrictMaybe'];
export type Location = components['schemas']['Location'];
export type StoreDetailed = components['schemas']['StoreDetailed'];

type GetUserStateSuccess = paths['/User/GetUserState']['get']['responses']['200']['content']['application/json'];

export const GetUserStateUrl = '/User/GetUserState';

export function GetUserState(): Promise<GetUserStateSuccess> {
    return fetchJSON<GetUserStateSuccess>(`/User/GetUserState`, {}, true);
}

type ChangeSelectedStoreParams = paths['/User/ChangeSelectedStore']['post']['parameters']['query'];

type ChangeSelectedStoreSuccess =
    paths['/User/ChangeSelectedStore']['post']['responses']['200']['content']['application/json'];

export const ChangeSelectedStoreUrl = '/User/ChangeSelectedStore';

export const ChangeSelectedStoreUrlFormat = (params: ChangeSelectedStoreParams): string =>
    ChangeSelectedStoreUrl + formatParams(params);

export function ChangeSelectedStore(params: ChangeSelectedStoreParams): Promise<ChangeSelectedStoreSuccess> {
    return postQuery<ChangeSelectedStoreSuccess>(`/User/ChangeSelectedStore${formatParams(params)}`, true);
}

type GetUserStoresDetailedSuccess =
    paths['/User/GetUserStoresDetailed']['get']['responses']['200']['content']['application/json'];

export const GetUserStoresDetailedUrl = '/User/GetUserStoresDetailed';

export function GetUserStoresDetailed(): Promise<GetUserStoresDetailedSuccess> {
    return fetchJSON<GetUserStoresDetailedSuccess>(`/User/GetUserStoresDetailed`, {}, true);
}
