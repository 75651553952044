import React, { useState } from 'react';
import { AssignToSelectedStore } from 'autogen/swagger/Consultant';
import { Grid, Typography, Button, CircularProgress, TableBody, TableRow, TableCell, Table, Chip } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { Link, useHistory } from 'react-router-dom';
import { getConsultantsUrl } from 'shared/urls';
import { showMessage } from 'components/messages/Message';

export interface ExistingConsultant {
    consultant: { id: number; firstName: string; lastName: string; email?: string; isExternal: boolean };
    assignedToSelectedStore: boolean;
    showEmail?: boolean;
}

interface IExistingConsultantProps {
    existingConsultants: ExistingConsultant[];
    reloadUserFunction: () => void;
    isEdit: boolean;
}

export function ExistingConsultantView(props: IExistingConsultantProps): JSX.Element {
    const { existingConsultants, reloadUserFunction, isEdit } = props;
    const history = useHistory();
    const { t } = useTypedTranslation();
    const [loading, setLoading] = useState(false);

    async function assignConsultantToStore(existingConsultant: ExistingConsultant): Promise<void> {
        try {
            setLoading(true);

            const result = await AssignToSelectedStore({ consultantId: existingConsultant.consultant.id });

            if (result.success) {
                showMessage({ content: t('Consultant', 'AssignedToStore'), severity: 'success' });
                if (isEdit) {
                    reloadUserFunction();
                } else {
                    history.push(getConsultantsUrl(existingConsultant.consultant.id));
                }
            } else {
                showMessage({ content: t('Consultant', 'ErrorAssigningToStore'), severity: 'error' });
            }
        } finally {
            setLoading(false);
        }
    }

    let text = t('Consultant', 'FoundMultipleConsultants');
    if (existingConsultants.length === 1) {
        if (!existingConsultants[0].assignedToSelectedStore) text = t('Consultant', 'FoundNotInStore');
        else if (isEdit) t('Consultant', 'FoundNotInStoreEdit');
        else text = t('Consultant', 'FoundInStore');
    }

    return (
        <Grid container item gap={2} direction="column">
            <Grid item>
                <Typography>{text}</Typography>
            </Grid>
            <Grid item>
                <Table>
                    <TableBody>
                        {existingConsultants.map((x) => (
                            <TableRow key={x.consultant.id}>
                                <TableCell>
                                    <Typography>
                                        {x.consultant.firstName} {x.consultant.lastName}
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    {x.showEmail && x.consultant.email && <Typography>{x.consultant.email}</Typography>}
                                </TableCell>
                                <TableCell>
                                    <Chip
                                        label={t('Shared', x.consultant.isExternal ? 'External' : 'Internal')}
                                        size="small"
                                        color={x.consultant.isExternal ? 'default' : 'primary'}
                                    />
                                </TableCell>
                                <TableCell>
                                    {!x.assignedToSelectedStore && (
                                        <Button
                                            onClick={(): void => {
                                                assignConsultantToStore(x);
                                            }}
                                            variant="contained"
                                            color="primary"
                                        >
                                            {t('Consultant', 'AssignToStore')}
                                            {loading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
                                        </Button>
                                    )}
                                    {x.assignedToSelectedStore && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                component={Link}
                                                to={getConsultantsUrl(x.consultant.id)}
                                            >
                                                {t('Consultant', 'GoToEdit')}
                                            </Button>
                                        </Grid>
                                    )}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </Grid>
        </Grid>
    );
}

export default ExistingConsultantView;
