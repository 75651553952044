/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Settings';

export type ClientAuthConfig = components['schemas']['ClientAuthConfig'];
export type ErrorType = components['schemas']['ErrorType'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type AppInsightsSettings = components['schemas']['AppInsightsSettings'];

type GetAuthSettingsSuccess =
    paths['/Settings/GetAuthSettings']['get']['responses']['200']['content']['application/json'];

export const GetAuthSettingsUrl = '/Settings/GetAuthSettings';

export function GetAuthSettings(): Promise<GetAuthSettingsSuccess> {
    return fetchJSON<GetAuthSettingsSuccess>(`/Settings/GetAuthSettings`, {}, false);
}

type GetAppInsightsSettingsSuccess =
    paths['/Settings/GetAppInsightsSettings']['get']['responses']['200']['content']['application/json'];

export const GetAppInsightsSettingsUrl = '/Settings/GetAppInsightsSettings';

export function GetAppInsightsSettings(): Promise<GetAppInsightsSettingsSuccess> {
    return fetchJSON<GetAppInsightsSettingsSuccess>(`/Settings/GetAppInsightsSettings`, {}, false);
}
