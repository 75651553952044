export const da = {
    Booking: {
        CancelConsultation: 'Aflys konsultation',
        ClubmatasMemberId: 'Clubmatas medlemsnummer (valgfri)',
        CreateTitle: 'Opret booking',
        ConfirmCancel:
            'Er du sikker på at du vil aflyse konsultationen? Der vil blive sendt information på både mail, notifikation og sms til kunden.',
        ConfirmCustomerAbsent: 'Er du sikker?',
        CouldNotGetUser: 'Kunne ikke hente kunden',
        Customer: "Om Kunden",
        CustomerAbsent: 'Kunde udeblevet',
        ExecutedBy: 'Afholdes af',
        FirstName: 'Fornavn (valgfri)',
        LastName: 'Efternavn (valgfri)',
        MemberId: 'Medlems nummer',
        MemberName: 'Navn',
        MemberPotential: "Tilbyd medlemskab i",
        MemberPotentialPlusRelevance: "Her er en guldkunde, som kan spare {{savedAmount}} kr./år med et Plus medlemskab.",
        MemberPotentialBrandClub: "Medlemmet er relevant for Mine Brands, og kan optjene bonusgaver.",
        MemberPotentialInfo: "Medlemmet kan tilmeldes på forsiden i Club Matas appen og under Mit Matas.",
        SearchMember: 'Søg medlem via mobilnummer (valgfri)',
        SelectService: 'Vælg ydelse',
        Service: 'Ydelse',
        VirtualAgentUrl: 'Åbn konsultation',
        IsPlusRelevance: "Guldikon",
        IsPlusRelevanceSavedAmount: "Guldikon: Spar {{savedAmount}}",
        IsBrandClubMember: "Mine brands"
    },
    Calendar: {
        Blocking: 'Spærretid',
        Bookings: '{{bookingCount}}/{{maxParticipants}} bookinger',
        Calendar: 'Kalender',
        CreateBooking: 'Opret booking',
        CreateShift: 'Tilføj vagt',
        CreateEventTime: 'Tilføj event',
        ColorLegend: {
            Shift: 'Åbn vagt',
            InActiveShift: 'Lukket vagt',
            EmptyBookings: 'Booking uden vagt',
            Events: 'Event',
            NotInSelectedStore: 'Ikke i den valgte butik',
            DeletedBookings: 'Aflyst event',
        },
        Copy: 'Kopier',
        CopyShifts: 'Kopier vagter',
        CopyShiftsDaily: 'Kopier Dage',
        CopyShiftsWeekly: 'Kopier Uger',
        CopyDayToDay: 'Kopier vagter fra {{dayNumberOne}} til {{dayNumberTwo}}',
        CopyWeekToWeek: 'Kopier Uge {{weekNumberOne}} til {{weekNumberTwo}}',
        DayNamesWithDate: {
            Friday: 'Fredag',
            Monday: 'Mandag',
            Saturday: 'Lørdag',
            Sunday: 'Søndag',
            Thursday: 'Torsdag',
            Tuesday: 'Tirsdag',
            Wednesday: 'Onsdag',
        },
        Duration: 'Varighed',
        Insert: 'Indsæt',
        LastUpdatedDate: 'Sidst opdateret {{dateTime}}',
        Minutes: 'min',
        MoreOptions: 'Flere muligheder',
        NumberOfWeeks: 'Antal uger',
        SelectAConsultant: 'Vælg en konsulent fra listen til venstre',
        WeekNumber: 'Uge {{weekNumber}}',
    },
    Consultant: {
        AssignToStore: 'Tilknyt til butik',
        AssignedToStore: 'Konsulenten blev tilknyttet den valgte butik',
        AuthUser: {
            AuthUserFixed: 'Der blev oprettet en login bruger',
            AuthUserText:
                'Der er ikke en login bruger til konsulenten, så der kan ikke logges ind via emailen. Vil du oprette en login bruger så konsulenten kan logge ind? ',
            AuthUserTextNoEmail:
                'Der er ikke en login bruger til konsulenten, så der kan ikke logges ind via emailen. Hvis det ønskes at konsulenten kan logge ind, skal der tilføjes en email.',
            FixUser: 'Opret login bruger',
        },
        CantCreateWithoutStore:
            'Du kan ikke oprette en konsulent uden en butik, vælg en butik i venstre hjørne for at fortsætte.',
        ConfirmDeleteConsultant:
            'Er du sikker på at du vil slette {{name}}? Konsulenten vil blive slettet fra alle butikker og alle vagter vil blive fjernet. En konsulent kan ikke slettes hvis der er bookinger på konsulenten.',
        ConfirmRemoveAssignment:
            'Er du sikker på at du vil fjerne {{name}} fra den valgte butik? Dette er ikke muligt hvis konsulenten har bookinger i din butik, men det vil slette alle fremtidige vagter i din butik.',
        ConsultantUpdated: 'Konsulenten blev opdateret',
        Consultants: 'Konsulenter',
        ConsultationProducts: 'Ydelser',
        CreateTitle: 'Opret konsulent',
        CreateNew: 'Opret ny konsulent',
        DeleteConsultant: 'Slet konsulent',
        DeleteConsultantSuccess: 'Konsulenten blev slettet',
        EditTile: 'Rediger konsulent',
        ErrorAssigningToStore: 'Noget gik galt under tilknytningen.',
        Event: 'Event',
        External: 'Eksterne',
        Filter: {
            ExternalPartner: 'brands',
            Search: 'Søg på navn, mobil eller email',
        },
        FoundInStore: 'En konsulent blev fundet, og er allerede tilknyttet den valgte butik',
        FoundNotInStore:
            'En konsulent blev fundet, Konsulenten er ikke tilknyttet den valgte butik. Vil du tilknytte konsulenten til butikken?',
        FoundNotInStoreEdit:
            'konsulenten er ikke tilknyttet den valgte butik. Vil du tilknytte konsulenten til butikken?',
        FoundMultipleConsultants: 'Der blev fundet flere konsulenter, vælg en eller lav en ny søgning',
        GoToEdit: 'Rediger konsulent',
        Input: {
            ClubMatasMemberId: 'Medlemsnummer',
            Description: 'Om personen/bio (valgfri)',
            Email: 'E-mail (bruges til login)',
            EmailEmptyHelpText: 'E-mail er pråkrævet hvis brugeren skal kunne logge ind, ellers efterlad den tom.',
            ExternalPartner: 'Brand',
            FirstName: 'Fornavn*',
            Header: 'Om personen',
            IsMatasEmployee: 'Ansat hos Matas',
            LastName: 'Efternavn*',
            MobileNumber: 'MobilNummer',
            NoMemberFound: 'Der blev ikke fundet en club bruger',
            NotificationsSettings: {
                Header: 'Notifikationsindstillinger',
                CheckboxLabelEmail: 'E-mail',
                CheckboxLabelSms: 'Sms',
            },
            NotificationsSettingsOptions: {
                BookingCancelledFromAdmin: 'En aftale er blevet aflyst af en medarbejder',
                BookingCancelledFromWebsite: 'En aftale er blevet aflyst af en kunde',
                BookingCreatedFromAdmin: 'En ny aftale er blevet oprettet af en medarbejder',
                BookingCreatedFromWebsite: 'En ny aftale er blevet oprettet af en kunde',
                BookingUpdated: 'Kunden har ændret tid på en aftale',
            },
            SearchConsultant: 'Søg på email',
            SelectExternalPartner: 'Vælg brand',
        },
        Internal: 'Interne',
        NewSearch: 'Ny søgning',
        RemoveAssignmentSuccess: 'Tilknytningen blev fjernet',
        RemoveAssignmentToStore: 'Fjern tilknytning til butik',
        Role: 'Rolle',
        SearchConsultant: 'Søg efter en konsulent',
        SearchEmployee: 'Søg efter medarbejder',
        SearchTypes: {
            CardNumber: 'Clubmatas kortnummer',
            MobileNumber: 'Mobilnummer',
        },
        SearchTypesExternal: {
            Email: 'E-mail',
            Name: 'Navn',
        },
        StoreManager: {
            ConfirmMakeStoreManager:
                'Er du sikker på at du vil gøre {{name}} til butiks administrator? Brugeren vil kunne administrere ydelser, konsulenter og vagter for alle butikker der er tilknyttet til brugeren.',
            ConfirmRemoveStoreManager:
                'Er du sikker på at du vil fjerne {{name}} som butiks administrator? Brugeren vil ikke kunne adminstrer nogen af sine tilknyttede butikker.',
            IsStoreManager: 'Butiks administrator',
            MakeStoreManager: 'Gør til butiks administrator',
            RemoveStoreManager: 'Fjern butiks manager',
            StoreManagerUpdated: 'Butiks administrator status opdateret.',
        },
        Virtual: {
            ConfirmCreate: 'Er du sikker på at du vil gøre {{name}} til online konsulent?',
            ConfirmRemove: 'Er du sikker på at du vil fjerne {{name}} fra online konsulenter?',
            Is: 'Online konsulent',
            Create: 'Gør til online konsulent',
            Remove: 'Fjern online konsulent',
            Updated: 'Online konsulent status opdateret.',
        },
        Stores: 'Butikker',
        Title: 'Konsulenter',
        Type: 'Er personen ansat hos Matas eller ekstern?',
    },
    ConsultantShift: {
        AddBreak: 'Tilføj Pause',
        ConfirmDelete: 'Er du sikker på at du vil slette denne vagt? Eksiterende bookninger vil stadig være gældende.',
        ConfirmDisable:
            'Er du sikker på at du vil lukke for booking på denne vagt? Man vil ikke længere kunne booke tider til denne vagt. Eksiterende bookninger vil stadig være gældende.',
        ConfirmEnable:
            'Er du sikker på at du vil åbne for bookinger på denne vagt? Man vil igen kunne booke tider til denne vagt.',
        CreateTitle: 'Opret vagt',
        Disable: 'Luk for bookinger',
        EditTile: 'Rediger vagt',
        Enable: 'Åben for bookinger',
        SelectServices: 'Vælg ydelser',
        Service: 'Ydelse',
    },
    ConsultationCategory: {
        CreateTitle: 'Opret Kategori',
        DeleteConfirmText: 'Er du sikker på at du vil deaktivere kategorien "{{name}}"?',
        EditTile: 'Rediger Kategori',
        Name: 'Navn',
        NameInput: 'Navn (vises til kunden på matas.dk)',
        Title: 'Kategorier',
        SortOrder: 'Rækkefølge',
        Teaser: 'Teaser',
        TeaserInput: 'Teaser (vises til kunden på matas.dk, maks 150 tegn)',
        UnDeleteConfirmText: 'Er du sikker på at du vil aktivere kategorien "{{name}}"?',
    },
    ConsultationProduct: {
        BookingType: {
            header: 'Type',
            Single: 'Konsultation',
            Event: 'Event',
        },
        CantEditExternalPartner: 'Brandet kan ikke ændres, lav i stedet en ny ydelse.',
        Category: 'Kategori',
        Centrally: 'Centralt',
        CreateGlobal: 'Opret centralt',
        CreateLocal: 'Opret lokalt',
        CreateNewGlobal: 'Opret ny central',
        CreateNewVirtual: 'Opret ny online',
        CreateNewLocal: 'Opret ny lokal',
        CreateTitleGlobal: 'Opret ydelse centralt',
        CreateTitleLocal: 'Opret ydelse lokalt',
        Created: 'Oprettet',
        CopyToLocal: 'Kopier til lokal',
        DeleteConfirmText: 'Er du sikker på, at du vil slette ydelsen "{{name}}"? OBS: påvirker alle butikker!',
        DisableConfirmText: 'Er du sikker på at du vil sætte ydelsen "{{name}}" inaktiv? OBS: påvirker alle butikker!',
        Duration: 'Længde',
        InBooth: 'I kabine',
        IsVirtual: 'Online ydelse',
        EditTile: 'Rediger Ydelse',
        EnableConfirmText: 'Er du sikker på at du vil sætte ydelsen "{{name}}" aktiv? OBS: påvirker alle butikker!',
        EnabledInStore: 'I valgte butik',
        ExternalPartner: 'Brand',
        Filter: {
            Category: 'kategorier',
            ExternalPartner: 'brands',
            OnlyLocalStore: 'Kun lokale',
            OnlyNews: 'Kun Nyheder',
            BookingType: 'typer',
            Search: 'Søg navn',
        },
        Input: {
            Blocking: 'Spærretid efter hver konsultation',
            BonusText:
                'Valgfri: Ekstra information til kunden (vises i booking bekræftelsen og i mail, skriv det i punktform)',
            Description: 'Lang beskrivelse af ydelsen (vises til kunden på matas.dk)',
            DescriptionPlaceholder:
                'Mere beskrivende og uddybende tekst. Hvad kan kunden forvente til denne konsultation / event? Hvad er slut resultatet, hvilken viden får kunden. Og ved event skriv gerne pris, og afhentningssted ’billetten koster 50,- som betales og afhentes senest d. xx i Matas xx’, og der er forplejning, goodiebag m.m. Det skal være en salgstekst til kunden.',
            Duration: 'Varighed (minutter)',
            Name: 'Navn på ydelse (vises til kunden på matas.dk)',
            Summary: 'Kort beskrivelse af ydelsen (vises til kunden på matas.dk)',
            SummaryPlaceholder:
                'Denne tekst skal ganske kort beskrive hvad konsultationen går ud på. F.eks. Få personlig ekspert vejledning når vores Clinique konsulent besøger butikken. Hvis det er et event, så skriv her dato samt by. F.eks. Ole Henriksen Glow event den 19/4 i Matas Vejle.',
            PriceDisclaimer: 'Valgfri: Pris pligt tekst (vises til kunden på matas.dk)',
            PriceDisclaimerPlaceholder: 'Denne tekst skal beskrive krav eller undtagelser ved prisen',
            ConsultationType: 'Ydelsen afvikles som',
            BookingTypeSingleHeader: '1:1 konsultation',
            BookingTypeSingleSubtitle: 'Individuelle bookinger',
            BookingTypeEventHeader: 'Event',
            BookingTypeEventSubtitle: 'Flere deltagere. Fast afviklingstidpunkt',
            Price: 'Valgfri: Pris',
            Type: 'Vælg brand på ydelsen',
            Internal: 'No brand',
            External: 'Brand navn',
            InBooth: 'I kabine',
            IsVirtual: 'Online ydelse',
        },
        Locally: 'Lokalt',
        Modifier: 'Egenskaber',
        Name: 'Navn',
        NoBrand: 'No brand',
        SelectCategory: 'Vælg kategori',
        SelectExternalPartner: 'Vælg brand',
        ShowAsNews: 'Vis som Nyhed',
        StoreId: 'Butiks nummer',
        Title: 'Ydelser',
    },
    Dashboard: {
        BookingType: {
            header: 'Type',
            Single: 'Konsultation',
            Event: 'Event',
        },
        ErrorToBeforeFrom: 'Til dato kan ikke være før fra dato',
        Filter: {
            BookingType: 'Booking type',
            Consultants: 'konsulenter',
            ExternalPartner: 'brands',
            ConsultationProducts: 'ydelser',
        },
        Group: {
            Empty: '',
            Consultations: 'Generel',
            ConsultationsListElements: '',
            Events: 'Generel',
            EventsListElements: '',
            ClubMatasMemberBookings: 'Club Matas Medlem Bookinger',
            GhostBookings: 'Ghost Bookinger',
        },
        Metric: {
            ActiveBrands: 'Aktive brands',
            ActiveConsultants: 'Aktive konsulenter',
            ActiveStores: 'Aktive butikker',
            ActiveShifts: 'Aktive vagter',
            AssociatedBrands: 'Brands tilknyttet',
            AssociatedConsultants: 'Konsulenter tilknyttet',
            AssociatedStores: 'Butikker tilknyttet ',
            AverageBookingPerPerson: 'Gns. bookinger per kunde',
            BookingNoShows: 'Kunde udeblevet',
            GhostBookingNoShows: 'Ghost kunde udeblevet',
            BookingsPerBrand: 'Bookinger fordelt på brands',
            BookingsPerCategory: 'Bookinger fordelt på kategori',
            Empty: '',
            InactiveShifts: 'Lukkede vagter',
            OneToManySessions: 'Aktive/Afholdte event bookinger',
            OneToManySessionsCancelledByCustomer: 'Event bookinger aflyst af kunde',
            OneToManySessionsCancelledByMatas: 'Event bookinger aflyst af Matas',
            GhostOneToManySessions: 'Aktive/Afholdte ghost event bookinger',
            GhostOneToManySessionsCancelledByMatas: 'Ghost event bookinger aflyst af Matas',
            OneOnOneSessions: 'Aktive/Afholdte bookinger',
            OneToOneSessionsCancelledByCustomer: 'Bookinger aflyst af kunde',
            OneToOneSessionsCancelledByMatas: 'Bookinger aflyst af Matas',
            GhostOneOnOneSessions: 'Aktive/Afholdte ghost bookinger',
            GhostOneToOneSessionsCancelledByMatas: 'Ghost bookinger aflyst af Matas',
            PercentageBookingWithFavouriteStore: 'Bookinger i favorit butik',
            ShiftsPerBrand: 'Vagter fordelt på brands',
            OneToManySessionNoShow: 'Kunde udebelevet event',
            GhostOneToManySessionNoShow: 'Ghost kunde udebelevet event',
            PercentageEventBookingWithFavouriteStore: 'Event bookinger i favorit butik',
            AverageEventBookingPerPerson: 'Gns. event bookinger per kunde',
            ActiveEventTimes: 'Aktive events',
            ActiveEventTimesInStore: 'Aktive events i butik',
            ActiveEventTimesNotInStore: 'Aktive events uden for butik',
            InactiveEventTimes: 'Lukkede events',
            EventTimesPerCategory: 'Events fordelt på kategori',
            EventBookingsPerCategory: 'Event bookinger fordelt på kategori',
            EventBookingsPerBrand: 'Event bookinger fordelt på brand',
            EventTimesPerBrand: 'Events fordelt på brand',
            ConsultationProductsAndBookings: 'Ydelser og bookings',
            ConsultantsAndShifts: 'Konsulenter og vagter',
            ConsultationProductsAndEventBookings: 'Events og bookings',
            ConsultantsAndEvents: 'Konsulenter og events',
        },
        MetricHelpText: {
            ActiveBrands: 'Antal brands som har ydelser der er valgt på en vagt i defineret periode',
            ActiveConsultants: 'Antal konsulenter der har vagter i defineret periode',
            ActiveStores: 'Antal butikker der har vagter i defineret periode',
            ActiveShifts:
                'Vagter der er aktive i perioden. Hvis der er flere vagter på samme konsulent samme dag, så tælles den for 1 vagt.',
            AssociatedBrands: 'Antal brands der er aktiveret i bookingsystemet',
            AssociatedConsultants: 'Antallet af konsulenter tilknyttet bookingsystemet/den valgte butik',
            AssociatedStores: 'Butikker som har konsulenter tilknyttet',
            AverageBookingPerPerson: 'Gennemsnitlige aktive/afholdte bookinger pr kunde i perioden',
            BookingNoShows: 'Kunder udeblevet i perioden',
            GhostBookingNoShows: 'Ghost kunder udeblevet i perioden',
            BookingsPerBrand: 'Bookinger i perioden, fordelt på brands',
            BookingsPerCategory: 'Bookinger i perioden, fordelt på kategori',
            Empty: '',
            InactiveShifts:
                'Lukkede vagter der er i perioden. Hvis der er flere vagter på samme konsulent samme dag, så tælles den for 1 vagt.',
            OneToManySessions: 'Event bookinger som er afholdt eller skal holdes i perioden',
            OneToManySessionsCancelledByCustomer: 'Event bookinger aflyst af kunde som lå i perioden',
            OneToManySessionsCancelledByMatas: 'Event bookinger aflyst af Matas som lå i perioden',
            OneToManySessionNoShow: 'Kunder udeblevet fra events i perioden',
            GhostOneToManySessions: 'Ghost event bookinger som er afholdt eller skal holdes i perioden',
            GhostOneToManySessionsCancelledByMatas: 'Ghost event bookinger aflyst af Matas som lå i perioden',
            GhostOneToManySessionNoShow: 'Ghost kunder udeblevet fra events i perioden',
            OneOnOneSessions: 'Kundebookninger som er afholdt eller skal holdes i perioden',
            OneToOneSessionsCancelledByCustomer: 'Bookinger aflyst af kunde som lå i perioden',
            OneToOneSessionsCancelledByMatas: 'Bookinger aflyst af Matas som lå i perioden',
            GhostOneOnOneSessions: 'Ghost bookninger som er afholdt eller skal holdes i perioden',
            GhostOneToOneSessionsCancelledByMatas: 'Ghost bookinger aflyst af Matas som lå i perioden',
            PercentageBookingWithFavouriteStore:
                'Procentdelen af aktive/afholdte bookinger hvor kunden har valgt sin favorit butik',
            ShiftsPerBrand:
                'Vagter i perioden fordelt på brand per dag. Hvis der er flere vagter på samme konsulent samme dag, så tælles den for 1 vagt. ' +
                'Obs. da vagter kan have flere brands, vil samme vagt fremgå i tallet for hver brand der er valgt på vagten',
            PercentageEventBookingWithFavouriteStore:
                'Procentdelen af aktive/afholdte events bookinger hvor kunden har valgt sin favorit butik',
            AverageEventBookingPerPerson: 'Gennemsnitlige aktive/afholdte event bookinger pr kunde i perioden',
            ActiveEventTimes: 'Events som kan bookes i den valgte periode',
            ActiveEventTimesInStore: 'Events som kan bookes i den valgte periode, der afholdes i butikken',
            ActiveEventTimesNotInStore:
                'Events som kan bookes i den valgte periode, der afholdes på en anden addresse end i butikken',
            InactiveEventTimes: 'Events i perioden som er lukket for nye bookinger',
            EventTimesPerCategory: 'Events i perioden, fordelt på kategori',
            EventBookingsPerCategory: 'Event bookinger i perioden, fordelt på kategori',
            EventTimesPerBrand: 'Events i perioden fordelt på brand',
            EventBookingsPerBrand: 'Event bookinger i perioden, fordelt på brand',
            ConsultationProductsAndBookings: '',
            ConsultantsAndShifts: '',
            ConsultationProductsAndEventBookings: '',
            ConsultantsAndEvents: '',
        },
        MetricDetails: {
            NoData: 'Ingen data.',
            Store: 'Butik',
            StoreId: 'Butik ID',
            Value: 'Værdi',
            SearchDetails: 'Søg på butiks ID eller navn',
        },
        Stores: 'butikker',
        Exports: {
            Export: 'Eksportere',
            Dashboard: 'Dashboard',
            CancellationAndNoShows: 'Udeblivelser og aflysning',
        },
    },
    EventBooking: {
        Amount: 'Antal',
        CancelConsultation: 'Fjern bookning',
        ClubmatasMemberId: 'Clubmatas medlemsnummer (valgfri)',
        Create: 'Tilmeld',
        CreateBooking: 'Tilmeld kunde',
        ConfirmCancel:
            'Er du sikker på at du vil fjerne kundens tilmelding til eventet? Der vil blive sendt information på både mail, notifikation og sms til kunden.',
        ConfirmCustomerAbsent: 'Er du sikker?',
        ConsultationProduct: 'Ydelse',
        CouldNotGetUser: 'Kunne ikke hente kunden',
        CustomerAbsent: 'Kunde udeblevet',
        DownloadAll: 'Hent alle',
        EventBooking: 'Event booking',
        Event: 'Event',
        ExecutedBy: 'Afholdes af',
        FirstName: 'Fornavn (valgfri)',
        LastName: 'Efternavn (valgfri)',
        MemberName: 'Navn',
        Name: 'Navn',
        ReservedUntil: 'Reserveret indtil',
        SpacesBooked: 'Antal',
        SeeDetails: 'Se detaljer',
        SearchMember: 'Søg medlem via mobilnummer (valgfri)',
        TicketNumber: 'Billetnummer',
        TicketNumberShort: 'Billet ID',
        TicketPrice: 'Billet pris (pris for 1 billet)',
        Tickets: 'Billetter',
    },
    EventTime: {
        AddBreak: 'Tilføj Pause',
        ConfirmDelete: 'Er du sikker på at du vil aflyse dette event? Alle tilmeldte vil modtage mail og sms.',
        ConfirmDisable:
            'Er du sikker på at du vil lukke for booking på dette event? Man vil ikke længere kunne booke plads til eventet. Eksiterende bookninger vil stadig være gældende.',
        ConfirmEnable:
            'Er du sikker på at du vil åbne for bookinger på dette event? Man vil igen kunne booke tider til eventet.',
        CreateTitle: 'Opret event',
        Delete: 'Aflys event',
        Disable: 'Luk for bookinger',
        EditTile: 'Rediger event',
        Enable: 'Åben for bookinger',
        InvalidAddress: 'Vi kunne ikke slå addressen op.',
        MaxParticipants: 'Maks antal deltagere',
        MaxSpacesPerBooking: 'Maks bookinger per person',
        NoResults: 'Ingen resultater',
        NotHeldInStore: 'Afholdes ikke i butik',
        Price: 'Price',
        SelectService: 'Vælg ydelse',
        Service: 'Ydelse',
        SearchBookings: 'Søg på navn eller mobilnummer',
    },
    Error: {
        ConsultantFutureBookings: 'Konsulenten har bookinger i fremtiden, du skal flytte bookingerne først.',
        EmailAlreadyInUse: 'Emailen er allederede i brug.',
        FutureBookingsInStore: 'Konsulenten har bookinger i butikken, du skal flytte bookingerne først.',
        MemberIsNotEmployee: 'Det fundne club medlem er ikke en medarbejder.',
        MobileNumberAlreadyInUse: 'Mobilnummeret er allerede i brug.',
        MobileNumberAlreadyInUseOnEmployee: 'Mobilnummeret tilhører en ansat ved Matas.',
        PrerequisiteError: 'Der er en konflikt mellem eksiterende data og det du er ved at lave',
        TimeOverlapError: 'Tidsrumet overlapper med et andet',
    },
    ExternalPartner: {
        ActivateConfirmText: 'Er du sikker på at du vil aktivere brandet "{{name}}"?',
        BrandIdInput: 'Brand id tilknyttet brandet',
        CreateTitle: 'Opret brand',
        CreateTitleWarning: 'OBS! Brands bør oprettes i ax',
        CreateTooltip: 'Opret et nyt brand, vær opmærksom på at det bør komme fra ax',
        CreateTooltipWarning: 'Kig i inaktive brands først, da de bør komme fra ax',
        DeleteConfirmText: 'Er du sikker på at du vil deaktivere brandet "{{name}}"?',
        DeactivateConfirmText: 'Er du sikker på at du vil deaktivere brandet "{{name}}"?',
        EditTile: 'Rediger brand',
        Name: 'Navn',
        NameInput: 'Navn (vises til kunden på matas.dk)',
        Title: 'Brands',
        UnDeleteConfirmText: 'Er du sikker på at du vil aktivere brandet "{{name}}"?',
    },
    Filter: {
        AutoUpdateSeconds: 'Opdater hver x sekund',
        FilterFor: 'Filtrer efter',
        FromDate: 'Fra dato',
        SelectOption: 'Alle {{label}}',
        ShowBookings: 'Vis bookinger',
        ShowDeleted: 'Vis slettede',
        ShowDisabled: 'Vis inaktive',
        ShowInBooth: 'Vis i kabine',
        ShowIsVirtual: 'Vis online',
        ToDate: 'Til dato',
        Select: 'Vælg {{label}}',
        Selected: '{{count}} {{label}} valgt',
        SelectedOptions: 'Valgte {{label}}',
        SearchType: 'Søg {{label}}',
        Search: 'Søg',
        DeselectAll: 'Fravælg alle',
        CloseSelectMultiple: 'Se tal',
        SelectedNotShown: 'Der er valgte {{label}} som ikke vises på grund af søgningen',
    },
    Hello: 'Hi',
    ImageChooser: {
        DragAndDropHere: 'Træk et billede ind i feltet eller klik',
    },
    MatasBooking: 'Matas Booking',
    Menu: {
        Links: {
            Calendar: 'Kalender',
            Categories: 'Kategorier',
            Consultants: 'Konsulenter',
            ConsultationProduct: 'Ydelser',
            Dashboard: 'Dashboard',
            ExternalPartners: 'Brands',
            Overview: 'Oversigt',
        },
        Logout: 'Log ud',
        Manual: 'Se Manual',
        ManualPos: 'Se Manual til POS',
        SelectStore: 'Vælg en butik',
        WelcomeText: 'Velkommen {{username}}',
    },
    Overview: {
        Bookings: '{{bookings}} bookinger',
        ConsultationBookingPercent: 'Viser hvor meget vagterne er booket',
        Events: '{{count}} events {{eventBookings}} deltagere',
        EventBookingPercent: 'Viser hvor meget eventene er booket',
        ColorLegend: {
            Shift: 'Åbne vagter',
            EmptyBookings: 'Bookinger uden vagt',
            Events: 'Event',
            Conflicting: 'Flere forskellige',
        },
    },
    Shared: {
        Action: 'Muligheder',
        Active: 'Aktiv',
        Address: {
            LocationName: 'Stednavn',
            StreetName: 'Vejnavn og nummer',
            ZipCode: 'Postnummer',
            City: 'By',
        },
        Amount: 'Antal',
        Back: 'Tilbage',
        Booking: "Booking",
        Break: 'Pause',
        Cancel: 'Annuller',
        ChangeDate: 'Skift dato',
        ChangeFromTime: 'Skift start tid',
        ChangeToTime: 'Skift slut tid',
        Close: "Luk vindue",
        ClubMatasMemberId: 'Club Matas Id',
        ClubMatasPlusMemberId: "Club Matas Id (Plus)",
        Create: 'Opret',
        CreateNew: 'Opret ny',
        Customer: 'Kunde',
        Day: 'Dag',
        Delete: 'Slet',
        Deleted: 'Slettet',
        DeselectAll: 'Fravælg alle',
        Disable: 'Deaktiver',
        Edit: 'Rediger',
        Email: 'E-mail',
        Enable: 'Aktiver',
        End: 'Slut',
        External: 'Ekstern',
        Externals: 'Eksterne',
        Hide: 'Vis',
        Image: 'Billede',
        Image169: 'Billede 16:9 (bruges som standard)',
        Image11: 'PLP billede 6:5 (valgfri)',
        Inactive: 'Inaktiv',
        Internal: 'Intern',
        Internals: 'Interne',
        MobileNumber: 'Mobilnummer',
        Name: 'Navn',
        No: 'Nej',
        Percentage: 'Procentdel',
        Remember: 'Husk',
        Save: 'Gem',
        SelectAll: 'Vælg alle',
        SelectStoreToViewPage: 'Vælg en butik i øverste højre hjørne for at se denne side',
        Show: 'Skjul',
        ShowLess: 'Vis færre',
        ShowMore: 'Vis flere',
        Search: 'Søg',
        SeeDetails: 'Se detaljer',
        Select: 'Vælg',
        Start: 'Start',
        Status: 'Status',
        Type: 'Type',
        TimeOfDay: 'Tidspunkt',
        Yes: 'Ja',
        UserComment: "Kundens kommentar"
    },
    ValidationError: {
        ActiveTimeSpanInvalid: 'Start og slut tid er forkert, sandsynligvis fordi slut tid ikke er efter start tid',
        ActiveTimeSpansOverlap: 'Der er overlappende tider',
        CollectionIsNullOrEmpty: 'Der skal vælges mindst en mulighed',
        DateInThePast: 'Datoen eller tidspunktet må ikke være i fortiden',
        EmailNotValid: 'Det skal være en valid email adresse',
        OutOfRange: 'Værdien skal være mellem {{minimum}} og {{maximum}}',
        Required: 'Feltet må ikke være tomt',
        StringTooLong: 'Teksten i feltet må maksimalt være {{length}} karakterer',
        Unknown: 'Ukendt fejl',
        ValueNotInEnum: 'Du skal vælge en værdi',
        RecommendedCharacters: '{{current}}/{{recommended}} anbefalet maks tegn',
        MaximumCharacters: '{{current}}/{{maximum}} maks tegn',
    },
};

export default da;
