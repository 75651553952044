import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Grid, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { DropzoneArea } from 'react-mui-dropzone';
import { GetMediumThumbnailUrl, UploadFile } from 'autogen/swagger/Media';
import styled from 'styled-components';

const ImageContainer169 = styled.div`
    position: relative;
    width: 98.7%;
    height: 0;
    padding-top: 55.5%;
    background-color: #efecea;
    border-radius: 4px;
    box-shadow: 1px 1px 5px;
`;

const ImageContainer11 = styled.div`
    position: relative;
    width: 66.5%;
    height: 0;
    padding-top: 55.5%;
    background-color: #efecea;
    border-radius: 4px;
    box-shadow: 1px 1px 5px;
`;

const Image = styled.div<{ imgSrc: string }>`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 4px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-image: url(${({ imgSrc }): string => imgSrc});
`;

interface IImageChooserProps {
    mediumId?: number;
    onMediumIdChange: (mediumId: number) => void;
    error?: boolean;
    helperText?: string;
    onImageLoading?: (loading: boolean) => void;
    disabled?: boolean;
    previewStyle?: 'None' | 'Wide' | 'Square';
}

export function ImageChooser(props: IImageChooserProps): JSX.Element {
    const { mediumId, onMediumIdChange, error, helperText, onImageLoading, disabled, previewStyle } = props;
    const [src, setSrc] = useState('');
    const [imageLoading, setImageLoading] = useState(false);

    const { t } = useTypedTranslation();

    async function onFileChange(files: File[]): Promise<void> {
        const file = files[0];

        if (!file) return;
        try {
            setImageLoading(true);

            const result = await UploadFile({ file });

            onMediumIdChange(result);
        } finally {
            setImageLoading(false);
        }
    }

    useEffect(() => {
        if (mediumId) {
            (async (): Promise<void> => {
                try {
                    setImageLoading(true);

                    const url = await GetMediumThumbnailUrl({ mediumId });
                    setSrc(url);
                } finally {
                    setImageLoading(false);
                }
            })();
        }
    }, [mediumId]);

    useEffect(() => {
        if (onImageLoading) onImageLoading(imageLoading);
    }, [imageLoading, onImageLoading]);

    const hasSrc = !!src;

    const color = error ? 'error' : 'initial';

    const title = () => {
        switch (previewStyle) {
            case 'Wide':
                return t('Shared', 'Image169');
            case 'Square':
                return t('Shared', 'Image11');
            default:
                return t('Shared', 'Image');
        }
    };

    const imagePreview = () => {
        switch (previewStyle) {
            case 'Wide':
                return (
                    <ImageContainer169>
                        <Image imgSrc={src} />
                    </ImageContainer169>
                );
            case 'Square':
                return (
                    <ImageContainer11>
                        <Image imgSrc={src} />
                    </ImageContainer11>
                );
            default:
                return <img src={src} alt="" />;
        }
    };

    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <Grid xs={12} item>
                    <Typography color={color}>{title()}</Typography>
                </Grid>
                <Grid item xs={8} style={{ position: 'relative' }}>
                    <DropzoneArea
                        key={src}
                        acceptedFiles={['image/*']}
                        filesLimit={1}
                        dropzoneText={t('ImageChooser', 'DragAndDropHere')}
                        onChange={onFileChange}
                        showAlerts={false}
                        maxFileSize={999999999}
                    />
                    <Backdrop
                        open={disabled || false}
                        style={{
                            position: 'absolute',
                            zIndex: '100',
                        }}
                    />
                </Grid>
                <Grid item xs={4}>
                    <Grid container justifyContent="center" alignItems="center">
                        {imageLoading && <CircularProgress size={70} />}
                        {hasSrc && !imageLoading && imagePreview()}
                    </Grid>
                </Grid>
                <Grid xs={12} item>
                    {!!helperText && <Typography color={color}>{helperText}</Typography>}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ImageChooser;
