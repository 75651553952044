/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTypedTranslation } from 'translations';
import {
    Button,
    CircularProgress,
    Grid,
    Icon,
    TextField,
    Tooltip,
    tooltipClasses,
    TooltipProps,
    Typography,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import {
    BookingWithUserDetailedModel,
    CancelBooking,
    ChangeConsultantForBooking,
    Consultant,
    GetAvailableConsultantsForBooking,
    GetDetailedBooking,
    SetCustomerAbsentForBooking,
} from 'autogen/swagger/Booking';
import { AuthRole } from 'autogen/swagger/Users';
import moment from 'moment';
import LabledText from 'components/display/LabledText';
import SpinnerButton from 'components/button/SpinnerButton';
import { IsInRole } from 'helpers/userHelper';
import { formatConsultationProductName } from 'helpers/string';
import { InfoOutlined } from '@mui/icons-material';
import CalendarItemChip from '../Items/CalendarItemChip';

interface IEditBookingProps {
    id: number;
    role: AuthRole;
    onComplete?: (update: boolean) => void;
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#002643',
        color: '#fff',
        maxWidth: '193px',
        fontSize: '12px',
        padding: '8px 12px',
        '& :before': {
            backgroundColor: '#002643',
        },
    },
}));

export function EditBooking(props: IEditBookingProps): JSX.Element {
    const { id, onComplete, role } = props;
    const { t, tf } = useTypedTranslation();
    const [booking, setBooking] = useState<BookingWithUserDetailedModel>();
    const [values, setValues] = useState({
        consultantId: 0,
    });
    const [consultants, setConsultants] = useState<Consultant[]>([]);
    const [savingConsultant, setSavingConsultant] = useState(false);
    const [savingAbsentOrCancel, setSavingAbsentOrCancel] = useState(false);

    const isPastBooking = booking
        ? moment().isSameOrAfter(moment(booking?.bookingDetailed?.activeTime.activeFromDate), 'minute')
        : false;

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await GetDetailedBooking({ bookingId: id });
            setBooking(result);
            setValues({
                consultantId: result.bookingDetailed.consultant.id,
            });
        })();

        if ((role === 'AdminRole' || role === 'StoreManagerRole') && !isPastBooking) {
            (async (): Promise<void> => {
                const result = await GetAvailableConsultantsForBooking({ bookingId: id });
                setConsultants(result);
            })();
        } else {
            setConsultants([]);
        }
    }, [id, isPastBooking, role]);

    async function saveConsultant(): Promise<void> {
        try {
            setSavingConsultant(true);
            const result = await ChangeConsultantForBooking({ bookingId: id, consultantId: values.consultantId });

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSavingConsultant(false);
        }
    }

    async function onCancelBooking(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('Booking', 'ConfirmCancel'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await CancelBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    async function onCustomerAbsent(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('Booking', 'ConfirmCustomerAbsent'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await SetCustomerAbsentForBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    const customerAbsent = booking?.bookingDetailed.booking.status === 'UserWasAbsent';

    const clubMatasMemberIdLabel = booking?.memberPotential?.isPlusMember
        ? t('Shared', 'ClubMatasPlusMemberId')
        : t('Shared', 'ClubMatasMemberId');

    const isBrandConsultation =
        booking?.bookingDetailed?.consultationProductDetailed?.consultationProductMasterDetailed?.externalPartner
            ?.hasBrandClub;
    const isBrandClubRelevance =
        isBrandConsultation && !!booking?.clubMatasMemberId && !booking?.memberPotential?.isBrandClubMember;

    const isPlusRelevance =
        !!booking?.clubMatasMemberId &&
        !booking?.memberPotential?.isPlusMember &&
        booking?.memberPotential?.isPlusRelevance &&
        !!booking?.memberPotential?.plusRelevanceSavedAmount;

    const plusRelevanceSavedAmount =
        booking?.memberPotential?.plusRelevanceSavedAmount &&
        Math.round(booking.memberPotential.plusRelevanceSavedAmount);

    const showMemberPotential = isBrandClubRelevance || isPlusRelevance;

    if (!booking)
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );

    return (
        <Grid container direction="column" justifyContent="flex-start" item xs gap={4}>
            <Grid container item>
                <Grid item xs>
                    <Typography variant="h4">
                        {formatConsultationProductName(
                            booking.bookingDetailed.consultationProductDetailed.name,
                            booking.bookingDetailed.consultationProductDetailed.consultationProductMasterDetailed
                                .externalPartner?.name,
                        )}
                    </Typography>
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid
                    container
                    xs={12}
                    sm={6}
                    gap={2}
                    item
                    direction="column"
                    justifyContent="flex-start"
                    sx={{
                        borderRight: {
                            sm: '1px solid #CDCED0',
                        },
                    }}
                    style={{
                        paddingTop: 0,
                    }}
                >
                    <Grid container item gap={2}>
                        <Grid item>
                            <Typography variant="h5">{t('Booking', 'Customer')}</Typography>
                        </Grid>
                        <Grid container item direction="column">
                            <Grid item>
                                {booking.firstName ? (
                                    <Typography variant="body1">
                                        {`${booking.firstName} ${booking.lastName}`}
                                    </Typography>
                                ) : (
                                    <Typography variant="body1" color="error">
                                        {t('Booking', 'CouldNotGetUser')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item>
                                {booking.mobileNumber && (
                                    <Typography variant="body1">{`${booking.mobileNumber}`}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <LabledText label={clubMatasMemberIdLabel} text={`${booking?.clubMatasMemberId}`} />
                    </Grid>
                    {showMemberPotential && (
                        <Grid container item direction="column">
                            <Grid container item direction="row" spacing={1}>
                                <Grid item>
                                    <Typography variant="h6">{t('Booking', 'MemberPotential')}</Typography>
                                </Grid>
                                <Grid item>
                                    <HtmlTooltip
                                        arrow
                                        placement="top"
                                        title={
                                            <>
                                                {isPlusRelevance &&
                                                    tf(
                                                        {
                                                            savedAmount: `${plusRelevanceSavedAmount}`,
                                                        },
                                                        'Booking',
                                                        'MemberPotentialPlusRelevance',
                                                    )}
                                                {isPlusRelevance && (
                                                    <>
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {isBrandClubRelevance && t('Booking', 'MemberPotentialBrandClub')}
                                                {isBrandClubRelevance && (
                                                    <>
                                                        <br />
                                                        <br />
                                                    </>
                                                )}
                                                {t('Booking', 'MemberPotentialInfo')}
                                            </>
                                        }
                                    >
                                        <Icon
                                            sx={{
                                                width: '1rem',
                                                height: '1rem',
                                                fontSize: '1rem',
                                                verticalAlign: 'middle',
                                                '& .MuiSvgIcon-root': {
                                                    width: '1rem',
                                                    height: '1rem',
                                                    fontSize: '1rem',
                                                },
                                            }}
                                        >
                                            <InfoOutlined />
                                        </Icon>
                                    </HtmlTooltip>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={1}>
                                {isPlusRelevance && (
                                    <Grid item>
                                        <CalendarItemChip
                                            label={tf(
                                                {
                                                    savedAmount: `${plusRelevanceSavedAmount}`,
                                                },
                                                'Booking',
                                                'IsPlusRelevanceSavedAmount',
                                            )}
                                        />
                                    </Grid>
                                )}
                                {isBrandClubRelevance && (
                                    <Grid item>
                                        <CalendarItemChip label={t('Booking', 'IsBrandClubMember')} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
                <Grid
                    container
                    xs={12}
                    sm={6}
                    gap={2}
                    item
                    direction="column"
                    justifyContent="flex-start"
                    style={{
                        paddingTop: 0,
                    }}
                >
                    <Grid container item gap={2}>
                        <Grid item>
                            <Typography variant="h5">{t('Shared', 'Booking')}</Typography>
                        </Grid>
                        <Grid container item direction="column">
                            <Grid item>
                                <Typography variant="body1">
                                    {moment(booking?.bookingDetailed.activeTime.activeFromDate).format('DD-MM-yyyy')}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Typography variant="body1">
                                    {`${moment(booking?.bookingDetailed.activeTime.activeFromDate).format(
                                        'HH:mm',
                                    )} - ${moment(booking?.bookingDetailed.activeTime.activeToDate).format('HH:mm')}`}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container gap={2}>
                        {(role === 'AdminRole' || role === 'StoreManagerRole') && !isPastBooking ? (
                            <>
                                <Grid item>
                                    <Typography variant="h6">{t('Booking', 'ExecutedBy')}</Typography>
                                </Grid>
                                <Grid item xs={9}>
                                    {consultants.length ? (
                                        <Autocomplete
                                            options={consultants}
                                            size="small"
                                            value={consultants.find((x) => values.consultantId === x.id) || null}
                                            onChange={(e, value): void =>
                                                setValues({
                                                    ...values,
                                                    consultantId: value?.id || 0,
                                                })
                                            }
                                            groupBy={(option): string =>
                                                option.isExternal ? t('Shared', 'External') : t('Shared', 'Internal')
                                            }
                                            getOptionLabel={(option): string =>
                                                `${option.firstName} ${option.lastName}`
                                            }
                                            fullWidth
                                            renderInput={(params): JSX.Element => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                />
                                            )}
                                        />
                                    ) : (
                                        <CircularProgress size={20} />
                                    )}
                                </Grid>
                                <Grid item xs={3}>
                                    <SpinnerButton
                                        variant="contained"
                                        color="primary"
                                        disabled={
                                            !values.consultantId ||
                                            values.consultantId === booking.bookingDetailed.consultant.id
                                        }
                                        loading={savingConsultant}
                                        onClick={saveConsultant}
                                    >
                                        {t('Shared', 'Save')}
                                    </SpinnerButton>
                                </Grid>
                            </>
                        ) : (
                            <Grid item xs={9}>
                                <LabledText
                                    label={t('Booking', 'ExecutedBy')}
                                    text={`${booking.bookingDetailed.consultant.firstName} ${booking.bookingDetailed.consultant.lastName}`}
                                />
                            </Grid>
                        )}
                    </Grid>
                    {booking?.bookingDetailed.booking.userComment && (
                        <Grid container item spacing={2}>
                            <Grid item lg={12} xs={12} md={12}>
                                <LabledText
                                    label={t('Shared', 'UserComment')}
                                    text={`${booking?.bookingDetailed.booking.userComment}`}
                                />
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Grid>

            {customerAbsent && (
                <Grid container item gap={2}>
                    <Grid item xs={12}>
                        <LabledText label={t('Shared', 'Status')} color="error" text={t('Booking', 'CustomerAbsent')} />
                    </Grid>
                </Grid>
            )}
            <Grid
                container
                item
                justifyContent="end"
                gap={2}
                sx={{
                    backgroundColor: '#F7F6F5',
                    margin: '0 -24px -24px',
                    padding: '16px',
                    width: 'auto',
                }}
            >
                <Grid item>
                    {isPastBooking ? (
                        !customerAbsent && (
                            <SpinnerButton
                                variant="contained"
                                color="secondary"
                                onClick={onCustomerAbsent}
                                loading={savingAbsentOrCancel}
                            >
                                {t('Booking', 'CustomerAbsent')}
                            </SpinnerButton>
                        )
                    ) : IsInRole(role, 'AdminRole', 'StoreManagerRole', 'ConsultantRole') ? (
                        <SpinnerButton
                            variant="contained"
                            color="secondary"
                            onClick={onCancelBooking}
                            loading={savingAbsentOrCancel}
                        >
                            {t('Booking', 'CancelConsultation')}
                        </SpinnerButton>
                    ) : (
                        <>{null}</>
                    )}
                </Grid>
                <Grid item>
                    {booking.bookingDetailed.consultationProductDetailed.consultationProductMasterDetailed
                        .isVirtual && (
                        <Button
                            variant="contained"
                            color="success"
                            href={booking.virtualAgentUrl ?? ''}
                            target="_blank"
                            disabled={!booking.virtualAgentUrl}
                        >
                            {t('Booking', 'VirtualAgentUrl')}
                        </Button>
                    )}
                </Grid>
                <Grid item>
                    <Button variant="contained" onClick={onCancelClick}>
                        {t('Shared', 'Close')}
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EditBooking;
