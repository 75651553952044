import React, { ComponentType, useState, useCallback, useEffect } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { UserState, actionCreators } from 'store/User';
import { Grid, Typography, Button, useMediaQuery, Theme, ButtonGroup, CircularProgress, Drawer } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@mui/icons-material';
import {
    ListOverviewItems,
    OverviewConsultant,
    OverviewItem,
    Consultant,
    CalendarItemShift,
    CalendarItemBooking,
    CalendarItemEvent,
    EventBookingViewModel,
} from 'autogen/swagger/Consultant';
import { DefaultModal } from 'components/modal/DefaultModal';
import moment, { Moment } from 'moment';
import { useHistory } from 'react-router';
import { Filter, IFilter } from 'components/Input/Filter';
import { useInterval } from 'helpers/hooks';
import { IsInRole } from 'helpers/userHelper';
import CreateOrEditEventTime from 'pages/calendar/components/Overlays/CreateOrEditEventTime';
import EditEventBooking from 'pages/calendar/components/Overlays/EditEventBooking';
import EventTimeBookingsDrawer from 'pages/calendar/components/Overlays/EventTimeBookingsDrawer';
import ColorLegend, { IColorLegend } from 'components/display/ColorLegend';
import CreateEventBookingComponent from 'pages/calendar/components/Overlays/CreateEventBooking';
import EditBooking from './components/Overlays/EditBooking';
import OverviewCalendar from './components/Layout/OverviewCalendar';
import OverviewDrawer from './components/Overlays/OverviewDrawer';
import CreateOrEditShift from './components/Overlays/CreateOrEditShift';

type IOverviewPageFilterValues = {
    autoUpdateSeconds: number;
};

interface IOverviewHistoryValues {
    startOfSelectedWeek?: string;
    autoUpdateSeconds?: number;
}

type IOverviewPageProps = typeof actionCreators & UserState;

export function OverviewPage(props: IOverviewPageProps): JSX.Element {
    const { role, selectedStoreId, selectableStores } = props;
    const history = useHistory();
    const historyValues = (history.location.state as IOverviewHistoryValues) || {};
    const { t, tf } = useTypedTranslation();
    const [startOfSelectedWeek, setStartOfSelectedWeek] = useState(
        historyValues.startOfSelectedWeek ? moment(historyValues.startOfSelectedWeek) : moment().startOf('isoWeek'),
    );
    const [shiftModalState, setShiftModalState] = useState<{
        open: boolean;
        shiftId?: number;
        consultant?: Consultant;
    }>({ open: false });
    const [bookingModalState, setBookingModalState] = useState<{ open: boolean; bookingId?: number }>({ open: false });
    const [itemDrawerState, setItemDrawerState] = useState<{
        open: boolean;
        overviewItem?: OverviewItem;
        consultant?: Consultant;
    }>({
        open: false,
    });
    const [eventTimeModalState, setEventTimeModalState] = useState<{ open: boolean; eventTimeId?: number }>({
        open: false,
    });
    const [eventBookingModalState, setEventBookingModalState] = useState<{
        open: boolean;
        eventBookingId?: number;
        callback?: () => void;
    }>({
        open: false,
    });
    const [createEventBookingModalState, setCreateEventBookingModalState] = useState<{
        open: boolean;
        eventTime?: CalendarItemEvent;
    }>({
        open: false,
    });
    const [eventTimeDrawerState, setEventTimeDrawerState] = useState<{
        open: boolean;
        eventTime?: CalendarItemEvent;
        consultant?: Consultant;
    }>({
        open: false,
    });
    const [overviewConsultants, setOverviewConsultants] = useState<OverviewConsultant[]>([]);
    const [loading, setLoading] = useState(false);
    const [filterValues, setFilterValues] = useState<IOverviewPageFilterValues>({
        autoUpdateSeconds: historyValues.autoUpdateSeconds || 120,
    });
    const [lastUpdated, setLastUpdated] = useState<Moment>();

    const filters: IFilter<IOverviewPageFilterValues>[] = [
        {
            label: t('Filter', 'AutoUpdateSeconds'),
            paramName: 'autoUpdateSeconds',
            type: 'number',
            size: '150px',
        },
    ];

    function onFilterChange(values: IOverviewPageFilterValues): void {
        setFilterValues(values);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            autoUpdateSeconds: values.autoUpdateSeconds,
        });
    }

    const fetchOverviewItems = useCallback(async () => {
        if (selectedStoreId) {
            const result = await ListOverviewItems({
                fromDate: startOfSelectedWeek.toISOString(true),
                toDate: startOfSelectedWeek.clone().endOf('isoWeek').toISOString(true),
            });

            setOverviewConsultants(result);
            setLastUpdated(moment());
        }
    }, [startOfSelectedWeek, selectedStoreId]);

    const fetchOverviewLoading = useCallback(async () => {
        try {
            setLoading(true);
            await fetchOverviewItems();
        } finally {
            setLoading(false);
        }
    }, [fetchOverviewItems]);

    useInterval(() => {
        fetchOverviewItems();
    }, (filterValues.autoUpdateSeconds > 10 || filterValues.autoUpdateSeconds === 0 ? filterValues.autoUpdateSeconds : 10) * 1000);

    useEffect(() => {
        if (selectedStoreId) fetchOverviewLoading();
    }, [fetchOverviewLoading, selectedStoreId]);

    useEffect(() => {
        if (itemDrawerState.open && itemDrawerState.consultant) {
            const consultantObj = overviewConsultants.find((x) => x.consultant.id === itemDrawerState.consultant?.id);
            const currentDate = moment(itemDrawerState.overviewItem?.activeTime.activeFromDate);
            const item = consultantObj?.items.find((x) =>
                currentDate.isSame(moment(x.activeTime.activeFromDate), 'day'),
            );
            if (item && consultantObj?.consultant) {
                setItemDrawerState((prev) => ({ ...prev, overviewItem: item, consultant: consultantObj.consultant }));
            }

            if (eventTimeDrawerState.open && eventTimeDrawerState.eventTime) {
                const eventTimeItem = item?.overviewShifts.find(
                    (x) => x.eventTime?.id === eventTimeDrawerState.eventTime?.id,
                );
                if (eventTimeItem) {
                    setEventTimeDrawerState((prev) => ({ ...prev, eventTime: eventTimeItem.eventTime }));
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overviewConsultants]);

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    function addWeeks(weeks: number): void {
        const newDate = startOfSelectedWeek.clone().add(weeks, 'weeks');
        setStartOfSelectedWeek(newDate);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            startOfSelectedWeek: newDate.toISOString(true),
        });
    }

    function openShiftModal(shift: CalendarItemShift, consultant: Consultant): void {
        if (!IsInRole(role, 'AdminRole', 'StoreManagerRole', 'ConsultantRole')) return;
        if (shift && selectableStores.every((x) => x.id !== shift.storeId)) return;

        setShiftModalState({
            ...shiftModalState,
            shiftId: shift.id,
            open: true,
            consultant,
        });
    }

    function handleCloseShiftModal(): void {
        setShiftModalState({ ...shiftModalState, open: false });
    }

    function onShiftModalComplete(update: boolean): void {
        handleCloseShiftModal();
        if (update) {
            fetchOverviewItems();
        }
    }

    function openBookingModal(booking: CalendarItemBooking): void {
        if (selectableStores.every((x) => x.id !== booking.storeId)) return;

        setBookingModalState({ ...bookingModalState, bookingId: booking.id, open: true });
    }

    function handleCloseBookingModal(): void {
        setBookingModalState({ ...bookingModalState, open: false });
    }

    function onBookingModalComplete(update: boolean): void {
        handleCloseBookingModal();
        if (update) {
            fetchOverviewItems();
        }
    }

    function openItemDrawer(overviewItem: OverviewItem, consultant: Consultant): void {
        setItemDrawerState({ open: true, overviewItem, consultant });
    }

    function closeItemDrawer(): void {
        setItemDrawerState({ open: false, overviewItem: undefined, consultant: undefined });
    }

    function openEventTimeModal(eventTime?: CalendarItemEvent): void {
        if (eventTime && selectableStores.every((x) => x.id !== eventTime.storeId)) return;

        setEventTimeModalState((prev) => ({ ...prev, eventTimeId: eventTime ? eventTime.id : 0, open: true }));
    }

    function handleCloseEventTimeModal(): void {
        setEventTimeModalState((prev) => ({ ...prev, open: false }));
    }

    function onEventTimeModalComplete(update: boolean): void {
        handleCloseEventTimeModal();
        if (update) {
            fetchOverviewItems();
        }
    }

    function openEventBookingModal(booking: EventBookingViewModel, callback?: () => void): void {
        setEventBookingModalState({ ...bookingModalState, eventBookingId: booking.id, open: true, callback });
    }

    function handleCloseEventBookingModal(): void {
        setEventBookingModalState({ ...bookingModalState, open: false, callback: undefined });
    }

    function onEventBookingModalComplete(update: boolean): void {
        const { callback } = eventBookingModalState;
        handleCloseEventBookingModal();
        if (update) {
            fetchOverviewItems();
            if (callback) {
                callback();
            }
        }
    }

    function openCreateEventBookingModal(eventTime: CalendarItemEvent): void {
        if (eventTime && selectableStores.every((x) => x.id !== eventTime.storeId)) return;

        setCreateEventBookingModalState((prev) => ({ ...prev, open: true, eventTime }));
    }

    function handleCloseCreateEventBookingModal(): void {
        setCreateEventBookingModalState((prev) => ({ ...prev, open: false, eventTime: undefined }));
    }

    function onCreateEventBookingModalComplete(update: boolean): void {
        handleCloseCreateEventBookingModal();
        if (update) {
            fetchOverviewItems();
        }
    }

    function openEventTimeDrawerState(eventTime?: CalendarItemEvent, consultant?: Consultant): void {
        setEventTimeDrawerState({ open: true, eventTime, consultant });
    }

    function closeEventTimeDrawerState(): void {
        setEventTimeDrawerState({ open: false, eventTime: undefined, consultant: undefined });
    }

    if (!selectedStoreId) return <div>{t('Shared', 'SelectStoreToViewPage')}</div>;

    const colorLegends: IColorLegend[] = [
        { color: '#D1FAE5', borderColor: '#10B981', text: t('Overview', 'ColorLegend', 'Shift') },
        { color: '#E0E7FF', borderColor: '#a6b6ff', text: t('Overview', 'ColorLegend', 'Events') },
        { color: '#E0F2FE', borderColor: '#6dc5ff', text: t('Overview', 'ColorLegend', 'EmptyBookings') },
        { color: '#FFFFFF', borderColor: '#9ca3af', text: t('Overview', 'ColorLegend', 'Conflicting') },
    ];

    return (
        <Grid
            container
            gap={2}
            item
            style={{
                width: 'calc(100vw - 9px)',
                position: 'relative',
                left: '50%',
                right: '50%',
                marginLeft: '-50vw',
                marginRight: '-50vw',
                padding: largeScreen ? '0 30px' : '0 20px',
                height: '100%',
            }}
            direction="column"
            wrap="nowrap"
        >
            <Grid item>
                <Grid container gap={2} direction={largeScreen ? 'row' : 'column'} alignItems="center">
                    <Grid
                        item
                        xs
                        container
                        alignItems={largeScreen ? 'center' : 'flex-start'}
                        direction={largeScreen ? 'row' : 'column'}
                    >
                        <Typography variant="h3">
                            {tf(
                                {
                                    weekNumber: startOfSelectedWeek.isoWeek(),
                                },
                                'Calendar',
                                'WeekNumber',
                            )}{' '}
                        </Typography>
                        <ButtonGroup
                            variant="contained"
                            color="inherit"
                            style={largeScreen ? { marginLeft: '15px' } : { marginTop: '15px' }}
                        >
                            <Button onClick={(): void => addWeeks(-1)}>
                                <KeyboardArrowLeft />
                            </Button>
                            <Button onClick={(): void => addWeeks(1)}>
                                <KeyboardArrowRight />
                            </Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item container justifyContent="center" xs>
                        <Filter
                            filters={filters}
                            onChange={onFilterChange}
                            filterValues={filterValues}
                            justify="center"
                        />
                    </Grid>
                    <Grid item>
                        <ColorLegend legends={colorLegends} />
                    </Grid>
                    <Grid item xs>
                        <Grid container justifyContent="flex-end" alignItems="flex-end" style={{ height: '100%' }}>
                            <Grid item>
                                <Typography variant="body2">
                                    {lastUpdated &&
                                        tf(
                                            { dateTime: lastUpdated?.format('HH:mm:ss') },
                                            'Calendar',
                                            'LastUpdatedDate',
                                        )}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {loading ? (
                <Grid container item justifyContent="center" alignItems="center" xs>
                    <CircularProgress />
                </Grid>
            ) : (
                <Grid container item xs direction={largeScreen ? 'row' : 'column'}>
                    <Grid item xs style={{ position: 'relative' }}>
                        <Grid container direction="column">
                            <OverviewCalendar
                                overviewConsultants={overviewConsultants}
                                fromDate={startOfSelectedWeek}
                                toDate={startOfSelectedWeek.clone().endOf('isoWeek')}
                                openItemDrawer={openItemDrawer}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <DefaultModal
                open={shiftModalState.open}
                onClose={handleCloseShiftModal}
                closeOnBackdrop={false}
                maxWidth="md"
            >
                {shiftModalState.open && shiftModalState.consultant ? (
                    <CreateOrEditShift
                        onComplete={onShiftModalComplete}
                        consultant={shiftModalState.consultant}
                        id={shiftModalState.shiftId}
                        currentSelectedWeekDate={startOfSelectedWeek}
                        role={role}
                        selectedStoreId={selectedStoreId}
                        selectableStores={selectableStores}
                    />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <DefaultModal open={bookingModalState.open} onClose={handleCloseBookingModal} maxWidth="sm">
                {bookingModalState.bookingId ? (
                    <EditBooking onComplete={onBookingModalComplete} id={bookingModalState.bookingId} role={role} />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <Drawer anchor="right" open={itemDrawerState.open} onClose={closeItemDrawer}>
                {itemDrawerState.open && itemDrawerState.consultant && itemDrawerState.overviewItem && (
                    <OverviewDrawer
                        consultant={itemDrawerState.consultant}
                        item={itemDrawerState.overviewItem}
                        selectedStoreId={selectedStoreId}
                        onBookingClicked={openBookingModal}
                        onShiftClicked={openShiftModal}
                        closeDrawer={closeItemDrawer}
                        role={role}
                        onEventTimeItemClick={openEventTimeDrawerState}
                    />
                )}
            </Drawer>
            <DefaultModal
                open={eventTimeModalState.open}
                onClose={handleCloseEventTimeModal}
                closeOnBackdrop={false}
                maxWidth="md"
            >
                {eventTimeModalState.open ? (
                    <CreateOrEditEventTime
                        onComplete={onEventTimeModalComplete}
                        id={eventTimeModalState.eventTimeId}
                        currentSelectedWeekDate={startOfSelectedWeek}
                        role={role}
                        selectedStoreId={selectedStoreId}
                        selectableStores={selectableStores}
                    />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <DefaultModal open={eventBookingModalState.open} onClose={handleCloseEventBookingModal} maxWidth="sm">
                {eventBookingModalState.eventBookingId ? (
                    <EditEventBooking
                        onComplete={onEventBookingModalComplete}
                        id={eventBookingModalState.eventBookingId}
                        role={role}
                    />
                ) : (
                    <>{null}</>
                )}
            </DefaultModal>
            <Drawer anchor="right" open={eventTimeDrawerState.open} onClose={closeEventTimeDrawerState}>
                {eventTimeDrawerState.open && eventTimeDrawerState.eventTime && eventTimeDrawerState.consultant && (
                    <EventTimeBookingsDrawer
                        item={eventTimeDrawerState.eventTime}
                        consultant={eventTimeDrawerState.consultant}
                        onEventBookingClicked={openEventBookingModal}
                        onEditEventTimeClicked={openEventTimeModal}
                        onCreateEventBookingClicked={openCreateEventBookingModal}
                        closeDrawer={closeEventTimeDrawerState}
                    />
                )}
            </Drawer>
            <DefaultModal
                open={createEventBookingModalState.open}
                onClose={handleCloseCreateEventBookingModal}
                closeOnBackdrop={false}
                maxWidth="sm"
            >
                {!!createEventBookingModalState.eventTime && (
                    <CreateEventBookingComponent
                        onComplete={onCreateEventBookingModalComplete}
                        eventTime={createEventBookingModalState.eventTime}
                        selectableStores={selectableStores}
                    />
                )}
            </DefaultModal>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(OverviewPage as ComponentType);
