import React, { useState, useEffect } from 'react';
import { DashboardMetric, DashboardMetricRow, MetricType } from 'autogen/swagger/Dashboard';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Table,
    TableRow,
    TableCell,
    TableBody,
    TextField,
    Button,
    Tooltip,
} from '@mui/material';
import { useTypedTranslation } from 'translations';
import Fuse from 'fuse.js';
import { Search, HelpOutline } from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';

interface IListMetricProps {
    metric: DashboardMetric;
    isByStoreMetric: boolean;
    openDetailsCallback?: (type: MetricType, valueSuffix?: string, isByStoreMetric?: boolean) => Promise<void>;
}

export function ListMetric(props: IListMetricProps): JSX.Element {
    const { metric, isByStoreMetric, openDetailsCallback } = props;
    const { values } = metric;
    const { t } = useTypedTranslation();
    const [showSearch, setShowSearch] = useState(false);
    const [searchText, setSearchText] = useState('');

    let shownValues = values;

    if (searchText) {
        const options: Fuse.IFuseOptions<DashboardMetricRow> = {
            threshold: 0.4,
            keys: ['name'],
        };
        const fuse = new Fuse(values, options);

        shownValues = fuse.search(searchText).map((x) => x.item);
    }

    useEffect(() => {
        if (!showSearch) {
            setSearchText('');
        }
    }, [showSearch]);

    return (
        <Card style={{ position: 'relative' }}>
            <Tooltip
                title={t('Dashboard', 'MetricHelpText', metric.metricType)}
                style={{ position: 'absolute', top: '3px', right: '3px', height: '15px', width: '15px' }}
            >
                <HelpOutline />
            </Tooltip>
            <CardContent>
                <Grid container direction="column" style={{ position: 'relative' }}>
                    {openDetailsCallback && (
                        <Button
                            size="small"
                            onClick={() => openDetailsCallback(metric.metricType, undefined, isByStoreMetric)}
                            style={{ position: 'absolute', left: 0, top: '-10px' }}
                        >
                            <DescriptionOutlinedIcon />
                        </Button>
                    )}
                    <Button
                        size="small"
                        onClick={(): void => setShowSearch(!showSearch)}
                        style={{ position: 'absolute', right: 0, top: '-10px' }}
                    >
                        <Search />
                    </Button>
                    <Grid item>
                        {showSearch ? (
                            <Grid container item justifyContent="center">
                                <TextField
                                    style={{ marginBottom: '5px', top: '-10px' }}
                                    value={searchText}
                                    type="text"
                                    size="small"
                                    variant="outlined"
                                    placeholder={t('Shared', 'Search')}
                                    onChange={(e): void => setSearchText(e.target.value)}
                                />
                            </Grid>
                        ) : (
                            <Typography variant="body2" style={{ marginBottom: '5px' }} align="center">
                                {t('Dashboard', 'Metric', metric.metricType)}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item style={{ overflowY: 'auto', maxHeight: '300px' }}>
                        <Table>
                            <TableBody>
                                {shownValues.map((x, i) => (
                                    <TableRow key={x.key} style={{ background: i % 2 === 0 ? '#eee' : 'white' }}>
                                        <TableCell size="small">
                                            <Typography>{x.name ?? t('ConsultationProduct', 'NoBrand')}</Typography>
                                        </TableCell>
                                        <TableCell size="small" align="right">
                                            <Typography>{x.value}</Typography>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default ListMetric;
