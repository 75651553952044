import React, { useState } from 'react';
import { ConsultantEditModelResponse, FixMissingUserRole } from 'autogen/swagger/Consultant';
import { showMessage } from 'components/messages/Message';
import { useTypedTranslation } from 'translations';
import { Grid, Typography, CardContent, Card } from '@mui/material';
import SpinnerButton from 'components/button/SpinnerButton';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import StoreManagerEdit from './StoreManagerEdit';
import VirtualConsultantEdit from './VirtualConsultantEdit';

interface IAuthUserProps {
    consultant: ConsultantEditModelResponse;
    role: AuthRole;
}

export function AuthUser(props: IAuthUserProps): JSX.Element {
    const { consultant, role } = props;
    const { t } = useTypedTranslation();
    const [loading, setLoading] = useState(false);
    const [hasAuthProblems, setHasAuthProblems] = useState(
        consultant.missingAuthUser || consultant.missingConsultantRole,
    );

    async function fixAuthUser(): Promise<void> {
        try {
            setLoading(true);
            const result = await FixMissingUserRole({
                consultantId: consultant.baseData.id,
            });
            if (result.success) {
                setHasAuthProblems(false);
                showMessage({
                    content: t('Consultant', 'AuthUser', 'AuthUserFixed'),
                    severity: 'success',
                });
            }
        } finally {
            setLoading(false);
        }
    }

    if (!hasAuthProblems) {
        return (
            <Grid item>
                <Grid container spacing={2}>
                    {IsInRole(role, 'AdminRole') && !!consultant && !consultant.baseData.isExternal && (
                        <Grid item xs={12} md={6} lg={4}>
                            <StoreManagerEdit consultant={consultant} />
                        </Grid>
                    )}
                    {IsInRole(role, 'AdminRole') && (
                        <Grid item xs={12} md={6} lg={4}>
                            <VirtualConsultantEdit consultant={consultant} />
                        </Grid>
                    )}
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid item>
            <Card>
                <CardContent style={{ background: consultant.baseData.email ? '#ffb84d' : '' }}>
                    <Grid container item gap={2}>
                        <Grid item xs={12} style={{ paddingBottom: 0 }} container justifyContent="center">
                            <Typography>
                                {t(
                                    'Consultant',
                                    'AuthUser',
                                    consultant.baseData.email ? 'AuthUserText' : 'AuthUserTextNoEmail',
                                )}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} style={{ paddingBottom: 0 }} container justifyContent="center">
                            {!!consultant.baseData.email && (
                                <SpinnerButton
                                    color="primary"
                                    style={{ marginLeft: '20px' }}
                                    onClick={fixAuthUser}
                                    disabled={!consultant.baseData.email}
                                    loading={loading}
                                >
                                    {t('Consultant', 'AuthUser', 'FixUser')}
                                </SpinnerButton>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default AuthUser;
