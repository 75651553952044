import React, { useEffect, useState } from 'react';
import {
    ConsultationCategory,
    ConsultationCategoryDetailed,
    CreateCategory,
    GetCategory,
    UpdateCategory,
    CreateOrUpdateConsultationCategoryViewModel,
} from 'autogen/swagger/ConsultationProduct';
import { useTypedTranslation } from 'translations';
import { Button, Grid, TextField, Typography, CardContent, Card } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { getCategoriesUrl } from 'shared/urls';
import { useValidationState } from 'helpers/validation';
import { nameof } from 'ts-simple-nameof';
import ImageChooser from 'components/Input/ImageChooser';
import SpinnerButton from 'components/button/SpinnerButton';

interface ICreateOrEditProps {
    id?: number;
}

export function CreateOrEdit(props: ICreateOrEditProps): JSX.Element {
    const { id } = props;
    const { t } = useTypedTranslation();
    const history = useHistory();
    const [category, setCategory] = useState<ConsultationCategory>();
    const [values, setValues] = useState<CreateOrUpdateConsultationCategoryViewModel>({
        name: '',
        teaser: '',
    });
    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const isEdit = !!id || !!category?.id;
    const [imageLoading, setImageLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    function onImageLoading(loading: boolean): void {
        setImageLoading(loading);
    }

    useEffect(() => {
        if (id) {
            const getCategory = async (): Promise<void> => {
                const result = await GetCategory({ id });
                setCategory(result);
                setValues({
                    name: result.name,
                    teaser: result.teaser || '',
                    mediumId: result.mediumId,
                });
            };
            getCategory();
        }
    }, [id]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            let result;
            if (id) {
                result = await UpdateCategory({ categoryId: id }, values);
            } else {
                result = await CreateCategory(values);
            }
            setValidationErrors(undefined);
            if (result) {
                history.push(getCategoriesUrl());
            }
        } catch (ex) {
            const isValidationErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isValidationErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    return (
        <Grid container direction="column" justifyContent="flex-start" item xs gap={2}>
            <Grid container item>
                <Grid item xs>
                    <Typography variant="h4">
                        {isEdit ? t('ConsultationCategory', 'EditTile') : t('ConsultationCategory', 'CreateTitle')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs>
                <Card>
                    <CardContent>
                        <Grid container direction="column" justifyContent="flex-start" gap={3}>
                            <Grid item xs>
                                <Grid container justifyContent="flex-start" gap={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('ConsultationCategory', 'NameInput')}
                                            type="string"
                                            fullWidth
                                            variant="outlined"
                                            value={values?.name}
                                            error={isError(nameof<ConsultationCategoryDetailed>((x) => x.name))}
                                            helperText={getErrorText(
                                                nameof<ConsultationCategoryDetailed>((x) => x.name),
                                            )}
                                            onChange={(e): void => setValues({ ...values, name: e.target.value })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label={t('ConsultationCategory', 'TeaserInput')}
                                            multiline
                                            type="string"
                                            fullWidth
                                            variant="outlined"
                                            value={values?.teaser}
                                            error={isError(nameof<ConsultationCategoryDetailed>((x) => x.teaser))}
                                            helperText={getErrorText(
                                                nameof<ConsultationCategoryDetailed>((x) => x.teaser),
                                            )}
                                            onChange={(e): void => setValues({ ...values, teaser: e.target.value })}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <ImageChooser
                                        mediumId={values.mediumId}
                                        onMediumIdChange={(mediumId): void => setValues({ ...values, mediumId })}
                                        error={isError(nameof<ConsultationCategoryDetailed>((x) => x.mediumId))}
                                        helperText={getErrorText(
                                            nameof<ConsultationCategoryDetailed>((x) => x.mediumId),
                                        )}
                                        onImageLoading={onImageLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" gap={2}>
                                    <Grid item>
                                        <Button variant="contained" component={Link} to={getCategoriesUrl()}>
                                            {t('Shared', 'Cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <SpinnerButton
                                            variant="contained"
                                            color="primary"
                                            onClick={save}
                                            disabled={imageLoading}
                                            loading={saving}
                                        >
                                            {isEdit ? t('Shared', 'Save') : t('Shared', 'Create')}
                                        </SpinnerButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CreateOrEdit;
