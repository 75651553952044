import React, { useEffect, useState } from 'react';
import {
    ExternalPartner,
    ExternalPartnerDetailed,
    CreateExternalPartner,
    GetExternalPartner,
    UpdateExternalPartner,
} from 'autogen/swagger/ExternalPartners';
import { useTypedTranslation } from 'translations';
import { Button, Grid, TextField, Typography, CardContent, Card } from '@mui/material';
import { Link, useHistory } from 'react-router-dom';
import { getExternalPartnersUrl } from 'shared/urls';
import { useValidationState } from 'helpers/validation';
import { nameof } from 'ts-simple-nameof';
import ImageChooser from 'components/Input/ImageChooser';
import SpinnerButton from 'components/button/SpinnerButton';

interface ICreateOrEditProps {
    id?: number;
}

export function CreateOrEdit(props: ICreateOrEditProps): JSX.Element {
    const { id } = props;
    const { t } = useTypedTranslation();
    const history = useHistory();
    const [externalPartner, setExternalPartner] = useState<ExternalPartner>({
        id: 0,
        brandId: undefined,
        name: '',
        isDeleted: false,
        isActive: true,
        hasBrandClub: false,
    });
    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const isEdit = !!id || !!externalPartner.id;
    const [imageLoading, setImageLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    function onImageLoading(loading: boolean): void {
        setImageLoading(loading);
    }

    useEffect(() => {
        if (id) {
            const getExternalPartner = async (): Promise<void> => {
                const result = await GetExternalPartner({ id });
                setExternalPartner(result);
            };
            getExternalPartner();
        }
    }, [id]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            let result;
            if (id) {
                result = await UpdateExternalPartner({ externalPartnerId: id }, externalPartner);
            } else {
                result = await CreateExternalPartner(externalPartner);
            }
            setValidationErrors(undefined);
            if (result) {
                history.push(getExternalPartnersUrl());
            }
        } catch (ex) {
            const isValidationErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isValidationErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    return (
        <Grid container direction="column" justifyContent="flex-start" item xs gap={2}>
            <Grid container item>
                <Grid item xs>
                    <Typography variant="h4">
                        {isEdit ? t('ExternalPartner', 'EditTile') : t('ExternalPartner', 'CreateTitle')}
                    </Typography>
                    {!isEdit && (
                        <Typography variant="h4" style={{ color: 'red' }}>
                            {t('ExternalPartner', 'CreateTitleWarning')}
                        </Typography>
                    )}
                </Grid>
            </Grid>
            <Grid item xs>
                <Card>
                    <CardContent>
                        <Grid container direction="column" justifyContent="flex-start" gap={3}>
                            <Grid item xs>
                                <Grid container gap={4}>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('ExternalPartner', 'NameInput')}
                                            type="string"
                                            fullWidth
                                            variant="outlined"
                                            value={externalPartner?.name}
                                            error={isError(nameof<ExternalPartnerDetailed>((x) => x.name))}
                                            helperText={getErrorText(nameof<ExternalPartnerDetailed>((x) => x.name))}
                                            onChange={(e): void =>
                                                setExternalPartner({ ...externalPartner, name: e.target.value })
                                            }
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            label={t('ExternalPartner', 'BrandIdInput')}
                                            type="number"
                                            fullWidth
                                            variant="outlined"
                                            value={externalPartner?.brandId || ''}
                                            error={isError(nameof<ExternalPartnerDetailed>((x) => x.brandId))}
                                            helperText={getErrorText(nameof<ExternalPartnerDetailed>((x) => x.brandId))}
                                            onChange={(e): void => {
                                                if (e.target.value === '' || Number(e.target.value) > 0)
                                                    setExternalPartner({
                                                        ...externalPartner,
                                                        brandId: Number(e.target.value),
                                                    });
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                            }}
                                        />
                                    </Grid>
                                    <ImageChooser
                                        mediumId={externalPartner.mediumId}
                                        onMediumIdChange={(mediumId): void =>
                                            setExternalPartner({ ...externalPartner, mediumId })
                                        }
                                        error={isError(nameof<ExternalPartnerDetailed>((x) => x.mediumId))}
                                        helperText={getErrorText(nameof<ExternalPartnerDetailed>((x) => x.mediumId))}
                                        onImageLoading={onImageLoading}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container justifyContent="center" gap={2}>
                                    <Grid item>
                                        <Button variant="contained" component={Link} to={getExternalPartnersUrl()}>
                                            {t('Shared', 'Cancel')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <SpinnerButton
                                            variant="contained"
                                            color="primary"
                                            onClick={save}
                                            disabled={imageLoading}
                                            loading={saving}
                                        >
                                            {isEdit ? t('Shared', 'Save') : t('Shared', 'Create')}
                                        </SpinnerButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default CreateOrEdit;
