import React, { useEffect, useState, useCallback } from 'react';
import { BookingType, ConsultantCreateRequest, ConsultantEditModelResponse } from 'autogen/swagger/Consultant';
import {
    ListConsultationProductsActiveInSelectedStore,
    ConsultationProductDetailed,
} from 'autogen/swagger/ConsultationProduct';
import { Grid, Checkbox, TextField, Chip } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { nameof } from 'ts-simple-nameof';
import { useTypedTranslation } from 'translations';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import ButtonLink from 'components/button/ButtonLink';
import { formatConsultationProductName } from 'helpers/string';

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;

interface IConsultationProductSelect {
    id: number;
    name: string;
    groupName: string;
    editable: boolean;
}

interface ISelectConsultationProductsProps {
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
    isError: (param: string) => boolean;
    getErrorText: (param: string) => string;
    consultant?: ConsultantEditModelResponse;
    isEdit: boolean;
    selectedStoreId?: number;
    role: AuthRole;
}

export function SelectConsultationProducts(props: ISelectConsultationProductsProps): JSX.Element {
    const { values, setValues, isError, getErrorText, consultant, isEdit, selectedStoreId, role } = props;
    const [consultationProducts, setConsultationProducts] = useState<IConsultationProductSelect[]>([]);
    const { t } = useTypedTranslation();

    const getConsultationProducts = useCallback(
        async (): Promise<ConsultationProductDetailed[]> =>
            IsInRole(role, 'AdminRole', 'StoreManagerRole')
                ? ListConsultationProductsActiveInSelectedStore({
                      isExternal: values.isExternal,
                      externalPartnerIds: values.externalPartnerIds,
                  })
                : Promise.resolve([]),
        [role, values.externalPartnerIds, values.isExternal],
    );

    const getGroupName = (bookingType: BookingType, categoryName: string): string =>
        bookingType === 'Event' ? t('Consultant', 'Event') : categoryName;

    const getProductsForExternal = useCallback(async (): Promise<void> => {
        let products: IConsultationProductSelect[] = [];

        if (values.externalPartnerIds.length) {
            products =
                consultant?.consultantProducts
                    .filter(
                        (x) =>
                            (x.externalPartner && values.externalPartnerIds.includes(x.externalPartner.id)) ||
                            consultant.baseData.consultationProductIds.includes(x.id),
                    )
                    .map((x) => ({
                        id: x.id,
                        name: formatConsultationProductName(x.name, x.externalPartner?.name),
                        groupName: getGroupName(x.bookingType, x.categoryName),
                        editable: x.isEditable && IsInRole(role, 'AdminRole', 'StoreManagerRole'),
                    })) || [];
        } else {
            products =
                consultant?.consultantProducts
                    .filter((x) => consultant.baseData.consultationProductIds.includes(x.id))
                    .map((x) => ({
                        id: x.id,
                        name: formatConsultationProductName(x.name, x.externalPartner?.name),
                        groupName: getGroupName(x.bookingType, x.categoryName),
                        editable: x.isEditable && IsInRole(role, 'AdminRole', 'StoreManagerRole'),
                    })) || [];
        }
        const result = await getConsultationProducts();

        const resultProducts = result
            .filter((x) => products.every((s) => s.id !== x.consultationProductMaster.id))
            .map((x) => ({
                id: x.consultationProductMaster.id,
                name: formatConsultationProductName(x.name, x.consultationProductMasterDetailed.externalPartner?.name),
                groupName: getGroupName(x.consultationProductMaster.bookingType, x.consultationCategory.name),
                editable: IsInRole(role, 'AdminRole', 'StoreManagerRole'),
            }));

        setConsultationProducts([...products, ...resultProducts]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [consultant, getConsultationProducts, values.externalPartnerIds, role]);

    useEffect(() => {
        if (isEdit) {
            if (consultant?.baseData.isExternal) {
                getProductsForExternal();
            } else {
                setConsultationProducts(
                    consultant?.consultantProducts.map((x) => ({
                        id: x.id,
                        name: formatConsultationProductName(x.name, x.externalPartner?.name),
                        groupName: getGroupName(x.bookingType, x.categoryName),
                        editable: x.isEditable && IsInRole(role, 'AdminRole', 'StoreManagerRole'),
                    })) || [],
                );
            }
        } else {
            (async (): Promise<void> => {
                const result = await getConsultationProducts();
                setConsultationProducts(
                    result.map((x) => ({
                        id: x.consultationProductMaster.id,
                        name: formatConsultationProductName(
                            x.name,
                            x.consultationProductMasterDetailed.externalPartner?.name,
                        ),
                        groupName: getGroupName(x.consultationProductMaster.bookingType, x.consultationCategory.name),
                        editable: IsInRole(role, 'AdminRole', 'StoreManagerRole'),
                    })),
                );
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        consultant,
        getConsultationProducts,
        getProductsForExternal,
        isEdit,
        selectedStoreId,
        values.externalPartnerIds,
        values.isExternal,
        role,
    ]);

    const allSelected = consultationProducts.every(
        (x) => !x.editable || values.consultationProductIds.some((s) => s === x.id),
    );

    function togleSelectAll(): void {
        let newValue: number[] = consultationProducts.filter((x) => !x.editable).map((x) => x.id);
        if (!allSelected) {
            newValue = consultationProducts.map((x) => x.id);
        }
        setValues({ ...values, consultationProductIds: newValue });
    }

    return (
        <Grid container item gap={2}>
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        options={consultationProducts.sort(
                            (a, b) => a.groupName.localeCompare(b.groupName) || a.name.localeCompare(b.name),
                        )}
                        value={consultationProducts.filter((x) => values.consultationProductIds.indexOf(x.id) > -1)}
                        onChange={(e, value): void =>
                            setValues({
                                ...values,
                                consultationProductIds: value.map((x) => x.id),
                            })
                        }
                        groupBy={(option): string => option.groupName}
                        getOptionDisabled={(options): boolean => !options.editable}
                        disableCloseOnSelect
                        disabled={!IsInRole(role, 'AdminRole', 'StoreManagerRole')}
                        renderTags={(options): JSX.Element => (
                            <>
                                {options.map((option) => (
                                    <Chip
                                        label={option.name}
                                        className="MuiAutocomplete-tag"
                                        disabled={!option.editable}
                                        key={option.id}
                                        onDelete={(): void => {
                                            setValues({
                                                ...values,
                                                consultationProductIds: values.consultationProductIds.reduce(
                                                    (acc, x) => (x === option.id ? acc : [...acc, x]),
                                                    [] as number[],
                                                ),
                                            });
                                        }}
                                    />
                                ))}
                            </>
                        )}
                        getOptionLabel={(option): string => option.name}
                        renderOption={(attributes, option, { selected }): JSX.Element => (
                            <li {...attributes} key={option.id}>
                                <Checkbox
                                    icon={uncheckedIcon}
                                    checkedIcon={checkedIcon}
                                    style={{ marginRight: 8 }}
                                    checked={selected}
                                />
                                {option.name}
                            </li>
                        )}
                        fullWidth
                        renderInput={(params): JSX.Element => (
                            <TextField
                                {...params}
                                variant="outlined"
                                label={t('ConsultantShift', 'Service')}
                                placeholder={t('ConsultantShift', 'SelectServices')}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                error={isError(nameof<ConsultantCreateRequest>((x) => x.consultationProductIds))}
                                helperText={getErrorText(
                                    nameof<ConsultantCreateRequest>((x) => x.consultationProductIds),
                                )}
                            />
                        )}
                    />
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end">
                    <ButtonLink color="primary" onClick={togleSelectAll}>
                        {t('Shared', allSelected ? 'DeselectAll' : 'SelectAll')}
                    </ButtonLink>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectConsultationProducts;
