import React, { CSSProperties } from 'react';
import { EventBookingViewModel } from 'autogen/swagger/Consultant';
import { Typography, Button, TableCell, TableRow, Stack } from '@mui/material';
import styled from 'styled-components';
import { useTypedTranslation } from 'translations';
import moment from 'moment/moment';

const BookingItem = styled(TableRow)`
    background: #f9fafb;
    margin-bottom: 5px;
    border: 1px solid #e9eaeb;
    padding: 5px;
    position: relative;
    cursor: pointer;
`;

function getBookingStyling(item: EventBookingViewModel): CSSProperties {
    const base: CSSProperties = {};
    if (item.status === 'Reserved') {
        return {
            ...base,
            backgroundColor: '#f9fafb',
            borderColor: '#e9eaeb',
            cursor: 'auto',
        };
    }

    if (item.status === 'UserWasAbsent') {
        return {
            ...base,
            backgroundColor: '#f9fafb',
            borderColor: '#fC3636',
            borderWidth: '2px',
        };
    }

    return {
        ...base,
        backgroundColor: '#f9fafb',
        borderColor: '#e9eaeb',
    };
}

interface IEventBookingItemProps {
    model: EventBookingViewModel;
    onBookingItemClick: (booking: EventBookingViewModel) => void;
}

export function EventBookingItem(props: IEventBookingItemProps): JSX.Element {
    const { model, onBookingItemClick } = props;
    const { t } = useTypedTranslation();

    const bookingStyling = getBookingStyling(model);

    return (
        <BookingItem
            onClick={
                model.status !== 'Reserved'
                    ? (e): void => {
                          e.stopPropagation();
                          onBookingItemClick(model);
                      }
                    : undefined
            }
            style={bookingStyling}
        >
            <TableCell>
                <Typography variant="body1" style={{ paddingRight: '25px' }}>
                    {model.firstName || model.lastName
                        ? `${model.firstName ?? ''} ${model.lastName ?? ''}`
                        : model.clubMatasMemberId}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body1">{model.spacesBooked}</Typography>
            </TableCell>
            <TableCell>
                {model.status === 'Reserved' ? (
                    <Stack spacing={0}>
                        <Typography variant="body2">{t('EventBooking', 'ReservedUntil')}</Typography>
                        <Typography variant="body2">
                            {moment(model.reservedUntil).format('HH:mm - DD-MM-yyyy')}
                        </Typography>
                    </Stack>
                ) : (
                    <Button variant="outlined" size="small">
                        {t('EventBooking', 'SeeDetails')}
                    </Button>
                )}
            </TableCell>
        </BookingItem>
    );
}

export default EventBookingItem;
