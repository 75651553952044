import React from 'react';
import { DashboardMetric, MetricType } from 'autogen/swagger/Dashboard';
import { Typography, Grid } from '@mui/material';
import StandardMetric from './StandardMetric';
import PieChartMetric from './PieChartMetric';
import ListMetric from './ListMetric';

interface IMetricSwitchProps {
    metric: DashboardMetric;
    openDetailsCallback: (type: MetricType, valueSuffix?: string, isByStoreMetric?: boolean) => Promise<void>;
}

export function MetricSwitch(props: IMetricSwitchProps): JSX.Element {
    const { metric, openDetailsCallback } = props;

    switch (metric.metricType) {
        case 'ActiveStores':
        case 'AssociatedStores':
        case 'AssociatedConsultants':
        case 'ActiveConsultants':
        case 'ActiveBrands':
        case 'BookingNoShows':
        case 'OneToOneSessionsCancelledByCustomer':
        case 'OneToOneSessionsCancelledByMatas':
        case 'OneOnOneSessions':
        case 'GhostBookingNoShows':
        case 'GhostOneToOneSessionsCancelledByMatas':
        case 'GhostOneOnOneSessions':
        case 'AverageBookingPerPerson':
        case 'ActiveShifts':
        case 'InactiveShifts':
        case 'OneToManySessions':
        case 'OneToManySessionNoShow':
        case 'OneToManySessionsCancelledByCustomer':
        case 'OneToManySessionsCancelledByMatas':
        case 'GhostOneToManySessions':
        case 'GhostOneToManySessionNoShow':
        case 'GhostOneToManySessionsCancelledByMatas':
        case 'AverageEventBookingPerPerson':
        case 'ActiveEventTimes':
        case 'ActiveEventTimesInStore':
        case 'ActiveEventTimesNotInStore':
        case 'InactiveEventTimes':
            return (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <StandardMetric metric={metric} openDetailsCallback={openDetailsCallback} />
                </Grid>
            );
        case 'AssociatedBrands':
            return (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <StandardMetric metric={metric} />
                </Grid>
            );
        case 'PercentageBookingWithFavouriteStore':
        case 'PercentageEventBookingWithFavouriteStore':
            return (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <StandardMetric metric={metric} valueSuffix="%" openDetailsCallback={openDetailsCallback} />
                </Grid>
            );
        case 'BookingsPerCategory':
        case 'EventBookingsPerCategory':
        case 'EventTimesPerCategory':
            return (
                <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <PieChartMetric metric={metric} />
                </Grid>
            );
        case 'BookingsPerBrand':
        case 'ShiftsPerBrand':
        case 'EventBookingsPerBrand':
        case 'EventTimesPerBrand':
            return (
                <Grid item xl={3} lg={4} md={6} sm={6} xs={12}>
                    <ListMetric metric={metric} isByStoreMetric={false} openDetailsCallback={openDetailsCallback} />
                </Grid>
            );
        case 'Empty':
            return <Grid item xl={2} lg={3} md={4} sm={6} xs={12} />;
        default:
            return (
                <Grid item xl={2} lg={3} md={4} sm={6} xs={12}>
                    <Typography>Not implemented {metric.metricType}</Typography>
                </Grid>
            );
    }
}

export default MetricSwitch;
