import React, { useEffect, useState } from 'react';
import { Grid, TextField, Typography } from '@mui/material';
import { IActiveTimeSpan } from 'autogen/swagger/Consultant';
import { useTypedTranslation } from 'translations';
import moment, { Moment } from 'moment';
import { setTime, getNowRoundedUp } from 'helpers/dateTime';
import { DatePicker, TimePicker } from '@mui/lab';

interface IActiveTimeSpanProps {
    timeSpan: IActiveTimeSpan;
    showDaySelection: boolean;
    onChange: (timeSpan: IActiveTimeSpan) => void;
    startDate: Moment | (() => Moment);
    error?: boolean;
    helperText?: string;
    disabled?: boolean;
}

export function ActiveTimeSpan(props: IActiveTimeSpanProps): JSX.Element {
    const { timeSpan, showDaySelection = true, onChange, startDate, error, helperText, disabled } = props;
    const [selectedDate, setSelectedDate] = useState(startDate);
    const [selectedStartTime, setSelectedStartTime] = useState(() => getNowRoundedUp(5));
    const [selectedEndTime, setSelectedEndTime] = useState(() => getNowRoundedUp(5));
    const { t } = useTypedTranslation();

    useEffect(() => {
        if (timeSpan.activeFromDate && timeSpan.activeToDate) {
            const fromDate = moment(timeSpan.activeFromDate);
            const toDate = moment(timeSpan.activeToDate);
            if (!fromDate.isSame(selectedStartTime) && !toDate.isSame(selectedEndTime)) {
                setSelectedDate(fromDate);
                setSelectedStartTime(fromDate);
                setSelectedEndTime(toDate);
            }
        }
    }, [timeSpan]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect((): void => {
        try {
            onChange({
                activeFromDate: setTime(selectedDate, selectedStartTime).toISOString(true),
                activeToDate: setTime(selectedDate, selectedEndTime).toISOString(true),
            });
        } catch {
            // do nothing
        }
    }, [selectedStartTime, selectedEndTime, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

    function handleDateChange(date: Moment | null): void {
        if (!date) return;
        setSelectedDate(date);
    }

    function handleStartTimeChange(date: Moment | null): void {
        if (!date) return;
        setSelectedStartTime(date);
    }

    function handleEndTimeChange(date: Moment | null): void {
        if (!date) return;
        setSelectedEndTime(date);
    }

    return (
        <Grid container item xs={12} spacing={1}>
            {showDaySelection && (
                <Grid item xs={12}>
                    <DatePicker
                        inputFormat="DD-MM-yyyy"
                        mask="__-__-____"
                        label={t('Shared', 'Day')}
                        value={selectedDate}
                        onChange={handleDateChange}
                        OpenPickerButtonProps={{
                            'aria-label': t('Shared', 'ChangeDate'),
                        }}
                        disabled={disabled}
                        renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
                    />
                </Grid>
            )}

            <Grid item xs={6}>
                <TimePicker
                    ampm={false}
                    label={t('Shared', 'Start')}
                    minutesStep={5}
                    value={selectedStartTime}
                    onChange={handleStartTimeChange}
                    OpenPickerButtonProps={{
                        'aria-label': t('Shared', 'ChangeFromTime'),
                    }}
                    disabled={disabled}
                    renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
                />
            </Grid>
            <Grid item xs={6}>
                <TimePicker
                    ampm={false}
                    label={t('Shared', 'End')}
                    minutesStep={5}
                    value={selectedEndTime}
                    onChange={handleEndTimeChange}
                    OpenPickerButtonProps={{
                        'aria-label': t('Shared', 'ChangeToTime'),
                    }}
                    disabled={disabled}
                    renderInput={(params) => <TextField margin="normal" fullWidth {...params} />}
                />
            </Grid>
            {error && (
                <Grid item xs={12}>
                    <Typography color="error" variant="body2">
                        {helperText}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default ActiveTimeSpan;
