import React from 'react';
import { OverviewConsultant, OverviewItem, Consultant } from 'autogen/swagger/Consultant';
import { TableRow, TableCell, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { OverviewItemComp } from '../Items/OverviewItem';

const StyledTableRow = styled(TableRow)`
    &:hover {
        background: #eee;
    }
`;

interface IOverviewRowProps {
    overviewConsultant: OverviewConsultant;
    dates: Moment[];
    openItemDrawer: (overviewItem: OverviewItem, consultant: Consultant) => void;
}

export function OverviewRow(props: IOverviewRowProps): JSX.Element {
    const { overviewConsultant, dates, openItemDrawer } = props;

    return (
        <StyledTableRow>
            <TableCell style={{ border: '1px solid #9ca3af50' }} size="small">
                <Typography>
                    {overviewConsultant.consultant.firstName} {overviewConsultant.consultant.lastName}
                </Typography>
            </TableCell>
            {dates.map((date) => {
                const item = overviewConsultant.items.find((x) =>
                    moment(x.activeTime.activeFromDate).isSame(date, 'date'),
                );
                return (
                    <TableCell key={date.toISOString()} style={{ border: '1px solid #9ca3af50' }} size="small">
                        {item && (
                            <OverviewItemComp
                                item={item}
                                onClick={(): void => openItemDrawer(item, overviewConsultant.consultant)}
                            />
                        )}
                    </TableCell>
                );
            })}
        </StyledTableRow>
    );
}

export default OverviewRow;
