import React, { useState } from 'react';
import { Button, Grid, TextField, Typography, Select, FormControl, MenuItem } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { LookupExternal, ConsultantLookup } from 'autogen/swagger/Consultant';
import SpinnerButton from 'components/button/SpinnerButton';

type SearchType = 'Email' | 'Name';

const SearchTypes: SearchType[] = ['Name', 'Email'];

export interface SearchedConsultantLookup {
    results: ConsultantLookup[];
    searchedEmail?: string;
    searchedName?: string;
}

interface ISearchExternalProps {
    onSearchResult: (consultantLookup: SearchedConsultantLookup) => void;
    hasExistingConsultants: boolean;
}

export function SearchExternal(props: ISearchExternalProps): JSX.Element {
    const { onSearchResult, hasExistingConsultants } = props;
    const [search, setSearch] = useState('');
    const { t } = useTypedTranslation();
    const [loading, setLoading] = useState(false);
    const [searchType, setSearchType] = useState<SearchType>('Name');

    async function searchExternal(): Promise<void> {
        try {
            setLoading(true);
            const result = await LookupExternal({
                emailAddress: searchType === 'Email' ? search : undefined,
                nameSearch: searchType === 'Name' ? search : undefined,
                onlyExternal: true,
            });

            onSearchResult({
                results: result,
                searchedEmail: searchType === 'Email' ? search : undefined,
                searchedName: searchType === 'Name' ? search : undefined,
            });
        } finally {
            setLoading(false);
        }
    }

    function createNew(): void {
        onSearchResult({
            results: [],
            searchedEmail: searchType === 'Email' ? search : undefined,
            searchedName: searchType === 'Name' ? search : undefined,
        });
    }

    return (
        <Grid container item gap={2}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography>{t('Consultant', 'SearchConsultant')}</Typography>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={searchType}
                        variant="outlined"
                        style={{ height: '37px' }}
                        onChange={(e): void => setSearchType(e.target.value as SearchType)}
                    >
                        {SearchTypes.map((x) => (
                            <MenuItem key={x} value={x}>
                                {t('Consultant', 'SearchTypesExternal', x)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    type="string"
                    fullWidth
                    size="small"
                    value={search}
                    variant="outlined"
                    onKeyPress={(e): void => {
                        if (e.key === 'Enter') searchExternal();
                    }}
                    onChange={(e): void => setSearch(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <SpinnerButton color="primary" loading={loading} fullWidth onClick={searchExternal}>
                    {t('Consultant', 'SearchConsultant')}
                </SpinnerButton>
            </Grid>
            <Grid item xs={3}>
                {hasExistingConsultants && (
                    <Button variant="contained" color="primary" disabled={loading} fullWidth onClick={createNew}>
                        {t('Consultant', 'CreateNew')}
                    </Button>
                )}
            </Grid>
        </Grid>
    );
}

export default SearchExternal;
