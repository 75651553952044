import moment, { Moment } from 'moment';

export function getDates(startDate: Moment, stopDate: Moment): Moment[] {
    const dateArray: Moment[] = [];
    let currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(currentDate);
        currentDate = currentDate.clone().add(1, 'days');
    }
    return dateArray;
}

export function sortIsoDate(a?: string, b?: string): number {
    if (!a) return !b ? 0 : 1;

    if (!b) return !a ? 0 : -1;

    return new Date(a).getTime() - new Date(a).getTime();
}

export function setTime(date: Moment, time: Moment): Moment {
    const d = date.clone();
    d.set({ hour: time.hour(), minutes: time.minute(), seconds: time.second() });
    return d;
}

export function getNowRoundedUp(roundToMinutes: number): Moment {
    const now = moment();
    const remainder = roundToMinutes - (now.minute() % roundToMinutes);
    return now.set({ s: 0, ms: 0 }).add(remainder, 'minutes');
}

export function getMinutesRoundedUp(date: Moment, roundToMinutes: number): Moment {
    const d = date.clone();
    const remainder = roundToMinutes - (d.minute() % roundToMinutes);
    return d.set({ s: 0, ms: 0 }).add(remainder, 'minutes');
}
