import React from 'react';
import styled from 'styled-components';
import {
    ConsultantCalendarItem,
    CalendarItemBooking,
    CalendarItemShift,
    CalendarItemEvent,
} from 'autogen/swagger/Consultant';
import { sortIsoDate } from 'helpers/dateTime';
import { Backdrop, Button, ButtonGroup, Grid, Tooltip, Typography } from '@mui/material';
import moment, { Moment } from 'moment';
import { breakpoint, sizes } from 'variables';
import { useTypedTranslation } from 'translations';
import { AuthRole } from 'autogen/swagger/Users';
import CalendarItem from '../Items/CalendarItem';

const Column = styled(Grid)`
    && {
        ${breakpoint.down(sizes.md)} {
            border-bottom: 1px solid #9ca3af;

            &:last-child {
                border-bottom: 0;
            }
        }

        ${breakpoint.up(sizes.md)} {
            border-right: 1px solid #9ca3af;
            width: 14.2857%;

            &:last-child {
                border-right: 0;
            }
        }
    }
`;

const Header = styled(Grid)`
    && {
        border-bottom: 1px solid #9ca3af;
        padding: 10px;
    }
`;

const Body = styled(Grid)`
    && {
        padding: 10px;
        max-width: 100%;
    }
`;

interface ICalendarDate {
    date: Moment;
    calendarItems?: ConsultantCalendarItem[];
    onShiftItemClick: (shiftId: CalendarItemShift) => void;
    selectedStoreId: number;
    onBookingItemClick: (booking: CalendarItemBooking) => void;
    onEventTimeItemClick: (eventTime: CalendarItemEvent) => void;
    setSelectedDate: (selectedDate: Moment) => void;
    setCopyShiftDaily: (showDailyOverlay: boolean) => void;
    copyShift: (fromDate: Moment, toDate: Moment) => void;
    selectedDay?: Moment;
    copyShiftsDailyEnabled?: boolean;
    itemsLoading: boolean;
    role: AuthRole;
}

function CalendarDate(props: ICalendarDate): JSX.Element {
    const {
        date,
        calendarItems,
        selectedStoreId,
        onShiftItemClick,
        onBookingItemClick,
        setSelectedDate,
        setCopyShiftDaily,
        copyShift,
        selectedDay,
        copyShiftsDailyEnabled = false,
        itemsLoading,
        role,
        onEventTimeItemClick,
    } = props;

    const { t, tf } = useTypedTranslation();

    const dayName = date.format('dddd');

    const isSelected = selectedDay?.isSame(date, 'day');

    const fromDateFormat = selectedDay?.format('DD-MM-yyyy');
    const toDateFormat = date.format('DD-MM-yyyy');

    const items = calendarItems
        ?.filter((x) => moment(x.activeTime.activeFromDate).isSame(date, 'day'))
        .sort((a, b) => sortIsoDate(a.activeTime.activeFromDate, b.activeTime.activeFromDate));

    return (
        <Column
            item
            xs
            style={{
                position: 'relative',
                ...(copyShiftsDailyEnabled ? { paddingBottom: '50px' } : {}),
            }}
        >
            <Grid container direction="column" maxWidth="100%">
                <Header item container direction="column">
                    <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                        {dayName}
                    </Typography>
                    <Typography variant="subtitle2">{date.format('DD-MM-yyyy')}</Typography>
                </Header>
                <Body item xs>
                    <Grid container direction="column">
                        {items &&
                            items.map((item) => (
                                <CalendarItem
                                    key={item.activeTime.activeFromDate}
                                    item={item}
                                    selectedStoreId={selectedStoreId}
                                    onShiftItemClick={onShiftItemClick}
                                    onBookingItemClick={onBookingItemClick}
                                    role={role}
                                    onEventTimeItemClick={onEventTimeItemClick}
                                />
                            ))}
                    </Grid>
                </Body>
                <Backdrop
                    open={copyShiftsDailyEnabled}
                    style={{
                        position: 'absolute',
                        zIndex: '100',
                        backgroundColor: isSelected && !itemsLoading ? '#35ef3633' : '',
                    }}
                    onClick={(): void => setCopyShiftDaily(false)}
                >
                    {!itemsLoading && (
                        <Grid
                            container
                            direction="row"
                            justifyContent="space-evenly"
                            alignItems="flex-end"
                            style={{ height: '100%' }}
                        >
                            <Grid
                                container
                                item
                                direction="column"
                                justifyContent="center"
                                alignItems="center"
                                onClick={(e): void => {
                                    e.stopPropagation();
                                }}
                            >
                                <ButtonGroup
                                    color="primary"
                                    orientation="vertical"
                                    style={{ width: 'fit-content', marginBottom: '10px' }}
                                >
                                    {copyShiftsDailyEnabled && !isSelected && isSelected !== undefined && (
                                        <Tooltip
                                            title={tf(
                                                {
                                                    dayNumberOne: fromDateFormat ? fromDateFormat?.toString() : '',
                                                    dayNumberTwo: toDateFormat.toString(),
                                                },
                                                'Calendar',
                                                'CopyDayToDay',
                                            )}
                                            placement="top"
                                            arrow
                                        >
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                size="small"
                                                style={{ marginBottom: '5px' }}
                                                onClick={(): void => copyShift(date, date)}
                                            >
                                                {t('Calendar', 'Insert')}
                                            </Button>
                                        </Tooltip>
                                    )}
                                    {copyShiftsDailyEnabled && !isSelected && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            onClick={(): void => setSelectedDate(date)}
                                        >
                                            {t('Calendar', 'Copy')}
                                        </Button>
                                    )}
                                </ButtonGroup>
                            </Grid>
                        </Grid>
                    )}
                </Backdrop>
            </Grid>
        </Column>
    );
}

export default CalendarDate;
