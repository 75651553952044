import React from 'react';
import { Grid, Typography } from '@mui/material';
import styled from 'styled-components';
import { stringToKey } from 'helpers/string';

const Legend = styled.span`
    width: 40px;
    height: 15px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: block;
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
`;

export interface IColorLegend {
    color: string;
    text: string;
    borderColor?: string;
}

interface IColorLegendProps {
    legends: IColorLegend[];
}

export function ColorLegend(props: IColorLegendProps): JSX.Element {
    const { legends } = props;

    return (
        <Grid container alignItems="center" gap={2}>
            {legends.map((x) => (
                <Grid key={stringToKey(x.text)} item>
                    <Grid container alignItems="center" gap={1}>
                        <Grid item>
                            <Legend style={{ backgroundColor: x.color, borderColor: x.borderColor }} />
                        </Grid>
                        <Grid item>
                            <Typography>{x.text}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
            ))}
        </Grid>
    );
}

export default ColorLegend;
