import React, { useCallback, useState } from 'react';
import Paper from '@mui/material/Paper';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { DashboardRequestViewModel, DashboardTableMetric, GetDashboardExport } from 'autogen/swagger/Dashboard';
import { useDataGridStyles } from 'shared/theme';
import { DataGrid, GridColDef, GridFooterContainer, GridPagination } from '@mui/x-data-grid';
import SpinnerButton from 'components/button/SpinnerButton';

interface IMetricDetailsProps {
    dashboardTableMetric: DashboardTableMetric;
    filterRequestBody: DashboardRequestViewModel;
}

export function ListMetricDetails(props: IMetricDetailsProps): JSX.Element {
    const { dashboardTableMetric, filterRequestBody } = props;
    const { t } = useTypedTranslation();
    const [exportLoading, setExportLoading] = useState(false);

    const classes = useDataGridStyles();

    const rows = dashboardTableMetric.values;

    const columnFlexSize: number[] = [0.4, 0.4];

    function getColumnFlex(index: number) {
        return columnFlexSize[index] ?? 1;
    }

    const columns: GridColDef[] = dashboardTableMetric.headers.map((row, index) => ({
        field: index.toString(),
        headerName: row,
        editable: false,
        flex: getColumnFlex(index),
    }));

    const CustomFooter = useCallback(
        () => (
            <GridFooterContainer>
                <Grid container direction="column" item xs>
                    <Grid container item xs>
                        {dashboardTableMetric.footerValues.map((s, index) => (
                            <Box key={index.toString()} sx={{ flex: getColumnFlex(index) }}>
                                <Box sx={{ paddingX: '10px', paddingY: 0.5 }}>{s}</Box>
                            </Box>
                        ))}
                    </Grid>
                    <Grid container item xs>
                        <Grid item xs="auto" />
                        <Grid item xs>
                            <GridPagination />
                        </Grid>
                    </Grid>
                </Grid>
            </GridFooterContainer>
        ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    if (rows.length === 0) {
        return (
            <Stack spacing={1}>
                <Typography variant="h4">{t('Dashboard', 'Metric', dashboardTableMetric.metricType)}</Typography>
                <Typography>{t('Dashboard', 'MetricDetails', 'NoData')}</Typography>
            </Stack>
        );
    }

    const tableRows = rows.map((row) => ({ ...row }));
    const lastRowIndex = (rows[0].length - 1).toString();

    async function onExportClick(): Promise<void> {
        try {
            setExportLoading(true);

            await GetDashboardExport({ metricType: dashboardTableMetric.metricType }, filterRequestBody);
        } finally {
            setExportLoading(false);
        }
    }

    return (
        <Grid container direction="column" spacing={1}>
            <Grid container item xs style={{ flexGrow: 0 }} wrap="nowrap">
                <Grid item xs>
                    <Typography variant="h4">{t('Dashboard', 'Metric', dashboardTableMetric.metricType)}</Typography>
                </Grid>
                <Grid item xs="auto">
                    <SpinnerButton variant="contained" color="primary" onClick={onExportClick} loading={exportLoading}>
                        {t('Dashboard', 'Exports', 'Export')}
                    </SpinnerButton>
                </Grid>
            </Grid>
            <Grid item>
                <Paper>
                    <DataGrid
                        className={classes.root}
                        columns={columns}
                        rows={tableRows}
                        autoHeight
                        getRowId={(row) => row[lastRowIndex]}
                        disableColumnMenu
                        components={{ Footer: CustomFooter }}
                        pageSize={20}
                        rowsPerPageOptions={[20]}
                        hideFooterSelectedRowCount
                        disableSelectionOnClick
                        disableColumnSelector
                        headerHeight={37}
                        rowHeight={30}
                        sx={{
                            fontWeight: 400,
                            color: 'rgba(0,0,0,0.87)',
                            '.MuiDataGrid-columnSeparator': {
                                display: 'none',
                            },
                            '&.MuiDataGrid-root': {
                                border: 'none',
                            },
                            '& .MuiDataGrid-cell:focus': {
                                outline: 'none',
                            },
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    );
}

export default ListMetricDetails;
