/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './ExternalPartners';

export type ExternalPartner = components['schemas']['ExternalPartner'];
export type ErrorType = components['schemas']['ErrorType'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type CreateOrUpdateExternalPartnerViewModel = components['schemas']['CreateOrUpdateExternalPartnerViewModel'];
export type Medium = components['schemas']['Medium'];
export type MediumMaybe = components['schemas']['MediumMaybe'];
export type ExternalPartnerDetailed = components['schemas']['ExternalPartnerDetailed'];
export type OperationResult = components['schemas']['OperationResult'];

type ListExternalPartnersParams = paths['/ExternalPartner/ListExternalPartners']['get']['parameters']['query'];

type ListExternalPartnersSuccess =
    paths['/ExternalPartner/ListExternalPartners']['get']['responses']['200']['content']['application/json'];

export const ListExternalPartnersUrl = '/ExternalPartner/ListExternalPartners';

export const ListExternalPartnersUrlFormat = (params: ListExternalPartnersParams): string =>
    ListExternalPartnersUrl + formatParams(params);

export function ListExternalPartners(params: ListExternalPartnersParams): Promise<ListExternalPartnersSuccess> {
    return fetchJSON<ListExternalPartnersSuccess>(
        `/ExternalPartner/ListExternalPartners${formatParams(params)}`,
        {},
        true,
    );
}

type CreateExternalPartnerRequestBody =
    paths['/ExternalPartner/CreateExternalPartner']['post']['requestBody']['content']['application/json'];

type CreateExternalPartnerSuccess =
    paths['/ExternalPartner/CreateExternalPartner']['post']['responses']['200']['content']['application/json'];

export const CreateExternalPartnerUrl = '/ExternalPartner/CreateExternalPartner';

export function CreateExternalPartner(
    requestBody: CreateExternalPartnerRequestBody,
): Promise<CreateExternalPartnerSuccess> {
    return postJSON<CreateExternalPartnerSuccess>(`/ExternalPartner/CreateExternalPartner`, requestBody, true);
}

type UpdateExternalPartnerParams = paths['/ExternalPartner/UpdateExternalPartner']['post']['parameters']['query'];

type UpdateExternalPartnerRequestBody =
    paths['/ExternalPartner/UpdateExternalPartner']['post']['requestBody']['content']['application/json'];

type UpdateExternalPartnerSuccess =
    paths['/ExternalPartner/UpdateExternalPartner']['post']['responses']['200']['content']['application/json'];

export const UpdateExternalPartnerUrl = '/ExternalPartner/UpdateExternalPartner';

export const UpdateExternalPartnerUrlFormat = (params: UpdateExternalPartnerParams): string =>
    UpdateExternalPartnerUrl + formatParams(params);

export function UpdateExternalPartner(
    params: UpdateExternalPartnerParams,
    requestBody: UpdateExternalPartnerRequestBody,
): Promise<UpdateExternalPartnerSuccess> {
    return postJSON<UpdateExternalPartnerSuccess>(
        `/ExternalPartner/UpdateExternalPartner${formatParams(params)}`,
        requestBody,
        true,
    );
}

type GetExternalPartnerParams = paths['/ExternalPartner/GetExternalPartner']['get']['parameters']['query'];

type GetExternalPartnerSuccess =
    paths['/ExternalPartner/GetExternalPartner']['get']['responses']['200']['content']['application/json'];

export const GetExternalPartnerUrl = '/ExternalPartner/GetExternalPartner';

export const GetExternalPartnerUrlFormat = (params: GetExternalPartnerParams): string =>
    GetExternalPartnerUrl + formatParams(params);

export function GetExternalPartner(params: GetExternalPartnerParams): Promise<GetExternalPartnerSuccess> {
    return fetchJSON<GetExternalPartnerSuccess>(`/ExternalPartner/GetExternalPartner${formatParams(params)}`, {}, true);
}

type ToggleDeletedExternalPartnerParams =
    paths['/ExternalPartner/ToggleDeletedExternalPartner']['get']['parameters']['query'];

type ToggleDeletedExternalPartnerSuccess =
    paths['/ExternalPartner/ToggleDeletedExternalPartner']['get']['responses']['200']['content']['application/json'];

export const ToggleDeletedExternalPartnerUrl = '/ExternalPartner/ToggleDeletedExternalPartner';

export const ToggleDeletedExternalPartnerUrlFormat = (params: ToggleDeletedExternalPartnerParams): string =>
    ToggleDeletedExternalPartnerUrl + formatParams(params);

export function ToggleDeletedExternalPartner(
    params: ToggleDeletedExternalPartnerParams,
): Promise<ToggleDeletedExternalPartnerSuccess> {
    return fetchJSON<ToggleDeletedExternalPartnerSuccess>(
        `/ExternalPartner/ToggleDeletedExternalPartner${formatParams(params)}`,
        {},
        true,
    );
}

type ToggleActiveExternalPartnerParams =
    paths['/ExternalPartner/ToggleActiveExternalPartner']['get']['parameters']['query'];

type ToggleActiveExternalPartnerSuccess =
    paths['/ExternalPartner/ToggleActiveExternalPartner']['get']['responses']['200']['content']['application/json'];

export const ToggleActiveExternalPartnerUrl = '/ExternalPartner/ToggleActiveExternalPartner';

export const ToggleActiveExternalPartnerUrlFormat = (params: ToggleActiveExternalPartnerParams): string =>
    ToggleActiveExternalPartnerUrl + formatParams(params);

export function ToggleActiveExternalPartner(
    params: ToggleActiveExternalPartnerParams,
): Promise<ToggleActiveExternalPartnerSuccess> {
    return fetchJSON<ToggleActiveExternalPartnerSuccess>(
        `/ExternalPartner/ToggleActiveExternalPartner${formatParams(params)}`,
        {},
        true,
    );
}
