import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { History } from 'history';
import { ApplicationState, reducers } from '.';

let store: Store;

export function GetStore(): Store {
    return store;
}

export default function configureStore(history: History, initialState?: ApplicationState): Store {
    const middleware = [thunk, routerMiddleware(history)];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history),
    });

    // eslint-disable-next-line @typescript-eslint/ban-types
    const enhancers: Function[] = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }
    store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleware), ...enhancers));
    return store;
}
