import React, { useState, useEffect } from 'react';
import { Typography, Grid, Button, Checkbox, FormControlLabel, TextField } from '@mui/material';
import DefaultModal from 'components/modal/DefaultModal';
import { useTypedTranslation } from 'translations';
import { groupBy } from 'helpers/arrayHelper';
import { CheckBoxOutlineBlank, CheckBox, IndeterminateCheckBox } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { useAfterMountEffect } from 'helpers/hooks';

import { IFilter, objectType, valueType } from '../types';

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;
const indeterminateIcon = <IndeterminateCheckBox fontSize="small" />;

interface ISelectMultipleModalProps<T extends objectType> {
    filter: IFilter<T>;
    value: valueType;
    handleChange: (filter: IFilter<T>, val: string[]) => void;
}

export function SelectMultipleModal<T extends objectType>(props: ISelectMultipleModalProps<T>): JSX.Element {
    const { filter, value, handleChange } = props;
    const { options, getText, getValue, getGroup, label } = filter;
    const [open, setOpen] = useState(false);
    const { t, tf } = useTypedTranslation();
    const [selected, setSelected] = useState((value || []) as string[]);
    const [search, setSearch] = useState('');
    const [searchValue] = useDebounce(search, 200);

    useEffect(() => {
        setSelected((value || []) as string[]);
    }, [value]);

    useAfterMountEffect(() => {
        if (!open) {
            handleChange(filter, selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const regex = new RegExp(searchValue, 'i');
    const filteredOptions =
        options && searchValue && getText ? options.filter((item) => getText(item).match(regex)) : options;

    const groups = filteredOptions && groupBy(filteredOptions, (item) => (getGroup ? getGroup(item).id : ''));
    const hasMultipleGroups = groups && Object.entries(groups).length > 1;
    const hasSelectedNotInSearch =
        !!filteredOptions &&
        !!getValue &&
        !!selected.length &&
        !selected.every((x) => filteredOptions.some((f) => getValue(f) === x));

    function selectValue(val: string, checked: boolean): void {
        let newArray: string[];
        if (checked) {
            newArray = [...selected, val];
        } else {
            newArray = selected.reduce((acc, x) => (x === val ? acc : [...acc, x]), [] as string[]);
        }
        setSelected(newArray);
    }

    function selectGroup(groupId: string, checked: boolean): void {
        if (!options) return;

        const groupOptions = options
            .filter((item) => (getGroup ? getGroup(item).id : '') === groupId)
            .map((x) => (getValue ? getValue(x) : ''));

        let newArray: string[];
        if (checked) {
            const newOptions = groupOptions.filter((itemValue) => !selected.some((y) => itemValue === y));
            newArray = [...selected, ...newOptions];
        } else {
            newArray = selected.reduce(
                (acc, x) => (groupOptions.some((y) => x === y) ? acc : [...acc, x]),
                [] as string[],
            );
        }
        setSelected(newArray);
    }

    if (!options || !getText || !getValue) {
        return <Typography>Invalid data</Typography>;
    }

    return (
        <>
            <Button
                color={selected.length > 0 ? 'info' : 'inherit'}
                variant="contained"
                style={{ paddingTop: '8px', paddingBottom: '8px', marginBottom: '8px', marginTop: '16px' }}
                onClick={(): void => setOpen(true)}
            >
                {tf(
                    { label, count: selected.length },
                    'Filter',
                    selected && selected.length > 0 ? 'Selected' : 'Select',
                )}
            </Button>
            <DefaultModal open={open} onClose={(): void => setOpen(false)} maxWidth="lg">
                <Grid container direction="column" gap={1}>
                    <Grid item>
                        <Grid container justifyContent="center" gap={2}>
                            <Grid item>
                                <TextField
                                    placeholder={tf({ label }, 'Filter', 'SearchType')}
                                    label={t('Filter', 'Search')}
                                    type="text"
                                    fullWidth
                                    value={search}
                                    variant="outlined"
                                    size="small"
                                    style={{ backgroundColor: 'white', width: '250px' }}
                                    onChange={(e): void => {
                                        setSearch(e.target.value);
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={!selected.length}
                                    onClick={(): void => setSelected([])}
                                >
                                    {t('Filter', 'DeselectAll')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={(): void => setOpen(false)}>
                                    {t('Filter', 'CloseSelectMultiple')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    {hasSelectedNotInSearch && (
                        <Grid item>
                            <Grid>
                                <Typography color="warning">{tf({ label }, 'Filter', 'SelectedNotShown')}</Typography>
                            </Grid>
                        </Grid>
                    )}
                    <Grid item>
                        <Grid container>
                            {groups &&
                                Object.entries(groups).map(([key, groupValues]) => {
                                    const group = getGroup ? getGroup(groupValues[0]) : undefined;
                                    const groupValuesWithSelected = groupValues.map((x) => ({
                                        item: x,
                                        selected: selected.some((y) => y === getValue(x)),
                                    }));
                                    // eslint-disable-next-line no-nested-ternary
                                    const groupSelected = groupValuesWithSelected.every((x) => x.selected)
                                        ? 'Selected'
                                        : groupValuesWithSelected.every((x) => !x.selected)
                                        ? 'NoneSelected'
                                        : 'Indeterminate';
                                    return (
                                        <Grid
                                            key={key}
                                            item
                                            xs={hasMultipleGroups ? 4 : 12}
                                            style={{ marginBottom: '10px' }}
                                        >
                                            <Grid container direction="column">
                                                {hasMultipleGroups && group && (
                                                    <Grid item>
                                                        <FormControlLabel
                                                            style={{
                                                                ...(hasMultipleGroups ? { margin: 0 } : {}),
                                                            }}
                                                            label={group.name}
                                                            control={
                                                                <Checkbox
                                                                    icon={
                                                                        groupSelected === 'NoneSelected'
                                                                            ? uncheckedIcon
                                                                            : indeterminateIcon
                                                                    }
                                                                    checkedIcon={checkedIcon}
                                                                    onChange={(e, checked): void => {
                                                                        selectGroup(group.id, checked);
                                                                    }}
                                                                    style={{ marginRight: '8px', padding: '3px' }}
                                                                    checked={groupSelected === 'Selected'}
                                                                />
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                                <Grid item>
                                                    <Grid container wrap="wrap">
                                                        {groupValuesWithSelected.map((y) => (
                                                            <Grid
                                                                item
                                                                xs={hasMultipleGroups ? 12 : 4}
                                                                key={getValue(y.item)}
                                                            >
                                                                <FormControlLabel
                                                                    style={{
                                                                        ...(hasMultipleGroups
                                                                            ? { paddingLeft: '15px', margin: 0 }
                                                                            : {}),
                                                                    }}
                                                                    label={getText(y.item)}
                                                                    control={
                                                                        <Checkbox
                                                                            icon={uncheckedIcon}
                                                                            checkedIcon={checkedIcon}
                                                                            style={{
                                                                                marginRight: '8px',
                                                                                padding: '3px',
                                                                            }}
                                                                            onChange={(e, checked): void =>
                                                                                selectValue(getValue(y.item), checked)
                                                                            }
                                                                            checked={selected.some(
                                                                                (x) => x === getValue(y.item),
                                                                            )}
                                                                        />
                                                                    }
                                                                />
                                                            </Grid>
                                                        ))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </DefaultModal>
        </>
    );
}

export default SelectMultipleModal;
