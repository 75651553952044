import React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import CreateOrEdit from './components/CreateOrEdit';
import List from './components/List';

export function ConsultationCategory(props: RouteComponentProps<{ categoryId?: string }>): JSX.Element {
    const {
        match: {
            params: { categoryId },
        },
    } = props;

    if (categoryId || categoryId === '0') {
        const id = parseInt(categoryId);
        return <CreateOrEdit id={id} />;
    }
    return <List />;
}

export default ConsultationCategory;
