import React, { useCallback, useEffect, useState } from 'react';
import { useTypedTranslation } from 'translations';
import { useValidationState } from 'helpers/validation';
import { Grid, Typography, Button, Checkbox, TextField, CircularProgress } from '@mui/material';
import {
    GetShift,
    CreateOrUpdateConsultantShiftViewModel,
    ConsultationProductDetailed,
    ListAvailableConsultationProductDetailsForShift,
    DeleteShift,
    ChangeDisableShift,
    UpdateShift,
    CreateShift,
    ConsultantShift,
    IActiveTimeSpan,
    Consultant,
} from 'autogen/swagger/Consultant';
import { nameof } from 'ts-simple-nameof';
import Autocomplete from '@mui/material/Autocomplete';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import ActiveTimeSpan from 'components/Input/ActiveTimeSpan';
import moment, { Moment } from 'moment';
import SpinnerButton from 'components/button/SpinnerButton';
import ButtonLink from 'components/button/ButtonLink';
import { formatConsultationProductName } from 'helpers/string';
import DynamicActiveTimeSpan from 'components/Input/DynamicActiveTimeSpan';
import { AuthRole, StoreSelectViewModel } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';

import StoreAndConsultantText from '../StoreAndConsultantText';

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;

function mapToShiftValues(shift: ConsultantShift): CreateOrUpdateConsultantShiftViewModel {
    return {
        activeTime: shift.activeTime,
        breaks: [{}],
        consultantId: shift.consultantId,
        consultationProductMasterIds: shift.consultationProductMasterIds,
    };
}

function getEmptyShiftValues(consultantId: number): CreateOrUpdateConsultantShiftViewModel {
    return {
        activeTime: {
            activeFromDate: '',
            activeToDate: '',
        },
        breaks: [],
        consultantId,
        consultationProductMasterIds: [],
    };
}

interface ICreateOrEditShiftProps {
    id?: number;
    consultant: Consultant;
    onComplete?: (update: boolean) => void;
    currentSelectedWeekDate: Moment;
    role: AuthRole;
    selectedStoreId?: number;
    selectableStores?: StoreSelectViewModel[];
}

export function CreateOrEditShift(props: ICreateOrEditShiftProps): JSX.Element {
    const { id, consultant, onComplete, currentSelectedWeekDate, selectedStoreId, selectableStores, role } = props;
    const consultantId = consultant.id;
    const { t } = useTypedTranslation();
    const [shift, setShift] = useState<ConsultantShift>();
    const [shiftValues, setShiftValues] = useState<CreateOrUpdateConsultantShiftViewModel>(
        getEmptyShiftValues(consultantId),
    );

    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [disabling, setDisabling] = useState(false);
    const [consultationProducts, setConsultationProducts] = useState<ConsultationProductDetailed[]>([]);

    const isEdit = !!id;

    useEffect(() => {
        if (id) {
            (async (): Promise<void> => {
                const result = await GetShift({ id });
                setShift(result);
                setShiftValues(mapToShiftValues(result));
            })();
        }

        (async (): Promise<void> => {
            const result = await ListAvailableConsultationProductDetailsForShift({
                shiftId: id,
                consultantId,
            });
            setConsultationProducts(result);
        })();
    }, [consultantId, id]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            setValidationErrors(undefined);

            let result;
            if (id) {
                result = await UpdateShift({ consultantShiftId: id }, shiftValues);
            } else {
                result = await CreateShift(shiftValues);
            }

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            let isShowableErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isShowableErrors)
                isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    async function deleteShift(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('ConsultantShift', 'ConfirmDelete'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setDeleting(true);
                    const result = await DeleteShift({ id });
                    setValidationErrors(undefined);
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isValidationErrors = await setValidationErrors(ex as Record<string, unknown>);

                    if (!isValidationErrors) throw ex;
                } finally {
                    setDeleting(false);
                }
            },
        });
    }

    async function changeDisableShift(): Promise<void> {
        if (!shift) return;
        ConfirmChoice({
            content: shift.isActive ? t('ConsultantShift', 'ConfirmDisable') : t('ConsultantShift', 'ConfirmEnable'),
            onConfirm: async () => {
                if (!shift.id) return;
                try {
                    setDisabling(true);
                    const result = await ChangeDisableShift({ id: shift.id, disable: shift.isActive });
                    setValidationErrors(undefined);
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isValidationErrors = await setValidationErrors(ex as Record<string, unknown>);

                    if (!isValidationErrors) throw ex;
                } finally {
                    setDisabling(false);
                }
            },
        });
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    function onActiveTimeSpanChange(activeTime: IActiveTimeSpan): void {
        setShiftValues((prev) => ({ ...prev, activeTime }));
    }

    const StartDate = useCallback(
        (): Moment =>
            (currentSelectedWeekDate.isBefore(moment())
                ? moment().add(1, 'days')
                : moment(currentSelectedWeekDate)
            ).set({ s: 0, ms: 0 }),
        [currentSelectedWeekDate],
    );

    const allSelected = consultationProducts.every((x) =>
        shiftValues.consultationProductMasterIds.some((s) => s === x.consultationProductMaster.id),
    );

    function togleSelectAll(): void {
        let newValue: number[] = [];
        if (!allSelected) {
            newValue = consultationProducts.map((x) => x.consultationProductMaster.id);
        }
        setShiftValues({ ...shiftValues, consultationProductMasterIds: newValue });
    }

    function onShiftBreakChangeEvent(breaks: IActiveTimeSpan[]): void {
        setShiftValues((prev) => ({ ...prev, breaks }));
    }

    if (id && !shift)
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );

    return (
        <Grid item xs>
            <Grid container direction="column" justifyContent="flex-start" gap={2}>
                <Grid container item>
                    <Grid item xs>
                        <Typography variant="h4">
                            {isEdit ? t('ConsultantShift', 'EditTile') : t('ConsultantShift', 'CreateTitle')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StoreAndConsultantText
                            consultant={consultant}
                            storeId={shift ? shift.storeId : selectedStoreId}
                            selectableStores={selectableStores}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={2} alignItems="flex-end" style={{ justifyContent: 'center' }}>
                    <ActiveTimeSpan
                        timeSpan={shift?.activeTime ?? { activeFromDate: '', activeToDate: '' }}
                        startDate={StartDate}
                        showDaySelection
                        onChange={onActiveTimeSpanChange}
                        error={isError(nameof<CreateOrUpdateConsultantShiftViewModel>((x) => x.activeTime))}
                        helperText={getErrorText(nameof<CreateOrUpdateConsultantShiftViewModel>((x) => x.activeTime))}
                        disabled={IsInRole(role, 'ConsultantRole')}
                    />

                    {!isEdit && (
                        <DynamicActiveTimeSpan
                            textField={t('ConsultantShift', 'AddBreak')}
                            day={
                                shiftValues.activeTime.activeFromDate
                                    ? moment(shiftValues.activeTime.activeFromDate).set({ h: 0, m: 0, s: 0, ms: 0 })
                                    : StartDate()
                            }
                            onChange={onShiftBreakChangeEvent}
                            error={isError(nameof<CreateOrUpdateConsultantShiftViewModel>((x) => x.breaks))}
                            helperText={getErrorText(nameof<CreateOrUpdateConsultantShiftViewModel>((x) => x.breaks))}
                        />
                    )}
                    <Grid item xs={12} container>
                        <Grid item xs={12}>
                            <Autocomplete
                                disabled={IsInRole(role, 'ConsultantRole')}
                                multiple
                                options={consultationProducts}
                                value={consultationProducts.filter(
                                    (x) =>
                                        shiftValues.consultationProductMasterIds.indexOf(
                                            x.consultationProductMaster.id,
                                        ) > -1,
                                )}
                                onChange={(e, value): void =>
                                    setShiftValues({
                                        ...shiftValues,
                                        consultationProductMasterIds: value.map((x) => x.consultationProductMaster.id),
                                    })
                                }
                                groupBy={(option): string => option.consultationCategory.name}
                                disableCloseOnSelect
                                getOptionLabel={(option): string =>
                                    formatConsultationProductName(
                                        option.name,
                                        option.consultationProductMasterDetailed.externalPartner?.name,
                                    )
                                }
                                renderOption={(attributes, option, { selected }): JSX.Element => (
                                    <li {...attributes} key={option.id}>
                                        <Checkbox
                                            icon={uncheckedIcon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        {formatConsultationProductName(
                                            option.name,
                                            option.consultationProductMasterDetailed.externalPartner?.name,
                                        )}
                                    </li>
                                )}
                                fullWidth
                                renderInput={(params): JSX.Element => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t('ConsultantShift', 'Service')}
                                        placeholder={t('ConsultantShift', 'SelectServices')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={isError(
                                            nameof<CreateOrUpdateConsultantShiftViewModel>(
                                                (x) => x.consultationProductMasterIds,
                                            ),
                                        )}
                                        helperText={getErrorText(
                                            nameof<CreateOrUpdateConsultantShiftViewModel>(
                                                (x) => x.consultationProductMasterIds,
                                            ),
                                        )}
                                    />
                                )}
                            />
                            {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                                <Grid item container xs={12} justifyContent="flex-end">
                                    <ButtonLink color="primary" onClick={togleSelectAll}>
                                        {t('Shared', allSelected ? 'DeselectAll' : 'SelectAll')}
                                    </ButtonLink>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="center" gap={2}>
                    <Grid item>
                        <Button variant="contained" onClick={onCancelClick}>
                            {t('Shared', 'Cancel')}
                        </Button>
                    </Grid>
                    {isEdit && (
                        <>
                            {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                                <Grid item>
                                    <SpinnerButton
                                        loading={deleting}
                                        variant="contained"
                                        color="secondary"
                                        onClick={deleteShift}
                                    >
                                        {t('Shared', 'Delete')}
                                    </SpinnerButton>
                                </Grid>
                            )}
                            <Grid item>
                                <SpinnerButton
                                    loading={disabling}
                                    variant="contained"
                                    color="warning"
                                    onClick={changeDisableShift}
                                >
                                    {shift?.isActive ? t('ConsultantShift', 'Disable') : t('ConsultantShift', 'Enable')}
                                </SpinnerButton>
                            </Grid>
                        </>
                    )}
                    {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                        <Grid item>
                            <SpinnerButton loading={saving} variant="contained" color="primary" onClick={save}>
                                {isEdit ? t('Shared', 'Save') : t('Shared', 'Create')}
                            </SpinnerButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateOrEditShift;
