import React, { useState, useEffect } from 'react';
import { ExternalPartner, ConsultantEditModelResponse, ConsultantCreateRequest } from 'autogen/swagger/Consultant';
import { useTypedTranslation } from 'translations';
import { ListExternalPartners } from 'autogen/swagger/ExternalPartners';
import { Grid, Checkbox, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { CheckBoxOutlineBlank, CheckBox } from '@mui/icons-material';
import { nameof } from 'ts-simple-nameof';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;

interface ISelectExternalPartnersProps {
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
    isError: (param: string) => boolean;
    getErrorText: (param: string) => string;
    consultant?: ConsultantEditModelResponse;
    isEdit: boolean;
    role: AuthRole;
}

export function SelectExternalPartners(props: ISelectExternalPartnersProps): JSX.Element {
    const { role, values, setValues, isError, getErrorText, consultant, isEdit } = props;

    const [externalPartners, setExternalPartners] = useState<ExternalPartner[]>([]);

    const { t } = useTypedTranslation();

    let selectableExternalPartners: ExternalPartner[] = [];
    if (IsInRole(role, 'AdminRole', 'StoreManagerRole')) {
        selectableExternalPartners = [
            ...externalPartners,
            ...(consultant && consultant.baseData.externalPartners
                ? consultant.baseData.externalPartners.reduce(
                      (acc, item) =>
                          externalPartners.every((x) => x.id !== item.id)
                              ? [
                                    ...acc,
                                    {
                                        ...item,
                                        name: `(${t('Shared', 'Deleted')}) ${item.name}`,
                                    },
                                ]
                              : acc,
                      [] as ExternalPartner[],
                  )
                : ([] as ExternalPartner[])),
        ];
    } else {
        selectableExternalPartners = consultant?.baseData.externalPartners || [];
    }

    useEffect(() => {
        if (IsInRole(role, 'AdminRole', 'StoreManagerRole')) {
            (async (): Promise<void> => {
                const result = await ListExternalPartners({ onlyActive: false });
                setExternalPartners(result);
            })();
        }
    }, [role]);

    return (
        <Grid container item gap={2}>
            <Grid item xs={6}>
                <Autocomplete
                    options={selectableExternalPartners}
                    multiple
                    value={selectableExternalPartners.filter((x) => values.externalPartnerIds.includes(x.id))}
                    onChange={(e, value): void =>
                        setValues({
                            ...values,
                            externalPartnerIds: value.map((x) => x.id),
                        })
                    }
                    disabled={isEdit && !IsInRole(role, 'AdminRole')}
                    getOptionLabel={(option): string => option.name}
                    fullWidth
                    disableCloseOnSelect
                    renderOption={(attributes, option, { selected }): JSX.Element => (
                        <li {...attributes} key={option.id}>
                            <Checkbox
                                icon={uncheckedIcon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={selected}
                            />
                            {option.name}
                        </li>
                    )}
                    renderInput={(params): JSX.Element => (
                        <TextField
                            {...params}
                            variant="outlined"
                            label={t('Consultant', 'Input', 'ExternalPartner')}
                            placeholder={t('Consultant', 'Input', 'SelectExternalPartner')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            error={isError(nameof<ConsultantCreateRequest>((x) => x.externalPartnerIds))}
                            helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.externalPartnerIds))}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}

export default SelectExternalPartners;
