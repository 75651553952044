import React, { ComponentType } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTypedTranslation } from 'translations';
import { connect } from 'react-redux';
import { ApplicationState, GetStore } from 'store';
import { actionCreators, ConfirmModalState, ConfirmChoiceActionProps } from 'store/modal/ConfirmModal';

type IConfirmDialogProps = typeof actionCreators & ConfirmModalState;

function ConfirmModal(props: IConfirmDialogProps): JSX.Element {
    const { isOpen, content, headline, onConfirm, onDeny, CloseConfirmAction } = props;
    const { t } = useTypedTranslation();

    const handleAcceptOption = (): void => {
        if (onConfirm) {
            onConfirm();
        }

        CloseConfirmAction();
    };

    const handleDenyOption = (): void => {
        if (onDeny) {
            onDeny();
        }

        CloseConfirmAction();
    };

    const handleClose = (): void => {
        if (onDeny) {
            onDeny();
        }

        CloseConfirmAction();
    };

    return (
        <Dialog
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            {!!headline && <DialogTitle id="alert-dialog-title">{headline}</DialogTitle>}
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDenyOption} color="primary">
                    {t('Shared', 'No')}
                </Button>
                <Button onClick={handleAcceptOption} color="primary" autoFocus>
                    {t('Shared', 'Yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default connect(
    (state: ApplicationState) => state.confirmModal, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(ConfirmModal as ComponentType);

export function ConfirmChoice(props: ConfirmChoiceActionProps): void {
    const store = GetStore();
    actionCreators.ConfirmChoiceAction(props)(store.dispatch, store.getState);
}
