import * as React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { CircularProgress } from '@mui/material';
import { useState } from 'react';
import { useTypedTranslation } from 'translations';
import {
    DashboardRequestViewModel,
    GetCancellationAndNoShowReport,
    GetDashboardExport,
} from '../../../autogen/swagger/Dashboard';

interface IExportSpinnerMenuProps {
    filterRequestBody: DashboardRequestViewModel;
}

export function ExportSpinnerMenu(props: IExportSpinnerMenuProps): JSX.Element {
    const { filterRequestBody } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const { t } = useTypedTranslation();
    const open = Boolean(anchorEl);
    const [dashboardExportLoading, setDashboardExportLoading] = useState(false);
    const [noShowExportLoading, setNoShowExportLoading] = useState(false);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    async function handleDashboardExport() {
        setDashboardExportLoading(true);

        try {
            await GetDashboardExport({}, filterRequestBody);
        } finally {
            setDashboardExportLoading(false);
        }
    }

    async function handleNoShowExport() {
        setNoShowExportLoading(true);

        try {
            await GetCancellationAndNoShowReport(filterRequestBody);
        } finally {
            setNoShowExportLoading(false);
        }
    }

    return (
        <div>
            <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
            >
                {t('Dashboard', 'Exports', 'Export')}
            </Button>
            <Menu
                id="demo-customized-menu"
                MenuListProps={{
                    'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={handleClose}
                PaperProps={{ style: { width: 200 } }}
            >
                <MenuItem onClick={handleDashboardExport} disableRipple dense>
                    {t('Dashboard', 'Exports', 'Dashboard')}
                    {dashboardExportLoading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
                </MenuItem>
                <MenuItem onClick={handleNoShowExport} disableRipple dense>
                    {t('Dashboard', 'Exports', 'CancellationAndNoShows')}
                    {noShowExportLoading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
                </MenuItem>
            </Menu>
        </div>
    );
}

export default ExportSpinnerMenu;
