import { GridCallbackDetails } from '@mui/x-data-grid';
import { useHistory } from 'react-router';
import { useState } from 'react';

export interface IPagination {
    pageNumber: number;
    pageSize: number;
    totalHits: number;
    totalPages: number;
}

export interface IDataGridPagination {
    paginationMode: 'server' | 'client';
    pagination: true | undefined;
    page: number;
    pageSize: number;
    onPageChange: (pageNumber: number, p: GridCallbackDetails) => void;
    onPageSizeChange: (pageSize: number, p: GridCallbackDetails) => void;
    rowsPerPageOptions: number[];
    rowCount: number;
}

export function usePaging(value: IPagination): [IPagination, (pagination: IPagination) => void, IDataGridPagination] {
    const history = useHistory();
    const initialValue = (history.location.state as IPagination) || value;
    const [pagination, setPagination] = useState<IPagination>(initialValue);

    function mapPagination(values: IPagination): void {
        const val = values as unknown as Record<string, number>;
        const newPagination = { ...pagination } as Record<string, number>;
        Object.entries(pagination).forEach(([key]) => {
            if (Object.prototype.hasOwnProperty.call(val, key)) {
                newPagination[key] = val[key];
            }
        });
        setPagination(newPagination as unknown as IPagination);

        history.replace(history.location.pathname + history.location.search, pagination);
    }

    return [
        pagination,
        mapPagination,
        {
            paginationMode: 'server',
            pagination: true,
            page: pagination.pageNumber - 1, // Datagrid is apparently zero based
            pageSize: pagination.pageSize,
            onPageChange: (pageNumber): void => setPagination({ ...pagination, pageNumber: pageNumber + 1 }),
            onPageSizeChange: (pageSize): void => setPagination({ ...pagination, pageNumber: 1, pageSize }),
            rowsPerPageOptions: [10, 25, 50, 100],
            rowCount: pagination.totalHits || 0,
        },
    ];
}
