import { createTheme, adaptV4Theme } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const theme = createTheme(
    adaptV4Theme({
        palette: {
            secondary: {
                light: '#fC3636',
                main: '#DC2626',
                dark: '#bC1616',
                contrastText: '#ffffff',
            },
        },
        breakpoints: {
            values: {
                xs: 0,
                sm: 600,
                md: 960,
                lg: 1280,
                xl: 1650,
            },
        },
        typography: {
            fontFamily: 'sans-serif',
            body1: {
                fontSize: 14,
                color: '#111827',
            },
            body2: {
                fontSize: 12,
                color: '#6B7280',
            },
            subtitle1: {
                fontSize: 14,
                color: '#111827',
                textTransform: 'uppercase',
            },
            subtitle2: {
                fontSize: 12,
                color: '#6B7280',
                textTransform: 'uppercase',
            },
            h1: {
                fontSize: 48,
                fontWeight: 'bold',
            },
            h2: {
                fontSize: 36,
                fontWeight: 'bold',
            },
            h3: {
                fontSize: 28,
                fontWeight: 'bold',
            },
            h4: {
                fontSize: 24,
                fontWeight: 'bold',
            },
            h5: {
                fontSize: 18,
                fontWeight: 'bold',
            },
            h6: {
                fontSize: 14,
                fontWeight: 'bold',
            },
        },
        overrides: {
            MuiListItem: {
                root: {
                    borderTop: '1px solid #E5E7EB',
                    '&$selected': {
                        background: '#E0F2FE',
                        border: '1px solid #2563EB',
                        borderRadius: '8px',
                    },
                    '&.Mui-selected + &': {
                        borderTop: '0',
                    },
                    '&:first-of-type': {
                        borderTop: '0',
                    },
                },
            },
            MuiMenuItem: {
                root: {
                    borderTop: '0',
                    '&$selected': {
                        border: '0',
                        borderRadius: '0',
                        borderBottom: '1px solid #2563EB',
                    },
                },
            },
            MuiButton: {
                root: {
                    textTransform: 'none',
                },
            },
            MuiToggleButton: {
                root: {
                    textTransform: 'none',
                },
            },
            MuiToggleButtonGroup: {
                root: {
                    textTransform: 'none',
                },
            },
            MuiDataGrid: {
                root: {
                    backgroundColor: 'white',
                },
            },
            MuiBackdrop: {
                root: {
                    backgroundColor: 'rgba(100, 100, 100, 0.2)',
                },
            },
        },
    }),
);

export default theme;

export const useDataGridStyles = makeStyles(() =>
    createStyles({
        root: {
            '&& .MuiDataGrid-columnHeaders': {
                backgroundColor: '#e9f6ff',
            },
        },
    }),
);

export const useTableStyles = makeStyles(() =>
    createStyles({
        root: {
            '&& .MuiTableRow-head ': {
                backgroundColor: '#e9f6ff',
            },
        },
    }),
);
