import React, { useState } from 'react';
import { Grid, Button, Menu, MenuItem } from '@mui/material';
import { IsInRole } from 'helpers/userHelper';
import { AuthRole } from 'autogen/swagger/Users';
import { FileCopy, Add } from '@mui/icons-material';
import { useTypedTranslation } from 'translations';

interface ICalendarButtonsProps {
    role: AuthRole;
    copyShiftsWeeklyEnabled: boolean;
    setCopyShiftsWeeklyEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    copyShiftsDailyEnabled: boolean;
    setCopyShiftsDailyEnabled: React.Dispatch<React.SetStateAction<boolean>>;
    disabled: boolean;
    openShiftModal: () => void;
    openEventTimeModal: () => void;
    openCreateBooking: () => void;
}

export function CalendarButtons(props: ICalendarButtonsProps): JSX.Element {
    const {
        role,
        setCopyShiftsWeeklyEnabled,
        setCopyShiftsDailyEnabled,
        copyShiftsWeeklyEnabled,
        copyShiftsDailyEnabled,
        disabled,
        openShiftModal,
        openEventTimeModal,
        openCreateBooking,
    } = props;

    const { t } = useTypedTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    function toggleCopyShiftWeekly(): void {
        setCopyShiftsWeeklyEnabled(!copyShiftsWeeklyEnabled);

        setAnchorEl(null);
    }

    function toggleCopyShiftDaily(): void {
        setCopyShiftsDailyEnabled(!copyShiftsDailyEnabled);
        setAnchorEl(null);
    }

    function onOpenCreateBooking(): void {
        openCreateBooking();
        setAnchorEl(null);
    }

    const toggleOptionsMenu = (event: React.MouseEvent<HTMLButtonElement>): void => {
        if (copyShiftsWeeklyEnabled) {
            setAnchorEl(null);
            toggleCopyShiftWeekly();
        } else if (copyShiftsDailyEnabled) {
            setAnchorEl(null);
            toggleCopyShiftDaily();
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    function closeCopyShiftMenu(): void {
        setAnchorEl(null);
    }

    return (
        <Grid container justifyContent="flex-end" gap={2}>
            {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                <>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            onClick={(): void => openEventTimeModal()}
                        >
                            <Add /> {t('Calendar', 'CreateEventTime')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={disabled}
                            onClick={(): void => openShiftModal()}
                        >
                            <Add /> {t('Calendar', 'CreateShift')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button
                            color={copyShiftsWeeklyEnabled || copyShiftsDailyEnabled ? 'secondary' : 'primary'}
                            style={{ width: '150px' }}
                            variant="contained"
                            disabled={disabled}
                            onClick={toggleOptionsMenu}
                        >
                            {copyShiftsWeeklyEnabled || copyShiftsDailyEnabled
                                ? t('Shared', 'End')
                                : t('Calendar', 'MoreOptions')}{' '}
                        </Button>
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            onClose={closeCopyShiftMenu}
                            open={open}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                            PaperProps={{
                                style: {
                                    width: '200px',
                                    maxWidth: '200px',
                                    left: 0,
                                    right: 0,
                                },
                            }}
                        >
                            <MenuItem onClick={toggleCopyShiftDaily} divider>
                                <FileCopy style={{ marginRight: '5px' }} /> {t('Calendar', 'CopyShiftsDaily')}
                            </MenuItem>
                            <MenuItem onClick={toggleCopyShiftWeekly} divider>
                                <FileCopy style={{ marginRight: '5px' }} /> {t('Calendar', 'CopyShiftsWeekly')}
                            </MenuItem>
                            <MenuItem onClick={onOpenCreateBooking}>
                                <Add style={{ marginRight: '5px' }} /> {t('Calendar', 'CreateBooking')}
                            </MenuItem>
                        </Menu>
                    </Grid>
                </>
            )}
        </Grid>
    );
}

export default CalendarButtons;
