import React from 'react';
import Layout from 'components/Layout';
import MomentUtils from '@date-io/moment';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import Routes from 'shared/routes';
import { Route, Switch } from 'react-router';
import ConfirmModal from 'components/modal/ConfirmModal';
import Message from 'components/messages/Message';
import Home from 'pages/Home';

export function App(): JSX.Element {
    return (
        <LocalizationProvider dateAdapter={AdapterMoment} utils={MomentUtils}>
            <Layout>
                <Switch>
                    <Route exact path="/" component={Home} />
                    {Routes.map((route) => (
                        <Route exact path={route.path} key={route.path} component={route.component} />
                    ))}
                </Switch>
                <ConfirmModal />
                <Message />
            </Layout>
        </LocalizationProvider>
    );
}

export default App;
