import React, { useState } from 'react';
import { Button, Grid, TextField, Select, FormControl, MenuItem, CircularProgress, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { LookupEmployee, EmployeeLookup } from 'autogen/swagger/Consultant';
import { showMessage } from 'components/messages/Message';

type SearchType = 'CardNumber' | 'MobileNumber';

const SearchTypes: SearchType[] = ['MobileNumber', 'CardNumber'];

interface ISearchEmployeeProps {
    onSearchResult: (employee?: EmployeeLookup) => void;
    showInputs: boolean;
}

export function SearchEmployee(props: ISearchEmployeeProps): JSX.Element {
    const { onSearchResult, showInputs } = props;
    const [search, setSearch] = useState('');
    const [searchType, setSearchType] = useState<SearchType>('MobileNumber');
    const [loading, setLoading] = useState(false);
    const { t } = useTypedTranslation();

    async function searchEmployee(): Promise<void> {
        onSearchResult(undefined);
        if (showInputs) {
            return;
        }
        setLoading(true);
        try {
            const result = await LookupEmployee({
                mobileNumber: searchType === 'MobileNumber' ? search : null,
                cardNumber: searchType === 'CardNumber' ? search : null,
            });
            if (!result) {
                showMessage({ content: t('Consultant', 'Input', 'NoMemberFound'), severity: 'warning' });
            } else {
                onSearchResult(result);
            }
        } finally {
            setLoading(false);
        }
    }

    return (
        <Grid container item gap={2}>
            <Grid item xs={12} style={{ paddingBottom: 0 }}>
                <Typography>{t('Consultant', 'SearchEmployee')}</Typography>
            </Grid>
            <Grid item xs={2}>
                <FormControl fullWidth>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={searchType}
                        disabled={showInputs}
                        variant="outlined"
                        style={{ height: '37px' }}
                        onChange={(e): void => setSearchType(e.target.value as SearchType)}
                    >
                        {SearchTypes.map((x) => (
                            <MenuItem key={x} value={x}>
                                {t('Consultant', 'SearchTypes', x)}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={4}>
                <TextField
                    type="string"
                    fullWidth
                    size="small"
                    value={search}
                    disabled={showInputs}
                    variant="outlined"
                    onKeyPress={(e): void => {
                        if (e.key === 'Enter') searchEmployee();
                    }}
                    onChange={(e): void => setSearch(e.target.value)}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={3}>
                <Button variant="contained" color="primary" disabled={loading} fullWidth onClick={searchEmployee}>
                    {showInputs ? t('Consultant', 'NewSearch') : t('Consultant', 'SearchEmployee')}
                    {loading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
                </Button>
            </Grid>
        </Grid>
    );
}

export default SearchEmployee;
