import React from 'react';
import { Button, ButtonProps, CircularProgress } from '@mui/material';

interface IButtonProps extends ButtonProps {
    loading: boolean;
}

export function SpinnerButton(props: IButtonProps): JSX.Element {
    const { loading, disabled, children, ...rest } = props;

    const disabledState = disabled || loading;

    let style = { paddingLeft: '25px', paddingRight: '25px' };
    if (loading) style = { paddingLeft: '10px', paddingRight: '10px' };

    return (
        <Button variant="contained" {...rest} disabled={disabledState} style={style}>
            {children}
            {loading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
        </Button>
    );
}

export default SpinnerButton;
