/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
    Button,
    CircularProgress,
    Grid,
    List,
    ListItem,
    ListItemText,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import { useTypedTranslation } from 'translations';
import { ConsultantModel, ListConsultants } from '../../autogen/swagger/Consultant';

const Wrapper = styled(Grid)`
    border: 1px solid #9ca3af;
    padding: 15px;
    background: white;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
`;

function RenderConsultant(
    consultant: ConsultantModel,
    selectConsultant: (consultantModel: ConsultantModel) => void,
    selectedId?: number,
): JSX.Element {
    const { id, firstName, lastName } = consultant;

    return (
        <ListItem key={id} button selected={selectedId === id} onClick={(): void => selectConsultant(consultant)}>
            <ListItemText>
                {firstName} {lastName}
            </ListItemText>
        </ListItem>
    );
}

interface ISelectConsultantProps {
    consultantId?: number;
    storeId: number;
    onSelected?: (consultantId: number) => void;
}

export function SelectConsultant(props: ISelectConsultantProps): JSX.Element {
    const { consultantId, storeId, onSelected } = props;
    const { t } = useTypedTranslation();
    const [selectedId, setSelectedId] = useState(consultantId);
    const [consultants, setConsultants] = useState<ConsultantModel[]>([]);
    const [loading, setLoading] = useState(false);
    const largeScreen = useMediaQuery((th: Theme) => th.breakpoints.up('md'));
    const [collapsed, setCollapsed] = useState(!largeScreen);

    const loadConsultants = useCallback(async () => {
        try {
            setLoading(true);
            const result = await ListConsultants({ PageNumber: 1, PageSize: 500 });
            setConsultants(result.hits);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        loadConsultants();
    }, [loadConsultants, storeId]);

    function selectConsultant(consultant: ConsultantModel): void {
        setSelectedId(consultant.id);
        if (onSelected) {
            onSelected(consultant.id);
        }
    }

    function sortAlphabetically(a: ConsultantModel, b: ConsultantModel): number {
        return `${a.firstName} ${a.lastName}` > `${b.firstName} ${b.lastName}` ? 1 : -1;
    }

    const internalConsultants = consultants.filter((x) => !x.isExternal).sort(sortAlphabetically);
    const externalConsultants = consultants.filter((x) => x.isExternal).sort(sortAlphabetically);

    return (
        <Wrapper container direction="column">
            <Grid item container justifyContent="space-between" alignItems="center">
                <Typography variant="h5">{t('Consultant', 'Consultants')}</Typography>
                {!largeScreen && (
                    <Button onClick={(): void => setCollapsed(!collapsed)}>
                        {collapsed ? t('Shared', 'Hide') : t('Shared', 'Show')}
                    </Button>
                )}
            </Grid>
            <Grid item>
                {!largeScreen && collapsed ? null : loading ? (
                    <Grid container justifyContent="center" alignItems="center">
                        <CircularProgress />
                    </Grid>
                ) : (
                    <List>
                        <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} dense>
                            <ListItemText>
                                <Typography variant="subtitle2">{t('Consultant', 'Internal')}</Typography>
                            </ListItemText>
                        </ListItem>
                        {internalConsultants.map((consultant) =>
                            RenderConsultant(consultant, selectConsultant, selectedId),
                        )}
                        {!!externalConsultants.length && (
                            <>
                                <ListItem style={{ paddingLeft: 0, paddingRight: 0 }} dense>
                                    <ListItemText>
                                        <Typography variant="subtitle2">{t('Consultant', 'External')}</Typography>
                                    </ListItemText>
                                </ListItem>
                                {externalConsultants.map((consultant) =>
                                    RenderConsultant(consultant, selectConsultant, selectedId),
                                )}
                            </>
                        )}
                    </List>
                )}
            </Grid>
        </Wrapper>
    );
}

export default SelectConsultant;
