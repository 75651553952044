import React, { useState, useEffect } from 'react';
import { Grid, Typography, CircularProgress } from '@mui/material';
import { Consultant } from 'autogen/swagger/Consultant';
import { StoreSelectViewModel } from 'autogen/swagger/Users';

interface IStoreAndConsultantTextProps {
    consultant?: Consultant;
    storeId?: number;
    selectableStores?: StoreSelectViewModel[];
}

export function StoreAndConsultantText(props: IStoreAndConsultantTextProps): JSX.Element {
    const { consultant, storeId, selectableStores } = props;
    const [store, setStore] = useState<StoreSelectViewModel>();

    useEffect(() => {
        if (storeId) {
            const storeObj = selectableStores?.find((x) => x.id === storeId);
            if (storeObj) setStore(storeObj);
        }
    }, [selectableStores, storeId]);

    if (!store || !consultant) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    }

    return (
        <Grid container direction="column">
            <Grid item>
                <Grid container justifyContent="flex-end">
                    <Typography variant="body2">
                        {store.id} - {store.name}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container justifyContent="flex-end">
                    <Typography variant="body2">
                        {consultant.firstName} {consultant.lastName}
                    </Typography>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default StoreAndConsultantText;
