import React, { useCallback, useEffect, useState, ComponentType } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { Add } from '@mui/icons-material';
import { Filter, IFilter } from 'components/Input/Filter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { IsInRole } from 'helpers/userHelper';
import { Link } from 'react-router-dom';
import { getConsultationProductUrl } from 'shared/urls';
import {
    ListConsultationProducts,
    ConsultationProductListModel,
    ConsultationCategory,
    ListCategories,
    BookingType,
} from 'autogen/swagger/ConsultationProduct';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators, UserState } from 'store/User';
import { ExternalPartner, ListExternalPartners } from 'autogen/swagger/ExternalPartners';
import { useDataGridStyles } from 'shared/theme';
import {
    getAdminColumns,
    getColumnDefinitions,
    getSingleStoreColumns,
} from 'pages/consultations/ConsultationProduct/components/ListColumnDefinitions';
import { useHistory } from 'react-router';
import { useDebouncedCallback } from 'use-debounce';

type IConsultationProductFilterValues = {
    categoryId?: number;
    externalPartnerId?: number;
    bookingType?: BookingType;
    onlyNews: boolean;
    onlyLocalStore: boolean;
    showDisabled: boolean;
    search: string;
    inBooth: boolean;
    isVirtual: boolean;
};

export function List(props: UserState): JSX.Element {
    const { selectedStoreId, selectableStores, role } = props;

    const isVirtualStore = selectableStores.find((x) => x.id === selectedStoreId)?.isVirtual ?? false;

    const { t } = useTypedTranslation();
    const [products, setProducts] = useState<ConsultationProductListModel[]>([]);
    const history = useHistory();
    const historyValues = (history.location.state as IConsultationProductFilterValues) || {};
    const [filterValues, setFilterValues] = useState<IConsultationProductFilterValues>({
        categoryId: historyValues.categoryId,
        externalPartnerId: historyValues.externalPartnerId,
        bookingType: historyValues.bookingType,
        onlyNews: historyValues.onlyNews ?? false,
        onlyLocalStore: historyValues.onlyLocalStore ?? false,
        showDisabled: historyValues.showDisabled ?? false,
        search: historyValues.search || '',
        inBooth: historyValues.inBooth ?? false,
        isVirtual: historyValues.isVirtual ?? isVirtualStore,
    });
    const [categories, setCategories] = useState<ConsultationCategory[]>([]);
    const [externalPartners, setExternalPartners] = useState<ExternalPartner[]>([]);
    const [filters, setFilters] = useState<IFilter<IConsultationProductFilterValues>[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilters([
            {
                label: t('ConsultationProduct', 'Filter', 'Search'),
                paramName: 'search',
                type: 'text',
            },
            {
                label: t('ConsultationProduct', 'Filter', 'BookingType'),
                paramName: 'bookingType',
                type: 'select',
                options: [{ value: 'Single' }, { value: 'Event' }],
                getText: (value): string =>
                    t('ConsultationProduct', 'BookingType', (value?.value?.toString() ?? '') as 'Single' | 'Event'),
                getValue: (value): string => value?.value?.toString() ?? '',
            },
            {
                label: t('ConsultationProduct', 'Filter', 'Category'),
                paramName: 'categoryId',
                type: 'select',
                options: categories,
                getText: (value): string => value?.name?.toString() ?? '',
                getValue: (value): string => value?.id?.toString() ?? '',
            },
            {
                label: t('ConsultationProduct', 'Filter', 'ExternalPartner'),
                paramName: 'externalPartnerId',
                type: 'select',
                options: externalPartners,
                getText: (value): string => value?.name?.toString() ?? '',
                getValue: (value): string => value?.id?.toString() ?? '',
            },
            {
                label: t('ConsultationProduct', 'Filter', 'OnlyNews'),
                paramName: 'onlyNews',
                type: 'checkbox',
            },
            {
                label: t('ConsultationProduct', 'Filter', 'OnlyLocalStore'),
                paramName: 'onlyLocalStore',
                type: 'checkbox',
            },
            {
                label: t('Filter', 'ShowDisabled'),
                paramName: 'showDisabled',
                type: 'checkbox',
            },
            {
                label: t('Filter', 'ShowInBooth'),
                paramName: 'inBooth',
                type: 'checkbox',
            },
            {
                label: t('Filter', 'ShowIsVirtual'),
                paramName: 'isVirtual',
                type: 'checkbox',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, externalPartners]);

    function onFilterChange(values: IConsultationProductFilterValues): void {
        if (values.onlyNews && !filterValues.onlyNews) {
            values.onlyLocalStore = !values.onlyNews;
        }
        if (values.onlyLocalStore && !filterValues.onlyLocalStore) {
            values.onlyNews = !values.onlyLocalStore;
        }
        setFilterValues(values);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            ...values,
        });
    }

    const loadProducts = useCallback(async () => {
        try {
            setLoading(true);
            const result = await ListConsultationProducts({
                ...filterValues,
                noExternalPartner: filterValues.externalPartnerId?.toString() === '-1',
            });
            setProducts(result);
        } finally {
            setLoading(false);
        }
    }, [filterValues]);

    const loadProductsDebounced = useDebouncedCallback(async () => {
        loadProducts();
    }, 200);

    useEffect(() => {
        loadProductsDebounced();
    }, [filterValues, loadProductsDebounced, selectedStoreId]);

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await ListCategories({ onlyDeleted: false });
            setCategories(result);
        })();

        (async (): Promise<void> => {
            const result = await ListExternalPartners({ onlyActive: false });
            result.splice(0, 0, {
                id: -1,
                name: t('ConsultationProduct', 'NoBrand'),
                isDeleted: false,
                isActive: true,
                hasBrandClub: false,
            });
            setExternalPartners(result);
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const columns: GridColDef[] = getColumnDefinitions(t, role, products, loadProducts);

    if (selectedStoreId) {
        columns.unshift(...getSingleStoreColumns(t, loadProducts));
    } else if (!selectedStoreId && IsInRole(role, 'AdminRole')) {
        columns.splice(columns.length - 1, 0, ...getAdminColumns(t, filterValues.onlyLocalStore, loadProducts));
    }

    const classes = useDataGridStyles();

    let createButtonText = t('ConsultationProduct', 'CreateNewGlobal');
    if (isVirtualStore) createButtonText = t('ConsultationProduct', 'CreateNewVirtual');
    else if (selectedStoreId) createButtonText = t('ConsultationProduct', 'CreateNewLocal');

    return (
        <Grid container direction="column" item xs gap={2}>
            <Grid container item xs style={{ flexGrow: 0 }} wrap="nowrap">
                <Grid item xs="auto">
                    <Typography variant="h4">{t('ConsultationProduct', 'Title')}</Typography>
                </Grid>
                <Grid item xs>
                    <Filter filters={filters} filterValues={filterValues} onChange={onFilterChange} justify="center" />
                </Grid>
                <Grid
                    container
                    item
                    xs="auto"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    style={{ width: 'auto' }}
                >
                    <Button variant="contained" color="primary" component={Link} to={getConsultationProductUrl(0)}>
                        <Add /> {createButtonText}
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs direction="column">
                <DataGrid
                    className={classes.root}
                    columns={columns}
                    rows={products}
                    getRowId={(row): number => row.consultationProductDetailed.consultationProductMasterDetailed.id}
                    loading={loading}
                />
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(List as ComponentType);
