import React from 'react';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { FormLabel, RadioGroup, Typography } from '@mui/material';

const StyledElement = styled.div`
    border-radius: 8px;
    border: 2px solid #d1d5db;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
    padding: 10px 40px 10px 25px;
    margin-left: 0;
    margin-right: 10px;

    .enabled & {
        cursor: pointer;

        &:hover {
            border-color: #7da2f0;
        }
    }

    input:checked + & {
        border-color: #2563eb;
    }

    .disabled & {
        opacity: 0.7;
    }
`;

export interface IStyledRadioButtonOption {
    header: string;
    subtitle?: string;
    value: string;
    disabled?: boolean;
}

interface IStyledRadioButtonsProps {
    headline: string;
    value: string;
    options: IStyledRadioButtonOption[];
    onChange: (val: string) => void;
}

export function StyledRadioButtons(props: IStyledRadioButtonsProps): JSX.Element {
    const { value, options, onChange, headline } = props;

    function change(val: IStyledRadioButtonOption): void {
        if (val.disabled) return;
        if (onChange) onChange(val.value);
    }

    return (
        <FormControl component="fieldset">
            <FormLabel component="legend" style={{ marginBottom: '10px' }}>
                {headline}
            </FormLabel>
            <RadioGroup row>
                {options.map((x) => (
                    <label key={x.value} className={x.disabled ? 'disabled' : 'enabled'}>
                        <input
                            style={{ display: 'none' }}
                            checked={x.value === value}
                            type="radio"
                            value={x.value}
                            disabled={x.disabled}
                            onChange={(): void => change(x)}
                        />
                        <StyledElement>
                            <Typography>{x.header}</Typography>
                            {!!x.subtitle && <Typography variant="body2">{x.subtitle}</Typography>}
                        </StyledElement>
                    </label>
                ))}
            </RadioGroup>
        </FormControl>
    );
}

export default StyledRadioButtons;
