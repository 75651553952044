import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from 'translations';
import { ConsultationCategory, ListCategories } from 'autogen/swagger/ConsultationProduct';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

interface ISelectCategoryProps {
    onChange: (externalPartnerId?: number) => void;
    label: string;
    value?: number;
    disabled?: boolean;
    error: boolean;
    helperText: string;
    currentCategory?: ConsultationCategory;
    placeholder?: string;
}

export function SelectCategory(props: ISelectCategoryProps): JSX.Element {
    const { label, onChange, value, disabled, error, helperText, currentCategory, placeholder } = props;
    const { t } = useTypedTranslation();
    const [categories, setCategories] = useState<ConsultationCategory[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await ListCategories({ onlyDeleted: false });
            setCategories(result);
        })();
    }, []);

    const chooseableCategories = [...categories];
    if (currentCategory && !categories.find((x) => x.id === value)) {
        chooseableCategories.push({
            ...currentCategory,
            name: `(${t('Shared', 'Deleted')}) ${currentCategory.name}`,
        });
    }

    return (
        <Autocomplete
            options={chooseableCategories}
            disabled={disabled}
            value={chooseableCategories.find((x) => value === x.id) || null}
            onChange={(e, val): void => onChange(val?.id)}
            getOptionLabel={(option): string => option.name}
            fullWidth
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={label}
                    placeholder={placeholder}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
}

export default SelectCategory;
