import React, { ComponentType, useEffect } from 'react';
import { ApplicationState } from 'store';
import { connect } from 'react-redux';
import { actionCreators, UserState } from 'store/User';
import { IsInRole } from 'helpers/userHelper';
import { useHistory } from 'react-router';
import { DashboardUrl, CalendarUrl } from 'shared/urls';

type IHomePageProps = typeof actionCreators & UserState;

function Home(props: IHomePageProps): JSX.Element {
    const { role } = props;

    const history = useHistory();

    useEffect(() => {
        if (IsInRole(role, 'AdminRole', 'StoreManagerRole')) {
            history.push(DashboardUrl);
        } else if (IsInRole(role, 'ConsultantRole')) {
            history.push(CalendarUrl);
        }
    }, [history, role]);

    return <>{null}</>;
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(Home as ComponentType);
