import React from 'react';
import { ConsultantCalendarItem } from 'autogen/swagger/Consultant';
import { Grid, Typography } from '@mui/material';
import { Error, Event } from '@mui/icons-material';
import moment from 'moment';
import { formatConsultationProductName } from 'helpers/string';
import { useTypedTranslation } from 'translations';

interface ICalendarItemEventRenderProps {
    item: ConsultantCalendarItem;
    hideStoreName: boolean;
}

export function CalendarItemEventRender(props: ICalendarItemEventRenderProps): JSX.Element {
    const { item, hideStoreName } = props;

    const { t, tf } = useTypedTranslation();

    if (!item.eventTime) return <>{null}</>;

    const minutes = moment.duration(item.eventTime.duration).asMinutes().toString();
    return (
        <Grid container>
            <Grid item xs={12}>
                <Event style={{ position: 'absolute', right: '5px', top: '5px' }} />
                <Typography variant="h6" style={{ paddingRight: '25px', lineHeight: 1.4 }}>
                    {formatConsultationProductName(
                        item.eventTime.consultationProductName,
                        item.eventTime.externalPartnerName,
                    )}
                </Typography>
            </Grid>
            {!hideStoreName && (
                <Grid item>
                    <Typography variant="body2">{item.eventTime.storeName}</Typography>
                </Grid>
            )}
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Typography variant="body2">
                            {moment(item.eventTime.activeTime.activeFromDate).format('HH:mm')}-
                            {moment(item.eventTime.activeTime.activeToDate).format('HH:mm')}
                        </Typography>
                    </Grid>
                    <Grid item container xs={6} justifyContent="flex-end">
                        <Typography title={t('Calendar', 'Duration')} variant="body2">
                            {minutes}
                        </Typography>
                        <Typography variant="body2">&nbsp;{t('Calendar', 'Minutes')}</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container alignItems="center">
                    <Grid item xs={9}>
                        <Typography variant="body2">
                            {tf(
                                {
                                    bookingCount: item.eventTime.bookingCount,
                                    maxParticipants: item.eventTime.maxParticipants,
                                },
                                'Calendar',
                                'Bookings',
                            )}
                        </Typography>
                    </Grid>
                    <Grid item xs={3} title={item.eventTime.invalidAddress ? t('EventTime', 'InvalidAddress') : ''}>
                        <Grid container justifyContent="flex-end">
                            {item.eventTime.invalidAddress && (
                                <Error style={{ marginLeft: 'auto' }} color="error" fontSize="small" />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CalendarItemEventRender;
