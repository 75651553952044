/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import { useTypedTranslation } from 'translations';
import { Grid, Typography, Button, CircularProgress, Stack } from '@mui/material';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import {
    EventBookingWithUserDetailedModel,
    GetDetailedEventBooking,
    CancelEventBooking,
    SetCustomerAbsentForEventBooking,
    GetTicketListPdf,
    EventBookingDetailed,
} from 'autogen/swagger/Booking';
import { AuthRole } from 'autogen/swagger/Users';
import moment from 'moment';
import LabledText from 'components/display/LabledText';
import SpinnerButton from 'components/button/SpinnerButton';
import { IsInRole } from 'helpers/userHelper';
import { formatConsultationProductName } from 'helpers/string';
import { TicketCardGrid } from '../Items/TicketCardGrid';

interface IEditEventBookingProps {
    id: number;
    role: AuthRole;
    onComplete?: (update: boolean) => void;
}

export function EditEventBooking(props: IEditEventBookingProps): JSX.Element {
    const { id, onComplete, role } = props;
    const { t } = useTypedTranslation();
    const [booking, setBooking] = useState<EventBookingWithUserDetailedModel>();
    const [savingAbsentOrCancel, setSavingAbsentOrCancel] = useState(false);

    const isPastBooking = booking
        ? moment().isSameOrAfter(moment(booking?.bookingDetailed?.activeTime.activeFromDate), 'minute')
        : false;

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await GetDetailedEventBooking({ id });
            setBooking(result);
        })();
    }, [id, isPastBooking, role]);

    async function onCancelBooking(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('EventBooking', 'ConfirmCancel'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await CancelEventBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    async function onCustomerAbsent(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('EventBooking', 'ConfirmCustomerAbsent'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setSavingAbsentOrCancel(true);
                    const result = await SetCustomerAbsentForEventBooking({ bookingId: id });
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setSavingAbsentOrCancel(false);
                }
            },
        });
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    async function onDownLoadAllClick(eventBookingDetailed: EventBookingDetailed) {
        GetTicketListPdf({ id: eventBookingDetailed.id });
    }

    const customerAbsent = booking?.bookingDetailed.status === 'UserWasAbsent';

    if (!booking)
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );

    return (
        <Grid item xs>
            <Grid container direction="column" justifyContent="flex-start" gap={2} wrap="nowrap">
                <Grid item>
                    <Typography variant="h4">{t('EventBooking', 'EventBooking')}</Typography>
                </Grid>
                <Grid item xs>
                    <Typography variant="h5">
                        {formatConsultationProductName(
                            booking.bookingDetailed.consultationProductDetailed.name,
                            booking.bookingDetailed.consultationProductDetailed.consultationProductMasterDetailed
                                .externalPartner?.name,
                        )}
                    </Typography>
                </Grid>
                <Grid item>
                    <Grid container gap={2}>
                        <Grid item xs={12}>
                            {booking.firstName ? (
                                <LabledText
                                    label={t('Shared', 'Customer')}
                                    text={`${booking.firstName} ${booking.lastName}`}
                                />
                            ) : (
                                <LabledText
                                    label={t('Shared', 'Customer')}
                                    color="error"
                                    text={t('EventBooking', 'CouldNotGetUser')}
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2}>
                        <Grid item lg={4} xs={6}>
                            <LabledText
                                label={t('Shared', 'ClubMatasMemberId')}
                                text={`${booking?.clubMatasMemberId}`}
                            />
                        </Grid>
                        <Grid item lg={4} xs={6}>
                            {booking.mobileNumber && (
                                <LabledText label={t('Shared', 'MobileNumber')} text={`${booking.mobileNumber}`} />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                {booking.bookingDetailed.spacesBooked && (
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <Typography variant="h6">{t('EventBooking', 'Tickets')}</Typography>
                                <TicketCardGrid tickets={booking.bookingDetailed.tickets} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {customerAbsent && (
                    <Grid item>
                        <Grid container gap={2}>
                            <Grid item xs={12}>
                                <LabledText
                                    label={t('Shared', 'Status')}
                                    color="error"
                                    text={t('EventBooking', 'CustomerAbsent')}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid item>
                    <Grid container justifyContent="center" gap={2}>
                        <Grid item>
                            <Button variant="contained" onClick={onCancelClick}>
                                {t('Shared', 'Back')}
                            </Button>
                        </Grid>
                        {booking.bookingDetailed.tickets.length > 1 && (
                            <Grid item>
                                <Button variant="contained" onClick={() => onDownLoadAllClick(booking.bookingDetailed)}>
                                    <Stack direction="row" alignItems="center" gap={1}>
                                        <Typography variant="inherit">{t('EventBooking', 'DownloadAll')}</Typography>
                                        <FileDownloadOutlinedIcon fontSize="small" />
                                    </Stack>
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            {isPastBooking ? (
                                !customerAbsent && (
                                    <SpinnerButton
                                        variant="contained"
                                        color="secondary"
                                        onClick={onCustomerAbsent}
                                        loading={savingAbsentOrCancel}
                                    >
                                        {t('EventBooking', 'CustomerAbsent')}
                                    </SpinnerButton>
                                )
                            ) : IsInRole(role, 'AdminRole', 'StoreManagerRole', 'ConsultantRole') ? (
                                <SpinnerButton
                                    variant="contained"
                                    color="secondary"
                                    onClick={onCancelBooking}
                                    loading={savingAbsentOrCancel}
                                >
                                    {t('EventBooking', 'CancelConsultation')}
                                </SpinnerButton>
                            ) : (
                                <>{null}</>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default EditEventBooking;
