import React, { useCallback, useEffect, useState, ComponentType } from 'react';
import { Button, Grid, Typography, Chip, Tooltip } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { Add } from '@mui/icons-material';
import { Filter, IFilter } from 'components/Input/Filter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { nameof } from 'ts-simple-nameof';
import { Link } from 'react-router-dom';
import { getConsultantsUrl } from 'shared/urls';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators, UserState } from 'store/User';
import { ExternalPartner, ListExternalPartners } from 'autogen/swagger/ExternalPartners';
import { ConsultantModel, ListConsultants, Store } from 'autogen/swagger/Consultant';
import { useDebouncedCallback } from 'use-debounce';
import { usePrevious } from 'helpers/hooks';
import { usePaging } from 'helpers/paging';
import { useDataGridStyles } from 'shared/theme';

type IConsultantsFilterValues = {
    search: string;
    externalPartnerId?: number;
};

export function List(props: UserState): JSX.Element {
    const { selectedStoreId } = props;
    const { t } = useTypedTranslation();
    const [consultants, setConsultants] = useState<ConsultantModel[]>([]);
    const [filterValues, setFilterValues] = useState<IConsultantsFilterValues>({
        search: '',
    });
    const [externalPartners, setExternalPartners] = useState<ExternalPartner[]>([]);
    const [filters, setFilters] = useState<IFilter<IConsultantsFilterValues>[]>([]);
    const prevFilterValues = usePrevious(filterValues);
    const [pagination, mapPagination, dataGridPageModel] = usePaging({
        pageNumber: 1,
        pageSize: 50,
        totalHits: 0,
        totalPages: 0,
    });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setFilters([
            {
                label: t('Consultant', 'Filter', 'Search'),
                paramName: 'search',
                type: 'text',
            },
            {
                label: t('Consultant', 'Filter', 'ExternalPartner'),
                paramName: 'externalPartnerId',
                type: 'select',
                options: externalPartners,
                getText: (value): string => value?.name?.toString() ?? '',
                getValue: (value): string => value?.id?.toString() ?? '',
            },
        ]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [externalPartners]);

    function onFilterChange(values: IConsultantsFilterValues): void {
        setFilterValues(values);
    }

    const loadConsultants = useCallback(async () => {
        try {
            setLoading(true);
            setConsultants([]);

            const result = await ListConsultants({
                ...filterValues,
                PageNumber: pagination.pageNumber,
                PageSize: pagination.pageSize,
            });
            setConsultants(result.hits);
            mapPagination(result);
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, pagination.pageSize, pagination.pageNumber]);

    const loadConsultantsDebounced = useDebouncedCallback(async () => {
        loadConsultants();
    }, 200);

    useEffect(() => {
        if (prevFilterValues?.search !== filterValues.search) {
            loadConsultantsDebounced();
        } else {
            loadConsultants();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterValues, selectedStoreId, loadConsultants, loadConsultantsDebounced]);

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await ListExternalPartners({ onlyActive: false });
            setExternalPartners(result);
        })();
    }, []);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: t('Shared', 'Name'),
            flex: 1,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => `${params.row?.firstName} ${params.row?.lastName}`,
        },
        {
            field: nameof<ConsultantModel>((x) => x.email),
            headerName: t('Shared', 'Email'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'role',
            headerName: t('Consultant', 'Role'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const isExternal = params.row?.isExternal;
                const isStoreManager = params.row?.isStoreManager;
                const externalPartnerNames =
                    params.row?.externalPartners.map((x: ExternalPartner) => x.name).join(', ') ||
                    t('Shared', 'External');

                // eslint-disable-next-line no-nested-ternary
                const text = isStoreManager
                    ? t('Consultant', 'StoreManager', 'IsStoreManager')
                    : isExternal
                    ? externalPartnerNames
                    : t('Shared', 'Internal');
                return (
                    <Chip
                        label={text}
                        size="small"
                        color={isExternal ? 'default' : 'primary'}
                        title={externalPartnerNames}
                    />
                );
            },
        },
        {
            field: nameof<ConsultantModel>((x) => x.mobileNumber),
            headerName: t('Shared', 'MobileNumber'),
            flex: 0.75,
            sortable: false,
            filterable: false,
        },
        {
            field: 'consultationProducts',
            headerName: t('Consultant', 'ConsultationProducts'),
            flex: 0.5,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => params.row?.consultationProductIds.length || '0',
        },
        {
            field: 'stores',
            headerName: t('Consultant', 'Stores'),
            flex: 0.5,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => params.row?.stores.length,
            renderCell: (params): JSX.Element => (
                <Tooltip title={params.row?.stores.map((x: Store) => x.name).join(', ')}>
                    <span className="table-cell-truncate">{params.row?.stores.length}</span>
                </Tooltip>
            ),
        },
        {
            field: 'id',
            headerName: t('Shared', 'Action'),
            width: 120,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const id = params.value as number;

                return (
                    <Button color="primary" component={Link} to={getConsultantsUrl(id)}>
                        {t('Shared', 'Edit')}
                    </Button>
                );
            },
        },
    ];

    const classes = useDataGridStyles();

    return (
        <Grid container direction="column" item xs gap={2}>
            <Grid container item xs style={{ flexGrow: 0 }} wrap="nowrap">
                <Grid item xs="auto">
                    <Typography variant="h4">{t('Consultant', 'Title')}</Typography>
                </Grid>
                <Grid item xs>
                    <Filter filters={filters} filterValues={filterValues} onChange={onFilterChange} justify="center" />
                </Grid>
                <Grid
                    container
                    item
                    xs="auto"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    style={{ width: 'auto' }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={!selectedStoreId}
                        component={Link}
                        to={getConsultantsUrl(0)}
                    >
                        <Add /> {t('Shared', 'CreateNew')}
                    </Button>
                </Grid>
            </Grid>
            <Grid container item xs direction="column">
                <DataGrid
                    columns={columns}
                    rows={consultants}
                    getRowId={(row): number => row.id}
                    loading={loading}
                    className={classes.root}
                    {...dataGridPageModel}
                />
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(List as ComponentType);
