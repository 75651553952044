import React from 'react';
import { GetTicketPdf, Ticket } from 'autogen/swagger/Booking';
import { useTypedTranslation } from 'translations';
import { Card, CardActionArea, CardContent, Grid, Stack, Typography } from '@mui/material';
import ConfirmationNumberOutlinedIcon from '@mui/icons-material/ConfirmationNumberOutlined';

interface ITicketCardGrid {
    tickets: Ticket[];
}

export function TicketCardGrid(props: ITicketCardGrid): JSX.Element {
    const { tickets } = props;
    const { t } = useTypedTranslation();

    async function onCardClick(ticket: Ticket) {
        GetTicketPdf({ secret: ticket.secret });
    }
    return (
        <Grid container spacing={2}>
            {tickets.map((ticket) => (
                <Grid key={ticket.ticketNumber} item xs={6}>
                    <Card color="primary" style={{ background: '#F2F2F2' }}>
                        <CardActionArea onClick={() => onCardClick(ticket)}>
                            <CardContent>
                                <Stack direction="row" alignItems="center" gap={1}>
                                    <ConfirmationNumberOutlinedIcon fontSize="small" />
                                    <Typography variant="body1">{t('EventBooking', 'TicketNumberShort')}</Typography>
                                    <Typography variant="body1">{ticket.ticketNumber}</Typography>
                                </Stack>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
