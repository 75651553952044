/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Dashboard';

export type BookingType = components['schemas']['BookingType'];
export type DashboardRequestViewModel = components['schemas']['DashboardRequestViewModel'];
export type MetricType = components['schemas']['MetricType'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type DashboardMetricRow = components['schemas']['DashboardMetricRow'];
export type DashboardMetric = components['schemas']['DashboardMetric'];
export type MetricGroupType = components['schemas']['MetricGroupType'];
export type DashboardMetricGroup = components['schemas']['DashboardMetricGroup'];
export type ErrorType = components['schemas']['ErrorType'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type StoreValue = components['schemas']['StoreValue'];
export type DashboardMetricByStore = components['schemas']['DashboardMetricByStore'];
export type DashboardTableMetric = components['schemas']['DashboardTableMetric'];

type GetDashboardRequestBody = paths['/Dashboard/GetDashboard']['post']['requestBody']['content']['application/json'];

type GetDashboardSuccess = paths['/Dashboard/GetDashboard']['post']['responses']['200']['content']['application/json'];

export const GetDashboardUrl = '/Dashboard/GetDashboard';

export function GetDashboard(requestBody: GetDashboardRequestBody): Promise<GetDashboardSuccess> {
    return postJSON<GetDashboardSuccess>(`/Dashboard/GetDashboard`, requestBody, true);
}

type GetMetricByStoreParams = paths['/Dashboard/GetMetricByStore']['post']['parameters']['query'];

type GetMetricByStoreRequestBody =
    paths['/Dashboard/GetMetricByStore']['post']['requestBody']['content']['application/json'];

type GetMetricByStoreSuccess =
    paths['/Dashboard/GetMetricByStore']['post']['responses']['200']['content']['application/json'];

export const GetMetricByStoreUrl = '/Dashboard/GetMetricByStore';

export const GetMetricByStoreUrlFormat = (params: GetMetricByStoreParams): string =>
    GetMetricByStoreUrl + formatParams(params);

export function GetMetricByStore(
    params: GetMetricByStoreParams,
    requestBody: GetMetricByStoreRequestBody,
): Promise<GetMetricByStoreSuccess> {
    return postJSON<GetMetricByStoreSuccess>(`/Dashboard/GetMetricByStore${formatParams(params)}`, requestBody, true);
}

type GetTableMetricParams = paths['/Dashboard/GetTableMetric']['post']['parameters']['query'];

type GetTableMetricRequestBody =
    paths['/Dashboard/GetTableMetric']['post']['requestBody']['content']['application/json'];

type GetTableMetricSuccess =
    paths['/Dashboard/GetTableMetric']['post']['responses']['200']['content']['application/json'];

export const GetTableMetricUrl = '/Dashboard/GetTableMetric';

export const GetTableMetricUrlFormat = (params: GetTableMetricParams): string =>
    GetTableMetricUrl + formatParams(params);

export function GetTableMetric(
    params: GetTableMetricParams,
    requestBody: GetTableMetricRequestBody,
): Promise<GetTableMetricSuccess> {
    return postJSON<GetTableMetricSuccess>(`/Dashboard/GetTableMetric${formatParams(params)}`, requestBody, true);
}

type GetDashboardExportParams = paths['/Dashboard/GetDashboardExport']['post']['parameters']['query'];

type GetDashboardExportRequestBody =
    paths['/Dashboard/GetDashboardExport']['post']['requestBody']['content']['application/json'];

type GetDashboardExportSuccess =
    paths['/Dashboard/GetDashboardExport']['post']['responses']['200']['content']['application/json'];

export const GetDashboardExportUrl = '/Dashboard/GetDashboardExport';

export const GetDashboardExportUrlFormat = (params: GetDashboardExportParams): string =>
    GetDashboardExportUrl + formatParams(params);

export function GetDashboardExport(
    params: GetDashboardExportParams,
    requestBody: GetDashboardExportRequestBody,
): Promise<GetDashboardExportSuccess> {
    return postDownloadFile<GetDashboardExportSuccess>(
        `/Dashboard/GetDashboardExport${formatParams(params)}`,
        requestBody,
        true,
    );
}

type GetCancellationAndNoShowReportRequestBody =
    paths['/Dashboard/GetCancellationAndNoShowReport']['post']['requestBody']['content']['application/json'];

type GetCancellationAndNoShowReportSuccess =
    paths['/Dashboard/GetCancellationAndNoShowReport']['post']['responses']['200']['content']['application/json'];

export const GetCancellationAndNoShowReportUrl = '/Dashboard/GetCancellationAndNoShowReport';

export function GetCancellationAndNoShowReport(
    requestBody: GetCancellationAndNoShowReportRequestBody,
): Promise<GetCancellationAndNoShowReportSuccess> {
    return postDownloadFile<GetCancellationAndNoShowReportSuccess>(
        `/Dashboard/GetCancellationAndNoShowReport`,
        requestBody,
        true,
    );
}
