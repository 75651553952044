import React from 'react';
import styled from 'styled-components';
import { useTypedTranslation } from 'translations';
import { stringToKey } from 'helpers/string';

const Wrapper = styled.div`
    position: relative;
    padding: 20px;
    border-radius: 8px;
    background-color: #d8d1cb3d;
`;

const NoticeHeadline = styled.div`
    font-size: 18px;
    margin-bottom: 22px;
    font-weight: bold;
`;

const NoticeContent = styled.ol`
    padding-left: 19px;

    li {
        margin-bottom: 10px;

        &::marker {
            font-weight: bold;
            font-size: 16px;
        }
    }
`;

interface IBonusTextPreviewProps {
    bonusText: string;
}

export function BonusTextPreview(props: IBonusTextPreviewProps): JSX.Element {
    const { bonusText } = props;
    const { t } = useTypedTranslation();

    const content = bonusText.split('\n');

    if (!bonusText) return <>{null}</>;

    return (
        <Wrapper>
            <NoticeHeadline>{t('Shared', 'Remember')}</NoticeHeadline>
            <NoticeContent>
                {content.map((line, i) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <li key={stringToKey(line) + i}>{line}</li>
                ))}
            </NoticeContent>
        </Wrapper>
    );
}

export default BonusTextPreview;
