import React, { CSSProperties } from 'react';
import { CalendarItemBooking, ConsultantCalendarItemType, OverviewItem } from 'autogen/swagger/Consultant';
import { Grid, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import moment from 'moment';

const shiftTypes: ConsultantCalendarItemType[] = ['ActiveShift', 'InActiveShift', 'Bookings', 'Break'];
const eventTypes: ConsultantCalendarItemType[] = ['ActiveEventTime', 'InActiveEventTime', 'Break'];

function getStyle(item: OverviewItem): CSSProperties {
    const obj: CSSProperties = {
        borderStyle: 'solid',
        borderWidth: '1px',
        padding: '5px',
        cursor: 'pointer',
        position: 'relative',
        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
    };

    if (
        item.overviewShifts.every((x) => shiftTypes.includes(x.type)) &&
        !item.overviewShifts.every((x) => x.type === 'Bookings')
    ) {
        const isActive = item.overviewShifts.some((x) => x.shift?.isActive);

        if (isActive) {
            obj.backgroundColor = '#D1FAE5';
            obj.borderColor = '#10B981';
        } else {
            obj.backgroundColor = '#ff9800';
            obj.borderColor = '#ED6C02';
        }
    } else if (item.overviewShifts.every((x) => eventTypes.includes(x.type))) {
        obj.backgroundColor = '#E0E7FF';
        obj.borderColor = '#a6b6ff';
    } else if (item.overviewShifts.every((x) => x.type === 'Bookings')) {
        obj.backgroundColor = '#E0F2FE';
        obj.borderColor = '#6dc5ff';
    } else {
        obj.backgroundColor = '#FFFFFF';
        obj.borderColor = '#9ca3af';
    }

    return obj;
}

function getBookedStyle(percentage: number, position: 'top' | 'bottom' = 'top'): CSSProperties {
    const obj: CSSProperties = {
        position: 'absolute',
        top: '-5px',
        right: '-5px',
        borderRadius: '50%',
        border: '1px solid transparent',
        width: '12px',
        height: '12px',
    };

    if (position === 'bottom') {
        delete obj.top;
        obj.bottom = '-5px';
    }

    if (percentage < 10) {
        obj.backgroundColor = '#fC3636';
        obj.borderColor = '#991212';
    } else if (percentage < 50) {
        obj.backgroundColor = '#ff9800';
        obj.borderColor = '#aa5007';
    } else {
        obj.backgroundColor = '#D1FAE5';
        obj.borderColor = '#0fa171';
    }
    return obj;
}

interface IOverviewItemProps {
    item: OverviewItem;
    onClick: () => void;
}

export function OverviewItemComp(props: IOverviewItemProps): JSX.Element {
    const { item, onClick } = props;
    const { t, tf } = useTypedTranslation();

    const shifts = item.overviewShifts.map((x) => x.shift).filter((x) => Boolean(x));

    const bookings = item.overviewShifts.reduce((acc, x) => [...acc, ...x.bookings], [] as CalendarItemBooking[]);

    const events = item.overviewShifts.map((x) => x.eventTime).filter((x) => Boolean(x));

    const eventBookings = events.reduce((acc, x) => acc + (x?.bookingCount || 0), 0);

    return (
        <Grid container direction="column" style={getStyle(item)} onClick={onClick}>
            {!!shifts.length && (
                <div
                    title={t('Overview', 'ConsultationBookingPercent')}
                    style={getBookedStyle(100 - item.percentageBooked)}
                />
            )}
            {!!events.length && (
                <div
                    title={t('Overview', 'EventBookingPercent')}
                    style={getBookedStyle(100 - item.percentageEventBooked, 'bottom')}
                />
            )}
            <Grid item>
                <Typography variant="h6">
                    {moment(item.activeTime.activeFromDate).format('HH:mm')} -{' '}
                    {moment(item.activeTime.activeToDate).format('HH:mm')}
                </Typography>
            </Grid>
            {(!!shifts.length || !!bookings.length) && (
                <Grid item>
                    <Typography variant="body2">{tf({ bookings: bookings.length }, 'Overview', 'Bookings')}</Typography>
                </Grid>
            )}
            {!!events.length && (
                <Grid item>
                    <Typography variant="body2">
                        {tf({ count: events.length, eventBookings }, 'Overview', 'Events')}
                    </Typography>
                </Grid>
            )}
        </Grid>
    );
}

export default OverviewItemComp;
