import React from "react";

import { Chip } from "@mui/material";

interface ICalendarItemChip {
    label: string;
}

export function CalendarItemChip(props: ICalendarItemChip): JSX.Element {
    const { label } = props;

    return (
        <Chip label={label} variant="outlined" size="small" sx={{
            borderColor: "#999",
            borderRadius: 1,
            height: "auto",
            py: .25,
            px: .75,
            textTransform: "uppercase",
            "& .MuiChip-label": {
                fontSize: "10px",
                fontWeight: 400,
                p: 0
            }
        }} />
    );
}

export default CalendarItemChip;
