import React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import CreateOrEdit from './components/CreateOrEdit';
import List from './components/List';

export function Consultants(props: RouteComponentProps<{ consultantId?: string }>): JSX.Element {
    const {
        match: {
            params: { consultantId },
        },
    } = props;

    if (consultantId || consultantId === '0') {
        const id = parseInt(consultantId);
        return <CreateOrEdit id={id} />;
    }
    return <List />;
}

export default Consultants;
