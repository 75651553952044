import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Button, Grid, TextField, Typography } from '@mui/material';
import { nameof } from 'ts-simple-nameof';
import Autocomplete from '@mui/material/Autocomplete';
import {
    Consultant,
    ConsultationProductDetailed,
    ListAvailableConsultationProductDetailsForShift,
} from 'autogen/swagger/Consultant';
import { StoreSelectViewModel } from 'autogen/swagger/Users';
import { formatConsultationProductName } from 'helpers/string';
import SpinnerButton from 'components/button/SpinnerButton';
import { useTypedTranslation } from 'translations';
import { useValidationState } from 'helpers/validation';
import { getMinutesRoundedUp } from 'helpers/dateTime';
import { DateTimePicker } from '@mui/lab';
import { BookingCreateRequest, CreateBooking, LookupMember, ClubMatasMemberModel } from 'autogen/swagger/Booking';
import StoreAndConsultantText from '../StoreAndConsultantText';

function getEmptyBookingValues(consultantId: number): BookingCreateRequest {
    return {
        startDateTime: '',
        consultantId,
        consultationProductId: 0,
        clubMatasMemberId: '',
        firstName: '',
        lastName: '',
    };
}

interface ICreateBookingProps {
    consultant?: Consultant;
    onComplete?: (update: boolean) => void;
    currentSelectedWeekDate: Moment;
    selectedStoreId?: number;
    selectableStores?: StoreSelectViewModel[];
}

export default function CreateBookingComponent(props: ICreateBookingProps): JSX.Element {
    const {
        consultant: initialConsultant,
        onComplete,
        currentSelectedWeekDate,
        selectedStoreId,
        selectableStores,
    } = props;
    const { t } = useTypedTranslation();
    const [consultant] = useState(initialConsultant);
    const consultantId = consultant?.id;

    const [bookingValues, setBookingValues] = useState<BookingCreateRequest>(getEmptyBookingValues(consultantId || 0));

    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const [saving, setSaving] = useState(false);
    const [consultationProducts, setConsultationProducts] = useState<ConsultationProductDetailed[]>([]);
    const [selectedDate, setSelectedDate] = useState(
        (): Moment =>
            getMinutesRoundedUp(
                currentSelectedWeekDate.isBefore(moment()) ? moment().add(1, 'days') : moment(currentSelectedWeekDate),
                5,
            ),
    );
    const [searchMobile, setSearchMobile] = useState('');
    const [member, setMember] = useState<ClubMatasMemberModel>();
    const [searchingMember, setSearchingMember] = useState(false);

    useEffect(() => {
        if (consultantId) {
            (async (): Promise<void> => {
                const result = await ListAvailableConsultationProductDetailsForShift({
                    consultantId,
                });
                setConsultationProducts(result);
            })();
        }
    }, [consultantId]);

    useEffect(() => {
        setBookingValues((prev) => ({ ...prev, startDateTime: selectedDate.toISOString(true) }));
    }, [selectedDate]);

    useEffect(() => {
        if (!member) {
            setBookingValues((prev) => ({
                ...prev,
                clubMatasMemberId: '',
                firstName: '',
                lastName: '',
            }));
        } else {
            setBookingValues((prev) => ({
                ...prev,
                clubMatasMemberId: member.clubMatasMemberId,
                ...(member.firstName ? { firstName: member.firstName } : {}),
                ...(member.lastName ? { lastName: member.lastName } : {}),
            }));
        }
    }, [member]);

    async function searchMember() {
        if (!searchMobile) {
            setMember(undefined);
            return;
        }
        setSearchingMember(true);
        try {
            const number = searchMobile.replace(/ /g, '');
            const result = await LookupMember({ mobileNumber: number });
            if (result.clubMatasMemberId) {
                setMember(result);
            } else {
                setMember(undefined);
            }
        } catch {
            setMember(undefined);
        } finally {
            setSearchingMember(false);
        }
    }

    useEffect(() => {
        if (searchMobile && /^([0-9]{2}[ ]?){4}$/.test(searchMobile)) {
            (async (): Promise<void> => {
                await searchMember();
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchMobile]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            setValidationErrors(undefined);

            const result = await CreateBooking(bookingValues);

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            let isShowableErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isShowableErrors)
                isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    function handleDateChange(date: Moment | null): void {
        if (!date) return;
        setSelectedDate(date);
    }

    return (
        <Grid item xs>
            <Grid container direction="column" justifyContent="flex-start" gap={2}>
                <Grid container item>
                    <Grid item xs>
                        <Typography variant="h4">{t('Booking', 'CreateTitle')}</Typography>
                    </Grid>
                    <Grid item>
                        <StoreAndConsultantText
                            consultant={consultant}
                            storeId={selectedStoreId}
                            selectableStores={selectableStores}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={3} alignItems="center" style={{ justifyContent: 'center' }}>
                        <Grid item sm={12}>
                            <DateTimePicker
                                inputFormat="DD-MM-yyyy HH:mm"
                                mask="__-__-____ __:__"
                                ampm={false}
                                label={t('Shared', 'Day')}
                                value={selectedDate}
                                onChange={handleDateChange}
                                OpenPickerButtonProps={{
                                    'aria-label': t('Shared', 'ChangeDate'),
                                }}
                                minutesStep={5}
                                renderInput={(params) => (
                                    <TextField
                                        fullWidth
                                        {...params}
                                        error={isError(nameof<BookingCreateRequest>((x) => x.startDateTime))}
                                        helperText={getErrorText(nameof<BookingCreateRequest>((x) => x.startDateTime))}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Autocomplete
                                options={consultationProducts}
                                value={
                                    consultationProducts.find((x) => bookingValues.consultationProductId === x.id) ||
                                    null
                                }
                                onChange={(e, value): void =>
                                    setBookingValues({
                                        ...bookingValues,
                                        consultationProductId: value?.id || 0,
                                    })
                                }
                                groupBy={(option): string => option.consultationCategory.name}
                                getOptionLabel={(option): string =>
                                    formatConsultationProductName(
                                        option.name,
                                        option.consultationProductMasterDetailed.externalPartner?.name,
                                    )
                                }
                                renderOption={(optionProps, option) => (
                                    <li {...optionProps} key={option.id}>
                                        {option.name}
                                    </li>
                                )}
                                fullWidth
                                renderInput={(params): JSX.Element => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t('Booking', 'Service')}
                                        placeholder={t('Booking', 'SelectService')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={isError(nameof<BookingCreateRequest>((x) => x.consultationProductId))}
                                        helperText={getErrorText(
                                            nameof<BookingCreateRequest>((x) => x.consultationProductId),
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item sm={9}>
                                    <TextField
                                        label={t('Booking', 'SearchMember')}
                                        type="text"
                                        fullWidth
                                        value={searchMobile}
                                        variant="outlined"
                                        onChange={(e): void => setSearchMobile(e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item sm={3}>
                                    <SpinnerButton
                                        onClick={searchMember}
                                        loading={searchingMember}
                                        size="large"
                                        fullWidth
                                    >
                                        {t('Shared', 'Search')}
                                    </SpinnerButton>
                                </Grid>
                                <Grid item sm={12}>
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item sm={6}>
                                            <TextField
                                                label={t('Booking', 'FirstName')}
                                                type="text"
                                                fullWidth
                                                value={bookingValues.firstName}
                                                variant="outlined"
                                                error={isError(nameof<BookingCreateRequest>((x) => x.firstName))}
                                                helperText={getErrorText(
                                                    nameof<BookingCreateRequest>((x) => x.firstName),
                                                )}
                                                onChange={(e): void =>
                                                    setBookingValues((prev) => ({
                                                        ...prev,
                                                        firstName: e.target.value,
                                                    }))
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField
                                                label={t('Booking', 'LastName')}
                                                type="text"
                                                fullWidth
                                                value={bookingValues.lastName}
                                                variant="outlined"
                                                error={isError(nameof<BookingCreateRequest>((x) => x.lastName))}
                                                helperText={getErrorText(
                                                    nameof<BookingCreateRequest>((x) => x.lastName),
                                                )}
                                                onChange={(e): void =>
                                                    setBookingValues((prev) => ({
                                                        ...prev,
                                                        lastName: e.target.value,
                                                    }))
                                                }
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item sm={12}>
                                    <TextField
                                        label={t('Booking', 'ClubmatasMemberId')}
                                        type="number"
                                        fullWidth
                                        value={bookingValues.clubMatasMemberId}
                                        variant="outlined"
                                        error={isError(nameof<BookingCreateRequest>((x) => x.clubMatasMemberId))}
                                        helperText={getErrorText(
                                            nameof<BookingCreateRequest>((x) => x.clubMatasMemberId),
                                        )}
                                        onChange={(e): void =>
                                            setBookingValues((prev) => ({ ...prev, clubMatasMemberId: e.target.value }))
                                        }
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained" onClick={onCancelClick}>
                            {t('Shared', 'Cancel')}
                        </Button>
                    </Grid>
                    <Grid item>
                        <SpinnerButton loading={saving} variant="contained" color="primary" onClick={save}>
                            {t('Shared', 'Create')}
                        </SpinnerButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}
