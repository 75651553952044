function replaceParameter(url: string, param: string, value?: string): string {
    if (!value) {
        return url.replace(`/${param}`, '');
    }
    return url.replace(param, value);
}

function replaceParameterNumber(url: string, param: string, value?: number): string {
    let val: string | undefined = value?.toString();
    if (!value && value !== 0) {
        val = undefined;
    }
    return replaceParameter(url, param, val);
}

export const DashboardUrl = '/dashboard';

export const CategoriesUrl = '/categories/:categoryId?';

export function getCategoriesUrl(categoryId?: number): string {
    return replaceParameterNumber(CategoriesUrl, ':categoryId?', categoryId);
}

export const ConsultationProductUrl = '/consultationproduct/:consultationProductId?';

export function getConsultationProductUrl(consultationProductId?: number): string {
    return replaceParameterNumber(ConsultationProductUrl, ':consultationProductId?', consultationProductId);
}

export const ConsultantsUrl = '/consultants/:consultantId?';

export function getConsultantsUrl(consultantId?: number): string {
    return replaceParameterNumber(ConsultantsUrl, ':consultantId?', consultantId);
}

export const ExternalPartnersUrl = '/externalpartners/:externalPartnerId?';

export function getExternalPartnersUrl(externalPartnerId?: number): string {
    return replaceParameterNumber(ExternalPartnersUrl, ':externalPartnerId?', externalPartnerId);
}

export const CalendarUrl = '/calendar';

export const OverviewUrl = '/overview';
