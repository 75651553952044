import React, { useEffect, useState, useCallback } from 'react';
import { useTypedTranslation } from 'translations';
import {
    Button,
    Grid,
    Typography,
    Card,
    CardContent,
    FormControl,
    FormLabel,
    RadioGroup,
    Radio,
    FormControlLabel,
    CircularProgress,
} from '@mui/material';
import { useHistory, Link } from 'react-router-dom';
import { getConsultantsUrl } from 'shared/urls';
import { useValidationState } from 'helpers/validation';
import {
    ConsultantEditModelResponse,
    ConsultantCreateRequest,
    GetConsultantDetailed,
    CreateConsultant,
    UpdateConsultant,
    EmployeeLookup,
    RemoveFromSelectedStore,
    UpdateConsultantConsultationProducts,
    UpdateConsultantExternalPartners,
    DeleteConsultant,
} from 'autogen/swagger/Consultant';
import { ApplicationState } from 'store';
import { connect } from 'react-redux';
import { actionCreators, UserState } from 'store/User';
import { showMessage } from 'components/messages/Message';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import SpinnerButton from 'components/button/SpinnerButton';
import { IsInRole } from 'helpers/userHelper';
import { splitName } from 'helpers/string';
import BasicInfo from './BasicInfo';
import SearchEmployee from './SearchEmployee';
import SearchExternal, { SearchedConsultantLookup } from './SearchExternal';
import ExistingConsultantView, { ExistingConsultant } from './ExistingConsultant';
import AuthUser from './AuthUser';
import SelectConsultationProducts from './SelectConsultationProducts';
import NotificationSettingsEdit from "./NotificationSettingsEdit";

const emptyCreateRequest: ConsultantCreateRequest = {
    firstName: '',
    lastName: '',
    externalPartnerIds: [],
    clubMatasMemberId: '',
    email: '',
    mobileNumber: '',
    description: '',
    consultationProductIds: [],
    isExternal: false,
    notificationSettings: []
};

interface ICreateOrEditProps extends UserState {
    id?: number;
}

export function CreateOrEdit(props: ICreateOrEditProps): JSX.Element {
    const { id, role, selectedStoreId } = props;
    const { t, tf } = useTypedTranslation();
    const history = useHistory();
    const [consultant, setConsultant] = useState<ConsultantEditModelResponse>();
    const [values, setValues] = useState<ConsultantCreateRequest>(emptyCreateRequest);
    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const [showExternalPartner, setShowExternalPartner] = useState(false);
    const [showInputs, setShowInputs] = useState(false);
    const [existingConsultant, setExistingConsultant] = useState<ExistingConsultant[]>();
    const [saving, setSaving] = useState(false);
    const [removingAssignedStatus, setRemovingAssignedStatus] = useState(false);
    const [deletingConsultant, setDeletingConsultant] = useState(false);
    const [loading, setLoading] = useState(false);

    const isEdit = !!id;

    const getConsultant = useCallback(async (): Promise<void> => {
        if (!id) return;

        try {
            setLoading(true);
            const result = await GetConsultantDetailed({ consultantId: id });
            if (selectedStoreId && !result.isAssignedToStore) {
                setExistingConsultant([
                    {
                        consultant: result.baseData,
                        assignedToSelectedStore: result.isAssignedToStore,
                    },
                ]);
                setShowInputs(false);
            } else {
                setConsultant(result);
                setValues({
                    firstName: result.baseData.firstName,
                    lastName: result.baseData.lastName,
                    externalPartnerIds: result.baseData.externalPartners.map((x) => x.id),
                    clubMatasMemberId: result.baseData.clubMatasMemberId,
                    email: result.baseData.email,
                    mobileNumber: result.baseData.mobileNumber,
                    description: result.baseData.description,
                    consultationProductIds: result.consultantProducts.reduce(
                        (acc, x) => (x.assignedToConsultant ? [...acc, x.id] : acc),
                        [] as number[],
                    ),
                    isExternal: result.baseData.isExternal,
                    notificationSettings: result.baseData.notificationSettings,
                });
                setExistingConsultant(undefined);
                setShowInputs(true);
            }
            setShowExternalPartner(!!result.baseData.isExternal);
        } finally {
            setLoading(false);
        }
    }, [id, selectedStoreId]);

    useEffect(() => {
        if (id) {
            getConsultant();
        }
    }, [getConsultant, id, selectedStoreId]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            let result;
            let newId;
            if (id) {
                result = await UpdateConsultant(
                    { consultantId: id },
                    {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        email: values.email,
                        mobileNumber: values.mobileNumber,
                        description: values.description,
                        notificationSettings: values.notificationSettings
                    },
                );
                if (role === 'AdminRole') {
                    await UpdateConsultantExternalPartners({
                        consultantId: id,
                        externalPartnerIds: values.externalPartnerIds,
                    });
                }
                if (role === 'AdminRole' || role === 'StoreManagerRole') {
                    await UpdateConsultantConsultationProducts({
                        consultantId: id,
                        addList: values.consultationProductIds.reduce(
                            (acc, x) =>
                                consultant?.consultantProducts.some(
                                    (e) => e.id === x && !e.assignedToConsultant && e.isEditable,
                                ) || consultant?.consultantProducts.every((e) => e.id !== x)
                                    ? [...acc, x]
                                    : acc,
                            [] as number[],
                        ),
                        removeList:
                            consultant?.consultantProducts.reduce(
                                (acc, x) =>
                                    x.isEditable &&
                                    x.assignedToConsultant &&
                                    values.consultationProductIds.every((e) => e !== x.id)
                                        ? [...acc, x.id]
                                        : acc,
                                [] as number[],
                            ) || [],
                    });
                }
            } else {
                const createResult = await CreateConsultant(values);
                newId = createResult.consultantId;
                result = createResult;
            }
            setValidationErrors(undefined);
            if (result) {
                showMessage({ content: t('Consultant', 'ConsultantUpdated'), severity: 'success' });
                if (!isEdit) {
                    history.replace(getConsultantsUrl(newId));
                } else {
                    await getConsultant();
                }
            }
        } catch (ex) {
            let isShowableErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isShowableErrors)
                isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    async function removeAssignment(): Promise<void> {
        const name = `${consultant?.baseData.firstName} ${consultant?.baseData.lastName}`;
        ConfirmChoice({
            content: tf({ name }, 'Consultant', 'ConfirmRemoveAssignment'),
            onConfirm: async () => {
                try {
                    setRemovingAssignedStatus(true);
                    let result;
                    if (id) {
                        result = await RemoveFromSelectedStore({ consultantId: id, deleteUserOnZeroStores: true });
                        if (result) {
                            showMessage({ content: t('Consultant', 'RemoveAssignmentSuccess'), severity: 'success' });
                            history.push(getConsultantsUrl());
                        }
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setRemovingAssignedStatus(false);
                }
            },
        });
    }

    async function deleteConsultant(): Promise<void> {
        const name = `${consultant?.baseData.firstName} ${consultant?.baseData.lastName}`;
        ConfirmChoice({
            content: tf({ name }, 'Consultant', 'ConfirmDeleteConsultant'),
            onConfirm: async () => {
                try {
                    setDeletingConsultant(true);
                    let result;
                    if (id) {
                        result = await DeleteConsultant({ consultantId: id });
                        if (result) {
                            showMessage({ content: t('Consultant', 'DeleteConsultantSuccess'), severity: 'success' });
                            history.push(getConsultantsUrl());
                        }
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setDeletingConsultant(false);
                }
            },
        });
    }

    function onEmployeeSearchResult(employee?: EmployeeLookup): void {
        if (employee) {
            if (employee.existingConsultant) {
                setExistingConsultant([
                    {
                        consultant: employee.existingConsultant,
                        assignedToSelectedStore: employee.assignedToSelectedStore,
                    },
                ]);
            } else {
                setShowInputs(true);
                setValues({
                    ...values,
                    firstName: employee.firstName,
                    lastName: employee.lastName,
                    clubMatasMemberId: employee.clubMatasMemberId,
                    email: '',
                    mobileNumber: '',
                    isExternal: false,
                });
            }
        } else {
            setShowInputs(false);
            setExistingConsultant(undefined);
        }
    }

    function onExternalSearchResult(consultantLookup: SearchedConsultantLookup): void {
        if (consultantLookup.results?.length) {
            const list = consultantLookup.results
                .filter((x) => x.maybeConsultant)
                .map(
                    (x) =>
                        ({
                            consultant: x.maybeConsultant,
                            assignedToSelectedStore: x.assignedToSelectedStore,
                            showEmail: true,
                        } as ExistingConsultant),
                );
            setExistingConsultant(list);
        } else {
            setExistingConsultant(undefined);
            setShowInputs(true);
            const names = splitName(consultantLookup.searchedName);
            setValues({
                ...values,
                email: consultantLookup.searchedEmail || '',
                ...names,
                isExternal: true,
            });
        }
    }

    function onTypeChange(showPartner: boolean): void {
        setShowExternalPartner(showPartner);
        setValues(emptyCreateRequest);
        setShowInputs(false);
        setExistingConsultant(undefined);
    }

    if (!isEdit && !selectedStoreId) {
        return <Typography>{t('Consultant', 'CantCreateWithoutStore')}</Typography>;
    }

    return (
        <Grid container direction="column" justifyContent="flex-start" item xs gap={5}>
            <Grid container item>
                <Grid item xs>
                    <Typography variant="h4">
                        {isEdit ? t('Consultant', 'EditTile') : t('Consultant', 'CreateTitle')}
                    </Typography>
                </Grid>
            </Grid>
            <Grid item xs>
                <Card>
                    <CardContent>
                        <Grid container direction="column" justifyContent="flex-start" gap={3}>
                            <Grid container direction="column" justifyContent="flex-start" item gap={4}>
                                <Grid container item spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend" style={{ fontWeight: 'bold' }}>
                                                {t('Consultant', 'Type')}
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                value={showExternalPartner.toString()}
                                                onChange={(e): void => onTypeChange(e.target.value === 'true')}
                                            >
                                                <FormControlLabel
                                                    value="false"
                                                    disabled={isEdit}
                                                    control={<Radio />}
                                                    label={t('Consultant', 'Input', 'IsMatasEmployee')}
                                                />
                                                <FormControlLabel
                                                    value="true"
                                                    disabled={isEdit}
                                                    control={<Radio />}
                                                    label={t('Shared', 'External')}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {!isEdit && !showExternalPartner && (
                                    <SearchEmployee onSearchResult={onEmployeeSearchResult} showInputs={showInputs} />
                                )}
                                {!isEdit && showExternalPartner && (
                                    <SearchExternal
                                        onSearchResult={onExternalSearchResult}
                                        hasExistingConsultants={!!existingConsultant}
                                    />
                                )}
                                {existingConsultant && !!selectedStoreId && (
                                    <ExistingConsultantView
                                        reloadUserFunction={getConsultant}
                                        existingConsultants={existingConsultant}
                                        isEdit={isEdit}
                                    />
                                )}
                                {!existingConsultant && showInputs && (
                                    <BasicInfo
                                        values={values}
                                        setValues={setValues}
                                        isError={isError}
                                        getErrorText={getErrorText}
                                        consultant={consultant}
                                        showExternalPartner={showExternalPartner}
                                        isEdit={isEdit}
                                        role={role}
                                    />
                                )}
                                {!existingConsultant && showInputs && (
                                    <SelectConsultationProducts
                                        values={values}
                                        setValues={setValues}
                                        isError={isError}
                                        getErrorText={getErrorText}
                                        consultant={consultant}
                                        selectedStoreId={selectedStoreId}
                                        isEdit={isEdit}
                                        role={role}
                                    />
                                )}
                                {!existingConsultant &&
                                    isEdit &&
                                    IsInRole(role, 'AdminRole', 'StoreManagerRole') &&
                                    !!consultant && <AuthUser consultant={consultant} role={role} />}
                                {!existingConsultant && showInputs && (<NotificationSettingsEdit values={values}
                                                                                                 setValues={setValues} />)}
                                {loading && (
                                    <Grid item container justifyContent="center" alignItems="center">
                                        <CircularProgress size={40} />
                                    </Grid>
                                )}
                            </Grid>
                            <Grid container item justifyContent="center" gap={2}>
                                {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                                    <Grid item>
                                        <Button variant="contained" component={Link} to={getConsultantsUrl()}>
                                            {t('Shared', 'Cancel')}
                                        </Button>
                                    </Grid>
                                )}
                                {isEdit &&
                                    IsInRole(role, 'AdminRole', 'StoreManagerRole') &&
                                    !!selectedStoreId &&
                                    !!consultant?.baseData.stores.find((x) => x.id === selectedStoreId) && (
                                        <Grid item>
                                            <SpinnerButton
                                                color="secondary"
                                                onClick={removeAssignment}
                                                loading={removingAssignedStatus}
                                            >
                                                {t('Consultant', 'RemoveAssignmentToStore')}
                                            </SpinnerButton>
                                        </Grid>
                                    )}
                                {isEdit && IsInRole(role, 'AdminRole') && !!consultant && (
                                    <Grid item>
                                        <SpinnerButton
                                            color="secondary"
                                            onClick={deleteConsultant}
                                            loading={deletingConsultant}
                                        >
                                            {t('Consultant', 'DeleteConsultant')}
                                        </SpinnerButton>
                                    </Grid>
                                )}
                                {showInputs && (
                                    <Grid item>
                                        <SpinnerButton color="primary" onClick={save} loading={saving}>
                                            {isEdit ? t('Shared', 'Save') : t('Shared', 'Create')}
                                        </SpinnerButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(CreateOrEdit);
