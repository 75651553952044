import React from 'react';
import { ConsultantCreateRequest, ConsultantEditModelResponse } from 'autogen/swagger/Consultant';
import { Grid, TextField, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { nameof } from 'ts-simple-nameof';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import SelectExternalPartners from './SelectExternalPartners';

interface IBasicInfoProps {
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
    isError: (param: string) => boolean;
    getErrorText: (param: string) => string;
    consultant?: ConsultantEditModelResponse;
    showExternalPartner: boolean;
    isEdit: boolean;
    role: AuthRole;
}

export function BasicInfo(props: IBasicInfoProps): JSX.Element {
    const { role, values, setValues, isError, getErrorText, consultant, showExternalPartner, isEdit } = props;

    const { t } = useTypedTranslation();

    return (
        <>
            <Grid container item gap={2}>
                <Grid item xs={6}>
                    <Typography variant="h6">{t('Consultant', 'Input', 'Header')}</Typography>
                </Grid>
            </Grid>
            {showExternalPartner && (
                <SelectExternalPartners
                    values={values}
                    setValues={setValues}
                    isError={isError}
                    getErrorText={getErrorText}
                    consultant={consultant}
                    isEdit={isEdit}
                    role={role}
                />
            )}
            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={t('Consultant', 'Input', 'FirstName')}
                        type="string"
                        fullWidth
                        value={values.firstName}
                        variant="outlined"
                        error={isError(nameof<ConsultantCreateRequest>((x) => x.firstName))}
                        helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.firstName))}
                        onChange={(e): void => setValues({ ...values, firstName: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={t('Consultant', 'Input', 'LastName')}
                        type="string"
                        fullWidth
                        value={values.lastName}
                        variant="outlined"
                        error={isError(nameof<ConsultantCreateRequest>((x) => x.lastName))}
                        helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.lastName))}
                        onChange={(e): void => setValues({ ...values, lastName: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container item spacing={2}>
                <Grid item xs={6}>
                    <TextField
                        label={t('Consultant', 'Input', 'Email')}
                        type="string"
                        fullWidth
                        value={values.email}
                        variant="outlined"
                        disabled={!IsInRole(role, 'AdminRole', 'StoreManagerRole')}
                        error={isError(nameof<ConsultantCreateRequest>((x) => x.email))}
                        helperText={
                            getErrorText(nameof<ConsultantCreateRequest>((x) => x.email)) ||
                            (!values.email ? t('Consultant', 'Input', 'EmailEmptyHelpText') : '')
                        }
                        onChange={(e): void => setValues({ ...values, email: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label={t('Consultant', 'Input', 'MobileNumber')}
                        type="string"
                        fullWidth
                        value={values.mobileNumber || ''}
                        variant="outlined"
                        error={isError(nameof<ConsultantCreateRequest>((x) => x.mobileNumber))}
                        helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.mobileNumber))}
                        onChange={(e): void => setValues({ ...values, mobileNumber: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                {!showExternalPartner && !!values.clubMatasMemberId && (
                    <Grid item xs={3}>
                        <TextField
                            label={t('Consultant', 'Input', 'ClubMatasMemberId')}
                            type="string"
                            fullWidth
                            value={values.clubMatasMemberId}
                            disabled
                            variant="outlined"
                            error={isError(nameof<ConsultantCreateRequest>((x) => x.clubMatasMemberId))}
                            helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.clubMatasMemberId))}
                            onChange={(e): void => setValues({ ...values, clubMatasMemberId: e.target.value })}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                )}
            </Grid>
            <Grid container item gap={2}>
                <Grid item xs={12}>
                    <TextField
                        multiline
                        label={t('Consultant', 'Input', 'Description')}
                        type="string"
                        fullWidth
                        value={values.description}
                        variant="outlined"
                        error={isError(nameof<ConsultantCreateRequest>((x) => x.description))}
                        helperText={getErrorText(nameof<ConsultantCreateRequest>((x) => x.description))}
                        onChange={(e): void => setValues({ ...values, description: e.target.value })}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
}

export default BasicInfo;
