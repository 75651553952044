/* eslint-disable @typescript-eslint/ban-types */
import React from 'react';
import { ValidationErrorType, ValidationResultModel } from 'autogen/swagger/ConsultationProduct';
import { useTypedTranslation } from 'translations';
import { objectType } from '../components/Input/Filter';
import { joinWithComma } from './string';

interface FieldAttributes {
    error: boolean;
    helperText: string;
}

interface ValidationParams {
    recommendedCharacters?: number;
    maximumCharacters?: number;
}

export function useValidationState(
    value?: objectType,
): [
    ValidationResultModel | undefined,
    (errorResult: Promise<object> | object | undefined) => Promise<boolean>,
    (param: string) => boolean,
    (param: string) => string,
    (param: string, validationParams?: ValidationParams) => FieldAttributes,
] {
    const [validationErrors, setValidationErrors] = React.useState<ValidationResultModel | undefined>();
    const { tf } = useTypedTranslation();

    async function setFromErrorObject(errorResult: Promise<object> | object | undefined): Promise<boolean> {
        if (errorResult === undefined) {
            setValidationErrors(undefined);
            return true;
        }

        const error = (await Promise.resolve(errorResult)) as ValidationResultModel;
        if (error?.type === 'ValidationError') {
            setValidationErrors(error);
            return true;
        }
        return false;
    }

    function isError(param: string): boolean {
        return !!(validationErrors?.validationErrors && validationErrors?.validationErrors[param]);
    }

    function getText(param: string): string {
        const errors = validationErrors?.validationErrors && validationErrors?.validationErrors[param];

        if (!errors) return '';

        let text = '';

        const typesAdded: ValidationErrorType[] = [];

        errors.forEach((valErr) => {
            if (valErr.errorType && valErr.errorType !== 'Unknown' && !typesAdded.includes(valErr.errorType)) {
                text += (text ? ' & ' : '') + tf({ ...valErr.errorValues }, 'ValidationError', valErr.errorType);
            }
            typesAdded.push(valErr.errorType);
        });

        if (text) return text;

        return errors[0].errorMessage ?? '';
    }

    function getValidationAttributes(param: string, validationParams?: ValidationParams): FieldAttributes {
        let helperText = getText(param);
        let error = isError(param);

        if (value && validationParams) {
            if (validationParams.recommendedCharacters) {
                helperText = joinWithComma(
                    helperText,
                    tf(
                        {
                            current: (value[param] as string)?.length,
                            recommended: validationParams.recommendedCharacters,
                        },
                        'ValidationError',
                        'RecommendedCharacters',
                    ),
                );
            } else if (validationParams.maximumCharacters) {
                helperText = joinWithComma(
                    helperText,
                    tf(
                        { current: (value[param] as string)?.length, maximum: validationParams.maximumCharacters },
                        'ValidationError',
                        'MaximumCharacters',
                    ),
                );
                if ((value[param] as string)?.length > validationParams.maximumCharacters) {
                    error = true;
                }
            }
        }

        return {
            error,
            helperText,
        };
    }

    return [validationErrors, setFromErrorObject, isError, getText, getValidationAttributes];
}
