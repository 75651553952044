import * as ConfirmModal from './modal/ConfirmModal';
import * as User from './User';
import * as Message from './messages/Message';

export { GetStore } from './configureStore';

// The top-level state object
export interface ApplicationState {
    confirmModal: ConfirmModal.ConfirmModalState | undefined;
    userState: User.UserState | undefined;
    messageState: Message.MessageState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    confirmModal: ConfirmModal.reducer,
    userState: User.reducer,
    messageState: Message.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
