import Oidc from 'oidc-client';
import { GetAuthSettings } from 'autogen/swagger/Settings';

const config = {
    response_type: 'code',
    automaticSilentRenew: true,
};

Oidc.Log.logger = console;
Oidc.Log.level = Oidc.Log.WARN;

let mgr: Oidc.UserManager;

const mgrPromise = (async (): Promise<void> => {
    const authConfig = await GetAuthSettings();

    mgr = new Oidc.UserManager({
        ...config,
        authority: authConfig.authority,
        client_id: authConfig.clientId,
        scope: authConfig.scope,
        redirect_uri: `${authConfig.publicSiteUrl}/login/callback.html`,
        post_logout_redirect_uri: authConfig.publicSiteUrl,
        silent_redirect_uri: `${authConfig.publicSiteUrl}/login/silentrenew.html`,
    });
})();

export async function login(): Promise<void> {
    if (!mgr) {
        await mgrPromise;
    }

    await mgr.signinRedirect();
}

export async function getAccessToken(): Promise<string | undefined> {
    if (!mgr) {
        await mgrPromise;
    }

    const user = await mgr.getUser();

    return user?.access_token;
}

export async function logout(): Promise<void> {
    if (!mgr) {
        await mgrPromise;
    }

    await mgr.signoutRedirect();
}

mgrPromise.then(async (): Promise<void> => {
    mgr.events.addUserSignedOut(async (): Promise<void> => {
        await mgr.removeUser();
    });

    const user = await mgr.getUser();

    if (user) {
        await mgr.startSilentRenew();
    } else {
        await login();
    }
});
