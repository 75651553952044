import React from 'react';
import { CalendarItemBooking, CalendarItemEvent, CalendarItemShift, OverviewItem } from 'autogen/swagger/Consultant';
import { Consultant } from 'autogen/swagger/Booking';
import { Grid, Button, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { AuthRole } from 'autogen/swagger/Users';
import CalendarItem from '../Items/CalendarItem';

interface IOverviewDrawerProps {
    item: OverviewItem;
    consultant: Consultant;
    closeDrawer: () => void;
    onBookingClicked: (booking: CalendarItemBooking) => void;
    onShiftClicked: (shift: CalendarItemShift, consultant: Consultant) => void;
    selectedStoreId: number;
    role: AuthRole;
    onEventTimeItemClick: (eventTime: CalendarItemEvent, consultant?: Consultant) => void;
}

export function OverviewDrawer(props: IOverviewDrawerProps): JSX.Element {
    const {
        item,
        consultant,
        closeDrawer,
        onBookingClicked,
        selectedStoreId,
        role,
        onShiftClicked,
        onEventTimeItemClick,
    } = props;
    return (
        <Grid container style={{ padding: '10px' }}>
            <Grid item style={{ minWidth: '250px', padding: '0 10px' }}>
                <Grid container direction="column">
                    <Grid item>
                        <Typography variant="h5">
                            {consultant.firstName} {consultant.lastName}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                            {moment(item.activeTime.activeFromDate).format('dddd DD/MM')}
                        </Typography>
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                        {item.overviewShifts.map((x) => (
                            <CalendarItem
                                key={x.activeTime.activeFromDate}
                                item={x}
                                onBookingItemClick={onBookingClicked}
                                onShiftItemClick={(shift: CalendarItemShift): void => onShiftClicked(shift, consultant)}
                                selectedStoreId={selectedStoreId}
                                hideStoreName
                                role={role}
                                onEventTimeItemClick={(eventTime: CalendarItemEvent): void =>
                                    onEventTimeItemClick(eventTime, consultant)
                                }
                            />
                        ))}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={closeDrawer} style={{ minWidth: 'auto' }}>
                    <Close />
                </Button>
            </Grid>
        </Grid>
    );
}

export default OverviewDrawer;
