import React from 'react';
import { DashboardMetric } from 'autogen/swagger/Dashboard';
import { Card, CardContent, Grid, Typography, Tooltip } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import { Chart, ChartData } from 'chart.js';
import { useTypedTranslation } from 'translations';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { HelpOutline } from '@mui/icons-material';

Chart.register(ChartDataLabels);

interface IPieChartMetricProps {
    metric: DashboardMetric;
}

export function PieChartMetric(props: IPieChartMetricProps): JSX.Element {
    const { metric } = props;
    const { values } = metric;
    const { t } = useTypedTranslation();

    const shownValues = values.filter((x) => x.value).sort((a, b) => `${a.name}`.localeCompare(b.name as string));

    const data: ChartData<'pie'> = {
        labels: shownValues.map((x) => x.name),

        datasets: [
            {
                label: t('Dashboard', 'Metric', metric.metricType),
                data: shownValues.map((x) => x.value),
                backgroundColor: [
                    'rgba(228, 26, 28, 0.2)',
                    'rgba(227, 110, 26, 0.2)',
                    'rgba(227, 197, 26, 0.2)',
                    'rgba(172, 227, 26, 0.2)',
                    'rgba(85, 227, 26, 0.2)',
                    'rgba(26, 227, 52, 0.2)',
                    'rgba(26, 227, 139, 0.2)',
                    'rgba(26, 227, 226, 0.2)',
                    'rgba(26, 143, 227, 0.2)',
                    'rgba(26, 56, 227, 0.2)',
                    'rgba(81, 26, 227, 0.2)',
                    'rgba(168, 26, 227, 0.2)',
                    'rgba(227, 26, 201, 0.2)',
                    'rgba(227, 26, 114, 0.2)',
                ],
                borderColor: [
                    'rgba(228, 26, 28, 1)',
                    'rgba(227, 110, 26, 1)',
                    'rgba(227, 197, 26, 1)',
                    'rgba(172, 227, 26, 1)',
                    'rgba(85, 227, 26, 1)',
                    'rgba(26, 227, 52, 1)',
                    'rgba(26, 227, 139, 1)',
                    'rgba(26, 227, 226, 1)',
                    'rgba(26, 143, 227, 1)',
                    'rgba(26, 56, 227, 1)',
                    'rgba(81, 26, 227, 1)',
                    'rgba(168, 26, 227, 1)',
                    'rgba(227, 26, 201, 1)',
                    'rgba(227, 26, 114, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return (
        <Card style={{ position: 'relative' }}>
            <Tooltip
                title={t('Dashboard', 'MetricHelpText', metric.metricType)}
                style={{ position: 'absolute', top: '3px', right: '3px', height: '15px', width: '15px' }}
            >
                <HelpOutline />
            </Tooltip>
            <CardContent>
                <Grid container direction="column" alignItems="center">
                    <Grid item>
                        <Typography variant="body2" style={{ marginBottom: '5px' }}>
                            {t('Dashboard', 'Metric', metric.metricType)}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Pie
                            data={data}
                            options={{
                                responsive: true,
                                plugins: {
                                    datalabels: {
                                        color: '#111827',
                                        padding: 6,
                                        formatter: Math.round,
                                    },
                                },
                            }}
                            plugins={[]}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default PieChartMetric;
