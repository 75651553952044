// eslint-disable-next-line no-shadow
export enum sizes {
    // Breakpoints shall not be changed without changing CmsSettings.cs -> GetCmsBreakpoints()
    // Extra small screen / phone
    xs = 0,
    // Small screen / phone
    sm = 600,
    // Medium screen / tablet
    md = 960,
    // Large screen / desktop
    lg = 1280,
    // Extra large screen / wide desktop
    xl = 1920,
}

function err(): void {
    console.error('Breakpoint is invalid');
}

type sizesObjectType = { [idx: string]: sizes };

function next(size: sizes): sizes | undefined {
    if (!sizes[size]) {
        err();
        return undefined;
    }
    const sizesObject: sizesObjectType = sizes as unknown as sizesObjectType;
    const entries = Object.entries(sizes).filter((value): boolean => Number.isNaN(Number(value)));
    const currentIndex = entries.findIndex(([breakpointInArray]): boolean => sizesObject[breakpointInArray] === size);
    const nextBreakpoint = entries[currentIndex + 1] || null;
    const [breakpoint] = nextBreakpoint;
    return nextBreakpoint ? sizesObject[breakpoint] : undefined;
}

function min(size: sizes): boolean {
    if (!size) {
        err();
        return false;
    }

    return window.matchMedia(`(min-width: ${size}px)`).matches;
}

function max(size: sizes): boolean {
    if (!size) {
        err();
        return false;
    }

    const nextSize = next(size);
    if (!nextSize) {
        return true;
    }

    return window.matchMedia(`(max-width: ${nextSize - 1}px)`).matches;
}

function getNameFromWidth(width: number): string {
    if (width < sizes.sm) return 'xs';
    if (width < sizes.md) return 'sm';
    if (width < sizes.lg) return 'md';
    if (width < sizes.xl) return 'lg';
    return 'xxxl';
}

type BreakpointFunctionType = (size: sizes) => string;
type BreakpointFunctionBetweenType = (minSize: sizes, maxSize: sizes) => string;

const up: BreakpointFunctionType = (size) => {
    const sizeVal = typeof size === 'string' ? sizes[size] : size;
    return `
        @media (min-width: ${sizeVal}px)
    `;
};

const down: BreakpointFunctionType = (size) => {
    const sizeVal = typeof size === 'string' ? sizes[size] : size;
    const nextSizeVal = Object.values(sizes).reduce((acc, curr, index, arr): number => {
        if (curr === sizeVal) {
            return (arr[index + 1] as number) || 0;
        }
        return acc;
    }, 0);

    if (!nextSizeVal) {
        err();
    }

    return `
            @media (max-width: ${nextSizeVal - 1}px)
        `;
};

function stripAttMedia(string: string): string {
    return string.replace('@media', '');
}

const only: BreakpointFunctionType = (size) => `@media ${stripAttMedia(up(size))} and ${stripAttMedia(down(size))}`;

const between: BreakpointFunctionBetweenType = (minSize, maxSize) =>
    `@media ${stripAttMedia(up(minSize))} and ${stripAttMedia(down(maxSize))}`;

function combine(...args: string[]): string {
    return `@media ${args.map(stripAttMedia).join(',')}`;
}

export const breakpoint = {
    sizes,
    getNameFromWidth,
    min,
    max,
    up,
    down,
    only,
    between,
    combine,
};
