import React, { useEffect, useState } from 'react';
import { ExternalPartner, ListExternalPartners } from 'autogen/swagger/ExternalPartners';
import { ConsultationProductDetailed } from 'autogen/swagger/ConsultationProduct';
import { useTypedTranslation } from 'translations';
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from '@mui/material';

interface ISelectProductExternalPartnerProps {
    consultationProduct?: ConsultationProductDetailed;
    onChange: (externalPartnerId?: number) => void;
    value?: number;
    disabled?: boolean;
    error: boolean;
    helperText: string;
}

export function SelectProductExternalPartner(props: ISelectProductExternalPartnerProps): JSX.Element {
    const { consultationProduct, onChange, value, disabled, error, helperText } = props;
    const { t } = useTypedTranslation();
    const [externalPartners, setExternalPartners] = useState<ExternalPartner[]>([]);

    useEffect(() => {
        (async (): Promise<void> => {
            const result = await ListExternalPartners({ onlyActive: false });
            setExternalPartners(result);
        })();
    }, []);

    const chooseableExternalPartners = [...externalPartners];
    if (
        consultationProduct &&
        consultationProduct.consultationProductMasterDetailed.externalPartner &&
        !externalPartners.find(
            (x) => x.id === consultationProduct.consultationProductMasterDetailed.externalPartner?.id,
        )
    ) {
        chooseableExternalPartners.push({
            ...consultationProduct.consultationProductMasterDetailed.externalPartner,
            name: `(${t('Shared', 'Deleted')}) ${
                consultationProduct.consultationProductMasterDetailed.externalPartner?.name
            }`,
        });
    }

    return (
        <Autocomplete
            disabled={disabled}
            options={chooseableExternalPartners}
            value={chooseableExternalPartners.find((x) => value === x.id) || null}
            onChange={(e, val): void => onChange(val?.id)}
            getOptionLabel={(option): string => option.name}
            fullWidth
            title={disabled ? t('ConsultationProduct', 'CantEditExternalPartner') : ''}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    variant="outlined"
                    label={t('ConsultationProduct', 'ExternalPartner')}
                    placeholder={t('ConsultationProduct', 'SelectExternalPartner')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    error={error}
                    helperText={helperText}
                />
            )}
        />
    );
}

export default SelectProductExternalPartner;
