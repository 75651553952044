import React, { useCallback, useEffect, useState } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { Add } from '@mui/icons-material';
import { Filter, IFilter } from 'components/Input/Filter';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
    ExternalPartnerDetailed,
    ListExternalPartners,
    ToggleActiveExternalPartner,
} from 'autogen/swagger/ExternalPartners';
import { nameof } from 'ts-simple-nameof';
import { Link } from 'react-router-dom';
import { getExternalPartnersUrl } from 'shared/urls';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import { useDataGridStyles } from 'shared/theme';
import { useDebouncedCallback } from 'use-debounce/esm';
import { useHistory } from 'react-router';

interface IExternalPartnerHistoryValues {
    ShowInActive: boolean;
    search: string;
}

type IExternalPartnerFilterValues = {
    ShowInActive: boolean;
    search: string;
};

export function List(): JSX.Element {
    const history = useHistory();
    const historyValues = (history.location.state as IExternalPartnerHistoryValues) || {};
    const { t, tf } = useTypedTranslation();
    const [externalPartners, setExternalPartners] = useState<ExternalPartnerDetailed[]>([]);
    const [filterValues, setFilterValues] = useState<IExternalPartnerFilterValues>({
        ShowInActive: historyValues.ShowInActive === undefined ? false : historyValues.ShowInActive,
        search: historyValues.search || '',
    });
    const [loading, setLoading] = useState(false);

    const filters: IFilter<IExternalPartnerFilterValues>[] = [
        {
            label: t('Filter', 'ShowDisabled'),
            paramName: 'ShowInActive',
            type: 'checkbox',
        },
        {
            label: t('Filter', 'Search'),
            paramName: 'search',
            type: 'text',
        },
    ];

    function onFilterChange(values: IExternalPartnerFilterValues): void {
        setFilterValues(values);
        history.replace(history.location.pathname + history.location.search, {
            ...historyValues,
            ShowInActive: values.ShowInActive,
            search: values.search,
        });
    }

    const loadExternalPartners = useCallback(async () => {
        try {
            setLoading(true);
            const result = await ListExternalPartners({
                onlyActive: filterValues.ShowInActive,
                search: filterValues.search,
            });
            setExternalPartners(result);
        } finally {
            setLoading(false);
        }
    }, [filterValues]);

    const loadExternalPartnersDebounced = useDebouncedCallback(async () => {
        loadExternalPartners();
    }, 300);

    useEffect(() => {
        loadExternalPartnersDebounced();
    }, [filterValues, loadExternalPartnersDebounced]);

    const columns: GridColDef[] = [
        {
            field: nameof<ExternalPartnerDetailed>((x) => x.name),
            headerName: t('ExternalPartner', 'Name'),
            flex: 1,
            sortable: false,
            filterable: false,
        },
        {
            field: 'id',
            headerName: t('Shared', 'Action'),
            width: 200,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const id = params.value as number;
                const isActive = params.row?.isActive;

                async function deleteExternalPartner(): Promise<void> {
                    const name = externalPartners.find((x) => x.id === id)?.name ?? '';
                    ConfirmChoice({
                        content: tf(
                            { name },
                            'ExternalPartner',
                            isActive ? 'DeactivateConfirmText' : 'ActivateConfirmText',
                        ),
                        onConfirm: async () => {
                            const result = await ToggleActiveExternalPartner({ id, active: !isActive });
                            if (result.success) {
                                loadExternalPartners();
                            }
                        },
                    });
                }

                return (
                    <>
                        <Button color="primary" component={Link} to={getExternalPartnersUrl(id)}>
                            {t('Shared', 'Edit')}
                        </Button>
                        <Button color="primary" onClick={deleteExternalPartner}>
                            {t('Shared', isActive ? 'Disable' : 'Enable')}
                        </Button>
                    </>
                );
            },
        },
    ];

    const classes = useDataGridStyles();

    return (
        <Grid container direction="column" item xs gap={2}>
            <Grid container item xs style={{ flexGrow: 0 }}>
                <Grid item xs>
                    <Typography variant="h4">{t('ExternalPartner', 'Title')}</Typography>
                </Grid>

                <Grid item xs>
                    <Filter filters={filters} filterValues={filterValues} onChange={onFilterChange} />
                </Grid>
                <Grid container item xs justifyContent="flex-end">
                    <div
                        title={
                            !filterValues.ShowInActive
                                ? t('ExternalPartner', 'CreateTooltipWarning')
                                : t('ExternalPartner', 'CreateTooltip')
                        }
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            component={Link}
                            to={getExternalPartnersUrl(0)}
                            disabled={!filterValues.ShowInActive}
                        >
                            <Add /> {t('Shared', 'CreateNew')}
                        </Button>
                    </div>
                </Grid>
            </Grid>
            <Grid container item xs direction="column">
                <DataGrid columns={columns} rows={externalPartners} loading={loading} className={classes.root} />
            </Grid>
        </Grid>
    );
}

export default List;
