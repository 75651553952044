import React, { ComponentType, useCallback } from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators, UserState } from 'store/User';
import { StoreSelectViewModel, ChangeSelectedStore } from 'autogen/swagger/Users';
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useTypedTranslation } from 'translations';

type IStoreSelectProps = typeof actionCreators & UserState;

export function StoreSelect(props: IStoreSelectProps): JSX.Element {
    const { selectedStoreId, selectableStores, requestUserState, role } = props;
    const { t } = useTypedTranslation();

    const selectedStore = selectableStores.find((x) => x.id === selectedStoreId) ?? null;

    const fetchData = useCallback((): void => {
        requestUserState();
    }, [requestUserState]);

    function onChange(newValue: StoreSelectViewModel | null): void {
        if (newValue || role === 'AdminRole') {
            ChangeSelectedStore({ storeId: newValue?.id }).then(() => fetchData());
        }
    }

    return (
        <Autocomplete
            id="StoreSelect"
            value={selectedStore}
            options={selectableStores}
            onChange={(event, newValue): void => {
                onChange(newValue);
            }}
            size="small"
            getOptionLabel={(option: StoreSelectViewModel): string => `${option.id} - ${option.name}`}
            isOptionEqualToValue={(option, value): boolean => option.id === value.id}
            style={{ width: 300, marginRight: '10px' }}
            renderInput={(params): JSX.Element => (
                <TextField
                    {...params}
                    placeholder={t('Menu', 'SelectStore')}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            )}
        />
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(StoreSelect as ComponentType);
