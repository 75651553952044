import React, { useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import { Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { nameof } from 'ts-simple-nameof';
import Autocomplete from '@mui/material/Autocomplete';
import {
    ChangeDisableEventTime,
    Consultant,
    ConsultationProductDetailed,
    CreateEventTime,
    CreateOrUpdateEventTimeViewModel,
    DeleteEventTime,
    EventTime,
    GetConsultant,
    GetEventTime,
    ListAvailableConsultationProductDetailsForEventTime,
    UpdateEventTime,
} from 'autogen/swagger/Consultant';
import { AuthRole, StoreSelectViewModel } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import { capitalizeFirstLetter, formatConsultationProductName } from 'helpers/string';
import SpinnerButton from 'components/button/SpinnerButton';
import { useTypedTranslation } from 'translations';
import { useValidationState } from 'helpers/validation';
import { ConfirmChoice } from 'components/modal/ConfirmModal';
import AddressInput from 'components/Input/AddressInput';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import { getMinutesRoundedUp } from 'helpers/dateTime';
import { DateTimePicker } from '@mui/lab';
import StoreAndConsultantText from '../StoreAndConsultantText';

function mapToEventTimeValues(eventTime: EventTime): CreateOrUpdateEventTimeViewModel {
    return {
        startDateTime: eventTime.activeTime.activeFromDate || '',
        consultantId: eventTime.consultantId,
        consultationProductId: eventTime.consultationProductId,
        maxParticipants: eventTime.maxParticipants,
        maxSpacesPerBooking: eventTime.maxSpacesPerBooking,
        address: {
            streetName: eventTime.streetName || '',
            cityName: eventTime.cityName || '',
            locationName: eventTime.locationName || '',
            zipCode: eventTime.zipCode || '',
        },
        price: eventTime.ticketPrice,
    };
}

function getEmptyEventTimeValues(consultantId: number): CreateOrUpdateEventTimeViewModel {
    return {
        startDateTime: '',
        consultantId,
        consultationProductId: 0,
        maxParticipants: 0,
        address: {
            streetName: '',
            cityName: '',
            locationName: '',
            zipCode: '',
        },
    };
}

interface ICreateOrEditEventTimeProps {
    id?: number;
    consultant?: Consultant;
    onComplete?: (update: boolean) => void;
    currentSelectedWeekDate: Moment;
    role: AuthRole;
    selectedStoreId?: number;
    selectableStores?: StoreSelectViewModel[];
}

export function CreateOrEditEventTime(props: ICreateOrEditEventTimeProps): JSX.Element {
    const {
        id,
        consultant: initialConsultant,
        onComplete,
        currentSelectedWeekDate,
        selectedStoreId,
        selectableStores,
        role,
    } = props;
    const { t } = useTypedTranslation();
    const [consultant, setConsultant] = useState(initialConsultant);
    const consultantId = consultant?.id;

    const [eventTime, setEventTime] = useState<EventTime>();
    const [eventTimeValues, setEventTimeValues] = useState<CreateOrUpdateEventTimeViewModel>(
        getEmptyEventTimeValues(consultantId || 0),
    );

    const [, setValidationErrors, isError, getErrorText] = useValidationState();
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [disabling, setDisabling] = useState(false);
    const [consultationProducts, setConsultationProducts] = useState<ConsultationProductDetailed[]>([]);
    const [selectedDate, setSelectedDate] = useState(
        (): Moment =>
            getMinutesRoundedUp(
                currentSelectedWeekDate.isBefore(moment()) ? moment().add(1, 'days') : moment(currentSelectedWeekDate),
                5,
            ),
    );
    const [notHeldInStore, setNotHeldInStore] = useState(false);

    const isEdit = !!id;

    useEffect(() => {
        if (eventTime && !consultant) {
            (async (): Promise<void> => {
                const con = await GetConsultant({ consultantId: eventTime.consultantId });
                setConsultant(con);
            })();
        }
    }, [consultant, eventTime]);

    useEffect(() => {
        if (id) {
            (async (): Promise<void> => {
                const result = await GetEventTime({ id });
                setEventTime(result);
                setEventTimeValues(mapToEventTimeValues(result));
                setSelectedDate(moment(result.activeTime.activeFromDate));
                setNotHeldInStore(!!result.locationName);
            })();
        }

        if (consultantId) {
            (async (): Promise<void> => {
                const result = await ListAvailableConsultationProductDetailsForEventTime({
                    eventTimeId: id,
                    consultantId,
                });
                setConsultationProducts(result);
            })();
        }
    }, [consultant, consultantId, id]);

    useEffect(() => {
        if (!notHeldInStore) {
            setEventTimeValues((prev) => ({
                ...prev,
                address: { streetName: '', cityName: '', locationName: '', zipCode: '' },
            }));
        }
    }, [notHeldInStore]);

    useEffect(() => {
        const consultationProduct = consultationProducts.find((x) => x.id === eventTimeValues.consultationProductId);
        if (consultationProduct) {
            setEventTimeValues((prev) => ({
                ...prev,
                price: consultationProduct.suggestedPrice,
            }));
        }
    }, [consultationProducts, eventTimeValues.consultationProductId]);

    useEffect(() => {
        setEventTimeValues((prev) => ({ ...prev, startDateTime: selectedDate.toISOString(true) }));
    }, [selectedDate]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            setValidationErrors(undefined);

            let result;
            if (id) {
                result = await UpdateEventTime({ id }, eventTimeValues);
            } else {
                result = await CreateEventTime(eventTimeValues);
            }

            if (result) {
                if (onComplete) onComplete(true);
            }
        } catch (ex) {
            let isShowableErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isShowableErrors)
                isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

            if (!isShowableErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    async function deleteEventTime(): Promise<void> {
        if (!id) return;
        ConfirmChoice({
            content: t('EventTime', 'ConfirmDelete'),
            onConfirm: async () => {
                if (!id) return;
                try {
                    setDeleting(true);
                    const result = await DeleteEventTime({ id });
                    setValidationErrors(undefined);
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setDeleting(false);
                }
            },
        });
    }

    async function changeDisableEventTime(): Promise<void> {
        if (!eventTime) return;
        ConfirmChoice({
            content: eventTime.isActive ? t('EventTime', 'ConfirmDisable') : t('EventTime', 'ConfirmEnable'),
            onConfirm: async () => {
                if (!eventTime.id) return;
                try {
                    setDisabling(true);
                    const result = await ChangeDisableEventTime({ id: eventTime.id, disable: eventTime.isActive });
                    setValidationErrors(undefined);
                    if (result) {
                        if (onComplete) onComplete(true);
                    }
                } catch (ex) {
                    const isShowableErrors = (await Promise.resolve(ex as Record<string, boolean>)).messageShown;

                    if (!isShowableErrors) throw ex;
                } finally {
                    setDisabling(false);
                }
            },
        });
    }

    function onCancelClick(): void {
        if (onComplete) onComplete(false);
    }

    function handleDateChange(date: Moment | null): void {
        if (!date) return;
        setSelectedDate(date);
    }

    if (id && !eventTime)
        return (
            <Grid container justifyContent="center" alignItems="center">
                <CircularProgress />
            </Grid>
        );
    return (
        <Grid item xs>
            <Grid container direction="column" justifyContent="flex-start" gap={2}>
                <Grid container item>
                    <Grid item xs>
                        <Typography variant="h4">
                            {isEdit ? t('EventTime', 'EditTile') : t('EventTime', 'CreateTitle')}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <StoreAndConsultantText
                            consultant={consultant}
                            storeId={eventTime ? eventTime.storeId : selectedStoreId}
                            selectableStores={selectableStores}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Grid container spacing={2} alignItems="center" style={{ justifyContent: 'center' }}>
                        <Grid item sm={6}>
                            <DateTimePicker
                                disabled={isEdit}
                                inputFormat="DD-MM-yyyy HH:mm"
                                mask="__-__-____ __:__"
                                ampm={false}
                                label={t('Shared', 'Day')}
                                value={selectedDate}
                                onChange={handleDateChange}
                                OpenPickerButtonProps={{
                                    'aria-label': t('Shared', 'ChangeDate'),
                                }}
                                disablePast
                                minutesStep={5}
                                renderInput={(params) => (
                                    <TextField
                                        margin="normal"
                                        fullWidth
                                        {...params}
                                        error={isError(
                                            nameof<CreateOrUpdateEventTimeViewModel>((x) => x.startDateTime),
                                        )}
                                        helperText={getErrorText(
                                            nameof<CreateOrUpdateEventTimeViewModel>((x) => x.startDateTime),
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                label={t('EventTime', 'MaxParticipants')}
                                type="number"
                                fullWidth
                                value={eventTimeValues.maxParticipants !== 0 ? eventTimeValues.maxParticipants : ''}
                                variant="outlined"
                                error={isError(nameof<CreateOrUpdateEventTimeViewModel>((x) => x.maxParticipants))}
                                helperText={getErrorText(
                                    nameof<CreateOrUpdateEventTimeViewModel>((x) => x.maxParticipants),
                                )}
                                onChange={(e): void =>
                                    setEventTimeValues({
                                        ...eventTimeValues,
                                        maxParticipants: e.target.value ? parseInt(e.target.value) : 0,
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                label={t('EventTime', 'MaxSpacesPerBooking')}
                                type="number"
                                fullWidth
                                value={eventTimeValues.maxSpacesPerBooking ? eventTimeValues.maxSpacesPerBooking : ''}
                                variant="outlined"
                                error={isError(nameof<CreateOrUpdateEventTimeViewModel>((x) => x.maxSpacesPerBooking))}
                                helperText={getErrorText(
                                    nameof<CreateOrUpdateEventTimeViewModel>((x) => x.maxSpacesPerBooking),
                                )}
                                onChange={(e): void =>
                                    setEventTimeValues({
                                        ...eventTimeValues,
                                        maxSpacesPerBooking: e.target.value ? parseInt(e.target.value) : undefined,
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={9}>
                            <Autocomplete
                                disabled={isEdit || IsInRole(role, 'ConsultantRole')}
                                options={consultationProducts}
                                value={
                                    consultationProducts.find((x) => eventTimeValues.consultationProductId === x.id) ||
                                    null
                                }
                                onChange={(e, value): void =>
                                    setEventTimeValues((prev) => ({
                                        ...prev,
                                        consultationProductId: value?.id || 0,
                                    }))
                                }
                                groupBy={(option): string => option.consultationCategory.name}
                                getOptionLabel={(option): string =>
                                    formatConsultationProductName(
                                        option.name,
                                        option.consultationProductMasterDetailed.externalPartner?.name,
                                    )
                                }
                                fullWidth
                                renderInput={(params): JSX.Element => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        label={t('EventTime', 'Service')}
                                        placeholder={t('EventTime', 'SelectService')}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        error={isError(
                                            nameof<CreateOrUpdateEventTimeViewModel>((x) => x.consultationProductId),
                                        )}
                                        helperText={getErrorText(
                                            nameof<CreateOrUpdateEventTimeViewModel>((x) => x.consultationProductId),
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item sm={3}>
                            <TextField
                                label={t('EventTime', 'Price')}
                                type="number"
                                disabled
                                fullWidth
                                value={eventTimeValues.price ? eventTimeValues.price : ''}
                                variant="outlined"
                                error={isError(nameof<CreateOrUpdateEventTimeViewModel>((x) => x.price))}
                                helperText={getErrorText(nameof<CreateOrUpdateEventTimeViewModel>((x) => x.price))}
                                onChange={(e): void =>
                                    setEventTimeValues({
                                        ...eventTimeValues,
                                        price: e.target.value ? parseInt(e.target.value) : undefined,
                                    })
                                }
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item sm={12}>
                            <Button
                                onClick={(): void => setNotHeldInStore(!notHeldInStore)}
                                variant="contained"
                                color="primary"
                            >
                                {notHeldInStore ? <CheckBox /> : <CheckBoxOutlineBlank />}&nbsp;
                                {t('EventTime', 'NotHeldInStore')}
                            </Button>
                        </Grid>
                        <Grid item sm={12}>
                            {notHeldInStore && (
                                <AddressInput
                                    address={eventTimeValues.address}
                                    onChange={(address): void => setEventTimeValues((prev) => ({ ...prev, address }))}
                                    isError={(attrName): boolean =>
                                        isError(`address.${capitalizeFirstLetter(attrName)}`)
                                    }
                                    getErrorText={(attrName): string =>
                                        getErrorText(`address.${capitalizeFirstLetter(attrName)}`)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="center" spacing={2}>
                    <Grid item>
                        <Button variant="contained" onClick={onCancelClick}>
                            {t('Shared', 'Cancel')}
                        </Button>
                    </Grid>
                    {isEdit && (
                        <>
                            {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                                <Grid item>
                                    <SpinnerButton
                                        loading={deleting}
                                        variant="contained"
                                        color="secondary"
                                        onClick={deleteEventTime}
                                    >
                                        {t('EventTime', 'Delete')}
                                    </SpinnerButton>
                                </Grid>
                            )}
                            <Grid item>
                                <SpinnerButton
                                    loading={disabling}
                                    variant="contained"
                                    color="warning"
                                    onClick={changeDisableEventTime}
                                >
                                    {eventTime?.isActive ? t('EventTime', 'Disable') : t('EventTime', 'Enable')}
                                </SpinnerButton>
                            </Grid>
                        </>
                    )}
                    {IsInRole(role, 'AdminRole', 'StoreManagerRole') && (
                        <Grid item>
                            <SpinnerButton loading={saving} variant="contained" color="primary" onClick={save}>
                                {isEdit ? t('Shared', 'Save') : t('Shared', 'Create')}
                            </SpinnerButton>
                        </Grid>
                    )}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default CreateOrEditEventTime;
