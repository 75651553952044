import React from 'react';
import styled from 'styled-components';
import { Button, ButtonProps } from '@mui/material';

const StyledButton = styled(Button)`
    && {
        font-size: 12px;
        text-transform: none;
    }
`;

export function ButtonLink(props: ButtonProps): JSX.Element {
    const { children, ...rest } = props;
    return <StyledButton {...rest}>{children}</StyledButton>;
}

export default ButtonLink;
