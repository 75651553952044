/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './ConsultationProduct';

export type Medium = components['schemas']['Medium'];
export type MediumMaybe = components['schemas']['MediumMaybe'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type ConsultationCategoryDetailed = components['schemas']['ConsultationCategoryDetailed'];
export type ErrorType = components['schemas']['ErrorType'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type ConsultationCategory = components['schemas']['ConsultationCategory'];
export type CreateOrUpdateConsultationCategoryViewModel =
    components['schemas']['CreateOrUpdateConsultationCategoryViewModel'];
export type OperationResult = components['schemas']['OperationResult'];
export type BookingType = components['schemas']['BookingType'];
export type ConsultationProductSearchRequest = components['schemas']['ConsultationProductSearchRequest'];
export type ExternalPartner = components['schemas']['ExternalPartner'];
export type ExternalPartnerMaybe = components['schemas']['ExternalPartnerMaybe'];
export type StoreConsultationProduct = components['schemas']['StoreConsultationProduct'];
export type ConsultationProductMasterDetailed = components['schemas']['ConsultationProductMasterDetailed'];
export type ConsultationProductMaster = components['schemas']['ConsultationProductMaster'];
export type ConsultationProductDetailed = components['schemas']['ConsultationProductDetailed'];
export type ConsultationProductListModel = components['schemas']['ConsultationProductListModel'];
export type CreateOrUpdateConsultationProductViewModel =
    components['schemas']['CreateOrUpdateConsultationProductViewModel'];
export type ConsultationProductSearchModel = components['schemas']['ConsultationProductSearchModel'];
export type ConsultationProductIdsRequest = components['schemas']['ConsultationProductIdsRequest'];

type ListCategoriesDetailedParams = paths['/ConsultationCategory/ListCategoriesDetailed']['get']['parameters']['query'];

type ListCategoriesDetailedSuccess =
    paths['/ConsultationCategory/ListCategoriesDetailed']['get']['responses']['200']['content']['application/json'];

export const ListCategoriesDetailedUrl = '/ConsultationCategory/ListCategoriesDetailed';

export const ListCategoriesDetailedUrlFormat = (params: ListCategoriesDetailedParams): string =>
    ListCategoriesDetailedUrl + formatParams(params);

export function ListCategoriesDetailed(params: ListCategoriesDetailedParams): Promise<ListCategoriesDetailedSuccess> {
    return fetchJSON<ListCategoriesDetailedSuccess>(
        `/ConsultationCategory/ListCategoriesDetailed${formatParams(params)}`,
        {},
        true,
    );
}

type ListCategoriesParams = paths['/ConsultationCategory/ListCategories']['get']['parameters']['query'];

type ListCategoriesSuccess =
    paths['/ConsultationCategory/ListCategories']['get']['responses']['200']['content']['application/json'];

export const ListCategoriesUrl = '/ConsultationCategory/ListCategories';

export const ListCategoriesUrlFormat = (params: ListCategoriesParams): string =>
    ListCategoriesUrl + formatParams(params);

export function ListCategories(params: ListCategoriesParams): Promise<ListCategoriesSuccess> {
    return fetchJSON<ListCategoriesSuccess>(`/ConsultationCategory/ListCategories${formatParams(params)}`, {}, true);
}

type CreateCategoryRequestBody =
    paths['/ConsultationCategory/CreateCategory']['post']['requestBody']['content']['application/json'];

type CreateCategorySuccess =
    paths['/ConsultationCategory/CreateCategory']['post']['responses']['200']['content']['application/json'];

export const CreateCategoryUrl = '/ConsultationCategory/CreateCategory';

export function CreateCategory(requestBody: CreateCategoryRequestBody): Promise<CreateCategorySuccess> {
    return postJSON<CreateCategorySuccess>(`/ConsultationCategory/CreateCategory`, requestBody, true);
}

type UpdateCategoryParams = paths['/ConsultationCategory/UpdateCategory']['post']['parameters']['query'];

type UpdateCategoryRequestBody =
    paths['/ConsultationCategory/UpdateCategory']['post']['requestBody']['content']['application/json'];

type UpdateCategorySuccess =
    paths['/ConsultationCategory/UpdateCategory']['post']['responses']['200']['content']['application/json'];

export const UpdateCategoryUrl = '/ConsultationCategory/UpdateCategory';

export const UpdateCategoryUrlFormat = (params: UpdateCategoryParams): string =>
    UpdateCategoryUrl + formatParams(params);

export function UpdateCategory(
    params: UpdateCategoryParams,
    requestBody: UpdateCategoryRequestBody,
): Promise<UpdateCategorySuccess> {
    return postJSON<UpdateCategorySuccess>(
        `/ConsultationCategory/UpdateCategory${formatParams(params)}`,
        requestBody,
        true,
    );
}

type GetCategoryParams = paths['/ConsultationCategory/GetCategory']['get']['parameters']['query'];

type GetCategorySuccess =
    paths['/ConsultationCategory/GetCategory']['get']['responses']['200']['content']['application/json'];

export const GetCategoryUrl = '/ConsultationCategory/GetCategory';

export const GetCategoryUrlFormat = (params: GetCategoryParams): string => GetCategoryUrl + formatParams(params);

export function GetCategory(params: GetCategoryParams): Promise<GetCategorySuccess> {
    return fetchJSON<GetCategorySuccess>(`/ConsultationCategory/GetCategory${formatParams(params)}`, {}, true);
}

type ToggleDeletedCategoryParams = paths['/ConsultationCategory/ToggleDeletedCategory']['get']['parameters']['query'];

type ToggleDeletedCategorySuccess =
    paths['/ConsultationCategory/ToggleDeletedCategory']['get']['responses']['200']['content']['application/json'];

export const ToggleDeletedCategoryUrl = '/ConsultationCategory/ToggleDeletedCategory';

export const ToggleDeletedCategoryUrlFormat = (params: ToggleDeletedCategoryParams): string =>
    ToggleDeletedCategoryUrl + formatParams(params);

export function ToggleDeletedCategory(params: ToggleDeletedCategoryParams): Promise<ToggleDeletedCategorySuccess> {
    return fetchJSON<ToggleDeletedCategorySuccess>(
        `/ConsultationCategory/ToggleDeletedCategory${formatParams(params)}`,
        {},
        true,
    );
}

type SetCategorySortOrderParams = paths['/ConsultationCategory/SetCategorySortOrder']['post']['parameters']['query'];

export const SetCategorySortOrderUrl = '/ConsultationCategory/SetCategorySortOrder';

export const SetCategorySortOrderUrlFormat = (params: SetCategorySortOrderParams): string =>
    SetCategorySortOrderUrl + formatParams(params);

export function SetCategorySortOrder(params: SetCategorySortOrderParams): Promise<unknown> {
    return postQuery<unknown>(`/ConsultationCategory/SetCategorySortOrder${formatParams(params)}`, true);
}

type ListConsultationProductsRequestBody =
    paths['/ConsultationProduct/ListConsultationProducts']['post']['requestBody']['content']['application/json'];

type ListConsultationProductsSuccess =
    paths['/ConsultationProduct/ListConsultationProducts']['post']['responses']['200']['content']['application/json'];

export const ListConsultationProductsUrl = '/ConsultationProduct/ListConsultationProducts';

export function ListConsultationProducts(
    requestBody: ListConsultationProductsRequestBody,
): Promise<ListConsultationProductsSuccess> {
    return postJSON<ListConsultationProductsSuccess>(
        `/ConsultationProduct/ListConsultationProducts`,
        requestBody,
        true,
    );
}

type CreateConsultationProductRequestBody =
    paths['/ConsultationProduct/CreateConsultationProduct']['post']['requestBody']['content']['application/json'];

type CreateConsultationProductSuccess =
    paths['/ConsultationProduct/CreateConsultationProduct']['post']['responses']['200']['content']['application/json'];

export const CreateConsultationProductUrl = '/ConsultationProduct/CreateConsultationProduct';

export function CreateConsultationProduct(
    requestBody: CreateConsultationProductRequestBody,
): Promise<CreateConsultationProductSuccess> {
    return postJSON<CreateConsultationProductSuccess>(
        `/ConsultationProduct/CreateConsultationProduct`,
        requestBody,
        true,
    );
}

type UpdateConsultationProductParams =
    paths['/ConsultationProduct/UpdateConsultationProduct']['post']['parameters']['query'];

type UpdateConsultationProductRequestBody =
    paths['/ConsultationProduct/UpdateConsultationProduct']['post']['requestBody']['content']['application/json'];

type UpdateConsultationProductSuccess =
    paths['/ConsultationProduct/UpdateConsultationProduct']['post']['responses']['200']['content']['application/json'];

export const UpdateConsultationProductUrl = '/ConsultationProduct/UpdateConsultationProduct';

export const UpdateConsultationProductUrlFormat = (params: UpdateConsultationProductParams): string =>
    UpdateConsultationProductUrl + formatParams(params);

export function UpdateConsultationProduct(
    params: UpdateConsultationProductParams,
    requestBody: UpdateConsultationProductRequestBody,
): Promise<UpdateConsultationProductSuccess> {
    return postJSON<UpdateConsultationProductSuccess>(
        `/ConsultationProduct/UpdateConsultationProduct${formatParams(params)}`,
        requestBody,
        true,
    );
}

type GetConsultationProductParams = paths['/ConsultationProduct/GetConsultationProduct']['get']['parameters']['query'];

type GetConsultationProductSuccess =
    paths['/ConsultationProduct/GetConsultationProduct']['get']['responses']['200']['content']['application/json'];

export const GetConsultationProductUrl = '/ConsultationProduct/GetConsultationProduct';

export const GetConsultationProductUrlFormat = (params: GetConsultationProductParams): string =>
    GetConsultationProductUrl + formatParams(params);

export function GetConsultationProduct(params: GetConsultationProductParams): Promise<GetConsultationProductSuccess> {
    return fetchJSON<GetConsultationProductSuccess>(
        `/ConsultationProduct/GetConsultationProduct${formatParams(params)}`,
        {},
        true,
    );
}

type ChangeEnableConsultationProductInStoreParams =
    paths['/ConsultationProduct/ChangeEnableConsultationProductInStore']['post']['parameters']['query'];

type ChangeEnableConsultationProductInStoreSuccess =
    paths['/ConsultationProduct/ChangeEnableConsultationProductInStore']['post']['responses']['200']['content']['application/json'];

export const ChangeEnableConsultationProductInStoreUrl = '/ConsultationProduct/ChangeEnableConsultationProductInStore';

export const ChangeEnableConsultationProductInStoreUrlFormat = (
    params: ChangeEnableConsultationProductInStoreParams,
): string => ChangeEnableConsultationProductInStoreUrl + formatParams(params);

export function ChangeEnableConsultationProductInStore(
    params: ChangeEnableConsultationProductInStoreParams,
): Promise<ChangeEnableConsultationProductInStoreSuccess> {
    return postQuery<ChangeEnableConsultationProductInStoreSuccess>(
        `/ConsultationProduct/ChangeEnableConsultationProductInStore${formatParams(params)}`,
        true,
    );
}

type ChangeGloballyDisableConsultationProductParams =
    paths['/ConsultationProduct/ChangeGloballyDisableConsultationProduct']['post']['parameters']['query'];

type ChangeGloballyDisableConsultationProductSuccess =
    paths['/ConsultationProduct/ChangeGloballyDisableConsultationProduct']['post']['responses']['200']['content']['application/json'];

export const ChangeGloballyDisableConsultationProductUrl =
    '/ConsultationProduct/ChangeGloballyDisableConsultationProduct';

export const ChangeGloballyDisableConsultationProductUrlFormat = (
    params: ChangeGloballyDisableConsultationProductParams,
): string => ChangeGloballyDisableConsultationProductUrl + formatParams(params);

export function ChangeGloballyDisableConsultationProduct(
    params: ChangeGloballyDisableConsultationProductParams,
): Promise<ChangeGloballyDisableConsultationProductSuccess> {
    return postQuery<ChangeGloballyDisableConsultationProductSuccess>(
        `/ConsultationProduct/ChangeGloballyDisableConsultationProduct${formatParams(params)}`,
        true,
    );
}

type DeleteConsultationProductParams =
    paths['/ConsultationProduct/DeleteConsultationProduct']['post']['parameters']['query'];

type DeleteConsultationProductSuccess =
    paths['/ConsultationProduct/DeleteConsultationProduct']['post']['responses']['200']['content']['application/json'];

export const DeleteConsultationProductUrl = '/ConsultationProduct/DeleteConsultationProduct';

export const DeleteConsultationProductUrlFormat = (params: DeleteConsultationProductParams): string =>
    DeleteConsultationProductUrl + formatParams(params);

export function DeleteConsultationProduct(
    params: DeleteConsultationProductParams,
): Promise<DeleteConsultationProductSuccess> {
    return postQuery<DeleteConsultationProductSuccess>(
        `/ConsultationProduct/DeleteConsultationProduct${formatParams(params)}`,
        true,
    );
}

type ListConsultationProductsActiveInSelectedStoreParams =
    paths['/ConsultationProduct/ListConsultationProductsActiveInSelectedStore']['post']['parameters']['query'];

type ListConsultationProductsActiveInSelectedStoreSuccess =
    paths['/ConsultationProduct/ListConsultationProductsActiveInSelectedStore']['post']['responses']['200']['content']['application/json'];

export const ListConsultationProductsActiveInSelectedStoreUrl =
    '/ConsultationProduct/ListConsultationProductsActiveInSelectedStore';

export const ListConsultationProductsActiveInSelectedStoreUrlFormat = (
    params: ListConsultationProductsActiveInSelectedStoreParams,
): string => ListConsultationProductsActiveInSelectedStoreUrl + formatParams(params);

export function ListConsultationProductsActiveInSelectedStore(
    params: ListConsultationProductsActiveInSelectedStoreParams,
): Promise<ListConsultationProductsActiveInSelectedStoreSuccess> {
    return postQuery<ListConsultationProductsActiveInSelectedStoreSuccess>(
        `/ConsultationProduct/ListConsultationProductsActiveInSelectedStore${formatParams(params)}`,
        true,
    );
}

type SearchConsultationProductParams =
    paths['/ConsultationProduct/SearchConsultationProduct']['post']['parameters']['query'];

type SearchConsultationProductSuccess =
    paths['/ConsultationProduct/SearchConsultationProduct']['post']['responses']['200']['content']['application/json'];

export const SearchConsultationProductUrl = '/ConsultationProduct/SearchConsultationProduct';

export const SearchConsultationProductUrlFormat = (params: SearchConsultationProductParams): string =>
    SearchConsultationProductUrl + formatParams(params);

export function SearchConsultationProduct(
    params: SearchConsultationProductParams,
): Promise<SearchConsultationProductSuccess> {
    return postQuery<SearchConsultationProductSuccess>(
        `/ConsultationProduct/SearchConsultationProduct${formatParams(params)}`,
        true,
    );
}

type ListConsultationProductsByIdsRequestBody =
    paths['/ConsultationProduct/ListConsultationProductsByIds']['post']['requestBody']['content']['application/json'];

type ListConsultationProductsByIdsSuccess =
    paths['/ConsultationProduct/ListConsultationProductsByIds']['post']['responses']['200']['content']['application/json'];

export const ListConsultationProductsByIdsUrl = '/ConsultationProduct/ListConsultationProductsByIds';

export function ListConsultationProductsByIds(
    requestBody: ListConsultationProductsByIdsRequestBody,
): Promise<ListConsultationProductsByIdsSuccess> {
    return postJSON<ListConsultationProductsByIdsSuccess>(
        `/ConsultationProduct/ListConsultationProductsByIds`,
        requestBody,
        true,
    );
}

type ChangeIsNewsConsultationProductMasterParams =
    paths['/ConsultationProduct/ChangeIsNewsConsultationProductMaster']['post']['parameters']['query'];

type ChangeIsNewsConsultationProductMasterSuccess =
    paths['/ConsultationProduct/ChangeIsNewsConsultationProductMaster']['post']['responses']['200']['content']['application/json'];

export const ChangeIsNewsConsultationProductMasterUrl = '/ConsultationProduct/ChangeIsNewsConsultationProductMaster';

export const ChangeIsNewsConsultationProductMasterUrlFormat = (
    params: ChangeIsNewsConsultationProductMasterParams,
): string => ChangeIsNewsConsultationProductMasterUrl + formatParams(params);

export function ChangeIsNewsConsultationProductMaster(
    params: ChangeIsNewsConsultationProductMasterParams,
): Promise<ChangeIsNewsConsultationProductMasterSuccess> {
    return postQuery<ChangeIsNewsConsultationProductMasterSuccess>(
        `/ConsultationProduct/ChangeIsNewsConsultationProductMaster${formatParams(params)}`,
        true,
    );
}
