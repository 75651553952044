/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Consultant';

export type ConsultantNotificationFlags = components['schemas']['ConsultantNotificationFlags'];
export type Consultant = components['schemas']['Consultant'];
export type ConsultantMaybe = components['schemas']['ConsultantMaybe'];
export type EmployeeLookup = components['schemas']['EmployeeLookup'];
export type ErrorType = components['schemas']['ErrorType'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];
export type ConsultantLookup = components['schemas']['ConsultantLookup'];
export type ConsultantIdsRequest = components['schemas']['ConsultantIdsRequest'];
export type ConsultantCreateRequest = components['schemas']['ConsultantCreateRequest'];
export type ConsultantCreateResponse = components['schemas']['ConsultantCreateResponse'];
export type OperationResult = components['schemas']['OperationResult'];
export type ExternalPartner = components['schemas']['ExternalPartner'];
export type Location = components['schemas']['Location'];
export type Store = components['schemas']['Store'];
export type ConsultantModel = components['schemas']['ConsultantModel'];
export type ExternalPartnerMaybe = components['schemas']['ExternalPartnerMaybe'];
export type BookingType = components['schemas']['BookingType'];
export type ConsultantProductModel = components['schemas']['ConsultantProductModel'];
export type ConsultantEditModelResponse = components['schemas']['ConsultantEditModelResponse'];
export type ConsultantUpdateRequest = components['schemas']['ConsultantUpdateRequest'];
export type ConsultantChangeProductsRequest = components['schemas']['ConsultantChangeProductsRequest'];
export type ConsultantChangeExternalPartnerRequest = components['schemas']['ConsultantChangeExternalPartnerRequest'];
export type ConsultantModelPagingResponse = components['schemas']['ConsultantModelPagingResponse'];
export type IActiveTimeSpan = components['schemas']['IActiveTimeSpan'];
export type CalendarItemShift = components['schemas']['CalendarItemShift'];
export type CalendarItemShiftMaybe = components['schemas']['CalendarItemShiftMaybe'];
export type BookingStatus = components['schemas']['BookingStatus'];
export type CalendarItemBooking = components['schemas']['CalendarItemBooking'];
export type CalendarItemEvent = components['schemas']['CalendarItemEvent'];
export type CalendarItemEventMaybe = components['schemas']['CalendarItemEventMaybe'];
export type ConsultantCalendarItemType = components['schemas']['ConsultantCalendarItemType'];
export type ConsultantCalendarItem = components['schemas']['ConsultantCalendarItem'];
export type ConsultantCalendarModel = components['schemas']['ConsultantCalendarModel'];
export type ActiveTimeSpanViewModel = components['schemas']['ActiveTimeSpanViewModel'];
export type CreateOrUpdateConsultantShiftViewModel = components['schemas']['CreateOrUpdateConsultantShiftViewModel'];
export type ConsultantShift = components['schemas']['ConsultantShift'];
export type StoreConsultationProduct = components['schemas']['StoreConsultationProduct'];
export type ConsultationProductMasterDetailed = components['schemas']['ConsultationProductMasterDetailed'];
export type ConsultationCategory = components['schemas']['ConsultationCategory'];
export type Medium = components['schemas']['Medium'];
export type MediumMaybe = components['schemas']['MediumMaybe'];
export type ConsultationProductMaster = components['schemas']['ConsultationProductMaster'];
export type ConsultationProductDetailed = components['schemas']['ConsultationProductDetailed'];
export type AddressViewModel = components['schemas']['AddressViewModel'];
export type CreateOrUpdateEventTimeViewModel = components['schemas']['CreateOrUpdateEventTimeViewModel'];
export type EventTime = components['schemas']['EventTime'];
export type EventBookingViewModel = components['schemas']['EventBookingViewModel'];
export type OverviewItem = components['schemas']['OverviewItem'];
export type OverviewConsultant = components['schemas']['OverviewConsultant'];

type LookupEmployeeParams = paths['/Consultant/LookupEmployee']['get']['parameters']['query'];

type LookupEmployeeSuccess =
    paths['/Consultant/LookupEmployee']['get']['responses']['200']['content']['application/json'];

export const LookupEmployeeUrl = '/Consultant/LookupEmployee';

export const LookupEmployeeUrlFormat = (params: LookupEmployeeParams): string =>
    LookupEmployeeUrl + formatParams(params);

export function LookupEmployee(params: LookupEmployeeParams): Promise<LookupEmployeeSuccess> {
    return fetchJSON<LookupEmployeeSuccess>(`/Consultant/LookupEmployee${formatParams(params)}`, {}, true);
}

type LookupExternalParams = paths['/Consultant/LookupExternal']['get']['parameters']['query'];

type LookupExternalSuccess =
    paths['/Consultant/LookupExternal']['get']['responses']['200']['content']['application/json'];

export const LookupExternalUrl = '/Consultant/LookupExternal';

export const LookupExternalUrlFormat = (params: LookupExternalParams): string =>
    LookupExternalUrl + formatParams(params);

export function LookupExternal(params: LookupExternalParams): Promise<LookupExternalSuccess> {
    return fetchJSON<LookupExternalSuccess>(`/Consultant/LookupExternal${formatParams(params)}`, {}, true);
}

type SearchConsultantParams = paths['/Consultant/SearchConsultant']['get']['parameters']['query'];

type SearchConsultantSuccess =
    paths['/Consultant/SearchConsultant']['get']['responses']['200']['content']['application/json'];

export const SearchConsultantUrl = '/Consultant/SearchConsultant';

export const SearchConsultantUrlFormat = (params: SearchConsultantParams): string =>
    SearchConsultantUrl + formatParams(params);

export function SearchConsultant(params: SearchConsultantParams): Promise<SearchConsultantSuccess> {
    return fetchJSON<SearchConsultantSuccess>(`/Consultant/SearchConsultant${formatParams(params)}`, {}, true);
}

type ListConsultantsByIdsRequestBody =
    paths['/Consultant/ListConsultantsByIds']['post']['requestBody']['content']['application/json'];

type ListConsultantsByIdsSuccess =
    paths['/Consultant/ListConsultantsByIds']['post']['responses']['200']['content']['application/json'];

export const ListConsultantsByIdsUrl = '/Consultant/ListConsultantsByIds';

export function ListConsultantsByIds(
    requestBody: ListConsultantsByIdsRequestBody,
): Promise<ListConsultantsByIdsSuccess> {
    return postJSON<ListConsultantsByIdsSuccess>(`/Consultant/ListConsultantsByIds`, requestBody, true);
}

type CreateConsultantRequestBody =
    paths['/Consultant/CreateConsultant']['post']['requestBody']['content']['application/json'];

type CreateConsultantSuccess =
    paths['/Consultant/CreateConsultant']['post']['responses']['200']['content']['application/json'];

export const CreateConsultantUrl = '/Consultant/CreateConsultant';

export function CreateConsultant(requestBody: CreateConsultantRequestBody): Promise<CreateConsultantSuccess> {
    return postJSON<CreateConsultantSuccess>(`/Consultant/CreateConsultant`, requestBody, true);
}

type AssignToSelectedStoreParams = paths['/Consultant/AssignToSelectedStore']['post']['parameters']['query'];

type AssignToSelectedStoreSuccess =
    paths['/Consultant/AssignToSelectedStore']['post']['responses']['200']['content']['application/json'];

export const AssignToSelectedStoreUrl = '/Consultant/AssignToSelectedStore';

export const AssignToSelectedStoreUrlFormat = (params: AssignToSelectedStoreParams): string =>
    AssignToSelectedStoreUrl + formatParams(params);

export function AssignToSelectedStore(params: AssignToSelectedStoreParams): Promise<AssignToSelectedStoreSuccess> {
    return postQuery<AssignToSelectedStoreSuccess>(`/Consultant/AssignToSelectedStore${formatParams(params)}`, true);
}

type RemoveFromSelectedStoreParams = paths['/Consultant/RemoveFromSelectedStore']['post']['parameters']['query'];

type RemoveFromSelectedStoreSuccess =
    paths['/Consultant/RemoveFromSelectedStore']['post']['responses']['200']['content']['application/json'];

export const RemoveFromSelectedStoreUrl = '/Consultant/RemoveFromSelectedStore';

export const RemoveFromSelectedStoreUrlFormat = (params: RemoveFromSelectedStoreParams): string =>
    RemoveFromSelectedStoreUrl + formatParams(params);

export function RemoveFromSelectedStore(
    params: RemoveFromSelectedStoreParams,
): Promise<RemoveFromSelectedStoreSuccess> {
    return postQuery<RemoveFromSelectedStoreSuccess>(
        `/Consultant/RemoveFromSelectedStore${formatParams(params)}`,
        true,
    );
}

type GetConsultantDetailedParams = paths['/Consultant/GetConsultantDetailed']['get']['parameters']['query'];

type GetConsultantDetailedSuccess =
    paths['/Consultant/GetConsultantDetailed']['get']['responses']['200']['content']['application/json'];

export const GetConsultantDetailedUrl = '/Consultant/GetConsultantDetailed';

export const GetConsultantDetailedUrlFormat = (params: GetConsultantDetailedParams): string =>
    GetConsultantDetailedUrl + formatParams(params);

export function GetConsultantDetailed(params: GetConsultantDetailedParams): Promise<GetConsultantDetailedSuccess> {
    return fetchJSON<GetConsultantDetailedSuccess>(
        `/Consultant/GetConsultantDetailed${formatParams(params)}`,
        {},
        true,
    );
}

type UpdateConsultantParams = paths['/Consultant/UpdateConsultant']['post']['parameters']['query'];

type UpdateConsultantRequestBody =
    paths['/Consultant/UpdateConsultant']['post']['requestBody']['content']['application/json'];

type UpdateConsultantSuccess =
    paths['/Consultant/UpdateConsultant']['post']['responses']['200']['content']['application/json'];

export const UpdateConsultantUrl = '/Consultant/UpdateConsultant';

export const UpdateConsultantUrlFormat = (params: UpdateConsultantParams): string =>
    UpdateConsultantUrl + formatParams(params);

export function UpdateConsultant(
    params: UpdateConsultantParams,
    requestBody: UpdateConsultantRequestBody,
): Promise<UpdateConsultantSuccess> {
    return postJSON<UpdateConsultantSuccess>(`/Consultant/UpdateConsultant${formatParams(params)}`, requestBody, true);
}

type UpdateConsultantConsultationProductsRequestBody =
    paths['/Consultant/UpdateConsultantConsultationProducts']['post']['requestBody']['content']['application/json'];

export const UpdateConsultantConsultationProductsUrl = '/Consultant/UpdateConsultantConsultationProducts';

export function UpdateConsultantConsultationProducts(
    requestBody: UpdateConsultantConsultationProductsRequestBody,
): Promise<unknown> {
    return postJSON<unknown>(`/Consultant/UpdateConsultantConsultationProducts`, requestBody, true);
}

type UpdateConsultantExternalPartnersRequestBody =
    paths['/Consultant/UpdateConsultantExternalPartners']['post']['requestBody']['content']['application/json'];

export const UpdateConsultantExternalPartnersUrl = '/Consultant/UpdateConsultantExternalPartners';

export function UpdateConsultantExternalPartners(
    requestBody: UpdateConsultantExternalPartnersRequestBody,
): Promise<unknown> {
    return postJSON<unknown>(`/Consultant/UpdateConsultantExternalPartners`, requestBody, true);
}

type ToggleStoreManagerParams = paths['/Consultant/ToggleStoreManager']['post']['parameters']['query'];

type ToggleStoreManagerSuccess =
    paths['/Consultant/ToggleStoreManager']['post']['responses']['200']['content']['application/json'];

export const ToggleStoreManagerUrl = '/Consultant/ToggleStoreManager';

export const ToggleStoreManagerUrlFormat = (params: ToggleStoreManagerParams): string =>
    ToggleStoreManagerUrl + formatParams(params);

export function ToggleStoreManager(params: ToggleStoreManagerParams): Promise<ToggleStoreManagerSuccess> {
    return postQuery<ToggleStoreManagerSuccess>(`/Consultant/ToggleStoreManager${formatParams(params)}`, true);
}

type ToggleVirtualConsultantParams = paths['/Consultant/ToggleVirtualConsultant']['post']['parameters']['query'];

type ToggleVirtualConsultantSuccess =
    paths['/Consultant/ToggleVirtualConsultant']['post']['responses']['200']['content']['application/json'];

export const ToggleVirtualConsultantUrl = '/Consultant/ToggleVirtualConsultant';

export const ToggleVirtualConsultantUrlFormat = (params: ToggleVirtualConsultantParams): string =>
    ToggleVirtualConsultantUrl + formatParams(params);

export function ToggleVirtualConsultant(
    params: ToggleVirtualConsultantParams,
): Promise<ToggleVirtualConsultantSuccess> {
    return postQuery<ToggleVirtualConsultantSuccess>(
        `/Consultant/ToggleVirtualConsultant${formatParams(params)}`,
        true,
    );
}

type FixMissingUserRoleParams = paths['/Consultant/FixMissingUserRole']['post']['parameters']['query'];

type FixMissingUserRoleSuccess =
    paths['/Consultant/FixMissingUserRole']['post']['responses']['200']['content']['application/json'];

export const FixMissingUserRoleUrl = '/Consultant/FixMissingUserRole';

export const FixMissingUserRoleUrlFormat = (params: FixMissingUserRoleParams): string =>
    FixMissingUserRoleUrl + formatParams(params);

export function FixMissingUserRole(params: FixMissingUserRoleParams): Promise<FixMissingUserRoleSuccess> {
    return postQuery<FixMissingUserRoleSuccess>(`/Consultant/FixMissingUserRole${formatParams(params)}`, true);
}

type ListConsultantsParams = paths['/Consultant/ListConsultants']['get']['parameters']['query'];

type ListConsultantsSuccess =
    paths['/Consultant/ListConsultants']['get']['responses']['200']['content']['application/json'];

export const ListConsultantsUrl = '/Consultant/ListConsultants';

export const ListConsultantsUrlFormat = (params: ListConsultantsParams): string =>
    ListConsultantsUrl + formatParams(params);

export function ListConsultants(params: ListConsultantsParams): Promise<ListConsultantsSuccess> {
    return fetchJSON<ListConsultantsSuccess>(`/Consultant/ListConsultants${formatParams(params)}`, {}, true);
}

type DeleteConsultantParams = paths['/Consultant/DeleteConsultant']['post']['parameters']['query'];

type DeleteConsultantSuccess =
    paths['/Consultant/DeleteConsultant']['post']['responses']['200']['content']['application/json'];

export const DeleteConsultantUrl = '/Consultant/DeleteConsultant';

export const DeleteConsultantUrlFormat = (params: DeleteConsultantParams): string =>
    DeleteConsultantUrl + formatParams(params);

export function DeleteConsultant(params: DeleteConsultantParams): Promise<DeleteConsultantSuccess> {
    return postQuery<DeleteConsultantSuccess>(`/Consultant/DeleteConsultant${formatParams(params)}`, true);
}

type GetConsultantParams = paths['/Consultant/GetConsultant']['get']['parameters']['query'];

type GetConsultantSuccess =
    paths['/Consultant/GetConsultant']['get']['responses']['200']['content']['application/json'];

export const GetConsultantUrl = '/Consultant/GetConsultant';

export const GetConsultantUrlFormat = (params: GetConsultantParams): string => GetConsultantUrl + formatParams(params);

export function GetConsultant(params: GetConsultantParams): Promise<GetConsultantSuccess> {
    return fetchJSON<GetConsultantSuccess>(`/Consultant/GetConsultant${formatParams(params)}`, {}, true);
}

type ListCalendarItemsParams = paths['/ConsultantShift/ListCalendarItems']['get']['parameters']['query'];

type ListCalendarItemsSuccess =
    paths['/ConsultantShift/ListCalendarItems']['get']['responses']['200']['content']['application/json'];

export const ListCalendarItemsUrl = '/ConsultantShift/ListCalendarItems';

export const ListCalendarItemsUrlFormat = (params: ListCalendarItemsParams): string =>
    ListCalendarItemsUrl + formatParams(params);

export function ListCalendarItems(params: ListCalendarItemsParams): Promise<ListCalendarItemsSuccess> {
    return fetchJSON<ListCalendarItemsSuccess>(`/ConsultantShift/ListCalendarItems${formatParams(params)}`, {}, true);
}

type CreateShiftRequestBody =
    paths['/ConsultantShift/CreateShift']['post']['requestBody']['content']['application/json'];

export const CreateShiftUrl = '/ConsultantShift/CreateShift';

export function CreateShift(requestBody: CreateShiftRequestBody): Promise<unknown> {
    return postJSON<unknown>(`/ConsultantShift/CreateShift`, requestBody, true);
}

type UpdateShiftParams = paths['/ConsultantShift/UpdateShift']['post']['parameters']['query'];

type UpdateShiftRequestBody =
    paths['/ConsultantShift/UpdateShift']['post']['requestBody']['content']['application/json'];

type UpdateShiftSuccess =
    paths['/ConsultantShift/UpdateShift']['post']['responses']['200']['content']['application/json'];

export const UpdateShiftUrl = '/ConsultantShift/UpdateShift';

export const UpdateShiftUrlFormat = (params: UpdateShiftParams): string => UpdateShiftUrl + formatParams(params);

export function UpdateShift(
    params: UpdateShiftParams,
    requestBody: UpdateShiftRequestBody,
): Promise<UpdateShiftSuccess> {
    return postJSON<UpdateShiftSuccess>(`/ConsultantShift/UpdateShift${formatParams(params)}`, requestBody, true);
}

type GetShiftParams = paths['/ConsultantShift/GetShift']['get']['parameters']['query'];

type GetShiftSuccess = paths['/ConsultantShift/GetShift']['get']['responses']['200']['content']['application/json'];

export const GetShiftUrl = '/ConsultantShift/GetShift';

export const GetShiftUrlFormat = (params: GetShiftParams): string => GetShiftUrl + formatParams(params);

export function GetShift(params: GetShiftParams): Promise<GetShiftSuccess> {
    return fetchJSON<GetShiftSuccess>(`/ConsultantShift/GetShift${formatParams(params)}`, {}, true);
}

type ListAvailableConsultationProductDetailsForShiftParams =
    paths['/ConsultantShift/ListAvailableConsultationProductDetailsForShift']['get']['parameters']['query'];

type ListAvailableConsultationProductDetailsForShiftSuccess =
    paths['/ConsultantShift/ListAvailableConsultationProductDetailsForShift']['get']['responses']['200']['content']['application/json'];

export const ListAvailableConsultationProductDetailsForShiftUrl =
    '/ConsultantShift/ListAvailableConsultationProductDetailsForShift';

export const ListAvailableConsultationProductDetailsForShiftUrlFormat = (
    params: ListAvailableConsultationProductDetailsForShiftParams,
): string => ListAvailableConsultationProductDetailsForShiftUrl + formatParams(params);

export function ListAvailableConsultationProductDetailsForShift(
    params: ListAvailableConsultationProductDetailsForShiftParams,
): Promise<ListAvailableConsultationProductDetailsForShiftSuccess> {
    return fetchJSON<ListAvailableConsultationProductDetailsForShiftSuccess>(
        `/ConsultantShift/ListAvailableConsultationProductDetailsForShift${formatParams(params)}`,
        {},
        true,
    );
}

type ChangeDisableShiftParams = paths['/ConsultantShift/ChangeDisableShift']['post']['parameters']['query'];

type ChangeDisableShiftSuccess =
    paths['/ConsultantShift/ChangeDisableShift']['post']['responses']['200']['content']['application/json'];

export const ChangeDisableShiftUrl = '/ConsultantShift/ChangeDisableShift';

export const ChangeDisableShiftUrlFormat = (params: ChangeDisableShiftParams): string =>
    ChangeDisableShiftUrl + formatParams(params);

export function ChangeDisableShift(params: ChangeDisableShiftParams): Promise<ChangeDisableShiftSuccess> {
    return postQuery<ChangeDisableShiftSuccess>(`/ConsultantShift/ChangeDisableShift${formatParams(params)}`, true);
}

type DeleteShiftParams = paths['/ConsultantShift/DeleteShift']['post']['parameters']['query'];

type DeleteShiftSuccess =
    paths['/ConsultantShift/DeleteShift']['post']['responses']['200']['content']['application/json'];

export const DeleteShiftUrl = '/ConsultantShift/DeleteShift';

export const DeleteShiftUrlFormat = (params: DeleteShiftParams): string => DeleteShiftUrl + formatParams(params);

export function DeleteShift(params: DeleteShiftParams): Promise<DeleteShiftSuccess> {
    return postQuery<DeleteShiftSuccess>(`/ConsultantShift/DeleteShift${formatParams(params)}`, true);
}

type DuplicateShiftsWeeklyParams = paths['/ConsultantShift/DuplicateShiftsWeekly']['post']['parameters']['query'];

type DuplicateShiftsWeeklySuccess =
    paths['/ConsultantShift/DuplicateShiftsWeekly']['post']['responses']['200']['content']['application/json'];

export const DuplicateShiftsWeeklyUrl = '/ConsultantShift/DuplicateShiftsWeekly';

export const DuplicateShiftsWeeklyUrlFormat = (params: DuplicateShiftsWeeklyParams): string =>
    DuplicateShiftsWeeklyUrl + formatParams(params);

export function DuplicateShiftsWeekly(params: DuplicateShiftsWeeklyParams): Promise<DuplicateShiftsWeeklySuccess> {
    return postQuery<DuplicateShiftsWeeklySuccess>(
        `/ConsultantShift/DuplicateShiftsWeekly${formatParams(params)}`,
        true,
    );
}

type DuplicateShiftsDailyParams = paths['/ConsultantShift/DuplicateShiftsDaily']['post']['parameters']['query'];

type DuplicateShiftsDailySuccess =
    paths['/ConsultantShift/DuplicateShiftsDaily']['post']['responses']['200']['content']['application/json'];

export const DuplicateShiftsDailyUrl = '/ConsultantShift/DuplicateShiftsDaily';

export const DuplicateShiftsDailyUrlFormat = (params: DuplicateShiftsDailyParams): string =>
    DuplicateShiftsDailyUrl + formatParams(params);

export function DuplicateShiftsDaily(params: DuplicateShiftsDailyParams): Promise<DuplicateShiftsDailySuccess> {
    return postQuery<DuplicateShiftsDailySuccess>(`/ConsultantShift/DuplicateShiftsDaily${formatParams(params)}`, true);
}

type CreateEventTimeRequestBody =
    paths['/EventTime/CreateEventTime']['post']['requestBody']['content']['application/json'];

export const CreateEventTimeUrl = '/EventTime/CreateEventTime';

export function CreateEventTime(requestBody: CreateEventTimeRequestBody): Promise<unknown> {
    return postJSON<unknown>(`/EventTime/CreateEventTime`, requestBody, true);
}

type UpdateEventTimeParams = paths['/EventTime/UpdateEventTime']['post']['parameters']['query'];

type UpdateEventTimeRequestBody =
    paths['/EventTime/UpdateEventTime']['post']['requestBody']['content']['application/json'];

export const UpdateEventTimeUrl = '/EventTime/UpdateEventTime';

export const UpdateEventTimeUrlFormat = (params: UpdateEventTimeParams): string =>
    UpdateEventTimeUrl + formatParams(params);

export function UpdateEventTime(
    params: UpdateEventTimeParams,
    requestBody: UpdateEventTimeRequestBody,
): Promise<unknown> {
    return postJSON<unknown>(`/EventTime/UpdateEventTime${formatParams(params)}`, requestBody, true);
}

type GetEventTimeParams = paths['/EventTime/GetEventTime']['get']['parameters']['query'];

type GetEventTimeSuccess = paths['/EventTime/GetEventTime']['get']['responses']['200']['content']['application/json'];

export const GetEventTimeUrl = '/EventTime/GetEventTime';

export const GetEventTimeUrlFormat = (params: GetEventTimeParams): string => GetEventTimeUrl + formatParams(params);

export function GetEventTime(params: GetEventTimeParams): Promise<GetEventTimeSuccess> {
    return fetchJSON<GetEventTimeSuccess>(`/EventTime/GetEventTime${formatParams(params)}`, {}, true);
}

type ListAvailableConsultationProductDetailsForEventTimeParams =
    paths['/EventTime/ListAvailableConsultationProductDetailsForEventTime']['get']['parameters']['query'];

type ListAvailableConsultationProductDetailsForEventTimeSuccess =
    paths['/EventTime/ListAvailableConsultationProductDetailsForEventTime']['get']['responses']['200']['content']['application/json'];

export const ListAvailableConsultationProductDetailsForEventTimeUrl =
    '/EventTime/ListAvailableConsultationProductDetailsForEventTime';

export const ListAvailableConsultationProductDetailsForEventTimeUrlFormat = (
    params: ListAvailableConsultationProductDetailsForEventTimeParams,
): string => ListAvailableConsultationProductDetailsForEventTimeUrl + formatParams(params);

export function ListAvailableConsultationProductDetailsForEventTime(
    params: ListAvailableConsultationProductDetailsForEventTimeParams,
): Promise<ListAvailableConsultationProductDetailsForEventTimeSuccess> {
    return fetchJSON<ListAvailableConsultationProductDetailsForEventTimeSuccess>(
        `/EventTime/ListAvailableConsultationProductDetailsForEventTime${formatParams(params)}`,
        {},
        true,
    );
}

type ChangeDisableEventTimeParams = paths['/EventTime/ChangeDisableEventTime']['post']['parameters']['query'];

export const ChangeDisableEventTimeUrl = '/EventTime/ChangeDisableEventTime';

export const ChangeDisableEventTimeUrlFormat = (params: ChangeDisableEventTimeParams): string =>
    ChangeDisableEventTimeUrl + formatParams(params);

export function ChangeDisableEventTime(params: ChangeDisableEventTimeParams): Promise<unknown> {
    return postQuery<unknown>(`/EventTime/ChangeDisableEventTime${formatParams(params)}`, true);
}

type DeleteEventTimeParams = paths['/EventTime/DeleteEventTime']['post']['parameters']['query'];

export const DeleteEventTimeUrl = '/EventTime/DeleteEventTime';

export const DeleteEventTimeUrlFormat = (params: DeleteEventTimeParams): string =>
    DeleteEventTimeUrl + formatParams(params);

export function DeleteEventTime(params: DeleteEventTimeParams): Promise<unknown> {
    return postQuery<unknown>(`/EventTime/DeleteEventTime${formatParams(params)}`, true);
}

type ListEventBookingsParams = paths['/EventTime/ListEventBookings']['get']['parameters']['query'];

type ListEventBookingsSuccess =
    paths['/EventTime/ListEventBookings']['get']['responses']['200']['content']['application/json'];

export const ListEventBookingsUrl = '/EventTime/ListEventBookings';

export const ListEventBookingsUrlFormat = (params: ListEventBookingsParams): string =>
    ListEventBookingsUrl + formatParams(params);

export function ListEventBookings(params: ListEventBookingsParams): Promise<ListEventBookingsSuccess> {
    return fetchJSON<ListEventBookingsSuccess>(`/EventTime/ListEventBookings${formatParams(params)}`, {}, true);
}

type ListOverviewItemsParams = paths['/Overview/ListOverviewItems']['get']['parameters']['query'];

type ListOverviewItemsSuccess =
    paths['/Overview/ListOverviewItems']['get']['responses']['200']['content']['application/json'];

export const ListOverviewItemsUrl = '/Overview/ListOverviewItems';

export const ListOverviewItemsUrlFormat = (params: ListOverviewItemsParams): string =>
    ListOverviewItemsUrl + formatParams(params);

export function ListOverviewItems(params: ListOverviewItemsParams): Promise<ListOverviewItemsSuccess> {
    return fetchJSON<ListOverviewItemsSuccess>(`/Overview/ListOverviewItems${formatParams(params)}`, {}, true);
}
