import { Action, Reducer } from 'redux';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ConfirmModalState {
    isOpen: boolean;
    content?: string;
    headline?: string;
    onConfirm?: () => void;
    onDeny?: () => void;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface ConfirmChoiceActionProps {
    content?: string;
    headline?: string;
    onConfirm?: () => void;
    onDeny?: () => void;
}

interface ConfirmChoiceAction extends ConfirmChoiceActionProps {
    type: 'ConfirmChoiceAction';
}

interface CloseConfirmAction {
    type: 'CloseConfirmAction';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ConfirmChoiceAction | CloseConfirmAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ConfirmChoiceAction:
        (newState: ConfirmChoiceActionProps): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            dispatch({ type: 'ConfirmChoiceAction', ...newState });
        },
    CloseConfirmAction:
        (): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            dispatch({ type: 'CloseConfirmAction' });
        },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: ConfirmModalState = { isOpen: false };

export const reducer: Reducer<ConfirmModalState> = (
    state: ConfirmModalState | undefined,
    incomingAction: Action,
): ConfirmModalState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ConfirmChoiceAction':
            return {
                isOpen: true,
                content: action.content,
                headline: action.headline,
                onConfirm: action.onConfirm,
                onDeny: action.onDeny,
            };
        case 'CloseConfirmAction':
            return {
                isOpen: false,
            };
        default:
            break;
    }

    return state;
};
