import React, { CSSProperties } from 'react';
import styled, { FlattenSimpleInterpolation, css } from 'styled-components';
import { Grid, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import {
    ConsultantCalendarItem,
    CalendarItemShift,
    CalendarItemBooking,
    CalendarItemEvent,
    ConsultantCalendarItemType,
} from 'autogen/swagger/Consultant';
import moment from 'moment';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import CalendarItemBookingRender from './CalendarItemBookingRender';
import CalendarItemEventRender from './CalendarItemEventRender';

interface IWrapperProps {
    backgroundColor: string;
    borderColor: string;
}

const Wrapper = styled.div<IWrapperProps>`
    margin-bottom: 10px;
    padding: 5px;
    cursor: pointer;
    max-width: 100%;
    ${({ backgroundColor }) =>
        backgroundColor !== 'transparent' &&
        css`
            box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        `}
    ${({ backgroundColor }): FlattenSimpleInterpolation | null =>
        backgroundColor
            ? css`
                  background: ${backgroundColor};
              `
            : null};
    ${({ borderColor }): FlattenSimpleInterpolation | null =>
        borderColor
            ? css`
                  border: 1px solid ${borderColor};
              `
            : null};
`;

function getWrapperStyling(itemType: ConsultantCalendarItemType): CSSProperties {
    const base: CSSProperties = {
        position: 'relative',
    };
    if (itemType === 'Bookings') {
        return {
            ...base,
            padding: 0,
        };
    }

    return {
        ...base,
    };
}

interface ICalendarItemsColors {
    backgroundColor: string;
    borderColor: string;
}

function getColors(item: ConsultantCalendarItem, selectedStoreId: number, role: AuthRole): ICalendarItemsColors {
    const isSelectedStore =
        IsInRole(role, 'ConsultantRole') ||
        (item.shift && item.shift.storeId === selectedStoreId) ||
        (item.eventTime && item.eventTime.storeId === selectedStoreId);

    switch (item.type) {
        case 'Break':
            return {
                backgroundColor: '#eefcf5',
                borderColor: '#9bd3c0',
            };
        case 'ActiveShift':
            return {
                backgroundColor: isSelectedStore ? '#D1FAE5' : '#dbdbdb',
                borderColor: isSelectedStore ? '#10B981' : '#808080',
            };
        case 'InActiveShift':
            return {
                backgroundColor: isSelectedStore ? '#fdbb84' : '#dbdbdb',
                borderColor: isSelectedStore ? '#ED6C02' : '#ED6C02',
            };
        case 'ActiveEventTime':
            if (item.eventTime?.isDeleted) {
                return {
                    backgroundColor: '#ff9494',
                    borderColor: '#fa4d32',
                };
            }

            return {
                backgroundColor: isSelectedStore ? '#E0E7FF' : '#dbdbdb',
                borderColor: isSelectedStore ? '#a6b6ff' : '#808080',
            };
        case 'InActiveEventTime':
            return {
                backgroundColor: isSelectedStore ? '#fdf3cb' : '#dbdbdb',
                borderColor: isSelectedStore ? '#7a0017' : '#7a0017',
            };
        default:
            return {
                backgroundColor: 'transparent',
                borderColor: 'transparent',
            };
    }
}

interface ICalendarItem {
    item: ConsultantCalendarItem;
    onShiftItemClick?: (shiftId: CalendarItemShift) => void;
    selectedStoreId: number;
    onBookingItemClick: (booking: CalendarItemBooking) => void;
    onEventTimeItemClick: (eventTime: CalendarItemEvent) => void;
    hideStoreName?: boolean;
    role: AuthRole;
}

export function CalendarItem(props: ICalendarItem): JSX.Element {
    const {
        item,
        selectedStoreId,
        onShiftItemClick,
        onBookingItemClick,
        hideStoreName = false,
        role,
        onEventTimeItemClick,
    } = props;
    const { t } = useTypedTranslation();

    const colors = getColors(item, selectedStoreId, role);

    function onItemClicked(): void {
        if (item.shift && onShiftItemClick) {
            onShiftItemClick(item.shift);
        }
        if (item.eventTime && onEventTimeItemClick && !item.eventTime.isDeleted) {
            onEventTimeItemClick(item.eventTime);
        }
    }

    const wrapperStyling = getWrapperStyling(item.type);

    return (
        <Wrapper
            backgroundColor={colors.backgroundColor}
            borderColor={colors.borderColor}
            onClick={onItemClicked}
            style={wrapperStyling}
        >
            {(item.type === 'ActiveShift' || item.type === 'InActiveShift') && item.shift && (
                <>
                    <Grid item>
                        <Typography variant="h6">
                            {moment(item.shift.activeTime.activeFromDate).format('HH:mm')} -{' '}
                            {moment(item.shift.activeTime.activeToDate).format('HH:mm')}
                        </Typography>
                    </Grid>
                    {!hideStoreName && (
                        <Grid item>
                            <Typography variant="body2">{item.shift.storeName}</Typography>
                        </Grid>
                    )}
                </>
            )}
            {(item.type === 'ActiveEventTime' || item.type === 'InActiveEventTime') && item.eventTime && (
                <CalendarItemEventRender item={item} hideStoreName={hideStoreName} />
            )}
            {item.type === 'Break' && (
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Typography variant="h6">
                                {moment(item.activeTime.activeFromDate).format('HH:mm')} -{' '}
                                {moment(item.activeTime.activeToDate).format('HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" style={{ marginRight: '15px', fontSize: '14px' }}>
                                {t('Shared', 'Break')}
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {item.bookings.map((booking) => (
                <CalendarItemBookingRender
                    key={booking.id}
                    booking={booking}
                    hideStoreName={hideStoreName}
                    selectedStoreId={selectedStoreId}
                    role={role}
                    onBookingItemClick={onBookingItemClick}
                    itemType={item.type}
                />
            ))}
        </Wrapper>
    );
}

export default CalendarItem;
