import React from 'react';
import { Grid, useMediaQuery, Theme, Typography, Backdrop, Button, CircularProgress } from '@mui/material';
import { getDates } from 'helpers/dateTime';
import styled from 'styled-components';
import {
    CalendarItemBooking,
    CalendarItemEvent,
    CalendarItemShift,
    ConsultantCalendarItem,
} from 'autogen/swagger/Consultant';
import { Moment } from 'moment';
import { useTypedTranslation } from 'translations';
import { AuthRole } from 'autogen/swagger/Users';
import CalendarDate from './CalendarDate';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const Wrapper = styled(Grid)`
    && {
        background: white;
        box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
        border: 1px solid #9ca3af;
    }
`;
interface IWeekCalendarProps {
    fromDate: Moment;
    toDate: Moment;
    items: ConsultantCalendarItem[];
    itemsLoading: boolean;
    showingMultiple?: boolean;
    selectedWeek?: Moment;
    selectedDay?: Moment;
    selectedStoreId: number;
    copyShiftsWeeklyEnabled?: boolean;
    copyShiftsDailyEnabled?: boolean;
    onShiftItemClick: (shift: CalendarItemShift) => void;
    onBookingItemClick: (booking: CalendarItemBooking) => void;
    onEventTimeItemClick: (eventTime: CalendarItemEvent) => void;
    setSelectedWeek: (selectedWeek: Moment) => void;
    setSelectedDay: (selectedDay: Moment) => void;
    copyShift: (fromDate: Moment, toDate: Moment) => void;
    setCopyShiftsWeekly: (showCopyWeekOverlay: boolean) => void;
    setCopyShiftsDaily: (showCopyDailyOverlay: boolean) => void;
    role: AuthRole;
}

export function WeekCalendar(props: IWeekCalendarProps): JSX.Element {
    const {
        fromDate,
        toDate,
        showingMultiple = false,
        copyShiftsWeeklyEnabled = false,
        copyShiftsDailyEnabled = false,
        selectedWeek,
        selectedDay,
        selectedStoreId,
        items,
        onShiftItemClick,
        onBookingItemClick,
        setSelectedWeek,
        setSelectedDay,
        copyShift,
        setCopyShiftsWeekly,
        setCopyShiftsDaily,
        itemsLoading,
        role,
        onEventTimeItemClick,
    } = props;

    const { t, tf } = useTypedTranslation();

    const isSelected = selectedWeek?.isSame(fromDate);

    const dates = getDates(fromDate, toDate);

    const largeScreen = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

    return (
        <Grid item container style={showingMultiple ? { marginBottom: '20px', position: 'relative' } : {}}>
            <Wrapper container item xs direction={largeScreen ? 'row' : 'column'}>
                {dates.map((date) => (
                    <CalendarDate
                        key={date.toString()}
                        date={date}
                        copyShift={copyShift}
                        calendarItems={items}
                        selectedStoreId={selectedStoreId}
                        onShiftItemClick={onShiftItemClick}
                        onBookingItemClick={onBookingItemClick}
                        onEventTimeItemClick={onEventTimeItemClick}
                        setSelectedDate={setSelectedDay}
                        setCopyShiftDaily={setCopyShiftsDaily}
                        selectedDay={selectedDay}
                        copyShiftsDailyEnabled={copyShiftsDailyEnabled}
                        itemsLoading={itemsLoading}
                        role={role}
                    />
                ))}
            </Wrapper>
            {showingMultiple && (
                <Typography
                    variant="body2"
                    style={{
                        position: 'absolute',
                        transform: 'rotate(90deg)',
                        right: '-30px',
                        top: '22px',
                        fontWeight: 'bold',
                    }}
                >
                    {tf({ weekNumber: fromDate.isoWeek() }, 'Calendar', 'WeekNumber')}
                </Typography>
            )}
            <Backdrop
                style={{
                    position: 'absolute',
                    zIndex: 100,
                    backgroundColor: isSelected && !itemsLoading ? '#35ef3633' : '',
                }}
                open={copyShiftsWeeklyEnabled || itemsLoading}
                onClick={(): void => setCopyShiftsWeekly(false)}
            >
                <Grid
                    container
                    justifyContent="center"
                    onClick={(e): void => {
                        e.stopPropagation();
                    }}
                >
                    {itemsLoading ? (
                        <Grid item>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <>
                            {copyShiftsWeeklyEnabled && isSelected !== undefined && !isSelected && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        style={{ marginRight: '15px' }}
                                        onClick={(): void => copyShift(fromDate, toDate)}
                                    >
                                        {tf(
                                            {
                                                weekNumberOne: selectedWeek ? selectedWeek.isoWeek() : 0,
                                                weekNumberTwo: fromDate.isoWeek(),
                                            },
                                            'Calendar',
                                            'CopyWeekToWeek',
                                        )}
                                    </Button>
                                </Grid>
                            )}
                            {copyShiftsWeeklyEnabled && !isSelected && (
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        size="large"
                                        onClick={(): void => setSelectedWeek(fromDate)}
                                    >
                                        {t('Shared', 'Select')}
                                    </Button>
                                </Grid>
                            )}
                        </>
                    )}
                </Grid>
            </Backdrop>
        </Grid>
    );
}

export default WeekCalendar;
