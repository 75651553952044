import { Action, Reducer } from 'redux';
import { GetUserState, UserStateViewModel, StoreSelectViewModel, AuthRole } from 'autogen/swagger/Users';
import { AppThunkAction } from '.';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface UserState {
    isLoading: boolean;
    username?: string;
    role: AuthRole;
    selectedStoreId?: number;
    selectableStores: StoreSelectViewModel[];
    consultantName?: string;
    consultantId?: number;
    stateLoaded?: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestUserStateAction {
    type: 'RequestUserStateAction';
}

interface ReceiveUserStateAction {
    type: 'ReceiveUserStateAction';
    userState: UserStateViewModel;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestUserStateAction | ReceiveUserStateAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestUserState:
        (): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            // Only load data if it's something we don't already have (and are not already loading)
            GetUserState().then((data) => {
                dispatch({
                    type: 'ReceiveUserStateAction',
                    userState: data,
                });
            });

            dispatch({ type: 'RequestUserStateAction' });
        },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: UserState = { isLoading: false, selectableStores: [], role: 'Unknown' };

export const reducer: Reducer<UserState> = (state: UserState | undefined, incomingAction: Action): UserState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'RequestUserStateAction':
            return { ...state, isLoading: true };
        case 'ReceiveUserStateAction':
            return {
                isLoading: false,
                username: action.userState.username ?? '',
                role: action.userState.role,
                selectedStoreId: action.userState.selectedStoreId,
                selectableStores: action.userState.selectableStores
                    ? action.userState.selectableStores.sort((x) => x.id)
                    : [],
                consultantName: action.userState.consultantName,
                consultantId: action.userState.consultantId,
                stateLoaded: true,
            };
        default:
            break;
    }

    return state;
};
