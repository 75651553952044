import { Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import { Add, RemoveCircle } from '@mui/icons-material';
import { IActiveTimeSpan } from 'autogen/swagger/Consultant';
import { setTime } from 'helpers/dateTime';
import moment, { Moment } from 'moment';
import React, { useEffect, useState } from 'react';
import ActiveTimeSpan from './ActiveTimeSpan';

interface IActiveTimeSpanLine {
    id: number;
    activeTimeSpan: IActiveTimeSpan;
}

interface IDynamicActiveTimeSpan {
    textField?: string;
    day: Moment;
    onChange: (breaks: IActiveTimeSpan[]) => void;
    error?: boolean;
    helperText?: string;
}

export function DynamicActiveTimeSpan(props: IDynamicActiveTimeSpan): JSX.Element {
    const { textField, day, onChange, error = false, helperText = '' } = props;

    const [timeSpans, setTimeSpans] = useState<IActiveTimeSpanLine[]>([]);
    const [internalCounter, setInteralCounter] = useState(0);

    function addSpan(): void {
        const newSpan: IActiveTimeSpanLine = {
            id: internalCounter,
            activeTimeSpan: {
                activeFromDate: '',
                activeToDate: '',
            },
        };
        setInteralCounter(internalCounter + 1);
        setTimeSpans([...timeSpans, newSpan]);
    }

    useEffect(() => {
        const spans = timeSpans.map((x) => x.activeTimeSpan);
        onChange(spans);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeSpans]);

    useEffect(() => {
        if (timeSpans.some((x) => !moment(x.activeTimeSpan.activeFromDate).isSame(day, 'day'))) {
            const newSpans = [...timeSpans];

            newSpans.forEach((x) => {
                x.activeTimeSpan = {
                    activeFromDate: setTime(day, moment(x.activeTimeSpan.activeFromDate)).toISOString(true),
                    activeToDate: setTime(day, moment(x.activeTimeSpan.activeToDate)).toISOString(true),
                };
            });

            setTimeSpans(newSpans);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [day]);

    function removeSpan(id: number): void {
        let list = [...timeSpans];
        list = list.filter((x) => x.id !== id);
        setTimeSpans(list);
    }

    function handleChange(span: IActiveTimeSpan, id: number): void {
        const list = [...timeSpans];
        const index = list.findIndex((x) => x.id === id);

        list[index].activeTimeSpan = span;

        setTimeSpans(list);
    }

    return (
        <Grid item xs={12}>
            <Grid container item xs={12} style={{ justifyContent: 'center' }}>
                <Button variant="contained" color="primary" onClick={(): void => addSpan()}>
                    <Add /> {textField}
                </Button>
            </Grid>

            {timeSpans.length > 0 && <Divider style={{ width: '100%', marginTop: '20px' }} />}
            {timeSpans.map((span) => (
                <Grid
                    key={span.id}
                    item
                    xs={12}
                    container
                    direction="row"
                    style={{ justifyContent: 'space-between' }}
                    alignItems="center"
                >
                    <Grid item xs={11}>
                        <ActiveTimeSpan
                            timeSpan={span.activeTimeSpan}
                            startDate={day}
                            showDaySelection={false}
                            onChange={(d: IActiveTimeSpan): void => {
                                handleChange(d, span.id);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label="delete"
                            color="secondary"
                            onClick={(): void => removeSpan(span.id)}
                            size="large"
                        >
                            <RemoveCircle fontSize="large" />
                        </IconButton>
                    </Grid>
                </Grid>
            ))}
            {error && (
                <Grid container item xs={12} style={{ marginTop: '5px' }}>
                    <Typography color="error" variant="body2">
                        {helperText}
                    </Typography>
                </Grid>
            )}
            {timeSpans.length > 0 && <Divider style={{ width: '100%', marginTop: '20px' }} />}
        </Grid>
    );
}

export default DynamicActiveTimeSpan;
