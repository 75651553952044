import React, { useState } from 'react';
import { ConsultantEditModelResponse, ToggleStoreManager, ToggleVirtualConsultant } from 'autogen/swagger/Consultant';
import { Grid, Checkbox, Button, CircularProgress, Typography } from '@mui/material';
import { useTypedTranslation } from 'translations';
import { showMessage } from 'components/messages/Message';
import { ConfirmChoice } from 'components/modal/ConfirmModal';

interface IVirtualConsultantEditProps {
    consultant: ConsultantEditModelResponse;
}

export function StoreManagerEdit(props: IVirtualConsultantEditProps): JSX.Element {
    const { consultant } = props;
    const { t, tf } = useTypedTranslation();
    const [loading, setLoading] = useState(false);
    const [isVirtual, setIsVirtual] = useState(!!consultant.bambuserUserId);

    const name = `${consultant.baseData.firstName} ${consultant.baseData.lastName}`;

    async function toggleStoreManager(): Promise<void> {
        const confirmTrans = isVirtual ? 'ConfirmRemove' : 'ConfirmCreate';
        ConfirmChoice({
            content: tf({ name }, 'Consultant', 'Virtual', confirmTrans),
            onConfirm: async () => {
                try {
                    setLoading(true);
                    const result = await ToggleVirtualConsultant({
                        consultantId: consultant.baseData.id,
                        enable: !isVirtual,
                    });
                    if (result.success) {
                        setIsVirtual(!isVirtual);
                        showMessage({
                            content: t('Consultant', 'Virtual', 'Updated'),
                            severity: 'success',
                        });
                    }
                } finally {
                    setLoading(false);
                }
            },
        });
    }

    return (
        <Grid container alignItems="center">
            <Checkbox checked={isVirtual} disabled />
            <Typography> {t('Consultant', 'Virtual', 'Is')}</Typography>
            <Button
                variant="contained"
                color="primary"
                disabled={loading}
                style={{ marginLeft: '20px' }}
                onClick={toggleStoreManager}
            >
                {t('Consultant', 'Virtual', isVirtual ? 'Remove' : 'Create')}
                {loading && <CircularProgress style={{ marginLeft: '10px' }} size={20} />}
            </Button>
        </Grid>
    );
}

export default StoreManagerEdit;
