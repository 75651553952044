/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    fetchJSON,
    postFormData,
    formatParams,
    postJSON,
    postQuery,
    deleteQuery,
    downloadFile,
    postDownloadFile,
} from 'helpers/fetch';
import { components, paths } from './Media';

export type ErrorType = components['schemas']['ErrorType'];
export type StringMaybe = components['schemas']['StringMaybe'];
export type ApiErrorModel = components['schemas']['ApiErrorModel'];
export type ValidationErrorType = components['schemas']['ValidationErrorType'];
export type ValidationError = components['schemas']['ValidationError'];
export type ValidationResultModel = components['schemas']['ValidationResultModel'];

type UploadFileRequestBody = {
    file: File;
};

type UploadFileSuccess = paths['/Medium/UploadFile']['post']['responses']['200']['content']['application/json'];

export const UploadFileUrl = '/Medium/UploadFile';

export function UploadFile(requestBody: UploadFileRequestBody): Promise<UploadFileSuccess> {
    return postFormData<UploadFileSuccess>(`/Medium/UploadFile`, requestBody, true);
}

type GetMediumThumbnailUrlParams = paths['/Medium/GetMediumThumbnailUrl']['get']['parameters']['query'];

type GetMediumThumbnailUrlSuccess =
    paths['/Medium/GetMediumThumbnailUrl']['get']['responses']['200']['content']['application/json'];

export const GetMediumThumbnailUrlUrl = '/Medium/GetMediumThumbnailUrl';

export const GetMediumThumbnailUrlUrlFormat = (params: GetMediumThumbnailUrlParams): string =>
    GetMediumThumbnailUrlUrl + formatParams(params);

export function GetMediumThumbnailUrl(params: GetMediumThumbnailUrlParams): Promise<GetMediumThumbnailUrlSuccess> {
    return fetchJSON<GetMediumThumbnailUrlSuccess>(`/Medium/GetMediumThumbnailUrl${formatParams(params)}`, {}, true);
}
