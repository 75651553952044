import React from 'react';
import { Grid, TextField } from '@mui/material';
import { nameof } from 'ts-simple-nameof';
import { AddressViewModel } from 'autogen/swagger/Consultant';
import { useTypedTranslation } from 'translations';

interface IAddressInputProps {
    address: AddressViewModel;
    onChange: (add: AddressViewModel) => void;
    isError: (param: string) => boolean;
    getErrorText: (param: string) => string;
}

export function AddressInput(props: IAddressInputProps): JSX.Element {
    const { address, onChange, isError, getErrorText } = props;
    const { t } = useTypedTranslation();

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <TextField
                    label={t('Shared', 'Address', 'LocationName')}
                    type="string"
                    fullWidth
                    value={address.locationName}
                    variant="outlined"
                    error={isError(nameof<AddressViewModel>((x) => x.locationName))}
                    helperText={getErrorText(nameof<AddressViewModel>((x) => x.locationName))}
                    onChange={(e): void => onChange({ ...address, locationName: e.target.value })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    label={t('Shared', 'Address', 'StreetName')}
                    type="string"
                    fullWidth
                    value={address.streetName}
                    variant="outlined"
                    error={isError(nameof<AddressViewModel>((x) => x.streetName))}
                    helperText={getErrorText(nameof<AddressViewModel>((x) => x.streetName))}
                    onChange={(e): void => onChange({ ...address, streetName: e.target.value })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={t('Shared', 'Address', 'ZipCode')}
                    type="string"
                    fullWidth
                    value={address.zipCode}
                    variant="outlined"
                    error={isError(nameof<AddressViewModel>((x) => x.zipCode))}
                    helperText={getErrorText(nameof<AddressViewModel>((x) => x.zipCode))}
                    onChange={(e): void => onChange({ ...address, zipCode: e.target.value })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={6}>
                <TextField
                    label={t('Shared', 'Address', 'City')}
                    type="string"
                    fullWidth
                    value={address.cityName}
                    variant="outlined"
                    error={isError(nameof<AddressViewModel>((x) => x.cityName))}
                    helperText={getErrorText(nameof<AddressViewModel>((x) => x.cityName))}
                    onChange={(e): void => onChange({ ...address, cityName: e.target.value })}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
        </Grid>
    );
}

export default AddressInput;
