import React from 'react';
import { Typography, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { IFilter, objectType, valueType } from '../types';

interface IRadioButtonsProps<T extends objectType> {
    filter: IFilter<T>;
    value: valueType;
    handleChange: (filter: IFilter<T>, val: string[]) => void;
}

export function RadioButtons<T extends objectType>(props: IRadioButtonsProps<T>): JSX.Element {
    const { filter, value, handleChange } = props;
    const { options, getText, getValue } = filter;

    if (!options || !getText || !getValue) {
        return <Typography>Invalid data</Typography>;
    }

    return (
        <ToggleButtonGroup
            value={value}
            exclusive
            color="info"
            onChange={(event, newVal) => !!newVal && handleChange(filter, newVal)}
            style={{ paddingTop: '8px', paddingBottom: '8px', marginBottom: '8px', marginTop: '16px' }}
        >
            {options &&
                options.map((x) => (
                    <ToggleButton key={getValue(x)} value={getValue(x)}>
                        {getText(x)}
                    </ToggleButton>
                ))}
        </ToggleButtonGroup>
    );
}

export default RadioButtons;
