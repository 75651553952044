import React, { CSSProperties } from 'react';
import moment from 'moment';
import { Storefront, VideocamOutlined } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { formatConsultationProductName } from 'helpers/string';
import { CalendarItemBooking, ConsultantCalendarItemType } from 'autogen/swagger/Consultant';
import { AuthRole } from 'autogen/swagger/Users';
import { IsInRole } from 'helpers/userHelper';
import styled from 'styled-components';
import { useTypedTranslation } from 'translations';
import CalendarItemChip from './CalendarItemChip';

const BookingItem = styled(Grid)`
    background: #f9fafb;
    border: 1px solid #e9eaeb;
    padding: 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    margin-bottom: 5px;
`;

function getBookingStyling(
    item: CalendarItemBooking,
    selectedStoreId: number,
    role: AuthRole,
    itemType: ConsultantCalendarItemType,
): CSSProperties {
    const isSelectedStore = IsInRole(role, 'ConsultantRole') || item.storeId === selectedStoreId;

    const base: CSSProperties = {
        position: 'relative',
    };
    if (item.status === 'UserWasAbsent') {
        return {
            ...base,
            backgroundColor: '#f9fafb',
            borderColor: '#fC3636',
            borderWidth: '2px',
            opacity: isSelectedStore ? '1' : '0.5',
        };
    }
    if (itemType === 'ActiveShift' || itemType === 'InActiveShift') {
        return {
            ...base,
        };
    }

    return {
        ...base,
        backgroundColor: '#f9fafb',
        borderColor: '#e9eaeb',
        opacity: isSelectedStore ? '1' : '0.5',
    };
}

interface ICalendarItemBookingRenderProps {
    booking: CalendarItemBooking;
    hideStoreName: boolean;
    selectedStoreId: number;
    role: AuthRole;
    onBookingItemClick: (booking: CalendarItemBooking) => void;
    itemType: ConsultantCalendarItemType;
}

export function CalendarItemBookingRender(props: ICalendarItemBookingRenderProps): JSX.Element {
    const { booking, hideStoreName, selectedStoreId, role, onBookingItemClick, itemType } = props;
    const { t } = useTypedTranslation();
    const bookingStyling = getBookingStyling(booking, selectedStoreId, role, itemType);
    const minutes = moment.duration(booking.duration).asMinutes().toString();
    const blockingMinutes = moment.duration(booking.blocking).asMinutes();

    const iconStyle: React.CSSProperties = { position: 'absolute', right: '5px', top: '5px' };

    const showMemberPotential = booking?.isBrandClubRelevance || booking?.isPlusRelevance;

    return (
        <BookingItem
            item
            onClick={(e): void => {
                e.stopPropagation();
                onBookingItemClick(booking);
            }}
            style={bookingStyling}
        >
            <Grid container>
                {booking.isVirtual ? <VideocamOutlined style={iconStyle} /> : <Storefront style={iconStyle} />}
                <Grid item xs={12}>
                    <Typography variant="h6" style={{ paddingRight: '25px', lineHeight: 1.4 }}>
                        {formatConsultationProductName(booking.consultationProductName, booking.externalPartnerName)}
                    </Typography>
                </Grid>
                {!hideStoreName && itemType === 'Bookings' && (
                    <Grid item xs={12}>
                        <Typography variant="body2">{booking.storeName}</Typography>
                    </Grid>
                )}

                <Grid item xs={12}>
                    <Grid container spacing={1}>
                        <Grid item xs={6}>
                            <Typography variant="body2">
                                {moment(booking.activeTime.activeFromDate).format('HH:mm')}-
                                {moment(booking.activeTime.activeToDate).format('HH:mm')}
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-end">
                                <Typography title={t('Calendar', 'Duration')} variant="body2">
                                    {minutes}
                                </Typography>
                                {!!blockingMinutes && (
                                    <Typography title={t('Calendar', 'Blocking')} variant="body2">
                                        &nbsp;+&nbsp;{blockingMinutes}
                                    </Typography>
                                )}
                                <Typography variant="body2">&nbsp;{t('Calendar', 'Minutes')}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                {(!!booking.firstName || !!booking.lastName) && (
                    <Grid item xs={12}>
                        <Typography variant="body2">
                            {booking.firstName ?? ''} {booking.lastName ?? ''}
                        </Typography>
                    </Grid>
                )}
                {showMemberPotential && (
                    <Grid container item columnSpacing="4px">
                        {booking?.isPlusRelevance && (
                            <Grid item>
                                <CalendarItemChip label={t('Booking', 'IsPlusRelevance')} />
                            </Grid>
                        )}
                        {booking?.isBrandClubRelevance && (
                            <Grid item>
                                <CalendarItemChip label={t('Booking', 'IsBrandClubMember')} />
                            </Grid>
                        )}
                    </Grid>
                )}
            </Grid>
        </BookingItem>
    );
}

export default CalendarItemBookingRender;
