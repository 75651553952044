import React from 'react';

import { RouteComponentProps } from 'react-router-dom';
import CreateOrEdit from './components/CreateOrEdit';
import List from './components/List';

export function ConsultationProduct(props: RouteComponentProps<{ consultationProductId?: string }>): JSX.Element {
    const {
        match: {
            params: { consultationProductId },
        },
    } = props;

    if (consultationProductId || consultationProductId === '0') {
        const id = parseInt(consultationProductId);
        return <CreateOrEdit id={id} />;
    }
    return <List />;
}

export default ConsultationProduct;
