import React, { useEffect, useState } from 'react';
import { CalendarItemEvent, Consultant, EventBookingViewModel, ListEventBookings } from 'autogen/swagger/Consultant';
import {
    Button,
    CircularProgress,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Theme,
    Typography,
    useMediaQuery,
} from '@mui/material';
import moment from 'moment';
import { Close, Error } from '@mui/icons-material';
import { formatConsultationProductName } from 'helpers/string';
import { useTypedTranslation } from 'translations';
import { LookupMember } from 'autogen/swagger/Booking';
import EventBookingItem from '../Items/EventBookingItem';

interface IEventTimeBookingsDrawerProps {
    item: CalendarItemEvent;
    consultant: Consultant;
    onEventBookingClicked: (booking: EventBookingViewModel, callback?: () => void) => void;
    onEditEventTimeClicked: (item: CalendarItemEvent) => void;
    onCreateEventBookingClicked: (item: CalendarItemEvent) => void;
    closeDrawer: () => void;
}

export function EventTimeBookingsDrawer(props: IEventTimeBookingsDrawerProps): JSX.Element {
    const {
        item,
        onEventBookingClicked,
        onEditEventTimeClicked,
        onCreateEventBookingClicked,
        closeDrawer,
        consultant,
    } = props;
    const [eventBookings, setEventBookings] = useState<EventBookingViewModel[]>();
    const { t, tf } = useTypedTranslation();
    const [search, setSearch] = useState('');
    const [idSearch, setIdSearch] = useState<string>();
    const [searchingOnServer, setSearchingOnServer] = useState(false);
    const [loadingBookings, setLoadingBookings] = useState(false);

    async function fetchBookings(): Promise<void> {
        if (item?.id) {
            try {
                setLoadingBookings(true);
                const bookings = await ListEventBookings({ id: item.id });
                setEventBookings(bookings);
            } finally {
                setLoadingBookings(false);
            }
        }
    }

    useEffect(() => {
        fetchBookings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    useEffect(() => {
        if (search && /^([0-9]{2}[ ]?){4}$/.test(search)) {
            (async (): Promise<void> => {
                setSearchingOnServer(true);
                try {
                    const number = search.replace(/ /g, '');
                    const result = await LookupMember({ mobileNumber: number });
                    if (result.clubMatasMemberId) {
                        setIdSearch(result.clubMatasMemberId);
                    }
                } catch {
                    setIdSearch(undefined);
                } finally {
                    setSearchingOnServer(false);
                }
            })();
        } else {
            setIdSearch(undefined);
        }
    }, [search]);

    function onEventBookingItemClicked(booking: EventBookingViewModel): void {
        onEventBookingClicked(booking, fetchBookings);
    }

    const shownBookings =
        !search || !eventBookings
            ? eventBookings
            : eventBookings.filter(
                  (x) =>
                      (x.clubMatasMemberId ? x.clubMatasMemberId.indexOf(search) : -1) > -1 ||
                      `${x.firstName} ${x.lastName}`.search(new RegExp(search, 'i')) > -1 ||
                      x.clubMatasMemberId === idSearch,
              );

    const isMobile = !useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

    return (
        <Grid container style={{ padding: '10px' }}>
            <Grid item style={{ width: isMobile ? '300px' : '400px', padding: '0 10px' }}>
                <Grid container direction="column">
                    <Grid item>
                        <Grid container>
                            <Grid item xs>
                                <Grid container direction="column">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {consultant.firstName} {consultant.lastName}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
                                            {moment(item.activeTime.activeFromDate).format('dddd DD/MM HH:mm')}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {formatConsultationProductName(
                                                item.consultationProductName,
                                                item.externalPartnerName,
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item>
                                        <Typography>
                                            {tf(
                                                {
                                                    bookingCount: item.bookingCount,
                                                    maxParticipants: item.maxParticipants,
                                                },
                                                'Calendar',
                                                'Bookings',
                                            )}
                                        </Typography>
                                    </Grid>
                                    {!!item.locationName && (
                                        <Grid item>
                                            <Grid
                                                container
                                                alignItems="center"
                                                title={item.invalidAddress ? t('EventTime', 'InvalidAddress') : ''}
                                                wrap="nowrap"
                                            >
                                                <Grid item>
                                                    <Typography>
                                                        <span style={{ whiteSpace: 'nowrap' }}>
                                                            {item.locationName},
                                                        </span>{' '}
                                                        <span style={{ whiteSpace: 'nowrap' }}>{item.streetName},</span>{' '}
                                                        <span style={{ whiteSpace: 'nowrap' }}>
                                                            {item.zipCode} {item.cityName}
                                                        </span>
                                                    </Typography>
                                                </Grid>
                                                <Grid item>
                                                    {item.invalidAddress && <Error color="error" fontSize="small" />}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs="auto">
                                <Grid container direction="column" spacing={1}>
                                    <Grid item>
                                        <Button
                                            onClick={(): void => onEditEventTimeClicked(item)}
                                            color="primary"
                                            variant="contained"
                                        >
                                            {t('Shared', 'Edit')}
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            onClick={(): void => onCreateEventBookingClicked(item)}
                                            color="primary"
                                            variant="contained"
                                            fullWidth
                                        >
                                            {t('EventBooking', 'CreateBooking')}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <TextField
                            label={t('EventTime', 'SearchBookings')}
                            type="string"
                            fullWidth
                            value={search}
                            variant="outlined"
                            size="small"
                            style={{ marginTop: '10px' }}
                            onChange={(e): void => setSearch(e.target.value)}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item style={{ marginTop: '10px' }}>
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {shownBookings && !searchingOnServer && !loadingBookings ? (
                            shownBookings.length ? (
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">
                                                <Typography variant="h6">{t('EventBooking', 'Name')}</Typography>
                                            </TableCell>
                                            <TableCell align="left">
                                                <Typography variant="h6">{t('EventBooking', 'Amount')}</Typography>
                                            </TableCell>
                                            <TableCell>&nbsp;</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {shownBookings.map((x) => (
                                            <EventBookingItem
                                                key={x.id}
                                                model={x}
                                                onBookingItemClick={onEventBookingItemClicked}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                            ) : (
                                <Grid container justifyContent="center" alignItems="center">
                                    <Typography>{t('EventTime', 'NoResults')}</Typography>
                                </Grid>
                            )
                        ) : (
                            <Grid container justifyContent="center" alignItems="center">
                                <CircularProgress />
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Button onClick={closeDrawer} style={{ minWidth: 'auto' }}>
                    <Close />
                </Button>
            </Grid>
        </Grid>
    );
}

export default EventTimeBookingsDrawer;
