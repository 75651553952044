import { Checkbox, FormControlLabel, Grid, Typography, TextField, Button } from '@mui/material';
import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { useTypedTranslation } from 'translations';
import { Moment } from 'moment';
import DatePicker from '@mui/lab/DatePicker';
import LookupSelectMultipleModal from 'components/Input/Filter/components/LookupSelectMultipleModal';
import RadioButtons from 'components/Input/Filter/components/RadioButtons';
import { objectType, IFilter, valueType } from './types';
import SelectMultipleModal from './components/SelectMultipleModal';

function getFilterValue<T extends objectType>(
    filter: IFilter<T>,
    value: string | string[] | Moment,
): string | number | symbol | boolean | undefined | Moment | string[] {
    switch (filter.type) {
        case 'checkbox':
            return value === 'true';
        case 'select':
            return value as string;
        case 'select-multiple':
            return value as string[];
        case 'lookup-select-multiple':
            return value as string[];
        case 'radio-buttons':
            return value as string;
        case 'text':
            return value as string;
        case 'number': {
            let val = value ? Number(value) : '';
            if (val && filter.maxValue && val > filter.maxValue) val = filter.maxValue;
            return val;
        }
        case 'date':
            return value as Moment;
        default:
            return undefined;
    }
}

interface IFilterInputProps<T extends objectType> {
    filter: IFilter<T>;
    value: valueType;
    handleChange: (filter: IFilter<T>, val: string | string[] | Moment) => void;
}

function FilterInput<T extends objectType>(props: IFilterInputProps<T>): JSX.Element {
    const { filter, handleChange, value } = props;
    const { t, tf } = useTypedTranslation();

    switch (filter.type) {
        case 'checkbox':
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={value as boolean}
                            onChange={(e, checked): void => handleChange(filter, checked ? 'true' : 'false')}
                            name={filter.paramName.toString()}
                            color="primary"
                        />
                    }
                    label={filter.label}
                />
            );
        case 'select': {
            const { options, getText, getValue, label } = filter;
            if (!options || !getText || !getValue) {
                return <Typography>Invalid data</Typography>;
            }
            return (
                <Autocomplete
                    options={options}
                    value={options.find((x: objectType) => getValue(x) === value) || null}
                    onChange={(e, val): void => handleChange(filter, val ? getValue(val) : '')}
                    getOptionLabel={(option): string => (option ? getText(option) : '')}
                    style={{ width: '200px' }}
                    renderInput={(params): JSX.Element => (
                        <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            style={{ backgroundColor: 'white' }}
                            placeholder={tf({ label }, 'Filter', 'SelectOption')}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    )}
                />
            );
        }
        case 'select-multiple': {
            return <SelectMultipleModal filter={filter} value={value} handleChange={handleChange} />;
        }
        case 'lookup-select-multiple': {
            return <LookupSelectMultipleModal filter={filter} value={value} handleChange={handleChange} />;
        }
        case 'radio-buttons': {
            return <RadioButtons filter={filter} value={value} handleChange={handleChange} />;
        }
        case 'text':
        case 'number': {
            const { label, size, maxValue } = filter;
            const inputType = filter.type === 'number' ? 'number' : 'text';

            return (
                <TextField
                    placeholder={label}
                    label={label}
                    type={inputType}
                    fullWidth
                    value={value}
                    variant="outlined"
                    size="small"
                    style={{ backgroundColor: 'white', width: size || '250px' }}
                    onChange={(e): void => {
                        handleChange(filter, e.target.value || '');
                    }}
                    InputLabelProps={{
                        shrink: true,
                        ...(maxValue ? { max: maxValue, min: 0 } : {}),
                    }}
                />
            );
        }
        case 'date': {
            const { label, size } = filter;

            return (
                <DatePicker
                    inputFormat="DD-MM-yyyy"
                    mask="__-__-____"
                    label={label}
                    value={value as Moment}
                    onChange={(date): void => handleChange(filter, date || '')}
                    OpenPickerButtonProps={{
                        'aria-label': t('Shared', 'ChangeDate'),
                    }}
                    renderInput={(params) => (
                        <TextField
                            margin="normal"
                            size="small"
                            style={{ backgroundColor: 'white', width: size || '250px' }}
                            fullWidth
                            {...params}
                        />
                    )}
                />
            );
        }
        default:
            return <Typography>Not implemented</Typography>;
    }
}

interface IFilterProps<T extends objectType> {
    filters: IFilter<T>[];
    filterValues: T;
    onChange: (values: T) => void;
    justify?: 'center' | 'end' | 'flex-end' | 'flex-start' | 'start';
    shownFiltersFromStart?: number;
}

export function Filter<T extends objectType>(props: IFilterProps<T>): JSX.Element {
    const { filters, onChange, justify, filterValues, shownFiltersFromStart = 4 } = props;
    const { t } = useTypedTranslation();

    const [expanded, setExpanded] = useState(() => Object.entries(filterValues).some(([, value]) => !!value));

    const tooManyFilters = filters.length > shownFiltersFromStart;

    function handleFilterChange(filter: IFilter<T>, val: string | string[] | Moment): void {
        const newValues = { ...filterValues, [filter.paramName]: getFilterValue(filter, val) };

        onChange(newValues);
    }

    const shownFilters = !tooManyFilters || expanded ? filters : filters.slice(0, shownFiltersFromStart - 1);
    return (
        <Grid container alignItems="center" justifyContent={justify} gap={2} wrap="nowrap">
            <Grid item xs>
                <Grid container alignItems="center" justifyContent={justify} gap={2}>
                    {shownFilters.map((filter) => (
                        <Grid
                            key={filter.paramName.toString()}
                            item
                            style={
                                // eslint-disable-next-line no-nested-ternary
                                filter.float === 'left'
                                    ? { marginRight: 'auto' }
                                    : filter.float === 'right'
                                    ? { marginLeft: 'auto' }
                                    : {}
                            }
                        >
                            <FilterInput
                                filter={filter}
                                value={filterValues[filter.paramName]}
                                handleChange={handleFilterChange}
                            />
                        </Grid>
                    ))}
                    {shownFilters.some((x) => x.float === 'left') && shownFilters.every((x) => x.float !== 'right') && (
                        <Grid item style={{ marginLeft: 'auto' }} />
                    )}
                </Grid>
            </Grid>
            {tooManyFilters && (
                <Grid item md={2}>
                    <Button color="inherit" onClick={(): void => setExpanded(!expanded)} variant="contained">
                        {t('Shared', expanded ? 'ShowLess' : 'ShowMore')}
                    </Button>
                </Grid>
            )}
        </Grid>
    );
}
