import React from 'react';
import { Grid, Typography } from '@mui/material';

interface ILabledTextProps {
    label: string;
    text: string;
    color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

export function LabledText(props: ILabledTextProps): JSX.Element {
    const { label, text, color } = props;
    return (
        <Grid container item direction="column">
            <Grid item>
                <Typography variant="h6">{label}</Typography>
            </Grid>
            <Grid item>
                <Typography variant="body1" color={color}>
                    {text}
                </Typography>
            </Grid>
        </Grid>
    );
}

export default LabledText;
