import {
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    Typography,
} from '@mui/material';
import React from 'react';
import { CheckBox, CheckBoxOutlineBlank, DoneAll, RemoveDone } from '@mui/icons-material';
import { ConsultantCreateRequest, ConsultantNotificationFlags } from 'autogen/swagger/Consultant';
import { useTypedTranslation } from '../../../translations';
import ButtonLink from '../../../components/button/ButtonLink';

enum NotificationCategory {
    BookingCancelledFromAdmin = 'BookingCancelledFromAdmin',
    BookingCancelledFromWebsite = 'BookingCancelledFromWebsite',
    BookingCreatedFromAdmin = 'BookingCreatedFromAdmin',
    BookingCreatedFromWebsite = 'BookingCreatedFromWebsite',
    BookingUpdated = 'BookingUpdated',
}

type NotificationCategoryType = `${NotificationCategory}`;

enum NotificationFlag {
    None = 'None',
    All = 'All',
    BookingCancelledFromAdminSms = 'BookingCancelledFromAdminSms',
    BookingCancelledFromAdminEmail = 'BookingCancelledFromAdminEmail',
    BookingCancelledFromWebsiteSms = 'BookingCancelledFromWebsiteSms',
    BookingCancelledFromWebsiteEmail = 'BookingCancelledFromWebsiteEmail',
    BookingCreatedFromAdminSms = 'BookingCreatedFromAdminSms',
    BookingCreatedFromAdminEmail = 'BookingCreatedFromAdminEmail',
    BookingCreatedFromWebsiteSms = 'BookingCreatedFromWebsiteSms',
    BookingCreatedFromWebsiteEmail = 'BookingCreatedFromWebsiteEmail',
    BookingUpdatedSms = 'BookingUpdatedSms',
    BookingUpdatedEmail = 'BookingUpdatedEmail',
}

type NotificationFlagType = `${NotificationFlag}`;

interface INotificationSettingsOptionProps {
    label: string;
    name: NotificationCategory;
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
}

interface INotificationSettingsCheckboxProps {
    label: string;
    name: string;
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
}

interface INotificationSettingsEditProps {
    values: ConsultantCreateRequest;
    setValues: React.Dispatch<React.SetStateAction<ConsultantCreateRequest>>;
}

type FlagsToCheckboxesMap = {
    [key in NotificationCategory]: {
        sms: ConsultantNotificationFlags;
        email: ConsultantNotificationFlags;
    };
};

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;

const flagsToCheckboxesMap: FlagsToCheckboxesMap = {
    [NotificationCategory.BookingCancelledFromAdmin]: {
        sms: NotificationFlag.BookingCancelledFromAdminSms,
        email: NotificationFlag.BookingCancelledFromAdminEmail,
    },
    [NotificationCategory.BookingCancelledFromWebsite]: {
        sms: NotificationFlag.BookingCancelledFromWebsiteSms,
        email: NotificationFlag.BookingCancelledFromWebsiteEmail,
    },
    [NotificationCategory.BookingCreatedFromAdmin]: {
        sms: NotificationFlag.BookingCreatedFromAdminSms,
        email: NotificationFlag.BookingCreatedFromAdminEmail,
    },
    [NotificationCategory.BookingCreatedFromWebsite]: {
        sms: NotificationFlag.BookingCreatedFromWebsiteSms,
        email: NotificationFlag.BookingCreatedFromWebsiteEmail,
    },
    [NotificationCategory.BookingUpdated]: {
        sms: NotificationFlag.BookingUpdatedSms,
        email: NotificationFlag.BookingUpdatedEmail,
    },
};

const containsAllPossibleFlags = (listOfFlags: NotificationFlagType[]) => {
    const currentFlags = new Set(listOfFlags);
    const allPossibleFlags = (Object.values(NotificationFlag) as NotificationFlag[])
        .filter((i) => i !== NotificationFlag.None)
        .filter((i) => i !== NotificationFlag.All);
    return allPossibleFlags.every((flag) => currentFlags.has(flag));
};

function NotificationSettingsCheckbox(props: Readonly<INotificationSettingsCheckboxProps>): JSX.Element {
    const { label, name, values, setValues } = props;
    const isChecked = () =>
        values.notificationSettings.includes(name as NotificationFlagType) ||
        values.notificationSettings.includes(NotificationFlag.All);

    const addFlag = (flag: NotificationFlagType, notificationSettings: NotificationFlagType[]) => {
        const newSettings = notificationSettings.filter((i) => i !== NotificationFlag.None);
        const updatedSettings = [...newSettings, flag];
        return containsAllPossibleFlags(updatedSettings) ? [NotificationFlag.All] : updatedSettings;
    };
    const removeFlag = (flag: NotificationFlagType, notificationSettings: NotificationFlagType[]) => {
        if (notificationSettings.includes(NotificationFlag.All)) {
            return (Object.values(NotificationFlag) as NotificationFlag[])
                .filter((i) => i !== NotificationFlag.All)
                .filter((i) => i !== NotificationFlag.None)
                .filter((i) => i !== flag);
        }
        const newSettings = notificationSettings.filter((i) => i !== flag);
        return newSettings.length > 0 ? newSettings : [NotificationFlag.None];
    };
    const toggleFlag = (
        e: React.ChangeEvent<HTMLInputElement>,
        value: boolean,
        notificationSettings: NotificationFlagType[],
    ) =>
        value
            ? addFlag(e.target.name as NotificationFlagType, notificationSettings)
            : removeFlag(e.target.name as NotificationFlagType, notificationSettings);

    return (
        <Grid item>
            <FormControlLabel
                control={
                    <Checkbox
                        icon={uncheckedIcon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 4 }}
                        name={name}
                        checked={isChecked()}
                        onChange={(e, value): void =>
                            setValues({
                                ...values,
                                notificationSettings: toggleFlag(e, value, values.notificationSettings),
                            })
                        }
                    />
                }
                label={label}
            />
        </Grid>
    );
}

function NotificationSettingsOption(props: Readonly<INotificationSettingsOptionProps>): JSX.Element {
    const { label, name, values, setValues } = props;
    const { t } = useTypedTranslation();
    const checkboxesLabels = {
        email: t('Consultant', 'Input', 'NotificationsSettings', 'CheckboxLabelEmail'),
        sms: t('Consultant', 'Input', 'NotificationsSettings', 'CheckboxLabelSms'),
    };
    const checkboxesProps = {
        email: {
            label: checkboxesLabels.email,
            name: flagsToCheckboxesMap[name].email,
        },
        sms: {
            label: checkboxesLabels.sms,
            name: flagsToCheckboxesMap[name].sms,
        },
    };
    const checkboxes = Object.values(checkboxesProps).map((value) => (
        <NotificationSettingsCheckbox
            key={value.name}
            label={value.label}
            name={value.name}
            values={values}
            setValues={setValues}
        />
    ));
    return (
        <>
            <Grid container item xs={12} direction="row">
                <Grid item md={4}>
                    <FormLabel component="legend" style={{ marginBlock: 10 }} focused={false}>
                        {label}
                    </FormLabel>
                </Grid>
                <Grid container item md={2}>
                    <FormGroup>
                        <Grid container item direction="row" spacing={4}>
                            {checkboxes}
                        </Grid>
                    </FormGroup>
                </Grid>
            </Grid>
            <Grid item md={6}>
                <Divider />
            </Grid>
        </>
    );
}

export function NotificationSettingsEdit(props: Readonly<INotificationSettingsEditProps>): JSX.Element {
    const { values, setValues } = props;
    const { t } = useTypedTranslation();
    const notificationOptionsNames: NotificationCategoryType | NotificationCategory[] = [
        NotificationCategory.BookingCancelledFromAdmin,
        NotificationCategory.BookingCancelledFromWebsite,
        NotificationCategory.BookingCreatedFromAdmin,
        NotificationCategory.BookingCreatedFromWebsite,
        NotificationCategory.BookingUpdated,
    ];
    const notificationOptions = notificationOptionsNames.map((type) => (
        <NotificationSettingsOption
            key={type}
            label={t('Consultant', 'Input', 'NotificationsSettingsOptions', type)}
            name={type}
            values={values}
            setValues={setValues}
        />
    ));

    const selectNone = () => {
        setValues({
            ...values,
            notificationSettings: [NotificationFlag.None],
        });
    };

    const selectAll = () => {
        setValues({
            ...values,
            notificationSettings: [NotificationFlag.All],
        });
    };

    const isSelectNoneDisabled = () => values.notificationSettings.includes(NotificationFlag.None);
    const isSelectAllDisabled = () =>
        values.notificationSettings.includes(NotificationFlag.All) ||
        containsAllPossibleFlags(values.notificationSettings);

    return (
        <>
            <Grid container item xs={12}>
                <Grid item xs={6}>
                    <Typography variant="h6">{t('Consultant', 'Input', 'NotificationsSettings', 'Header')}</Typography>
                </Grid>
            </Grid>
            <Grid container item xs={12}>
                <Grid container item xs={6} spacing={5}>
                    <Grid item>
                        <DoneAll
                            color={isSelectAllDisabled() ? 'disabled' : 'primary'}
                            fontSize="small"
                            style={{ verticalAlign: 'text-bottom' }}
                        />
                        <ButtonLink color="primary" onClick={selectAll} disabled={isSelectAllDisabled()}>
                            {t('Shared', 'SelectAll')}
                        </ButtonLink>
                    </Grid>
                    <Grid item>
                        <RemoveDone
                            color={isSelectNoneDisabled() ? 'disabled' : 'primary'}
                            fontSize="small"
                            style={{ verticalAlign: 'text-bottom' }}
                        />
                        <ButtonLink color="primary" onClick={selectNone} disabled={isSelectNoneDisabled()}>
                            {t('Shared', 'DeselectAll')}
                        </ButtonLink>
                    </Grid>
                </Grid>
                <FormControl component="fieldset" fullWidth>
                    {notificationOptions}
                </FormControl>
            </Grid>
        </>
    );
}

export default NotificationSettingsEdit;
