import React, { useState, useEffect } from 'react';
import {
    Typography,
    Grid,
    Button,
    Checkbox,
    FormControlLabel,
    TextField,
    CircularProgress,
    Backdrop,
} from '@mui/material';
import DefaultModal from 'components/modal/DefaultModal';
import { useTypedTranslation } from 'translations';
import { groupBy } from 'helpers/arrayHelper';
import { CheckBoxOutlineBlank, CheckBox, IndeterminateCheckBox } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { useAfterMountEffect } from 'helpers/hooks';

import { IFilter, objectType, valueType } from '../types';

const uncheckedIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" color="primary" />;
const indeterminateIcon = <IndeterminateCheckBox fontSize="small" />;

interface IRenderGroup<T extends objectType> {
    groups: Record<string, objectType[]>;
    selectGroup: (groupName: string, checked: boolean) => void;
    selectValue: (val: string, checked: boolean) => void;
    filter: IFilter<T>;
    selected: string[];
}

function RenderGroups<T extends objectType>({
    groups,
    selectGroup,
    selectValue,
    selected,
    filter,
}: IRenderGroup<T>): JSX.Element {
    const { getText, getValue, getGroup, alwaysShowGroups = false } = filter;

    if (!getValue || !getText) return <Typography>Invalid data</Typography>;

    const showGroups = alwaysShowGroups || (groups && Object.entries(groups).length > 1);

    return (
        <>
            {groups &&
                Object.entries(groups).map(([key, groupValues]) => {
                    const group = getGroup ? getGroup(groupValues[0]) : undefined;

                    const groupValuesWithSelected = groupValues.map((x) => ({
                        item: x,
                        selected: selected.some((y) => y === getValue(x)),
                    }));
                    // eslint-disable-next-line no-nested-ternary
                    const groupSelected = groupValuesWithSelected.every((x) => x.selected)
                        ? 'Selected'
                        : groupValuesWithSelected.every((x) => !x.selected)
                        ? 'NoneSelected'
                        : 'Indeterminate';
                    return (
                        <Grid key={key} item xs={showGroups ? 6 : 12} style={{ marginBottom: '10px' }}>
                            <Grid container direction="column">
                                {showGroups && group && (
                                    <Grid item>
                                        <FormControlLabel
                                            style={{
                                                ...(showGroups ? { margin: 0 } : {}),
                                            }}
                                            label={group.name}
                                            control={
                                                <Checkbox
                                                    icon={
                                                        groupSelected === 'NoneSelected'
                                                            ? uncheckedIcon
                                                            : indeterminateIcon
                                                    }
                                                    checkedIcon={checkedIcon}
                                                    onChange={(e, checked): void => {
                                                        selectGroup(group.id, checked);
                                                    }}
                                                    style={{ marginRight: '8px', padding: '3px' }}
                                                    checked={groupSelected === 'Selected'}
                                                />
                                            }
                                        />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Grid container wrap="wrap">
                                        {groupValuesWithSelected.map((y) => (
                                            <Grid item xs={showGroups ? 12 : 6} key={getValue(y.item)}>
                                                <FormControlLabel
                                                    style={{
                                                        ...(showGroups ? { paddingLeft: '15px', margin: 0 } : {}),
                                                    }}
                                                    label={getText(y.item)}
                                                    control={
                                                        <Checkbox
                                                            icon={uncheckedIcon}
                                                            checkedIcon={checkedIcon}
                                                            style={{
                                                                marginRight: '8px',
                                                                padding: '3px',
                                                            }}
                                                            onChange={(e, checked): void =>
                                                                selectValue(getValue(y.item), checked)
                                                            }
                                                            checked={selected.some((x) => x === getValue(y.item))}
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    );
                })}
        </>
    );
}

interface ILookupSelectMultipleModalProps<T extends objectType> {
    filter: IFilter<T>;
    value: valueType;
    handleChange: (filter: IFilter<T>, val: string[]) => void;
}

export function LookupSelectMultipleModal<T extends objectType>(
    props: ILookupSelectMultipleModalProps<T>,
): JSX.Element {
    const { filter, value, handleChange } = props;
    const { searchOptions, getText, getValue, getGroup, label, lookupSelected } = filter;
    const [open, setOpen] = useState(false);
    const { t, tf } = useTypedTranslation();
    const [selected, setSelected] = useState((value || []) as string[]);
    const [search, setSearch] = useState('');
    const [searchValue] = useDebounce(search, 400);
    const [options, setOptions] = useState<objectType[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<objectType[]>([]);
    const [loadingOptions, setLoadingOptions] = useState(false);

    useEffect(() => {
        const val = (value || []) as string[];

        setSelected(val);
        if (lookupSelected && val.length > 0 && val.length !== selectedOptions.length) {
            (async (): Promise<void> => {
                const lookup = await lookupSelected(val);
                setSelectedOptions(lookup);
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useAfterMountEffect(() => {
        if (!open) {
            handleChange(filter, selected);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        if (searchOptions && searchValue.length >= 2) {
            (async (): Promise<void> => {
                try {
                    setLoadingOptions(true);
                    const lookup = await searchOptions(searchValue);
                    setOptions(lookup);
                } finally {
                    setLoadingOptions(false);
                }
            })();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue]);

    const groups = options && groupBy(options, (item) => (getGroup ? getGroup(item).id : ''));

    const selectedGroups = selectedOptions && groupBy(selectedOptions, (item) => (getGroup ? getGroup(item).id : ''));

    function selectValue(val: string, checked: boolean): void {
        if (!getValue) return;

        const option = checked
            ? options.find((x) => getValue(x) === val)
            : selectedOptions.find((x) => getValue(x) === val);

        if (!option) return;

        let newArray: string[];
        let newSelectedOptionsArray: objectType[];
        if (checked) {
            newArray = [...selected, val];
            newSelectedOptionsArray = [...selectedOptions, option];
        } else {
            newArray = selected.reduce((acc, x) => (x === val ? acc : [...acc, x]), [] as string[]);
            newSelectedOptionsArray = selectedOptions.reduce(
                (acc, x) => (getValue(x) === val ? acc : [...acc, x]),
                [] as objectType[],
            );
        }
        setSelected(newArray);
        setSelectedOptions(newSelectedOptionsArray);
    }

    function selectGroup(groupId: string, checked: boolean): void {
        if (!options || !getValue) return;

        const groupOptions = checked
            ? options.filter((item) => (getGroup ? getGroup(item).id : '') === groupId)
            : selectedOptions.filter((item) => (getGroup ? getGroup(item).id : '') === groupId);
        const groupOptionValues = groupOptions.map((x) => getValue(x));

        let newArray: string[];
        let newSelectedOptionsArray: objectType[];
        if (checked) {
            const newOptionValues = groupOptionValues.filter((itemValue) => !selected.some((y) => itemValue === y));
            const newOptions = groupOptions.filter((x) => newOptionValues.includes(getValue(x)));
            newArray = [...selected, ...newOptionValues];
            newSelectedOptionsArray = [...selectedOptions, ...newOptions];
        } else {
            newArray = selected.reduce(
                (acc, x) => (groupOptionValues.some((y) => x === y) ? acc : [...acc, x]),
                [] as string[],
            );
            newSelectedOptionsArray = selectedOptions.reduce(
                (acc, x) => (groupOptionValues.includes(getValue(x)) ? acc : [...acc, x]),
                [] as objectType[],
            );
        }
        setSelected(newArray);
        setSelectedOptions(newSelectedOptionsArray);
    }

    if (!options || !getText || !getValue || !searchOptions) {
        return <Typography>Invalid data</Typography>;
    }

    return (
        <>
            <Button
                color={selected.length > 0 ? 'info' : 'inherit'}
                variant="contained"
                style={{ paddingTop: '8px', paddingBottom: '8px', marginBottom: '8px', marginTop: '16px' }}
                onClick={(): void => setOpen(true)}
            >
                {tf(
                    { label, count: selected.length },
                    'Filter',
                    selected && selected.length > 0 ? 'Selected' : 'Select',
                )}
            </Button>
            <DefaultModal open={open} onClose={(): void => setOpen(false)} maxWidth="lg">
                <Grid container direction="column" gap={1}>
                    <Grid item>
                        <Grid container justifyContent="center" gap={2}>
                            <Grid item>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    disabled={!selected.length}
                                    onClick={(): void => {
                                        setSelected([]);
                                        setSelectedOptions([]);
                                    }}
                                >
                                    {t('Filter', 'DeselectAll')}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button color="primary" variant="contained" onClick={(): void => setOpen(false)}>
                                    {t('Filter', 'CloseSelectMultiple')}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center">
                            <Typography>{tf({ label }, 'Filter', 'SelectedOptions')}</Typography>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container>
                            {selectedGroups && (
                                <RenderGroups
                                    groups={selectedGroups}
                                    selectGroup={selectGroup}
                                    selectValue={selectValue}
                                    selected={selected}
                                    filter={filter}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item>
                        <Grid container justifyContent="center">
                            <TextField
                                placeholder={tf({ label }, 'Filter', 'SearchType')}
                                label={t('Filter', 'Search')}
                                type="text"
                                fullWidth
                                value={search}
                                variant="outlined"
                                size="small"
                                style={{ backgroundColor: 'white', width: '250px' }}
                                onChange={(e): void => {
                                    setSearch(e.target.value);
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Grid item xs style={{ position: 'relative', paddingBottom: '20px' }}>
                        <Grid container>
                            {groups && (
                                <RenderGroups
                                    groups={groups}
                                    selectGroup={selectGroup}
                                    selectValue={selectValue}
                                    filter={filter}
                                    selected={selected}
                                />
                            )}
                        </Grid>
                        <Backdrop style={{ position: 'absolute', zIndex: 100 }} open={loadingOptions}>
                            <CircularProgress />
                        </Backdrop>
                    </Grid>
                </Grid>
            </DefaultModal>
        </>
    );
}

export default LookupSelectMultipleModal;
