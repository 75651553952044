import { useRef, useEffect, EffectCallback, DependencyList } from 'react';

export function usePrevious<T>(value: T): T | undefined {
    const ref = useRef<T>();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function useInterval(callback: () => void, delay: number): void {
    const savedCallback = useRef<() => void>();

    // Remember the latest callback.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        function tick(): void {
            if (savedCallback.current) savedCallback.current();
        }
        let id: NodeJS.Timeout;
        if (delay !== null && delay > 0) {
            id = setInterval(tick, delay);
        }

        return (): void => {
            if (id) clearInterval(id);
        };
    }, [delay]);
}

export function useAfterMountEffect(func: EffectCallback, deps?: DependencyList): void {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
}
