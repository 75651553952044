import React, { useEffect, useState } from 'react';
import {
    ConsultationProductDetailed,
    CreateConsultationProduct,
    CreateOrUpdateConsultationProductViewModel,
    GetConsultationProduct,
    UpdateConsultationProduct,
    BookingType,
} from 'autogen/swagger/ConsultationProduct';
import { useTypedTranslation } from 'translations';
import { Button, Card, CardContent, Grid, TextField, Typography, CircularProgress, Switch } from '@mui/material';
import { useHistory } from 'react-router-dom';
import { getConsultationProductUrl } from 'shared/urls';
import { useValidationState } from 'helpers/validation';
import { nameof } from 'ts-simple-nameof';
import moment from 'moment';
import ImageChooser from 'components/Input/ImageChooser';
import SpinnerButton from 'components/button/SpinnerButton';
import { connect } from 'react-redux';
import { ApplicationState } from 'store';
import { actionCreators, UserState } from 'store/User';
import StyledRadioButtons from 'components/Input/StyledRadioButtons';
import BonusTextPreview from './BonusTextPreview';
import SelectProductExternalPartner from './SelectProductExternalPartner';
import SelectCategory from './SelectCategory';

const defaultBonusText = `For at give dig den bedste oplevelse anbefaler vi du ankommer 5 min. før din aftalte tid
Henvend dig til en af vores medarbejdere, der vil guide dig til din rådgiver`;

const defaultBonusTextVirtual = `Du vil modtage et link, på sms, til din online aftale 15 minutter inden aftalen starter. Du finder også linket under "Mit Matas".
Vær venlig at være klar et par minutter før din online aftale begynder.
Husk, det er op til dig, om du ønsker at bruge video eller ej.
Vi glæder os til at rådgive dig.`;

interface ICreateOrEditProps extends UserState {
    id?: number;
}

export function CreateOrEdit(props: ICreateOrEditProps): JSX.Element {
    const { id, selectedStoreId, selectableStores, role } = props;
    const { t } = useTypedTranslation();
    const history = useHistory();

    const isVirtualStore = selectableStores.find((x) => x.id === selectedStoreId)?.isVirtual ?? false;

    const [consultationProduct, setConsultationProduct] = useState<ConsultationProductDetailed>();
    const [productValues, setProductValues] = useState<CreateOrUpdateConsultationProductViewModel>({
        name: '',
        consultationCategoryId: 0,
        duration: '00:00:00',
        blocking: '00:00:00',
        description: '',
        summary: '',
        bookingType: 'Single',
        bonusText: defaultBonusText,
        mediumId: -1,
        priceDisclaimer: '',
        inBooth: false,
        isVirtual: isVirtualStore,
    });
    const [, setValidationErrors, isError, getErrorText, getValidationAttributes] = useValidationState(productValues);
    const [showExternalPartner, setShowExternalPartner] = useState(false);
    const [saving, setSaving] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [isCopyLocal, setIsCopyLocal] = useState(false);

    const isEdit = !!id && !isCopyLocal;

    useEffect(() => {
        if (id && !isCopyLocal) {
            (async (): Promise<void> => {
                const result = await GetConsultationProduct({ id });
                setConsultationProduct(result);
                setProductValues({
                    externalPartnerId: result.consultationProductMasterDetailed.externalPartner?.id,
                    name: result.name,
                    consultationCategoryId: result.consultationCategory.id,
                    duration: result.duration,
                    blocking: result.blocking,
                    description: result.description,
                    summary: result.summary,
                    bonusText: result.bonusText ?? '',
                    mediumId: result.medium?.id ?? -1,
                    mobileMediumId: result.mobileMedium?.id,
                    bookingType: result.consultationProductMaster.bookingType,
                    suggestedPrice: result.suggestedPrice,
                    priceDisclaimer: result.priceDisclaimer ?? '',
                    inBooth: result.inBooth,
                    isVirtual: result.consultationProductMaster.isVirtual,
                });
                setShowExternalPartner(!!result.consultationProductMasterDetailed.externalPartner);
            })();
        }
    }, [id, isCopyLocal]);

    useEffect(() => {
        if (!showExternalPartner) {
            setProductValues((prev) => ({ ...prev, externalPartnerId: undefined }));
        }
    }, [showExternalPartner]);

    useEffect(() => {
        if (!isEdit) {
            if (
                !productValues.bonusText ||
                productValues.bonusText === defaultBonusText ||
                productValues.bonusText === defaultBonusTextVirtual
            ) {
                let text = defaultBonusText;
                if (productValues.bookingType === 'Event') text = '';
                else if (productValues.isVirtual) text = defaultBonusTextVirtual;

                setProductValues((prev) => ({
                    ...prev,
                    bonusText: text,
                }));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productValues.bookingType, productValues.isVirtual]);

    async function save(): Promise<void> {
        try {
            setSaving(true);
            let result;
            if (isEdit) {
                result = await UpdateConsultationProduct({ consultationProductMasterId: id }, productValues);
            } else {
                result = await CreateConsultationProduct(productValues);
            }
            setValidationErrors(undefined);
            if (result) {
                history.push(getConsultationProductUrl());
            }
        } catch (ex) {
            const isValidationErrors = await setValidationErrors(ex as Record<string, unknown>);

            if (!isValidationErrors) throw ex;
        } finally {
            setSaving(false);
        }
    }

    function onImageLoading(loading: boolean): void {
        setImageLoading(loading);
    }

    function copyToLocal() {
        setIsCopyLocal(true);
        setConsultationProduct(undefined);
    }

    const isReadOnly =
        isEdit &&
        consultationProduct &&
        role !== 'AdminRole' &&
        !consultationProduct.consultationProductMasterDetailed.localStoreId;

    let saveButtonText = t('ConsultationProduct', 'CreateGlobal');
    if (isEdit) saveButtonText = t('Shared', 'Save');
    else if (isVirtualStore) saveButtonText = t('ConsultationProduct', 'CreateNewVirtual');
    else if (selectedStoreId) saveButtonText = t('ConsultationProduct', 'CreateNewLocal');

    return (
        <Grid item xs style={{ paddingBottom: '20px' }}>
            <Grid container direction="column" justifyContent="flex-start" gap={4}>
                <Grid item>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="h4">
                                {
                                    // eslint-disable-next-line no-nested-ternary
                                    isEdit
                                        ? t('ConsultationProduct', 'EditTile')
                                        : selectedStoreId
                                        ? t('ConsultationProduct', 'CreateTitleLocal')
                                        : t('ConsultationProduct', 'CreateTitleGlobal')
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={6}>
                            <Grid container justifyContent="flex-end">
                                {consultationProduct &&
                                    !consultationProduct.consultationProductMasterDetailed.localStoreId &&
                                    selectedStoreId && (
                                        <Button variant="contained" onClick={copyToLocal}>
                                            {t('ConsultationProduct', 'CopyToLocal')}
                                        </Button>
                                    )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs>
                    <Card>
                        <CardContent>
                            <Grid container direction="column" justifyContent="flex-start" gap={3}>
                                {!isEdit || consultationProduct ? (
                                    <Grid item xs>
                                        <Grid container justifyContent="flex-start" gap={4}>
                                            <Grid item xs={12}>
                                                <Grid container gap={2}>
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        title={
                                                            isEdit
                                                                ? t('ConsultationProduct', 'CantEditExternalPartner')
                                                                : ''
                                                        }
                                                    >
                                                        <StyledRadioButtons
                                                            value={productValues.bookingType}
                                                            headline={t(
                                                                'ConsultationProduct',
                                                                'Input',
                                                                'ConsultationType',
                                                            )}
                                                            onChange={(val): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    bookingType: val as BookingType,
                                                                })
                                                            }
                                                            options={[
                                                                {
                                                                    header: t(
                                                                        'ConsultationProduct',
                                                                        'Input',
                                                                        'BookingTypeSingleHeader',
                                                                    ),
                                                                    subtitle: t(
                                                                        'ConsultationProduct',
                                                                        'Input',
                                                                        'BookingTypeSingleSubtitle',
                                                                    ),
                                                                    value: 'Single',
                                                                    disabled: isEdit || isReadOnly,
                                                                },
                                                                {
                                                                    header: t(
                                                                        'ConsultationProduct',
                                                                        'Input',
                                                                        'BookingTypeEventHeader',
                                                                    ),
                                                                    subtitle: t(
                                                                        'ConsultationProduct',
                                                                        'Input',
                                                                        'BookingTypeEventSubtitle',
                                                                    ),
                                                                    value: 'Event',
                                                                    disabled: isEdit || isReadOnly,
                                                                },
                                                            ]}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12} gap={2}>
                                                <Grid
                                                    item
                                                    xs={4}
                                                    title={
                                                        isEdit
                                                            ? t('ConsultationProduct', 'CantEditExternalPartner')
                                                            : ''
                                                    }
                                                >
                                                    <StyledRadioButtons
                                                        value={showExternalPartner.toString()}
                                                        headline={t('ConsultationProduct', 'Input', 'Type')}
                                                        onChange={(val): void => setShowExternalPartner(val === 'true')}
                                                        options={[
                                                            {
                                                                header: t('ConsultationProduct', 'Input', 'Internal'),
                                                                value: 'false',
                                                                disabled: isEdit || isReadOnly,
                                                            },
                                                            {
                                                                header: t('ConsultationProduct', 'Input', 'External'),
                                                                value: 'true',
                                                                disabled: isEdit || isReadOnly,
                                                            },
                                                        ]}
                                                    />
                                                </Grid>
                                                {productValues.bookingType === 'Single' &&
                                                    (isVirtualStore || !selectedStoreId) && (
                                                        <Grid item xs={2}>
                                                            <Typography>
                                                                {t('ConsultationProduct', 'Input', 'IsVirtual')}
                                                            </Typography>
                                                            <Switch
                                                                checked={productValues?.isVirtual}
                                                                color="primary"
                                                                disabled={isReadOnly || isEdit}
                                                                onChange={(event): void =>
                                                                    setProductValues({
                                                                        ...productValues,
                                                                        isVirtual: event.target.checked,
                                                                    })
                                                                }
                                                            />
                                                        </Grid>
                                                    )}
                                            </Grid>
                                            {showExternalPartner && (
                                                <Grid container item gap={2}>
                                                    <Grid item xs={6}>
                                                        <SelectProductExternalPartner
                                                            consultationProduct={consultationProduct}
                                                            value={productValues.externalPartnerId}
                                                            disabled={isEdit || isReadOnly}
                                                            onChange={(val?: number): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    externalPartnerId: val,
                                                                })
                                                            }
                                                            {...getValidationAttributes(
                                                                nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                    (x) => x.externalPartnerId,
                                                                ),
                                                            )}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <Grid container item gap={2}>
                                                <Grid item xs={4} style={{ height: '59px' }}>
                                                    <SelectCategory
                                                        currentCategory={consultationProduct?.consultationCategory}
                                                        value={productValues.consultationCategoryId}
                                                        disabled={isReadOnly}
                                                        onChange={(val): void =>
                                                            setProductValues({
                                                                ...productValues,
                                                                consultationCategoryId: val ?? 0,
                                                            })
                                                        }
                                                        label={t('ConsultationProduct', 'Category')}
                                                        placeholder={t('ConsultationProduct', 'SelectCategory')}
                                                        {...getValidationAttributes(
                                                            nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                (x) => x.consultationCategoryId,
                                                            ),
                                                        )}
                                                    />
                                                </Grid>
                                                {productValues.bookingType === 'Single' && (
                                                    <Grid item xs={2}>
                                                        <Typography>
                                                            {t('ConsultationProduct', 'Input', 'InBooth')}
                                                        </Typography>
                                                        <Switch
                                                            checked={productValues?.inBooth}
                                                            color="primary"
                                                            disabled={isReadOnly}
                                                            onChange={(event): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    inBooth: event.target.checked,
                                                                })
                                                            }
                                                        />
                                                    </Grid>
                                                )}
                                            </Grid>
                                            <Grid container item gap={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        label={t('ConsultationProduct', 'Input', 'Name')}
                                                        disabled={isReadOnly}
                                                        type="string"
                                                        fullWidth
                                                        value={productValues.name}
                                                        variant="outlined"
                                                        {...getValidationAttributes(
                                                            nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                (x) => x.name,
                                                            ),
                                                            { recommendedCharacters: 50 },
                                                        )}
                                                        onChange={(e): void =>
                                                            setProductValues({ ...productValues, name: e.target.value })
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container item gap={2}>
                                                <Grid item xs={8}>
                                                    <TextField
                                                        multiline
                                                        label={t('ConsultationProduct', 'Input', 'Description')}
                                                        placeholder={t(
                                                            'ConsultationProduct',
                                                            'Input',
                                                            'DescriptionPlaceholder',
                                                        )}
                                                        disabled={isReadOnly}
                                                        type="string"
                                                        fullWidth
                                                        value={productValues.description}
                                                        variant="outlined"
                                                        {...getValidationAttributes(
                                                            nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                (x) => x.description,
                                                            ),
                                                            { maximumCharacters: 500 },
                                                        )}
                                                        onChange={(e): void =>
                                                            setProductValues({
                                                                ...productValues,
                                                                description: e.target.value,
                                                            })
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Grid container item gap={2}>
                                                <Grid item xs={6}>
                                                    <TextField
                                                        multiline
                                                        label={t('ConsultationProduct', 'Input', 'BonusText')}
                                                        disabled={isReadOnly}
                                                        type="string"
                                                        fullWidth
                                                        value={productValues.bonusText}
                                                        variant="outlined"
                                                        {...getValidationAttributes(
                                                            nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                (x) => x.bonusText,
                                                            ),
                                                            { maximumCharacters: 500 },
                                                        )}
                                                        onChange={(e): void =>
                                                            setProductValues({
                                                                ...productValues,
                                                                bonusText: e.target.value,
                                                            })
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <BonusTextPreview bonusText={productValues.bonusText} />
                                                </Grid>
                                            </Grid>
                                            <Grid container item gap={2}>
                                                <Grid item xs={3}>
                                                    <TextField
                                                        label={t('ConsultationProduct', 'Input', 'Duration')}
                                                        disabled={isReadOnly}
                                                        type="number"
                                                        fullWidth
                                                        variant="outlined"
                                                        value={
                                                            productValues.duration
                                                                ? moment.duration(productValues.duration).asMinutes()
                                                                : ''
                                                        }
                                                        {...getValidationAttributes(
                                                            nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                (x) => x.duration,
                                                            ),
                                                        )}
                                                        onChange={(e): void =>
                                                            setProductValues({
                                                                ...productValues,
                                                                duration: e.target.value
                                                                    ? moment
                                                                          .duration(parseInt(e.target.value), 'm')
                                                                          .format('HH:mm:ss', { trim: false })
                                                                    : e.target.value,
                                                            })
                                                        }
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {productValues.bookingType === 'Single' && (
                                                        <TextField
                                                            label={t('ConsultationProduct', 'Input', 'Blocking')}
                                                            disabled={isReadOnly}
                                                            type="number"
                                                            fullWidth
                                                            variant="outlined"
                                                            value={
                                                                productValues.blocking
                                                                    ? moment
                                                                          .duration(productValues.blocking)
                                                                          .asMinutes()
                                                                    : ''
                                                            }
                                                            {...getValidationAttributes(
                                                                nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                    (x) => x.blocking,
                                                                ),
                                                            )}
                                                            onChange={(e): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    blocking: e.target.value
                                                                        ? moment
                                                                              .duration(parseInt(e.target.value), 'm')
                                                                              .format('HH:mm:ss', { trim: false })
                                                                        : e.target.value,
                                                                })
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    )}
                                                </Grid>
                                            </Grid>
                                            {!productValues.isVirtual && (
                                                <Grid container item gap={2}>
                                                    <Grid item xs={3}>
                                                        <TextField
                                                            label={t('ConsultationProduct', 'Input', 'Price')}
                                                            disabled={isReadOnly}
                                                            type="number"
                                                            fullWidth
                                                            variant="outlined"
                                                            value={productValues.suggestedPrice ?? ''}
                                                            {...getValidationAttributes(
                                                                nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                    (x) => x.suggestedPrice,
                                                                ),
                                                            )}
                                                            onChange={(e): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    suggestedPrice: e.target.value
                                                                        ? parseInt(e.target.value)
                                                                        : undefined,
                                                                })
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={5}>
                                                        <TextField
                                                            multiline
                                                            label={t('ConsultationProduct', 'Input', 'PriceDisclaimer')}
                                                            placeholder={t(
                                                                'ConsultationProduct',
                                                                'Input',
                                                                'PriceDisclaimerPlaceholder',
                                                            )}
                                                            disabled={isReadOnly}
                                                            type="string"
                                                            fullWidth
                                                            value={productValues.priceDisclaimer}
                                                            variant="outlined"
                                                            {...getValidationAttributes(
                                                                nameof<CreateOrUpdateConsultationProductViewModel>(
                                                                    (x) => x.priceDisclaimer,
                                                                ),
                                                                { recommendedCharacters: 250 },
                                                            )}
                                                            onChange={(e): void =>
                                                                setProductValues({
                                                                    ...productValues,
                                                                    priceDisclaimer: e.target.value,
                                                                })
                                                            }
                                                            InputLabelProps={{
                                                                shrink: true,
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            )}
                                            <ImageChooser
                                                mediumId={productValues.mediumId}
                                                disabled={isReadOnly}
                                                onMediumIdChange={(mediumId): void =>
                                                    setProductValues((prev) => ({ ...prev, mediumId }))
                                                }
                                                error={isError(
                                                    nameof<CreateOrUpdateConsultationProductViewModel>(
                                                        (x) => x.mediumId,
                                                    ),
                                                )}
                                                helperText={getErrorText(
                                                    nameof<CreateOrUpdateConsultationProductViewModel>(
                                                        (x) => x.mediumId,
                                                    ),
                                                )}
                                                onImageLoading={onImageLoading}
                                                previewStyle="Wide"
                                            />
                                            <ImageChooser
                                                mediumId={productValues.mobileMediumId || productValues.mediumId}
                                                disabled={isReadOnly}
                                                onMediumIdChange={(mobileMediumId): void =>
                                                    setProductValues((prev) => ({ ...prev, mobileMediumId }))
                                                }
                                                error={isError(
                                                    nameof<CreateOrUpdateConsultationProductViewModel>(
                                                        (x) => x.mobileMediumId,
                                                    ),
                                                )}
                                                helperText={getErrorText(
                                                    nameof<CreateOrUpdateConsultationProductViewModel>(
                                                        (x) => x.mobileMediumId,
                                                    ),
                                                )}
                                                onImageLoading={onImageLoading}
                                                previewStyle="Square"
                                            />
                                        </Grid>
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="center" alignItems="center">
                                        <CircularProgress />
                                    </Grid>
                                )}
                                <Grid item>
                                    <Grid container justifyContent="center" gap={2}>
                                        <Grid item>
                                            <Button variant="contained" onClick={(): void => history.goBack()}>
                                                {t('Shared', isReadOnly ? 'Back' : 'Cancel')}
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <SpinnerButton
                                                loading={saving}
                                                variant="contained"
                                                color="primary"
                                                onClick={save}
                                                disabled={imageLoading || isReadOnly}
                                            >
                                                {saveButtonText}
                                            </SpinnerButton>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default connect(
    (state: ApplicationState) => state.userState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(CreateOrEdit);
