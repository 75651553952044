import { Action, Reducer } from 'redux';
import { AlertColor as Severity } from '@mui/material/Alert';
import { AppThunkAction } from '..';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessageState {
    isOpen: boolean;
    content?: string;
    severity?: Severity;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

export interface ShowMessageActionProps {
    content?: string;
    severity?: Severity;
}

interface ShowMessageAction extends ShowMessageActionProps {
    type: 'ShowMessageAction';
}

interface CloseMessageAction {
    type: 'CloseMessageAction';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ShowMessageAction | CloseMessageAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    ShowMessageAction:
        (newState: ShowMessageActionProps): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            dispatch({ type: 'ShowMessageAction', ...newState });
        },
    CloseMessageAction:
        (): AppThunkAction<KnownAction> =>
        (dispatch): void => {
            dispatch({ type: 'CloseMessageAction' });
        },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessageState = { isOpen: false };

export const reducer: Reducer<MessageState> = (
    state: MessageState | undefined,
    incomingAction: Action,
): MessageState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'ShowMessageAction':
            return {
                isOpen: true,
                content: action.content,
                severity: action.severity,
            };
        case 'CloseMessageAction':
            return {
                ...state,
                isOpen: false,
            };
        default:
            break;
    }

    return state;
};
