export function formatConsultationProductName(name: string, externalPartnerName?: string): string {
    return `${name}${externalPartnerName ? ` med ${externalPartnerName}` : ''}`;
}

export const stringToKey = (string: string): string => {
    if (!string) return '';
    // map special characters
    const charsMap: Record<string, string> = {
        æ: 'ae',
        ø: 'oe',
        å: 'aa',
    };
    const mappedString = string.replace(/[æøå]/g, (c) => charsMap[c]);

    // replace everything but letters
    return mappedString.toLowerCase().replace(/[^a-z]/g, '');
};

export function splitName(name?: string): { firstName: string; lastName: string } {
    const names = name ? name.split(' ') : [];

    if (!names.length) {
        return { firstName: '', lastName: '' };
    }

    if (names.length === 1) {
        return { firstName: names[0], lastName: '' };
    }

    return { firstName: names.slice(0, -1).join(' '), lastName: names[names.length - 1] };
}

export function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function joinWithComma(s1: string, s2: string): string {
    if (s1 && s2) return `${s1}, ${s2}`;

    return s1 || s2;
}
