import React, { ComponentType, CSSProperties } from 'react';
import { Snackbar } from '@mui/material';
import Alert, { AlertColor as Severity } from '@mui/material/Alert';
import { connect } from 'react-redux';
import { ApplicationState, GetStore } from 'store';
import { actionCreators, MessageState, ShowMessageActionProps } from 'store/messages/Message';
import { ApiErrorModel } from 'autogen/swagger/Users';
import i18next from 'i18next';

function getAlertStyle(severity: Severity): CSSProperties {
    const css: CSSProperties = {
        fontSize: '16px',
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
    };

    switch (severity) {
        case 'error':
            css.backgroundColor = 'rgb(255, 244, 229)';
            css.color = '#bC1616';
            break;
        default:
            break;
    }
    return css;
}

type IErrorMessageProps = typeof actionCreators & MessageState;

export function ErrorMessage(props: IErrorMessageProps): JSX.Element {
    const { isOpen, content, severity, CloseMessageAction } = props;

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string): void => {
        if (reason === 'clickaway') {
            return;
        }

        CloseMessageAction();
    };

    const alertStyle = getAlertStyle(severity || 'info');

    return (
        <Snackbar
            open={isOpen}
            autoHideDuration={6000}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ marginTop: '50px' }}
        >
            <Alert onClose={handleClose} severity={severity || 'info'} style={alertStyle}>
                {content}
            </Alert>
        </Snackbar>
    );
}

export default connect(
    (state: ApplicationState) => state.messageState, // Selects which state properties are merged into the component's props
    actionCreators, // Selects which action creators are merged into the component's props
)(ErrorMessage as ComponentType);

export function showMessage(props: ShowMessageActionProps): void {
    const store = GetStore();
    actionCreators.ShowMessageAction(props)(store.dispatch, store.getState);
}

export async function showMessageFromErrorModel(errorResult: Promise<ApiErrorModel>): Promise<boolean> {
    const error = (await Promise.resolve(errorResult)) as ApiErrorModel;

    if (error?.type) {
        const { type, title } = error;

        const transKey = `Error.${type}`;
        let text = i18next.t(transKey);
        if (text === transKey) text = title;

        showMessage({ content: text, severity: 'error' });
        return true;
    }
    return false;
}
