import { TransFunction } from 'translations';
import { AuthRole } from 'autogen/swagger/Users';
import {
    ChangeEnableConsultationProductInStore,
    ChangeIsNewsConsultationProductMaster,
    ConsultationProductListModel,
} from 'autogen/swagger/ConsultationProduct';
import { GridColDef } from '@mui/x-data-grid';
import { Chip, Switch, Typography } from '@mui/material';
import ProductRowOptions from 'pages/consultations/ConsultationProduct/components/ProductRowOptions';
import { nameof } from 'ts-simple-nameof';
import React from 'react';
import moment from 'moment/moment';

export function getColumnDefinitions(
    t: TransFunction,
    role: AuthRole,
    products: ConsultationProductListModel[],
    loadProducts: () => void,
): GridColDef[] {
    return [
        {
            field: 'bookingType',
            headerName: t('ConsultationProduct', 'BookingType', 'header'),
            flex: 1,
            sortable: false,
            filterable: false,
            valueGetter: (params): string =>
                t(
                    'ConsultationProduct',
                    'BookingType',
                    params.row?.consultationProductDetailed.consultationProductMasterDetailed.bookingType,
                ),
        },
        {
            field: 'externalPartner',
            headerName: t('ConsultationProduct', 'ExternalPartner'),
            flex: 1,
            sortable: false,
            filterable: false,
            valueGetter: (params): string =>
                params.row?.consultationProductDetailed.consultationProductMasterDetailed.externalPartner?.name,
        },
        {
            field: 'categoryName',
            headerName: t('ConsultationProduct', 'Category'),
            flex: 1,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => params.row?.consultationProductDetailed.consultationCategory.name,
        },
        {
            field: 'name',
            headerName: t('ConsultationProduct', 'Name'),
            flex: 2,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => params.row?.consultationProductDetailed.name,
        },
        {
            field: 'duration',
            headerName: t('ConsultationProduct', 'Duration'),
            flex: 1,
            sortable: false,
            filterable: false,
            valueGetter: (params): string => {
                const minutes = moment
                    .duration(params.row?.consultationProductDetailed.duration)
                    .asMinutes()
                    .toString();
                const blockingMinutes = moment.duration(params.row?.consultationProductDetailed.blocking).asMinutes();
                return `${minutes}${blockingMinutes ? ` + ${blockingMinutes}` : ''}`;
            },
        },
        {
            field: 'inBooth',
            headerName: t('ConsultationProduct', 'Modifier'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const inBooth = params.row?.consultationProductDetailed.inBooth;
                const isVirtual = params.row?.consultationProductDetailed.consultationProductMasterDetailed.isVirtual;

                if (inBooth) return <Chip label={t('ConsultationProduct', 'InBooth')} size="small" color="primary" />;

                if (isVirtual)
                    return <Chip label={t('ConsultationProduct', 'IsVirtual')} size="small" color="success" />;

                return <>{null}</>;
            },
        },
        {
            field: 'isLocal',
            headerName: t('ConsultationProduct', 'Created'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const localStoreId =
                    params.row?.consultationProductDetailed.consultationProductMasterDetailed.localStoreId;

                return (
                    <Typography>
                        {localStoreId ? t('ConsultationProduct', 'Locally') : t('ConsultationProduct', 'Centrally')}
                    </Typography>
                );
            },
        },
        {
            field: 'globalDisableDate',
            headerName: t('Shared', 'Status'),
            flex: 0.75,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const globalDisableDate =
                    params.row?.consultationProductDetailed.consultationProductMasterDetailed.globalDisableDate;

                const text = globalDisableDate ? t('Shared', 'Inactive') : t('Shared', 'Active');
                return <Chip label={text} size="small" color={globalDisableDate ? 'default' : 'primary'} />;
            },
        },
        {
            field: 'id',
            headerName: t('Shared', 'Action'),
            width: 150,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => (
                <ProductRowOptions params={params} role={role} products={products} loadProducts={loadProducts} />
            ),
        },
    ];
}

export function getSingleStoreColumns(t: TransFunction, loadProducts: () => void): GridColDef[] {
    return [
        {
            field: nameof<ConsultationProductListModel>((x) => x.isActiveInCurrentStore),
            headerName: t('ConsultationProduct', 'EnabledInStore'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const isActive = params.value as boolean;
                const { row } = params;
                const { id } = row.consultationProductDetailed.consultationProductMasterDetailed;

                async function changeEnableProductForStore(): Promise<void> {
                    const result = await ChangeEnableConsultationProductInStore({ id, enable: !isActive });
                    if (result.success) {
                        loadProducts();
                    }
                }

                return <Switch checked={isActive} color="primary" onChange={changeEnableProductForStore} />;
            },
        },
    ];
}

export function getAdminColumns(t: TransFunction, onlyLocalStore: boolean, loadProducts: () => void): GridColDef[] {
    const columns: GridColDef[] = [];
    if (onlyLocalStore) {
        columns.push({
            field: 'localStoreId',
            headerName: t('ConsultationProduct', 'StoreId'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const localStoreId =
                    params.row?.consultationProductDetailed.consultationProductMasterDetailed.localStoreId;

                return <Typography>{localStoreId}</Typography>;
            },
        });
    } else {
        columns.push({
            field: nameof<ConsultationProductListModel>((x) => x.isActiveInCurrentStore),
            headerName: t('ConsultationProduct', 'ShowAsNews'),
            flex: 1,
            sortable: false,
            filterable: false,
            renderCell: (params): JSX.Element => {
                const { row } = params;
                const { id } = row.consultationProductDetailed.consultationProductMasterDetailed;
                const { isNews } = row.consultationProductDetailed.consultationProductMasterDetailed;

                async function changeIsNews(): Promise<void> {
                    const result = await ChangeIsNewsConsultationProductMaster({ id, isNews: !isNews });
                    if (result.success) {
                        loadProducts();
                    }
                }

                return <Switch checked={isNews} color="primary" onChange={changeIsNews} />;
            },
        });
    }
    return columns;
}
